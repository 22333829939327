import { Collapse } from 'antd';
import _ from 'lodash';
import MapLibreGL from "maplibre-gl";
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { addAccountData, setAccountData } from '../../redux/account/accountReducer';
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";
import { addTerminal, updateTerminal } from '../../services/terminalServices';
import { isAccountAdmin, isAccountUser } from '../../utils/roleUtils';
import FormTabs from '../Commons/Layouts/FormTabs';
import PrimaryDetails from './PrimaryDetails';
import { addTerminalData, setTerminalData } from '../../redux/terminal/terminalReducer';
import { ConsoleSqlOutlined } from '@ant-design/icons';

function TerminalForm({
  isFormEdit = false,
  selectedTerminal = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
  openOrCloseButtonRef
}) {
  const apiKey = "f06e3dc5296b40c3b600e0fc4aeae197"; // temp
  const mapContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    formState,
  } = useForm({});
  const { dirtyFields, errors, isDirty } = formState;
  const userType = useSelector((state) => state.auth.user.userType);
  const coordinatesState = useSelector((state) => state.global.coordinates);
  const accountId = useSelector((state) => state.auth.user.accountId);

  const provinceStateRef = useRef("null");
  const isInitialRender = useRef(true);

  // form values
  setValue('terminalId', 0);
  setValue('accountId', accountId);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (isFormHidden) {
      setValue("phone", "")
    }
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("accountName");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  useEffect(() => {
    loadMap();
  }, [coordinatesState])

  const loadMap = async () => {
    if (mapContainerRef.current !== null) {
      MapLibreGL.accessToken = apiKey;
      const map = new MapLibreGL.Map({
        container: mapContainerRef.current,
        style: `https://maps.geoapify.com/v1/styles/klokantech-basic/style.json?apiKey=${apiKey}`,
        center: [coordinatesState?.origin?.longitude, coordinatesState?.origin?.latitude],
        zoom: 15,
      });

      new MapLibreGL.Marker()
        .setLngLat([
          coordinatesState?.origin?.longitude,
          coordinatesState?.origin?.latitude,
        ])
        .addTo(map);

      // Add navigation control (optional)
      map.addControl(new MapLibreGL.NavigationControl());

      // Clean up the map instance when the component unmounts
      return () => map.remove();
    }
  };

  const onSubmit = async (payload) => {
    let response = null;

    _.set(payload, 'provinceState', payload.provinceState.value || payload.provinceState);
    _.set(payload, 'country', payload.country.value || payload.country);

    if (isFormEdit) {
      _.set(payload, 'accountId', selectedTerminal.accountId);
      _.set(payload, 'terminalId', selectedTerminal.terminalId);

      response = await updateTerminal(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } terminal.`,
        })
      );
    } else {

      response = await addTerminal(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } terminal.`,
        })
      );
    }

    if (response.ok) {
      const terminalData = await response.json();

      dispatch(isFormEdit ? setTerminalData(terminalData) : addTerminalData(terminalData));

      toggleFormDisplay();
      reset();
      focusOnOpenOrCloseButton()

      provinceStateRef.current.clearValue();

      if (isDirty) {
        setModal(false);
      }

      setIsFormDirty(false);
      dispatch(setFormPendingCompletion(false));
    }
  }

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-7-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedTerminal={selectedTerminal}
              errors={errors}
              register={register}
              setValue={setValue}
              reset={reset}
              provinceStateRef={provinceStateRef}
            />
          </div>
        </div>
      ),
    },
  ];

  const items = [
    {
      key: '1',
      label: 'Maps',
      forceRender: true,
      children:
        <>
          <div className="row d-flex justify-content-center">
            <div
              ref={(element) => (mapContainerRef.current = element)}
              id="my-map"
              className="col-12"
              style={{
                height: "700px",
                width: "100%",
              }}
            />
          </div>
        </>
    }
  ];

  return (
    <>
      <div className="collapsible-map-container">
        <Collapse items={items} />
      </div>
      <form id="account-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs
          items={tabItems}
          tabClassName="color-7"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>


    </>
  )
}

export default TerminalForm
