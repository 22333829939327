import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Empty, Space } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

function NoData({
  color,
  content,
}) {

  return (
    <div className={`d-flex justify-content-center mt-5`}>
      <Empty
        image="/images/empty.png"
        imageStyle={{
          height: 150,
        }}
        description={
          <>
            <p className={`title ${color} mb-1`}>
              No Data Available
            </p>
            <p className='content'>
              There are no {content} data.
            </p>
          </>
        }
      >
      </Empty>
    </div>
  )
}

export default NoData