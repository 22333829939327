export const fetchSuggestions = async (userInput) => {
  // Replace 'YOUR_API_KEY' with your actual Maptiler API key
  const apiKey = 'f06e3dc5296b40c3b600e0fc4aeae197';
  const baseUrl = 'https://api.geoapify.com/v1/geocode/';
  const biasLocation = 'proximity:-97.1385589,49.8953934'

  // Build the URL with user input and API key
  const url = new URL("autocomplete", baseUrl);
  url.searchParams.set('text', userInput);
  url.searchParams.set('bias', biasLocation);
  url.searchParams.set('apiKey', apiKey); // Add API key to query string

  try {
    const response = await fetch(url.toString(), {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`API request failed with status: ${response.status}`);
    }

    const data = await response.json();
    
    // Process the data to extract relevant information for suggestions
    // This assumes 'features' contains suggestions and 'label' has the suggestion text
    return data.features;
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    // Handle errors gracefully, like showing an error message to the user
    return []; // Return empty array to avoid issues with rendering suggestions
  }
}