import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { getTotalCustomersByMonth } from '../../../utils/dashboardUtils';
import { THEME_COLORS, CHART_CONSTANTS } from '../../../constants';

function MonthlyOrders({ customers }) {
  // Months are zero-indexed, so January starts with 0
  const data = [
    {
      name: 'Jan',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Feb',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Mar',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Apr',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'May',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Jun',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Jul',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Aug',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Sep',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Oct',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Nov',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
    {
      name: 'Dec',
      LTL: Math.floor(Math.random() * 30),
      FTL: Math.floor(Math.random() * 30),
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={290}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" style={{ fontSize: 12 }} />
        <YAxis style={{ fontSize: 12 }} allowDecimals={false} axisLine={false} />
        <Tooltip cursor={{ fill: "transparent" }} />
        <Legend
          payload={[
            { value: 'LTL', type: 'rect', color: THEME_COLORS.COLOR_1 },
            { value: 'FTL', type: 'rect', color: THEME_COLORS.COLOR_4 },
          ]}
          wrapperStyle={{ fontSize: CHART_CONSTANTS.LEGEND_FONT_SIZE, lineHeight: CHART_CONSTANTS.LEGEND_LINE_HEIGHT }}
          formatter={(value, entry, index) => <span style={{color:CHART_CONSTANTS.LEGEND_TEXT_COLOR}}>{value}</span>}
        />

        <Bar dataKey="LTL" className='ltl-color' />
        <Bar dataKey="FTL" className="ftl-color" />

      </BarChart>
    </ResponsiveContainer>
  )
}

export default MonthlyOrders
