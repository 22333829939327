import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import QuoteTaxableEditor from '../Templates/Editor/QuoteTaxableEditor';
import { useLocation, useNavigate } from 'react-router-dom';
import InvoiceDetails from './InvoiceDetails';
import InvoiceDetailFooter from './InvoiceDetailFooter';
import QuoteTaxableRenderer from '../Templates/CellRenderers/QuoteTaxableRenderer';
import InvoiceContentHeader from '../Commons/Layouts/InvoiceContentHeader'
import { displayProvince } from '../../utils/provinceUtils';
import { fetchItemList, fetchUpdateQuoteItem, fetchUpdateQuoteStatus, fetchUpdateQuoteNote } from '../../services/quoteServices'
import { fetchInvoiceData, fetchTableData } from '../../services/invoiceServices';
import { currencyRendererSelector } from '../../utils/quoteUtils';
import { isSuperAdmin, isNTMSUSer } from '../../utils/roleUtils';
import { getInvoiceBalance } from '../../utils/paymentUtils';
import { formatTaxRate } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { createInvoiceItemsReportFile, emailInvoiceItemsReport } from '../../services/reportServices';
import { printPreviewReportWithBase64 } from '../../utils/payrollRunUtils';
import { useForm } from 'react-hook-form';
import { setNotificationData } from '../../redux/global/globalReducer';

function EditInvoicePage(props, args) {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const navigate = useNavigate();
  const userType = useSelector((state) => state.auth.user.userType);
  const { state } = useLocation()
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [invoiceData, setInvoiceData] = useState({})
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [estimateNo, setEstimateNo] = useState("");
  const [estimateName, setEstimateName] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [tax, setTax] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [province, setProvince] = useState("");
  const [status, setStatus] = useState(0);
  const [note, setNote] = useState("");
  const [balance, setBalance] = useState(0);
  const [accountId, setAccountId] = useState("")
  const [customerId, setCustomerId] = useState("");
  const [rowData, setRowData] = useState()
  const [inputRow, setInputRow] = useState({ 'Action': 'action', 'Item': 'Add item:' });
  const [itemList, setItemList] = useState([])
  const [editable, setEditable] = useState(false)
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const [oldValues, setOldValues] = useState({
    subtotal: 0,
    discount: 0,
    total: 0,
    tax: 0,
  })
  const [printMode, setPrintMode] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const taxRef = useRef()
  const currentProvince = useRef()
  const currentInputRow = useRef()

  //Loads data on render
  useEffect(() => {
    fillInvoiceData()
    fillTableData()
  }, [])

  // Fetch after getting the accountId
  useEffect(() => {
    if (accountId !== "") {
      fillItemList();
    }

  }, [accountId])

  useEffect(() => {
    currentInputRow.current = inputRow
    if (gridApi != null) {
      gridApi.refreshCells({ columns: ['Action'] })
    }
  }, [inputRow])

  //Fetch for the customer data
  const fillInvoiceData = async () => {
    const data = await fetchInvoiceData(state)
    setInvoiceData(data)
    setBalance(getInvoiceBalance(data));
    setName(data.customer.customerName)
    setEmail(data.customer.defaultEmail)
    setPhone(data.customer.phone)
    setAddress(data.customer.addressLine1 + ", " + data.customer.city + ", " + displayProvince(data.customer.provinceState))
    setEstimateNo(state.invoiceId)
    setEstimateName(data.quoteName)
    setEstimateDate(data.invoiceDate)
    setTax(data.totalTax)
    setSubtotal(data.subtotal)
    setTotal(data.totalInvoice)
    setDiscount(data.discount)
    setNote(data.notes)
    setAccountId(data.accountId)
    setCustomerId(data.customerId)
    setStatus(data.status)
    setOldValues({
      subtotal: parseFloat(data.subtotal),
      discount: parseFloat(data.discount),
      total: parseFloat(data.totalInvoice),
      tax: parseFloat(data.totalTax)
    })

    if (parseInt(data.status) !== 0 || (isSuperAdmin(userType) || isNTMSUSer(userType))) {
      setEditable(false);
      setColumnDefs(columnDefinitions(false))
    }

    setProvince(data.customer.provinceState)
    currentProvince.current = data.customer.provinceState
  }

  //Fetch for the table data
  const fillTableData = async () => {
    const data = await fetchTableData(state)
    setRowData(data.map((currentData, index) => ({
      Item: currentData.invoiceItemId,
      // index: index + 1,
      Description: currentData.description,
      QTY: currentData.quantity,
      'Unit Price': parseFloat(currentData.rate),
      'Tax Type': currentData.taxType,
      'Tax Rate': currentData.taxRate,
      Discount: parseFloat(currentData.discount),
      Subtotal: parseFloat(currentData.subtotal),
      "Total Tax": parseFloat(currentData.totalTax),
      Total: parseFloat(currentData.total)
    })))
  }

  const fillItemList = async () => {
    const data = await fetchItemList(accountId)
    const labour = data.labour
    const material = data.productMaterialItem
    const expense = data.expenseItem
    var items = {}
    items = [...labour, ...material, ...expense]
    setItemList(items)
  }

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onCellEditingStopped = useCallback(
    async (params) => {
      // Updates field in database when updating a cell
      if (params.node.rowPinned !== 'top') {
        const selectedNode = gridRef.current.api.getSelectedNodes();
        const subtotal = selectedNode[0].data["QTY"] * selectedNode[0].data["Unit Price"];
        const total = (selectedNode[0].data["QTY"] * selectedNode[0].data["Unit Price"]) - selectedNode[0].data["Discount"];
        const totalTax = ((selectedNode[0].data["QTY"] * selectedNode[0].data["Unit Price"]) - selectedNode[0].data["Discount"]) * selectedNode[0].data["Tax Rate"];

        let response = null;
        //sets the data using the columns of the field you're editing
        const payload = {
          description: selectedNode[0].data["Description"],
          quantity: selectedNode[0].data["QTY"],
          discount: selectedNode[0].data["Discount"],
          rate: selectedNode[0].data["Unit Price"],
          taxType: parseInt(selectedNode[0].data["Tax Type"]),
          taxRate: selectedNode[0].data["Tax Rate"],
          total: total > 0 ? total : 0,
          subTotal: subtotal,
          totalTax: totalTax > 0 ? totalTax : 0,
        }

        response = await fetchUpdateQuoteItem(payload, selectedNode[0].data["Item"]);
        if (response.ok) {
          fillTableData()
          fillInvoiceData()
        }

        dispatch(
          setNotificationData({
            type: `${response.ok ? "success" : "error"}`,
            message: `${response.ok ? "Success!" : "Error!"}`,
            description: `${response.ok ? "Successfully saved" : "Failed to save"
              } quote status.`,
          })
        );
      }
    },
  );

  //Resizes the columns to fit the whole grid
  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
    setGridApi(params.api);
  }, []);

  const columnDefinitions = (isEditable, isPrintableLayout) => {
    const defs = [
      {
        field: 'Description',
        cellClass: 'grid-column',
        minWidth: 100,
        editable: isEditable,
        maxWidth: isPrintableLayout ? 120 : null
      },
      {
        field: 'QTY',
        cellClass: 'grid-column gray',
        minWidth: 60,
        width: 60,
        editable: isEditable,
        maxWidth: isPrintableLayout ? 60 : null
      },
      {
        field: 'Unit Price',
        cellClass: 'grid-column gray',
        minWidth: 110,
        editable: isEditable,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null
      },
      {
        field: 'Tax Type',
        cellClass: 'grid-column gray',
        headerName: 'Tax Type',
        minWidth: 110,
        singleClickEdit: true,
        cellRendererSelector: (params) => {
          if (params.data['Tax Type'] !== undefined) {
            return {
              component: QuoteTaxableRenderer
            }
          } else {
            return undefined;
          }
        }
        ,
        cellEditor: QuoteTaxableEditor,
        cellEditorParams: {
          taxList: () => taxRef.current,
          province: () => currentProvince.current,
          gridRef: gridRef,
          setInputRow: setInputRow,
          inputRow: () => currentInputRow.current,
        },
        editable: isEditable,
        autoHeight: true,
        maxWidth: isPrintableLayout ? 110 : null
      },
      {
        field: 'Tax Rate',
        cellClass: 'grid-column gray',
        headerName: 'Tax Rate',
        valueGetter: params => formatTaxRate(params.data["Tax Rate"]),
        autoHeight: true,
        minWidth: 110,
        maxWidth: isPrintableLayout ? 110 : null
      },
      {
        field: 'Discount',
        cellClass: 'grid-column gray',
        editable: isEditable,
        minWidth: 110,
        // cellEditor: NumericEditor,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null
      },
      {
        field: 'Subtotal',
        cellClass: 'grid-column gray',
        minWidth: 110,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null
      },

      {
        field: 'Total',
        cellClass: 'grid-column gray',
        minWidth: 110,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null
      },
      {
        field: 'Total Tax',
        cellClass: 'grid-column gray',
        minWidth: 110,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null
      },
    ]

    return defs
  }

  const [columnDefs, setColumnDefs] = useState(columnDefinitions(true))

  useEffect(() => {
    if (gridRef.current.columnApi !== undefined) {
      gridRef.current.columnApi.setColumnVisible("Action", editable)
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [columnDefs])

  const updateQuoteStatus = async (value) => {
    let response = null;
    const payload = {
      status: parseInt(value),
    }
    response = await fetchUpdateQuoteStatus(payload, state.invoiceId);
    if (response.ok) {
      setStatus(value)
      fillInvoiceData()
      fillTableData()
    }

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } invoice status.`,
      })
    );
  }

  const updateNoteStatus = async (event) => {
    var value = event.target.value
    let response = null
    const payload = {
      notes: value,
    }
    response = await fetchUpdateQuoteNote(payload, state.invoiceId);
    if (response.ok) {
      setStatus(value)
      fillInvoiceData()
      fillTableData()
    }

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } invoice note.`,
      })
    );
  }

  const getRowStyle = useCallback((params) => {
    if (params.node.rowPinned) {
      return { fontWeight: 'bold' };
    }
  }, []);

  const sendInvoiceItemsReport = async (payload) => {
    setIsLoading(true);

    const emailPayload = {
      invoiceId: state.invoiceId,
      toEmail: payload.emailAddress,
      subject: 'Invoice Items Report',
      body: 'Report attached here',
      attachmentFileName: 'Invoice Items Report'
    };

    const response = await emailInvoiceItemsReport(emailPayload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {

      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    setIsLoading(false);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully emailed" : "Failed to email"
          } invoice items report.`,
      })
    );
  }

  const printInvoiceItems = async () => {
    const payload = {
      invoiceId: state.invoiceId,
    };

    const response = await createInvoiceItemsReportFile(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    printPreviewReportWithBase64(data.base64);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const takePayment = () => {
    navigate("/payments/invoice-payments", { state: { invoiceId: estimateNo } });
  }

  return (
    <div>
      <div className='non-printable'>
        <div className='me-auto'>
          <InvoiceContentHeader
            title={editable ? "Edit Invoice" : "Invoice"}
            updateQuoteStatus={(e) => updateQuoteStatus(e)}
            status={status} fillQuoteData={() => fillInvoiceData()}
            editable={editable}
            takePayment={takePayment}
            balance={balance}
          />
        </div>
      </div>
      <div className='row non-printable mb-2 d-md-none'>
        {balance > 0 && (
          <div className='col-6'>
            <button className="btn btn-primary w-100" onClick={takePayment}>Take Payment</button>
          </div>
        )}
      </div>
      <div id='printableContent'>
        <InvoiceDetails
          name={name}
          email={email}
          phone={phone}
          address={address}
          estimateNo={estimateNo}
          estimateName={estimateName}
          estimateDate={estimateDate}
          subtotal={subtotal}
          discount={discount}
          total={total}
          balance={balance}
          tax={tax}
          printMode={printMode}
          balanceCheck={balance > 0}
          takePayment={takePayment}
          printInvoiceItems={printInvoiceItems}
          setIsOpen={setIsOpen}
        />
        <div className='row content-body-container quote-body-container'>
          <div className='col-12'>
            <div id='quoteGrid' className='ag-theme-alpine content-section-container quote-container color-4-grid color-4-section p-0 shadow-none'>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                onCellEditingStopped={onCellEditingStopped}
                rowSelection={'single'}
                getRowStyle={getRowStyle}
                rowHeight={70}
              >
              </AgGridReact>
            </div>
          </div>
        </div>
        <div className='row content-body-container quote-footer-container'>
          <div className='col-12'>
            <div className='content-section-bottom'>
              <InvoiceDetailFooter
                updateNoteStatus={updateNoteStatus}
                note={note}
                editable={editable}
                subtotal={subtotal}
                discount={discount}
                total={total}
                tax={tax}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} {...args}>
        <ModalHeader toggle={() => setIsOpen(false)}>Send Email</ModalHeader>
        <ModalBody>
          <form className="row form-container" id="send-email-form" onSubmit={handleSubmit(sendInvoiceItemsReport)}>
            <div className="col-12 my-3">
              <label htmlFor="emailAddress" className="form-label">Email Address</label>
              <input type="text" className="form-control" id="emailAddress"
                defaultValue={email}
                {...register("emailAddress", { required: "Email Address is required" })}
              />
              <small className='form-error-message'>
                {errors?.emailAddress && errors.emailAddress.message}
              </small>
            </div>

            <div className='d-flex flex-row-reverse align-items-center mt-3'>
              <button className="btn btn-primary ms-2" form='send-email-form' type="submit" disabled={isLoading}>Send</button>
              <button className="btn btn-primary" disabled={isLoading} type='button' onClick={() => setIsOpen(false)}>Cancel</button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditInvoicePage