import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ENGINE_TYPES,
  FUEL_TYPES,
  OWNERSHIP_STATUS_TYPES,
  TRANSMISSION_TYPES,
  VEHICLE_CLASSES,
  VEHICLE_TYPES,
} from "../../constants";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { NumericFormat } from 'react-number-format';
import { DatePicker } from 'antd';
import dayjs from "dayjs";
function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedTruck = {},
  control,
  register,
  errors,
  setValue,
  reset,
  provinceStateRef
}) {

  const [ownershipStatus, setOwnershipStatus] = useState(1);
  const [vehicleType, setVehicleType] = useState(1);
  const [vehicleClass, setVehicleClass] = useState(8);
  const [engineType, setEngineType] = useState(1);
  const [transmissionType, setTransmissionType] = useState(1);

  useEffect(() => {
    if (isFormEdit) {
      setOwnershipStatus(selectedTruck["ownershipStatus"]);
      setVehicleType(selectedTruck["vehicleType"]);
      setVehicleClass(selectedTruck["vehicleClass"]);
      setEngineType(selectedTruck["engineType"]);
      setTransmissionType(selectedTruck["transmissionType"]);

      setValue("ownershipStatus", selectedTruck["ownershipStatus"]);
      setValue("vehicleType", selectedTruck["vehicleType"]);
      setValue("vehicleClass", selectedTruck["vehicleClass"]);
      setValue("engineType", selectedTruck["engineType"]);
      setValue("transmissionType", selectedTruck["transmissionType"]);
      setValue("year", dayjs(selectedTruck["year"]));
      setValue(
        "truckFinancials.purchaseDate",
        dayjs(selectedTruck.truckFinancials["purchaseDate"])
      );
      setValue("engineSpec", selectedTruck["engineSpec"]);
      setValue("vehicleFeatures", selectedTruck["vehicleFeatures"]);
      setValue(
        "truckFinancials.leaseLoanDetails",
        selectedTruck.truckFinancials["leaseLoanDetails"]
      );
      setValue('axles', selectedTruck["axles"]);
      setValue('fuelCapacity', selectedTruck["fuelCapacity"]);
      setValue('displacement', selectedTruck["displacement"]);
      setValue('horsepower', selectedTruck["horsepower"]);
      setValue('torque', selectedTruck["torque"]);
      setValue('truckLength', selectedTruck["truckLength"]);
      setValue('truckWidth', selectedTruck["truckWidth"]);
      setValue('truckHeight', selectedTruck["truckHeight"]);
      setValue('gvw', selectedTruck["gvw"]);
      setValue('tareWeight', selectedTruck["tareWeight"]);
      setValue('payloadCapacity', selectedTruck["payloadCapacity"]);
    } else {
      setOwnershipStatus(1);
      setVehicleType(1);
      setVehicleClass(8);
      setEngineType(1);
      setTransmissionType(1);
      reset();
    }
  }, [selectedTruck["truckId"]]);

  return (
    <>
      <div className="col-12 form-section-title">Truck Details</div>
      <div className="col-12">
        <hr className="mt-1"></hr>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="vin" className="form-label">
          Vehicle Identification Number <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="vin"
          tabIndex={1}
          defaultValue={isFormEdit ? selectedTruck?.vin || "" : ""}
          autoComplete="on"
          {...register("vin", {
            required: "Vehicle Identification Number is required",
          })}
        />
        <small className="form-error-message">
          {errors?.vin && errors.vin.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="make" className="form-label">
          Make <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="make"
          tabIndex={2}
          defaultValue={isFormEdit ? selectedTruck?.make || "" : ""}
          autoComplete="on"
          {...register("make", { required: "Make is required" })}
        />
        <small className="form-error-message">
          {errors?.make && errors.make.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="model" className="form-label">
          Model <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="model"
          tabIndex={3}
          defaultValue={isFormEdit ? selectedTruck?.model || "" : ""}
          autoComplete="on"
          {...register("model", { required: "Model is required" })}
        />
        <small className="form-error-message">
          {errors?.model && errors.model.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="year" className="form-label">
          Year <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="year"
          register={register}
          rules={{ required: "Year is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} picker="year" />
          )}
        />
        <small className="form-error-message">
          {errors?.year && errors.year.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="ownershipStatus" className="form-label">
          Ownership Status <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={ownershipStatus}
          tabIndex={5}
          id="ownershipStatus"
          {...register("ownershipStatus", {
            valueAsNumber: true,
            onChange: (e) => setOwnershipStatus(e.target.value),
            required: "Ownership Status is required",
          })}
        >
          <option key="none" value="" hidden>
            Ownership Status
          </option>
          {OWNERSHIP_STATUS_TYPES.map((ownershipStatus, index) => (
            <option key={index} value={ownershipStatus.value}>
              {ownershipStatus.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.ownershipStatus && errors.ownershipStatus.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="vehicleType" className="form-label">
          Vehicle Type <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={vehicleType}
          tabIndex={6}
          id="vehicleType"
          {...register("vehicleType", {
            valueAsNumber: true,
            onChange: (e) => setVehicleType(e.target.value),
            required: "Vehicle Type is required",
          })}
        >
          <option key="none" value="" hidden>
            Vehicle Type
          </option>
          {VEHICLE_TYPES.map((vehicleType, index) => (
            <option key={index} value={vehicleType.value}>
              {vehicleType.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.vehicleType && errors.vehicleType.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="vehicleClass" className="form-label">
          Vehicle Class <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={vehicleClass}
          tabIndex={7}
          id="vehicleClass"
          {...register("vehicleClass", {
            valueAsNumber: true,
            onChange: (e) => setVehicleClass(e.target.value),
            required: "Vehicle Class is required",
          })}
        >
          <option key="none" value="" hidden>
            Vehicle Class
          </option>
          {VEHICLE_CLASSES.map((vehicleClass, index) => (
            <option key={index} value={vehicleClass.value}>
              {vehicleClass.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.vehicleClass && errors.vehicleClass.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="transmissionType" className="form-label">
          Transmission Type
        </label>
        <select
          className="form-select"
          value={transmissionType}
          tabIndex={8}
          id="transmissionType"
          {...register("transmissionType", {
            valueAsNumber: true,
            onChange: (e) => setTransmissionType(e.target.value),
          })}
        >
          <option key="none" value="" hidden>
            Transmission Type
          </option>
          {TRANSMISSION_TYPES.map((transmissionType, index) => (
            <option key={index} value={transmissionType.value}>
              {transmissionType.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.transmissionType && errors.transmissionType.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="engineType" className="form-label">
          Engine Type <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={engineType}
          tabIndex={9}
          id="engineType"
          {...register("engineType", {
            valueAsNumber: true,
            onChange: (e) => setEngineType(e.target.value),
            required: "Engine Type is required",
          })}
        >
          <option key="none" value="" hidden>
            Engine Type
          </option>
          {ENGINE_TYPES.map((engineType, index) => (
            <option key={index} value={engineType.value}>
              {engineType.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.engineType && errors.engineType.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="axles" className="form-label">Axles <span className="required-asterisk">*</span> </label>
        <Controller
          control={control}
          name='axles'
          register={register}
          rules={{ required: "Axles is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="axles"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.axles && errors.axles.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="fuelCapacity" className="form-label">Fuel Capacity</label>
        <Controller
          control={control}
          name='fuelCapacity'
          register={register}
          // rules={{ required: "Quantity is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="fuelCapacity"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.paymentAmount && errors.paymentAmount.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="displacement" className="form-label">Displacement</label>
        <Controller
          control={control}
          name='displacement'
          register={register}
          // rules={{ required: "Quantity is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="displacement"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.paymentAmount && errors.paymentAmount.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="horsepower" className="form-label">Horsepower</label>
        <Controller
          control={control}
          name='horsepower'
          register={register}
          // rules={{ required: "Quantity is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="horsepower"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.paymentAmount && errors.paymentAmount.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="torque" className="form-label">Torque</label>
        <Controller
          control={control}
          name='torque'
          register={register}
          // rules={{ required: "Quantity is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="torque"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.paymentAmount && errors.paymentAmount.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="truckLength" className="form-label">Truck Length <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='truckLength'
          register={register}
          rules={{ required: "Truck Length is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="truckLength"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.truckLength && errors.truckLength.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="truckWidth" className="form-label">Truck Width <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='truckWidth'
          register={register}
          rules={{ required: "Truck Width is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="truckWidth"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.truckWidth && errors.truckWidth.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="truckHeight" className="form-label">Truck Height <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='truckHeight'
          register={register}
          rules={{ required: "Truck Height is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="truckHeight"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.truckHeight && errors.truckHeight.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="gvw" className="form-label">GVW <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='gvw'
          register={register}
          rules={{ required: "GVW is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="gvw"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.gvw && errors.gvw.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="tareWeight" className="form-label">Tare Weight <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='tareWeight'
          register={register}
          rules={{ required: "Tare Weight is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="tareWeight"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.tareWeight && errors.tareWeight.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="payloadCapacity" className="form-label">Payload Capacity <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='payloadCapacity'
          register={register}
          rules={{ required: "Payload Capacity is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              id="payloadCapacity"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.payloadCapacity && errors.payloadCapacity.message}
        </small>
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="engineSpec" className="form-label">
          Engine Spec <span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          id="engineSpec"
          rows="5"
          maxLength={1000}
          tabIndex={21}
          {...register("engineSpec", {
            required: "Engine Spec are required",
          })}
        />
        <small className="form-error-message">
          {errors?.engineSpec && errors.engineSpec.message}
        </small>
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="vehicleFeatures" className="form-label">
          Vehicle Features <span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          id="vehicleFeatures"
          rows="5"
          maxLength={1000}
          tabIndex={22}
          {...register("vehicleFeatures", {
            required: "Vehicle Features are required"
          })}
        />
        <small className="form-error-message">
          {errors?.vehicleFeatures && errors.vehicleFeatures.message}
        </small>
      </div>

      <div className="col-12 form-section-title mt-5">Financials</div>
      <div className="col-12">
        <hr className="mt-1"></hr>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="truckFinancials.purchaseDate"
          className="form-label"
        >
          Purchase Date <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="truckFinancials.purchaseDate"
          register={register}
          rules={{ required: "Purchase Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className="form-error-message">
          {errors?.truckFinancials?.purchaseDate &&
            errors.truckFinancials?.purchaseDate.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="truckFinancials.purchasePrice"
          className="form-label"
        >
          Purchase Price <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="truckFinancials.purchasePrice"
          tabIndex={24}
          defaultValue={
            isFormEdit
              ? selectedTruck?.truckFinancials?.purchasePrice || ""
              : ""
          }
          autoComplete="on"
          {...register("truckFinancials.purchasePrice", {
            required: "Purchase Price is required",
          })}
        />
        <small className="form-error-message">
          {errors?.truckFinancials?.purchasePrice &&
            errors.truckFinancials?.purchasePrice.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="truckFinancials.depreciatedValue"
          className="form-label"
        >
          Depreciated Value <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="truckFinancials.depreciatedValue"
          tabIndex={25}
          defaultValue={
            isFormEdit
              ? selectedTruck?.truckFinancials?.depreciatedValue || ""
              : ""
          }
          autoComplete="on"
          {...register("truckFinancials.depreciatedValue", {
            required: "Depreciated Value is required",
          })}
        />
        <small className="form-error-message">
          {errors?.truckFinancials?.depreciatedValue &&
            errors.truckFinancials?.depreciatedValue.message}
        </small>
      </div>
      <div className="col-12 mb-2">
        <label
          htmlFor="truckFinancials.leaseLoanDetails"
          className="form-label"
        >
          Lease Loan Details <span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          id="truckFinancials.leaseLoanDetails"
          rows="5"
          maxLength={1000}
          tabIndex={26}
          {...register("truckFinancials.leaseLoanDetails")}
        />
        <small className="form-error-message">
          {errors?.truckFinancials?.leaseLoanDetails &&
            errors.truckFinancials?.leaseLoanDetails.message}
        </small>
      </div>
    </>
  );
}

export default PrimaryDetails;
