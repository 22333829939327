import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { addDriver, updateDriver } from '../../services/driverServices';
import { isNTMSUSer, isAccountAdmin, isSuperAdmin } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { PatternFormat } from 'react-number-format';
import { removePhoneFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { PROVINCE_STATE, DRIVER_TYPE, EMPLOYMENT_STATUS, DEFAULT_PAY_TYPE } from '../../constants';
import { addDriverData, setDriverData } from '../../redux/driver/driverReducer';
import FormTabs from '../Commons/Layouts/FormTabs';
import DriverModal from '../Commons/Modals/DriverModal';
import Select from 'react-select';
import { selectStyle } from '../../utils/styleUtils';
import PrimaryDetails from './PrimaryDetails';
import moment from 'moment';
import { setFormPendingCompletion } from '../../redux/global/globalReducer';
import { setNotificationData } from '../../redux/global/globalReducer';
import * as dayjs from 'dayjs'; 

function DriverForm({
  isFormEdit = false,
  selectedDriver = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
  userCreationInformation,
  setUserCreationInformation,
  removeUserFromOptions
}) {
  const dispatch = useDispatch();
  const { 
    register, 
    handleSubmit, 
    setValue, 
    setError, 
    clearErrors, 
    reset, 
    setFocus, 
    control, 
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const isInitialRender = useRef(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState();

  const [employmentStatus, setEmploymentStatus] = useState("");
  const [defaultPayType, setDefaultPayType] = useState("");





  // // form values
  // setValue('driverId', 0);
  // setValue('accountId', accountId);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("firstName");
      }, 50);

      if (userCreationInformation !== null) {
        setFirstName(userCreationInformation.firstName);
        setLastName(userCreationInformation.lastName);
        setEmail(userCreationInformation.email);
        setUserId(userCreationInformation.value);
        setUserCreationInformation(null);
      }
    } else {
      setFirstName();
      setLastName();
      setEmail();
      setUserId();
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  const onSubmit = async (payload) => {
    let response = null;
    var dlExpiryDate = dayjs(payload.dlExpiryDate).format('YYYY-MM-DD');
    _.set(payload, 'dlExpiryDate', dlExpiryDate);

    var cdlExpiryDate = dayjs(payload.cdlExpiryDate).format('YYYY-MM-DD');
    _.set(payload, 'cdlExpiryDate', cdlExpiryDate);

    var passportExpiryDate = dayjs(payload.passportExpiryDate).format('YYYY-MM-DD');
    _.set(payload, 'passportExpiryDate', passportExpiryDate);

    var overweightPermitExpiryDate = dayjs(payload.overweightPermitExpiryDate).format('YYYY-MM-DD');
    _.set(payload, 'overweightPermitExpiryDate', overweightPermitExpiryDate);

    var doublesPermitExpiryDate = dayjs(payload.doublesPermitExpiryDate).format('YYYY-MM-DD');
    _.set(payload, 'doublesPermitExpiryDate', doublesPermitExpiryDate);

    var insuranceExpiryDate = dayjs(payload.insuranceExpiryDate).format('YYYY-MM-DD');
    _.set(payload, 'insuranceExpiryDate', insuranceExpiryDate);

    if (isFormEdit) {
      _.set(payload, 'driverId', selectedDriver.driverId);
      _.set(payload, 'accountId', selectedDriver.accountId);
      response = await updateDriver(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } driver.`,
        })
      );
    } else {
      _.set(payload, 'driverId', 0);
      if (isNTMSUSer(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      _.set(payload, 'userId', userId);
      response = await addDriver(payload);

      // showToast({
      //   type: `${response.ok ? 'success' : 'error'}`,
      //   message: `${response.ok ? 'Successfully added' : 'Failed to add'} driver. ${response.status === 409 ? 'Driver is already a user.' : ''}`
      // });
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } driver.`,
        })
      );
    }

    if (response.ok) {
      const driverData = await response.json();
      dispatch(isFormEdit ? setDriverData(driverData) : addDriverData(driverData));

      setDriverData("");
      setEmploymentStatus("");
      setDefaultPayType("");

      if (!isFormEdit) {
        removeUserFromOptions(userId);
      }

      toggleFormDisplay();
      if (isDirty) {
        setModal(false)
      }
      setIsFormDirty(false)
      dispatch(setFormPendingCompletion(false));
      reset()
      focusOnOpenOrCloseButton()
    }
  }

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-8-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedDriver={selectedDriver}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setUserId={setUserId}
              setEmploymentStatus={setEmploymentStatus}
              setDefaultPayType={setDefaultPayType}
              setUserCreationInformation={setUserCreationInformation}
              firstName={firstName}
              lastName={lastName}
              email={email}
              employmentStatus={employmentStatus}
              defaultPayType={defaultPayType}
              userType={userType}
            />
          </div>
        </div>
      ),
    }
  ];

  return (
    <>
      <form id="driver-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs
          items={tabItems}
          tabClassName="color-8"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default DriverForm
