import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { COUNTRY, PROVINCE_STATE } from "../../constants";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedTerminal = {},
  register,
  errors,
  setValue,
  reset
}) {
  const [selectedOrderAddressValues, setSelectedOrderAddressValues] = useState({});

  const setAddressValues = (selectedSuggestion) => {
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedShipperProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (selectedShipperProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue("address1", selectedSuggestion?.properties?.address_line1);
    setValue("address2", selectedSuggestion?.properties?.address_line2);
    setValue("city", selectedSuggestion?.properties?.city);
    setValue("postalCode", selectedSuggestion?.properties?.postcode);
    setValue("provinceState", selectedShipperProvinceState);
    setValue("country", selectedCountry);
    setValue("latitude", `${selectedSuggestion?.properties?.lat}`);
    setValue("longitude", `${selectedSuggestion?.properties?.lon}`);
  };

  const setAddressNotes = (addressNotes) => {
    setValue("addressNotes", addressNotes);
  };

  useEffect(() => {
    if (isFormEdit) {
      reset();
      setValue('address1', selectedTerminal.address1);
      setValue('address2', selectedTerminal.address2);
      setValue('provinceState', selectedTerminal.provinceState);
      setValue('city', selectedTerminal.city);
      setValue('country', selectedTerminal.country);
      setValue('postalCode', selectedTerminal.postalCode);

      setSelectedOrderAddressValues({
        addressLine1: selectedTerminal.address1,
        addressLine2: selectedTerminal.address2,
        provinceState: selectedTerminal.provinceState,
        city: selectedTerminal.city,
        country: selectedTerminal.country,
        postalCode: selectedTerminal.postalCode,
        addressNotes: selectedTerminal.addressNotes
      });
    }
  }, [selectedTerminal])


  return (
    <>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="terminalName" className="form-label">
          Terminal Name <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="terminalName"
          defaultValue={selectedTerminal?.terminalName}
          tabIndex={1}
          {...register("terminalName", {
            required: "Terminal Name is required",
          })}
        />
        <small className="form-error-message">
          {errors?.terminalName && errors.terminalName.message}
        </small>
      </div>
      <div className="col-12 p-0 mb-3">
        <hr></hr>
      </div>
      <AutocompleteAddressForm setAddressValues={setAddressValues} setAddressNotes={setAddressNotes} selectedOrderAddressValues={selectedOrderAddressValues} />
    </>
  );
}

export default PrimaryDetails;
