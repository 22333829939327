import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
// import OrderAccessorialActionRenderer from '../Templates/CellRenderers/Orders/OrderAccessorialActionRenderer';
import Select from "react-select";
import { selectStyle } from "../../utils/styleUtils";
import DeleteItemActionRenderer from "../Templates/CellRenderers/Commons/DeleteItemActionRenderer";
import { addOrderAccessorial, deleteOrderAccessorial } from "../../services/orderAccessorialServices";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { addOrderAccessorialData, deleteOrderAccessorialData } from "../../redux/order/orderReducer";
import { setNotificationData } from "../../redux/global/globalReducer";
import { GRID_CONSTANTS } from "../../constants";

function OrderAccessorial({
  selectedOrder,
  accessorialOptions,
  isFormHidden,
  selectedAccessorialList,
  checkPageEditable
}) {
  const orderState = useSelector((state) => state.order);
  const currentOrder = _.find(orderState?.data, { orderId: selectedOrder?.orderId })

  const dispatch = useDispatch();

  const columnDefinitions = (isEditable) => {
    const defs = [
      {
        field: "Name",
        minWidth: 150,
        cellClass: "grid-column",
      },
      {
        field: "Description",
        minWidth: 150,
        cellClass: "grid-column gray",
        autoHeight: true,
      },
      {
        field: "Edit",
        minWidth: 150,
        headerName: "",
        cellClass: "d-flex flex-row-reverse",
        autoHeight: true,
        hide: isEditable,
        cellRenderer: DeleteItemActionRenderer,
        cellRendererParams: props  =>  ({
          header: "Accessorial",
          parentType: "Order",
          checkPageEditable: isEditable,
          handleDeleteFunction: async () => await handleDeleteAccessorial(props, true)
        }),
      },
    ];

    return defs;
  }

  useEffect(() => {
    let data = [];
    _.each(currentOrder?.orderAccessorial, (accessorial, index) => {
      data.push({
        '#': index + 1,
        Name: accessorial.accessorialName,
        Description: accessorial.description,
        AccessorialId: accessorial.accessorialId,
        OrderAccessorialId: accessorial.orderAccessorialId,
        Id: accessorial.orderAccessorialId,
        AccountId: accessorial.accountId,
        OrderId: accessorial.orderId
      });
    });
    setRowData(data);
  }, [currentOrder?.orderAccessorial])

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    if (!isFormHidden) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    if (isFormHidden) {
      gridRef.current.api.sizeColumnsToFit();
    }
  };

  const onGridSizeChanged = (params) => {
    if (!isFormHidden && params.clientHeight > 0 && params.clientWidth > 0) {
      gridRef.current.api.sizeColumnsToFit();
    }
  };

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(columnDefinitions(false));
  const [selectedAccessorial, setSelectedAccessorial] = useState(null);
  const gridRef = useRef();

  const handleAddAccessorial = async () => {
    if (selectedAccessorial) {
      const payload = {
        "accessorialName": selectedAccessorial.label,
        "description": selectedAccessorial.description,
        "accessorialId": selectedAccessorial.value,
        "accountId": selectedAccessorial.accountId,
        "orderId": selectedOrder?.orderId,
      }

      const response = await addOrderAccessorial(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } item.`,
        })
      );

      if (response.ok) {
        const returnedData = await response.json()
        const newItem = {
          "#": rowData.length + 1,
          Name: returnedData.accessorialName,
          Description: returnedData.description,
          AccessorialId: returnedData.accessorialId,
          OrderAccessorialId: returnedData.orderAccessorialId,
          Id: returnedData.orderAccessorialId,
          AccountId: returnedData.accountId,
          OrderId: returnedData.orderId
        };

        const transaction = {
          add: [newItem],
        };

        gridRef.current.api.applyTransaction(transaction);

        dispatch(addOrderAccessorialData({ orderId: selectedOrder?.orderId, orderAccessorial: returnedData }));

        setSelectedAccessorial(null);
      }


    } else {

      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: `Please select an accessorial`,
        })
      );
    }
  };

  const handleDeleteAccessorial = async (currentData, showNotification = true) => {
    const orderId = currentData.node.data.OrderId;
    const itemId = currentData.node.data?.Id;

    const response = await deleteOrderAccessorial(itemId)

    if (showNotification) {
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully deleted" : "Failed to delete"
            } item.`,
        })
      );
    }

    if (response.ok) {
      currentData.api.applyTransaction({ remove: [currentData.node.data] });
      dispatch(deleteOrderAccessorialData({ orderId, itemId }));
    }
  }

  useEffect(() => {
    setColumnDefs(columnDefinitions(checkPageEditable()));
  }, [checkPageEditable()])

  return (
    <div className="">
      <div className="col-12 mb-3">
        {!checkPageEditable() && (<div className="d-flex flex-row align-items-center">
          <div className="ms-auto me-2">
            <div className="custom-selector">
              <Select
                onChange={(accessorial) => setSelectedAccessorial(accessorial)}
                options={accessorialOptions}
                value={selectedAccessorial}
                placeholder={"Select Accessorial"}
                className="order-select-container"
                classNamePrefix="react-select"
                isClearable
                styles={selectStyle}
              />
            </div>
          </div>
          <div className="ms-2">
            <button
              type="button"
              className="btn btn-color-3"
              style={{ width: 150 }}
              onClick={handleAddAccessorial}
            >
              Add Accessorial
            </button>
          </div>
        </div>)}
      </div>
      <div className="col-12">
      </div>
      <div className={`col-12 mb-2`}>
        <div className={`ag-theme-alpine form-content-section-container color-3-grid p-0`}>
          <AgGridReact
            className="no-header"
            rowData={rowData}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onColumnSizeChanged={onColumnsSizeChanged}
            domLayout="autoHeight"
            onGridSizeChanged={onGridSizeChanged}
            rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}

export default OrderAccessorial;
