import React from "react";
import _ from "lodash";
import NavMenu from "./components/Navigation/NavMenu";
import SidebarPage from "./components/Navigation/Sidebar";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayrollSettings } from "./services/payrollSettingsServices";
import {
  setAccountingSettingsData,
  setPayrollSettingsData,
  setLoadingData,
} from "./redux/settings/settingsReducer";
import { fetchAccountingSettings } from "./services/accountingSettingsServices";
import { isNTMSUSer } from "./utils/roleUtils";
import LoadingPage from "./components/Commons/Authorization/LoadingPage";
import { fetchCustomersByAccount } from "./services/customerServices";
import { setCustomersListData } from "./redux/customer/customerReducer";
import { notification } from "antd";
import { setNotificationData } from "./redux/global/globalReducer";

function Layout(props) {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  // Selectors
  const accountId = useSelector((state) => state.auth.user.accountId);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const userType = useSelector((state) => state.auth.user.userType);
  const loadingData = useSelector((state) => state.settings.loading);
  const globalState = useSelector((state) => state.global);

  // Fetch needed data on app load only if user is authenticated
  useEffect(() => {
    if (!_.isNil(accountId)) {
      init();
    }
  }, [accountId, selectedAccountState.accountId]);

  useEffect(() => {
    if (!_.isEmpty(globalState.notification)) {
      const notificationType = globalState.notification.type;
      const apiFunction = api[notificationType];
  
      if (apiFunction && typeof apiFunction === 'function') {
        apiFunction({
          message: globalState.notification.message,
          description: globalState.notification.description,
          placement: "top",
          duration: 5,
        });
      }
  
      // reset notification data
      dispatch(setNotificationData({}));
    }
  }, [globalState.notification]);
  const init = async () => {
    dispatch(setLoadingData(true));

    if (isNTMSUSer(userType)) {
      const payrollSettingsData = await fetchPayrollSettings(
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId
      );
      dispatch(setPayrollSettingsData(payrollSettingsData));

      const accountSettingsData = await fetchAccountingSettings(
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId
      );
      dispatch(setAccountingSettingsData(accountSettingsData));
    } else {
      const payrollSettingsData = await fetchPayrollSettings(accountId);
      dispatch(setPayrollSettingsData(payrollSettingsData));

      const accountSettingsData = await fetchAccountingSettings(accountId);
      dispatch(setAccountingSettingsData(accountSettingsData));
    }

    // @TODO: Remove fetching of customers on other pages.
    const customersData = await fetchCustomersByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
    dispatch(setCustomersListData(customersData));

    dispatch(setLoadingData(false));
  };

  return (
    <div style={{ height: "100%" }}>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
      />
      {contextHolder}
      <NavMenu />
      <div className="main-container">
        <ProSidebarProvider>
          <SidebarPage />
        </ProSidebarProvider>
        <div className="layout-container">
          {loadingData ? <LoadingPage /> : props.children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
