import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

import {
  TD1_PROVINCE,
  TD2_FEDERAL,
  PAY_FREQUENCY,
  VACATION_TYPE,
  PAY_METHOD_TYPE,
} from '../../constants';
import { NumericFormat } from 'react-number-format';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedAccount = {},
  control,
  register,
  errors,
  setValue,
  reset,
  payrollSettings,
  disabledFieldCheck,
  gLCodeList,
  disabledPayrollStartDateCheck,
  editableAccountSettingsCheck,
  payrollStartDateToggle,
  disabledPayrollPeriodCheck,
  payrollPeriodToggle
}) {

  const [payFrequency, setPayFrequency] = useState("");
  const [payrollPeriod, setPayrollPeriod] = useState("");
  const [vacationType, setVacationType] = useState("");
  const [payMethodType, setPayMethodType] = useState("");
  const [tD1Province, setTD1Province] = useState("");
  const [tD2Federal, setTD2Federal] = useState("");
  const [gLCode, setGLCode] = useState("");

  useEffect(() => {
    if (payrollSettings !== undefined) {
      setValue("overtimePaymentRate", (payrollSettings.overtimePaymentRate * 100).toFixed(2))
      setValue("tD1Province", payrollSettings.tD1Province)
      setValue("tD2Federal", payrollSettings.tD2Federal)
      setValue("payFrequency", payrollSettings.payFrequency)
      setValue("payrollPeriod", payrollSettings.payrollPeriod)
      setValue("vacationType", payrollSettings.vacationType)
      setValue("payMethodType", payrollSettings.payMethodType)
      setValue("glCodeId", payrollSettings.glCodeId)
      setValue("payrollStartDate", moment(payrollSettings.payrollStartDate).hour(7).minute(0).second(0).toDate());

      setTD1Province(payrollSettings.tD1Province);
      setTD2Federal(payrollSettings.tD2Federal);
      setPayFrequency(payrollSettings.payFrequency);
      setPayrollPeriod(payrollSettings.payrollPeriod);
      setVacationType(payrollSettings.vacationType);
      setPayMethodType(payrollSettings.payMethodType);
      setGLCode(payrollSettings.glCodeId);
    }
  }, [payrollSettings])



  return (
    <>
      <div className="form-title d-flex justify-content-start mb-4">
        Employee Settings
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="tD1Province" className="form-label">TD1 Province:</label>
        <select className="form-select" id="tD1Province" value={tD1Province} tabIndex={1} {...register("tD1Province", { valueAsNumber: true, onChange: (e) => setTD1Province(e.target.value), required: "TD1 Province is required" })}>
          <option key="none" value="" hidden>TD1 Prov</option>
          {TD1_PROVINCE.map((td1, index) =>
            <option key={index} value={td1.value}>{td1.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.tD1Province && errors.tD1Province.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="tD2Federal" className="form-label">TD2 Federal:</label>
        <select className="form-select" id="tD2Federal" value={tD2Federal} tabIndex={2} {...register("tD2Federal", { valueAsNumber: true, onChange: (e) => setTD2Federal(e.target.value), required: "TD2 Federal is required" })}>
          <option key="none" value="" hidden>TD1 Prov</option>
          {TD2_FEDERAL.map((td2, index) =>
            <option key={index} value={td2.value}>{td2.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.tD2Federal && errors.tD2Federal.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="payFrequency" className="form-label">Pay Frequency:</label>
        <select className="form-select" id="payFrequency" value={payFrequency} tabIndex={3} disabled={disabledFieldCheck} {...register("payFrequency", { valueAsNumber: true, onChange: (e) => setPayFrequency(e.target.value), required: "Pay frequency is required" })}>
          <option key="none" value="" hidden>Pay Frequency</option>
          {PAY_FREQUENCY.map((payFrequency, index) =>
            <option key={index} value={payFrequency.value}>{payFrequency.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.payFrequency && errors.payFrequency.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="vacationType" className="form-label">Vacation Type:</label>
        <select className="form-select" id="vacationType" value={vacationType} tabIndex={4} {...register("vacationType", { valueAsNumber: true, onChange: (e) => setVacationType(e.target.value), required: "Vacation type is required" })}>
          <option key="none" value="" hidden>Vacation Type</option>
          {VACATION_TYPE.map((vacationType, index) =>
            <option key={index} value={vacationType.value}>{vacationType.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.vacationType && errors.vacationType.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="payMethodType" className="form-label">Pay Method Type:</label>
        <select className="form-select" id="payMethodType" value={payMethodType} tabIndex={5} {...register("payMethodType", { valueAsNumber: true, onChange: (e) => setPayMethodType(e.target.value), required: "Pay method type is required" })}>
          <option key="none" value="" hidden>Pay Method Type</option>
          {PAY_METHOD_TYPE.filter(method => method.value !== 4).map((payMethodType, index) =>
            <option key={index} value={payMethodType.value}>{payMethodType.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.payMethodType && errors.payMethodType.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="overtimePaymentRate" className="form-label">Overtime Payment Rate:</label>
        <Controller
          control={control}
          name='overtimePaymentRate'
          register={register}
          rules={{ valueAsNumber: true }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              defaultValue={(payrollSettings?.overtimePaymentRate * 100).toFixed(2) || ''}
              value={(payrollSettings?.overtimePaymentRate * 100).toFixed(2) || ''}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              tabIndex={6}
              className='form-control'
              suffix="%"
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="overtimePaymentRate"
              mask="_" />
          )}
        />
        <small className='form-error-message'>
          {errors?.overtimePaymentRate && errors.overtimePaymentRate.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="glCodeId" className="form-label">GL Code:</label>
        <select className="form-select" id="glCodeId" value={gLCode} tabIndex={7} {...register("glCodeId", { valueAsNumber: true, onChange: (e) => setGLCode(e.target.value), required: "GL Code is required" })}>
          <option key="none" value="" hidden>GL Code</option>
          {gLCodeList.map((gl, index) =>
            <option key={index} value={gl.glCodeId}>{gl.glCodeName} - {gl.description}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.glCodeId && errors.glCodeId.message}
        </small>
      </div>
      <div className="col-12 my-2">
        <hr></hr>
      </div>
      <div className="form-title d-flex justify-content-start mb-4">
        Account Settings
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="payrollStartDate" className="form-label">Payroll Start Date:</label>
        <div className="d-flex flex-row align-items-center editable-datepicker">
          <Controller
            control={control}
            name='payrollStartDate'
            register={register}
            rules={{ required: "Payroll start date is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <ReactDatePicker
                className='datepicker-field'
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                tabIndex={8}
                dateFormat='yyyy-MM-dd'
                id="payrollStartDate"
                disabled={disabledPayrollStartDateCheck}
              />
            )}
          />
          {(disabledFieldCheck && editableAccountSettingsCheck) &&
            <button className="btn btn-primary btn-sm ms-2" type="button" onClick={payrollStartDateToggle}>
              <i className='fa-solid fa-pen'></i>
            </button>
          }
        </div>
        <small className='form-error-message'>
          {errors?.payrollStartDate && errors.payrollStartDate.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="payrollPeriod" className="form-label">Payroll Period:</label>
        <div className="d-flex flex-row align-items-center editable-datepicker">
          <select className="form-select" id="payrollPeriod" value={payrollPeriod} tabIndex={9} disabled={disabledPayrollPeriodCheck} {...register("payrollPeriod", { valueAsNumber: true, onChange: (e) => setPayrollPeriod(e.target.value), required: "Payroll period is required" })}>
            <option key="none" value="" hidden>Payroll Period</option>
            {PAY_FREQUENCY.map((payrollPeriod, index) =>
              <option key={index} value={payrollPeriod.value}>{payrollPeriod.label}</option>
            )}
          </select>
          {(disabledFieldCheck && editableAccountSettingsCheck) &&
            <button className="btn btn-primary btn-sm ms-2" type="button" onClick={payrollPeriodToggle}>
              <i className='fa-solid fa-pen'></i>
            </button>
          }
        </div>
        <small className='form-error-message'>
          {errors?.payFrequency && errors.payFrequency.message}
        </small>
      </div>
      <div className='col-12'>
        <p className='text-link' onClick={() => window.open('/claim-codes', '_blank')}>Claim Codes Values</p>
      </div>
    </>
  );
}

export default PrimaryDetails;
