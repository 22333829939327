import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  OWNERSHIP_STATUS_TYPES,
  TRAILER_TYPES
} from "../../constants";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { NumericFormat } from 'react-number-format';
import { DatePicker } from 'antd';
import dayjs from "dayjs";


function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedTrailer = {},
  control,
  register,
  errors,
  setValue,
  reset,
  provinceStateRef
}) {

  const [ownershipStatus, setOwnershipStatus] = useState(1);
  const [trailerType, setTrailerType] = useState(1);

  useEffect(() => {
    if (isFormEdit) {
      setOwnershipStatus(selectedTrailer["ownershipStatus"]);
      setTrailerType(selectedTrailer["trailerType"]);

      setValue("ownershipStatus", selectedTrailer["ownershipStatus"]);
      setValue("trailerType", selectedTrailer["trailerType"]);
      setValue("year", dayjs(selectedTrailer["year"]));
      setValue(
        "trailerFinancials.purchaseDate",
        dayjs(selectedTrailer.trailerFinancials["purchaseDate"])
      );
      setValue("engineSpec", selectedTrailer["engineSpec"]);
      setValue("trailerFeatures", selectedTrailer["trailerFeatures"]);
      setValue(
        "trailerFinancials.leaseLoanDetails",
        selectedTrailer.trailerFinancials["leaseLoanDetails"]
      );
      setValue('axles', selectedTrailer["axles"]);
      setValue('volumeCapacity', selectedTrailer["volumeCapacity"]);
      setValue('weightCapacity', selectedTrailer["weightCapacity"]);
      setValue('trailerLength', selectedTrailer["trailerLength"]);
      setValue('trailerWidth', selectedTrailer["trailerWidth"]);
      setValue('trailerHeight', selectedTrailer["trailerHeight"]);
      setValue('gvw', selectedTrailer["gvw"]);
      setValue('tareWeight', selectedTrailer["tareWeight"]);
      setValue('leaseLoanDetails', selectedTrailer.trailerFinancials["leaseLoanDetails"]);
    } else {
      setOwnershipStatus(1);
      setTrailerType(1);
      reset();
    }
  }, [selectedTrailer["trailerId"]]);

  return (
    <>
      <div className="col-12 form-section-title">Trailer Details</div>
      <div className="col-12">
        <hr className="mt-1"></hr>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="vin" className="form-label">
          Vehicle Identification Number <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="vin"
          tabIndex={1}
          defaultValue={isFormEdit ? selectedTrailer?.vin || "" : ""}
          autoComplete="on"
          {...register("vin", {
            required: "Vehicle Identification Number is required",
          })}
        />
        <small className="form-error-message">
          {errors?.vin && errors.vin.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="make" className="form-label">
          Make <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="make"
          tabIndex={2}
          defaultValue={isFormEdit ? selectedTrailer?.make || "" : ""}
          autoComplete="on"
          {...register("make", { required: "Make is required" })}
        />
        <small className="form-error-message">
          {errors?.make && errors.make.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="model" className="form-label">
          Model <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="model"
          tabIndex={3}
          defaultValue={isFormEdit ? selectedTrailer?.model || "" : ""}
          autoComplete="on"
          {...register("model", { required: "Model is required" })}
        />
        <small className="form-error-message">
          {errors?.model && errors.model.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="year" className="form-label">
          Year <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="year"
          register={register}
          rules={{ required: "Year is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} picker="year" />
          )}
        />
        <small className="form-error-message">
          {errors?.year && errors.year.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="ownershipStatus" className="form-label">
          Ownership Status <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={ownershipStatus}
          tabIndex={5}
          id="ownershipStatus"
          {...register("ownershipStatus", {
            valueAsNumber: true,
            onChange: (e) => setOwnershipStatus(e.target.value),
            required: "Ownership Status is required",
          })}
        >
          <option key="none" value="" hidden>
            Ownership Status
          </option>
          {OWNERSHIP_STATUS_TYPES.map((ownershipStatus, index) => (
            <option key={index} value={ownershipStatus.value}>
              {ownershipStatus.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.ownershipStatus && errors.ownershipStatus.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="trailerType" className="form-label">
          Trailer Type <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={trailerType}
          tabIndex={6}
          id="trailerType"
          {...register("trailerType", {
            valueAsNumber: true,
            onChange: (e) => setTrailerType(e.target.value),
            required: "Trailer Type is required",
          })}
        >
          <option key="none" value="" hidden>
            Trailer Type
          </option>
          {TRAILER_TYPES.map((trailerType, index) => (
            <option key={index} value={trailerType.value}>
              {trailerType.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.trailerType && errors.trailerType.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="axles" className="form-label">Axles <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='axles'
          register={register}
          rules={{ required: "Axles is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="axles"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.axles && errors.axles.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="volumeCapacity" className="form-label">Volume Capacity <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='volumeCapacity'
          register={register}
          rules={{ required: "Volume Capacity is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="volumeCapacity"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.volumeCapacity && errors.volumeCapacity.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="weightCapacity" className="form-label">Weight Capacity <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='weightCapacity'
          register={register}
          rules={{ required: "Weight Capacity is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="weightCapacity"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.weightCapacity && errors.weightCapacity.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="trailerLength" className="form-label">Trailer Length <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='trailerLength'
          register={register}
          rules={{ required: "Trailer Length is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="trailerLength"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.trailerLength && errors.trailerLength.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="trailerWidth" className="form-label">Trailer Width <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='trailerWidth'
          register={register}
          rules={{ required: "Trailer Width is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="trailerWidth"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.trailerWidth && errors.trailerWidth.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="trailerHeight" className="form-label">Trailer Height <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='trailerHeight'
          register={register}
          rules={{ required: "Trailer Height is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="trailerHeight"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.trailerHeight && errors.trailerHeight.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="gvw" className="form-label">GVW <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='gvw'
          register={register}
          rules={{ required: "GVW is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="gvw"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.gvw && errors.gvw.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="tareWeight" className="form-label">Tare Weight <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='tareWeight'
          register={register}
          rules={{ required: "Tare Weight is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              name={name}
              value={value === undefined ? '' : value}
              onChange={onChange}
              onBlur={onBlur}
              className='form-control'
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              id="tareWeight"
              mask="_"
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.tareWeight && errors.tareWeight.message}
        </small>
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="trailerFeatures" className="form-label">
          Trailer Features <span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          id="trailerFeatures"
          rows="5"
          maxLength={1000}
          tabIndex={15}
          {...register("trailerFeatures", {
            required: "Trailer Features are required",
          })}
        />
        <small className="form-error-message">
          {errors?.trailerFeatures && errors.trailerFeatures.message}
        </small>
      </div>

      <div className="col-12 form-section-title mt-5">Financials</div>
      <div className="col-12">
        <hr className="mt-1"></hr>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="trailerFinancials.purchaseDate"
          className="form-label"
        >
          Purchase Date <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="trailerFinancials.purchaseDate"
          register={register}
          rules={{ required: "Purchase Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className="form-error-message">
          {errors?.trailerFinancials?.purchaseDate &&
            errors.trailerFinancials?.purchaseDate.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="trailerFinancials.purchasePrice"
          className="form-label"
        >
          Purchase Price <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="trailerFinancials.purchasePrice"
          tabIndex={17}
          defaultValue={
            isFormEdit
              ? selectedTrailer?.trailerFinancials?.purchasePrice || ""
              : ""
          }
          autoComplete="on"
          {...register("trailerFinancials.purchasePrice", {
            required: "Purchase Price is required",
          })}
        />
        <small className="form-error-message">
          {errors?.trailerFinancials?.purchasePrice &&
            errors.trailerFinancials?.purchasePrice.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="trailerFinancials.depreciatedValue"
          className="form-label"
        >
          Depreciated Value <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="trailerFinancials.depreciatedValue"
          tabIndex={18}
          defaultValue={
            isFormEdit
              ? selectedTrailer?.trailerFinancials?.depreciatedValue || ""
              : ""
          }
          autoComplete="on"
          {...register("trailerFinancials.depreciatedValue", {
            required: "Depreciated Value is required",
          })}
        />
        <small className="form-error-message">
          {errors?.trailerFinancials?.depreciatedValue &&
            errors.trailerFinancials?.depreciatedValue.message}
        </small>
      </div>
      <div className="col-12 mb-2">
        <label
          htmlFor="trailerFinancials.leaseLoanDetails"
          className="form-label"
        >
          Lease Loan Details <span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          id="trailerFinancials.leaseLoanDetails"
          rows="5"
          maxLength={1000}
          tabIndex={19}
          {...register("leaseLoanDetails", {
            required: "Lease Loan Details are required",
          })}
        />
        <small className="form-error-message">
          {errors?.trailerFinancials?.leaseLoanDetails &&
            errors.trailerFinancials?.leaseLoanDetails.message}
        </small>
      </div>
    </>
  );
}

export default PrimaryDetails;
