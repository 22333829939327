import React, { useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ChevronRight } from "@mui/icons-material";
import { PlusOutlined } from "@ant-design/icons";
import { Space, Button, Dropdown, Flex, Menu } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFormPendingCompletion } from "../../../redux/global/globalReducer";
import { ConfirmationModal } from "../Modals/ConfirmationModal";
import _ from "lodash";
import { WARNING_MODAL_TEXTS } from "../../../constants";
import { formatDateOnly } from "../../../utils/formatUtils";
import { EllipsisOutlined } from "@ant-design/icons";

function ContentHeader({
  title,
  subtitle,
  dataCount,
  filterData,
  setBalanceFilter,
  parentNavItem = {},
  onClickAdd,
  onClickCancel,
  onClickFilter = null,
  isFormHidden,
  openOrCloseButtonRef,
  customAddButtonLabel,
  actionFunction = null,
  customButtons = [],
  hideTotal = false,
  hideSearch = false,
  hideStatus = true,
  hideBalanceFilter = true,
  hideAccountFilter = true,
  hideButton = false,
  hideSubtitle = false,
  hideDropdownButton = true,
  editablePage = false,
  disabledAddButton = false,
  date = null,
  dropdownList,
  dropdownTitle = "Actions",
  color
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formPendingCompletion = useSelector(
    (state) => state.global.formPendingCompletion
  );

  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [navigationLink, setNavigationLink] = useState("");

  const handleChange = (e) => {
    filterData(e.target.value);
  };

  const handleBalanceFilterChange = (e) => {
    setBalanceFilter(e.target.checked);
  };

  const handleUnsaveChangesCancel = () => {
    setUnsavedChangesModal(false);
    setNavigationLink("");
  };

  const handleUnsavedChangesConfirm = () => {
    setUnsavedChangesModal(false);
    dispatch(setFormPendingCompletion(false));

    if (navigationLink !== "") {
      navigate(navigationLink);
      setNavigationLink("");
    } else {
      onClickCancel();
    }
  };

  const handleLinkNavigation = (link) => {
    if (formPendingCompletion) {
      setUnsavedChangesModal(true);
      setNavigationLink(link);
    } else {
      navigate(link);
    }
  };

  const handleCloseForm = () => {
    if (formPendingCompletion) {
      setUnsavedChangesModal(true);
    } else {
      onClickCancel();
    }
  };

  return (
    <>
      <div className="content-header-container">
        <div className="navigation-info d-flex flex-row flex-wrap align-items-center mb-2">
          {/* Navigation Info */}
          <div
            className="me-3 navigation-link"
            onClick={() => handleLinkNavigation("/dashboard")}
          >
            Dashboard
          </div>
          <div className="me-3">
            <ChevronRight />
          </div>
          {!_.isEmpty(parentNavItem) && (
            <>
              <div
                className="me-3 navigation-link"
                onClick={() => handleLinkNavigation(parentNavItem?.link)}
              >
                {parentNavItem?.title}
              </div>
              <div className="me-3">
                <ChevronRight />
              </div>
            </>
          )}
          <div className="me-3 navigation-link">{title}</div>
        </div>

        <div className="d-flex flex-row flex-wrap align-items-center">
          <div
            className={`content-title ${!hideStatus && !editablePage ? "me-2" : "me-5"
              }`}
          >
            <div className="content-title-label">
              {title}
              <i id="locked-tooltip" className={`fa-solid fa-lock ms-2 color-4-icon ${!hideStatus && !editablePage ? "" : "d-none"}`}></i>
            </div>
            <div className={`content-subtitle ${hideSubtitle ? "d-none" : ""}`}>
              View all of your {subtitle} information
            </div>
          </div>
          <div
            className={`icon ${!hideStatus && !editablePage ? "" : "d-none"}`}
          >
            <ReactTooltip
              anchorId="locked-tooltip"
              className="tooltip-container"
              place="top"
              content="This payroll run has already been commited"
            />
          </div>
          <div
            className={`divider ms-5 me-3 ${hideTotal ? "d-none" : ""}`}
          ></div>
          <div
            className={`content-data-count me-5 ${hideTotal ? "d-none" : ""}`}
          >
            <span className="fw-bold">{dataCount}</span> <br />
            Total
          </div>

          <div className={`content-data-text me-4 ${(date !== null && date !== undefined) && !editablePage ? '' : 'd-none'}`}>
            This payroll was run on <b>{formatDateOnly(date)}</b>
          </div>

          <div
            className={`form-check balance-filter-container ${hideBalanceFilter ? "d-none" : ""
              }`}
          >
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="balanceFilterCheckbox"
              defaultChecked={true}
              onChange={(e) => handleBalanceFilterChange(e)}
            />
            <label className="form-check-label" htmlFor="balanceFilterCheckbox">
              with Balances
            </label>
          </div>

          <div
            className={`search-input-field-container ms-auto me-3 ${hideSearch ? "d-none" : ""
              }`}
          >
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                <i className="fa-solid fa-search"></i>
              </span>
              <input
                type="text"
                className="search-input-field form-control shadow-none"
                placeholder="Search..."
                id="search"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          {!hideAccountFilter && (
            <button
              className={`btn btn-md btn-primary me-2`}
              type="submit"
              onClick={onClickFilter}
              ref={openOrCloseButtonRef}
            >
              <Space>Remove Filter</Space>
            </button>
          )}

          <div
            className={`${!hideDropdownButton ? "" : "d-none"}`}
          >
            {actionFunction === null ?
              <Dropdown menu={{ items: dropdownList }} overlayClassName={`${color}`}>
                <Button className="btn btn-md btn-primary">
                  <span className="button-content">
                    <span className="title">{dropdownTitle}</span>
                    <EllipsisOutlined />
                  </span>
                </Button>
              </Dropdown>
              :
              <Dropdown.Button overlayClassName={`${color}`} onClick={actionFunction} menu={{ items: dropdownList }} type="primary">
                {dropdownTitle}
              </Dropdown.Button>
            }
          </div>
          {customButtons.length > 0 &&
            customButtons.map((currentButton, index) => (
              currentButton.shown === true &&
              <button
                key={index}
                className={`btn btn-md ${currentButton?.className || "btn-primary"} me-2`}
                onClick={currentButton.onClick}
                disabled={currentButton.disabled}
              >
                <Space >
                  {currentButton.icon}
                  {currentButton.label}
                </Space>
              </button>
            ))}

          <button
            id="btn-add-new"
            className={`btn btn-md ${isFormHidden ? "btn-primary" : "btn-secondary"
              } ${hideButton ? "d-none" : ""}`}
            type="submit"
            onClick={isFormHidden ? onClickAdd : handleCloseForm}
            ref={openOrCloseButtonRef}
            disabled={disabledAddButton}
          >
            {isFormHidden ? (
              <Space>
                <PlusOutlined />
                {customAddButtonLabel || "Add New"}
              </Space>
            ) : (
              "Close"
            )}
          </button>
        </div>
      </div>

      <ConfirmationModal
        isOpen={unsavedChangesModal}
        toggle={handleUnsaveChangesCancel}
        onClick={handleUnsavedChangesConfirm}
        headerText={WARNING_MODAL_TEXTS.headerText}
        bodyText={WARNING_MODAL_TEXTS.bodyText}
        confirmButtonText={WARNING_MODAL_TEXTS.confirmButtonText}
        cancelButtonText={WARNING_MODAL_TEXTS.cancelButtonText}
        actionType="delete"
      />
    </>
  );
}

export default ContentHeader;
