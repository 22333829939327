import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { addReportTemplate, updateReportTemplate } from '../../services/reportTemplateServices';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { isNTMSUSer } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from 'react-redux';
import { REPORT_TYPES } from '../../constants';
import { addReportTemplateData, setReportTemplateData } from '../../redux/reportTemplate/reportTemplateReducer';
import FormTabs from '../Commons/Layouts/FormTabs';
import PrimaryDetails from './PrimaryDetails';
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";

function ReportTemplateForm({
  isFormEdit = false,
  selectedReportTemplate = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton
}) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const isInitialRender = useRef(true);

  const accountId = useSelector((state) => state.auth.user.accountId);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm(
    {
      defaultValues: {
        header: _.isEmpty(selectedReportTemplate) ? "" : selectedReportTemplate['header'],
        footer: _.isEmpty(selectedReportTemplate) ? "" : selectedReportTemplate['footer']
      }
    }
  );
  const { dirtyFields, errors, isDirty } = formState;

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("templateName");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  const onSubmit = async (payload) => {
    let response = null;


    if (isFormEdit) {
      _.set(payload, 'accountId', selectedReportTemplate.accountId);
      _.set(payload, 'reportTemplateId', selectedReportTemplate.reportTemplateId);
      response = await updateReportTemplate(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } report template.`,
        })
      );
    } else {
      if (isNTMSUSer(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      _.set(payload, 'reportTemplateId', 0);
      response = await addReportTemplate(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } report template.`,
        })
      );
    }

    if (response.ok) {
      const reportTemplateData = await response.json();
      dispatch(isFormEdit ? setReportTemplateData(reportTemplateData) : addReportTemplateData(reportTemplateData));

      if (isDirty) {
        setModal(false);
      }

      setIsFormDirty(false);
      dispatch(setFormPendingCompletion(false));
      toggleFormDisplay();
      focusOnOpenOrCloseButton();
    }
  }



  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-6-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedReportTemplate={selectedReportTemplate}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
            />
          </div>
        </div>
      ),
    },
  ];


  return (
    <>
      <form id="report-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs
          items={tabItems}
          tabClassName="color-6"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>

      <ModalRender
        modal={modal}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setModal={setModal}
        toggle={toggle}
        isFormEdit={isFormEdit}
        formType={"report"}
        toggleFormDisplay={toggleFormDisplay}
      />
    </>
  )
}

export default ReportTemplateForm
