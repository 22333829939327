import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, DRIVER_TYPE, EMPLOYMENT_STATUS, DEFAULT_PAY_TYPE } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { DatePicker } from 'antd';
import dayjs from "dayjs";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedDriver = {},
  control,
  register,
  errors,
  setValue,
  reset,
  setFirstName,
  setLastName,
  setEmail,
  setUserId,
  setEmploymentStatus,
  setDefaultPayType,
  setUserCreationInformation,
  firstName,
  lastName,
  email,
  employmentStatus,
  defaultPayType,
  userType
}) {
  const [provinceState, setProvinceState] = useState("");
  const [defaultTax, setDefaultTax] = useState("");
  const [driverType, setDriverType] = useState("");

  useEffect(() => {
    if (isFormEdit) {
      setFirstName(selectedDriver.user['firstName']);
      setLastName(selectedDriver.user['lastName']);
      setEmail(selectedDriver.user['email']);
      setUserId(selectedDriver.user['userId']);
      setDriverType(parseInt(selectedDriver['driverType']));
      setEmploymentStatus(parseInt(selectedDriver['employmentStatus']));
      setDefaultPayType(parseInt(selectedDriver['defaultPayType']));
      setValue("driverType", parseInt(selectedDriver['driverType']));
      setValue("motorCarrierNumber", selectedDriver['motorCarrierNumber']);
      setValue("employmentStatus", selectedDriver['employmentStatus']);
      setValue("dlNumber", selectedDriver['dlNumber']);
      setValue("dlClass", selectedDriver['dlClass']);
      setValue("dlExpiryDate", dayjs(selectedDriver['dlExpiryDate']));
      setValue("cdlNumber", selectedDriver['cdlNumber']);
      setValue("cdlClass", selectedDriver['cdlClass']);
      setValue("cdlExpiryDate", dayjs(selectedDriver['cdlExpiryDate']));
      setValue("cdlMedicalCertificate", selectedDriver['cdlMedicalCertificate']);
      setValue("cdlVisionTest", selectedDriver['cdlVisionTest']);
      setValue("passport", selectedDriver['passport']);
      setValue("passportExpiryDate", dayjs(selectedDriver['passportExpiryDate']));
      setValue("overweightPermitExpiryDate", dayjs(selectedDriver['overweightPermitExpiryDate']));
      setValue("doublesPermitExpiryDate", dayjs(selectedDriver['doublesPermitExpiryDate']));
      setValue("insuranceExpiryDate", dayjs(selectedDriver['insuranceExpiryDate']));
      setValue("policyHolder", selectedDriver['policyHolder']);
      setValue("policyNumber", selectedDriver['policyNumber']);
      setValue("defaultPayType", parseInt(selectedDriver['defaultPayType']));
      setValue("fastCard", selectedDriver['fastCard']);
      setValue("fuelCardNumber", selectedDriver['fuelCardNumber']);
      setValue("notes", selectedDriver['fuelCardNumber']);
      setValue("ownerOperator", selectedDriver['ownerOperator']);
    } else {
      setFirstName();
      setLastName();
      setEmail();
      setUserId();
      setDriverType();
      setEmploymentStatus();
      setUserCreationInformation(null);

      reset()
    }

  }, [selectedDriver["driverId"]])

  const getOwnerOperatorValue = () => {
    if (selectedDriver.length <= 0) {
      return 'true';
    }

    return selectedDriver.ownerOperator ? 'true' : '';
  }

  const getActiveDefaultValue = () => {
    if (selectedDriver.length <= 0) {
      return 'true';
    }

    return selectedDriver.active ? 'true' : '';
  }

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="firstName" className="form-label">First Name:</label>
        <input type="text" className="form-control" id="firstName" tabIndex={1}
          defaultValue={firstName}
          disabled
        />
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="lastName" className="form-label">Last Name:</label>
        <input type="text" className="form-control" id="lastName" tabIndex={2}
          defaultValue={lastName}
          disabled
        />
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="email" className="form-label">Email:</label>
        <input type="text" className="form-control" id="email" tabIndex={3}
          defaultValue={email}
          autoComplete='on'
          disabled
        />
      </div>

      <div className="col-12 my-2">
        <hr></hr>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="driverType" className="form-label">Driver Type: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="driverType" value={driverType} tabIndex={4} {...register("driverType", { valueAsNumber: true, onChange: (e) => setDriverType(e.target.value), required: "Driver Type is required" })}>
          <option key="none" value="" hidden>Driver Type</option>
          {DRIVER_TYPE.map((driverType, index) =>
            <option key={driverType.value} value={driverType.value}>{driverType.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.driverType && errors.driverType.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="motorCarrierNumber" className="form-label">Motor Carrier Number:</label>
        <input type="text" className="form-control" id="motorCarrierNumber" tabIndex={5}
          defaultValue={isFormEdit ? selectedDriver?.motorCarrierNumber || '' : ''}
          {...register("motorCarrierNumber")}
        />
        <small className='form-error-message'>
          {errors?.motorCarrierNumber && errors.motorCarrierNumber.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="employmentStatus" className="form-label">Employment Status: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="employmentStatus" value={employmentStatus} tabIndex={6} {...register("employmentStatus", { valueAsNumber: true, onChange: (e) => setEmploymentStatus(e.target.value), required: "Employment Status is required" })}>
          <option key="none" value="" hidden>Employment Status</option>
          {EMPLOYMENT_STATUS.map((employmentStatus, index) =>
            <option key={employmentStatus.value} value={employmentStatus.value}>{employmentStatus.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.employmentStatus && errors.employmentStatus.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="dlNumber" className="form-label">Driver's License Number: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="dlNumber" tabIndex={7}
          defaultValue={isFormEdit ? selectedDriver?.dlNumber || '' : ''}
          {...register("dlNumber", { required: "Driver's License Number is required" })}
        />
        <small className='form-error-message'>
          {errors?.dlNumber && errors.dlNumber.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="dlClass" className="form-label">Driver's License Class: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="dlClass" tabIndex={8}
          defaultValue={isFormEdit ? selectedDriver?.dlClass || '' : ''}
          {...register("dlClass", { required: "Driver's License Number is required" })}
        />
        <small className='form-error-message'>
          {errors?.dlClass && errors.dlClass.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="dlExpiryDate" className="form-label">Driver's License Expiry Date: <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='dlExpiryDate'
          register={register}
          rules={{ required: "Driver's LicenseExpiry Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className='form-error-message'>
          {errors?.dlExpiryDate && errors.dlExpiryDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="cdlNumber" className="form-label">Commercial Driver's License Number: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="cdlNumber" tabIndex={10}
          defaultValue={isFormEdit ? selectedDriver?.cdlNumber || '' : ''}
          {...register("cdlNumber", { required: "Commercial Driver's License Number is required" })}
        />
        <small className='form-error-message'>
          {errors?.cdlNumber && errors.cdlNumber.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="cdlClass" className="form-label">Commercial Driver's License Class: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="cdlClass" tabIndex={11}
          defaultValue={isFormEdit ? selectedDriver?.cdlClass || '' : ''}
          {...register("cdlClass", { required: "Commercial Driver's License Number is required" })}
        />
        <small className='form-error-message'>
          {errors?.cdlClass && errors.cdlClass.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="cdlExpiryDate" className="form-label">Commercial Driver's License Expiry Date: <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='cdlExpiryDate'
          register={register}
          rules={{ required: "Commercial Driver's License Expiry Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className='form-error-message'>
          {errors?.cdlExpiryDate && errors.cdlExpiryDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="cdlMedicalCertificate" className="form-label">CDL Medical Certificate: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="cdlMedicalCertificate" tabIndex={13}
          defaultValue={isFormEdit ? selectedDriver?.cdlMedicalCertificate || '' : ''}
          {...register("cdlMedicalCertificate", { required: "CDL Medical Certificate is required" })}
        />
        <small className='form-error-message'>
          {errors?.cdlMedicalCertificate && errors.cdlMedicalCertificate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="cdlVisionTest" className="form-label">CDL Vision Test: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="cdlVisionTest" tabIndex={14}
          defaultValue={isFormEdit ? selectedDriver?.cdlVisionTest || '' : ''}
          {...register("cdlVisionTest", { required: "CDL Vision Test is required" })}
        />
        <small className='form-error-message'>
          {errors?.cdlVisionTest && errors.cdlVisionTest.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="passport" className="form-label">Passport:</label>
        <input type="text" className="form-control" id="passport" tabIndex={15}
          defaultValue={isFormEdit ? selectedDriver?.passport || '' : ''}
          {...register("passport")}
        />
        <small className='form-error-message'>
          {errors?.passport && errors.passport.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="passportExpiryDate" className="form-label">Passport Expiry Date: <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='passportExpiryDate'
          register={register}
          rules={{ required: "Passport Expiry Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className='form-error-message'>
          {errors?.passportExpiryDate && errors.passportExpiryDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="overweightPermitExpiryDate" className="form-label">Overweight Permit Expiry Date:</label>
        <Controller
          control={control}
          name='overweightPermitExpiryDate'
          register={register}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className='form-error-message'>
          {errors?.overweightPermitExpiryDate && errors.overweightPermitExpiryDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="doublesPermitExpiryDate" className="form-label">Doubles Permit Expiry Date:</label>
        <Controller
          control={control}
          name='doublesPermitExpiryDate'
          register={register}
          // rules={{ required: "Doubles Permit Expiry Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className='form-error-message'>
          {errors?.doublesPermitExpiryDate && errors.doublesPermitExpiryDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="insuranceExpiryDate" className="form-label">Insurance Expiry Date:</label>
        <Controller
          control={control}
          name='insuranceExpiryDate'
          register={register}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className='form-error-message'>
          {errors?.insuranceExpiryDate && errors.insuranceExpiryDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="policyHolder" className="form-label">Policy Holder:</label>
        <input type="text" className="form-control" id="policyHolder" tabIndex={20}
          defaultValue={isFormEdit ? selectedDriver?.policyHolder || '' : ''}
          {...register("policyHolder")}
        />
        <small className='form-error-message'>
          {errors?.policyHolder && errors.policyHolder.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="policyNumber" className="form-label">Policy Number:</label>
        <input type="text" className="form-control" id="policyNumber" tabIndex={21}
          defaultValue={isFormEdit ? selectedDriver?.policyNumber || '' : ''}
          {...register("policyNumber")}
        />
        <small className='form-error-message'>
          {errors?.policyNumber && errors.policyNumber.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultPayType" className="form-label">Default Pay Type: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="defaultPayType" value={defaultPayType} tabIndex={22} {...register("defaultPayType", { valueAsNumber: true, onChange: (e) => setDefaultPayType(e.target.value), required: "Default Pay Type is required" })}>
          <option key="none" value="" hidden>Default Pay Type</option>
          {DEFAULT_PAY_TYPE.map((defaultPayType, index) =>
            <option key={defaultPayType.value} value={defaultPayType.value}>{defaultPayType.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.defaultPayType && errors.defaultPayType.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultPayRate" className="form-label">Default Pay Rate:</label>
        <input type="text" className="form-control" id="defaultPayRate" tabIndex={23}
          defaultValue={isFormEdit ? selectedDriver?.defaultPayRate || '' : ''}
          {...register("defaultPayRate")}
        />
        <small className='form-error-message'>
          {errors?.defaultPayRate && errors.defaultPayRate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="fastCard" className="form-label">FAST Card:</label>
        <input type="text" className="form-control" id="fastCard" tabIndex={24}
          defaultValue={isFormEdit ? selectedDriver?.fastCard || '' : ''}
          {...register("fastCard")}
        />
        <small className='form-error-message'>
          {errors?.fastCard && errors.fastCard.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="fuelCardNumber" className="form-label">Fuel Card Number:</label>
        <input type="text" className="form-control" id="fuelCardNumber" tabIndex={25}
          defaultValue={isFormEdit ? selectedDriver?.fuelCardNumber || '' : ''}
          {...register("fuelCardNumber")}
        />
        <small className='form-error-message'>
          {errors?.fuelCardNumber && errors.fuelCardNumber.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="notes" className="form-label">Notes:</label>
        <input type="text" className="form-control" id="notes" tabIndex={26}
          defaultValue={isFormEdit ? selectedDriver?.notes || '' : ''}
          {...register("notes")}
        />
        <small className='form-error-message'>
          {errors?.notes && errors.notes.message}
        </small>
      </div>

      <div className="col-12 mb-2 mt-3">
        <div className="form-check">
          <input className="form-check-input align-middle" type="checkbox" value="" id="ownerOperator" tabIndex={27}
            defaultChecked={getOwnerOperatorValue}
            {...register("ownerOperator")}
          />
          <label className="form-check-label align-middle " htmlFor="ownerOperator"> Owner Operator</label>
          <small className='form-error-message'>
            {errors?.ownerOperator && errors.ownerOperator.message}
          </small>
        </div>
      </div>
      {
        (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
        <div className="col-12 mb-2 mt-3">
          <div className="form-check">
            <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={28}
              defaultChecked={getActiveDefaultValue}
              {...register("active")}
            />
            <label className="form-check-label align-middle " htmlFor="active"> Active</label>
            <small className='form-error-message'>
              {errors?.active && errors.active.message}
            </small>
          </div>
        </div>
      }
    </>
  );
}

export default PrimaryDetails;
