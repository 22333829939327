import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import { fetchTrailersByAccount } from "../../services/trailerServices";
import { useDispatch, useSelector } from "react-redux";
import { setTrailersListData } from "../../redux/trailer/trailerReducer";
import TrailerForm from "./TrailerForm";
import { enumValueFormatter } from "../../utils/formatUtils";
import {
  ENGINE_TYPES,
  OWNERSHIP_STATUS_TYPES,
  TRAILER_TYPES,
  TRANSMISSION_TYPES,
  VEHICLE_CLASSES,
  VEHICLE_TYPES,
} from "../../constants";
import moment from "moment";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Trailers() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const trailerState = useSelector((state) => state.trailer);

  const toggleFormDisplay = (isEdit = false, trailerId = null) => {
    if (isFormHidden) {
      setSelectedTrailerId(trailerId);
    } else {
      setSelectedTrailerId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && trailerList?.length > 0) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "VIN",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Make",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Model",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Year",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Ownership Status",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) =>
        enumValueFormatter(params, OWNERSHIP_STATUS_TYPES),
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Trailer Type",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) => enumValueFormatter(params, TRAILER_TYPES),
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Axles",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      resizable: false,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [trailerList, setTrailerList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedTrailer, setSelectedTrailer] = useState({});
  const [selectedTrailerId, setSelectedTrailerId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setTrailerList(trailerState.data);
  }, [trailerState.data]);

  useEffect(() => {
    let data = [];
    _.each(trailerList, (trailer, index) => {
      data.push({
        "#": index + 1,
        "Trailer ID": trailer.trailerId,
        VIN: trailer.vin,
        Make: trailer.make,
        Model: trailer.model,
        Year: moment(trailer.year).clone().hour(7).minute(0).second(0).format("yyyy"),
        "Ownership Status": trailer.ownershipStatus,
        "Trailer Type": trailer.trailerType,
        Axles: trailer.axles,
        "Volume Capacity": trailer.volumeCapacity,
        "Weight Capacity": trailer.weightCapacity,
        "Trailer Length": trailer.trailerLength,
        "Trailer Width": trailer.trailerWidth,
        "Trailer Height": trailer.trailerHeight,
        GVW: trailer.gvw,
        "Tare Weight": trailer.tareWeight,
        "Trailer Features": trailer.trailerFeatures,
        Edit: { id: trailer.trailerId, btnClass: "btn-color-7" },
      });
    });

    setRowData(data);
  }, [trailerList]);

  useEffect(() => {
    fillSelectedTrailer(selectedTrailerId);
  }, [selectedTrailerId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getTrailersData();
    dispatch(setTrailersListData(response));
    setIsLoading(false);
  };

  const getTrailersData = async () => {
    return await fetchTrailersByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
  };

  const fillSelectedTrailer = (id) => {
    const trailer = _.find(trailerList, { trailerId: id });
    setSelectedTrailer(trailer);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Trailers"
                : isFormEdit
                  ? "Edit Trailer"
                  : "New Trailer"
            }
            subtitle={"trailers"}
            dataCount={trailerList.length}
            filterData={filterData}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(trailerList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-7-grid color-7-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-7' content='trailer' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <TrailerForm
            isFormEdit={isFormEdit}
            selectedTrailer={selectedTrailer}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  );
}

export default Trailers;
