import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    userId: null,
    firstName: '',
    lastName: '',
    email: '',
    cell: '',
    city: '',
    notes: '',
    phone: '',
    postalCode: '',
    province: null,
    role: null,
    street: '',
    userType: null,
    accountId: null
  },
  isAuthenticated: false
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      return {
        ...state,
        user: {
          userId: action.payload.userId,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          cell: action.payload.cell,
          city: action.payload.city,
          notes: action.payload.notes,
          phone: action.payload.phone,
          postalCode: action.payload.postalCode,
          province: action.payload.provinceState,
          role: action.payload.role,
          street: action.payload.street,
          userType: action.payload.userType,
          accountId: action.payload.accountId
        },
      }
    },
    setAuthenticatedStatus: (state, action) => {
      return {
        ...state,
        isAuthenticated: action.payload
      }
    }
  },
});

export const { setUserData, setAuthenticatedStatus } = authReducer.actions;

export default authReducer.reducer;
