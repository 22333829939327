import React from 'react';
import { BarChart, Area, Cell, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { getTotalCustomersByMonth } from '../../../utils/dashboardUtils';
import { THEME_COLORS, CHART_CONSTANTS } from '../../../constants';

function TopCustomers({ customers }) {
  // Months are zero-indexed, so January starts with 0
  const data = [
    {
      name: 'Walmart',
      amount: Math.floor(Math.random() * 30),
      color: THEME_COLORS.COLOR_5,
    },
    {
      name: 'Costco',
      amount: Math.floor(Math.random() * 30),
      color: THEME_COLORS.COLOR_4,
    },
    {
      name: 'Macdon',
      amount: Math.floor(Math.random() * 30),
      color: THEME_COLORS.COLOR_1,
    },
    {
      name: 'Versatile',
      amount: Math.floor(Math.random() * 30),
      color: THEME_COLORS.COLOR_2,
    },
    {
      name: 'Dulux',
      amount: Math.floor(Math.random() * 30),
      color: THEME_COLORS.COLOR_3,
    },
  ];


  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={300}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid  vertical={false} horizontal={false} />
        <Tooltip cursor={{ fill: "transparent" }} />
        
        <Bar
          dataKey="amount"
          width={50}
        >
          {
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} name={entry.name}/>
            ))
          }
        </Bar>
        <Legend 
          verticalAlign='bottom' 
          align='left' 
          layout='vertical'
          wrapperStyle={{ fontSize: CHART_CONSTANTS.LEGEND_FONT_SIZE, lineHeight: CHART_CONSTANTS.LEGEND_LINE_HEIGHT }}
          formatter={(value, entry, index) => <span style={{color:CHART_CONSTANTS.LEGEND_TEXT_COLOR}}>{value}</span>}
          payload={data.map((entry, index) => ({
          value: entry.name,
          type: 'rect',
          color: entry.color,
        }))}
        />

      </BarChart>
    </ResponsiveContainer>
  )
}

export default TopCustomers
