import { DRIVER_TYPE, EMPLOYMENT_STATUS, DEFAULT_PAY_TYPE } from "../constants"

export const driverTypeValueGetter = (params) => {
    const driverType = params.data["Driver Type"];

    if (!(driverType >= 1 && driverType <= DRIVER_TYPE.length)) {
        return "None";
    } 

    return DRIVER_TYPE[driverType - 1]?.label;
}

export const employmentStatusValueGetter = (params) => {
    const employmentStatus = params.data["Employment Status"];

    if (!(employmentStatus >= 1 && employmentStatus <= EMPLOYMENT_STATUS.length)) {
        return "None";
    } 

    return EMPLOYMENT_STATUS[employmentStatus - 1]?.label;
}

export const defaultPayTypeValueGetter = (params) => {
    const defaultPayType = params.data["Default Pay Type"];

    if (!(defaultPayType >= 1 && defaultPayType <= DEFAULT_PAY_TYPE.length)) {
        return "None";
    } 

    return DEFAULT_PAY_TYPE[defaultPayType - 1]?.label;
}