import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat, NumericFormat } from "react-number-format";
import { TAX_TYPES, PAYMENT_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";
import { TimePicker } from 'antd';
import * as dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import { removeNumberFormat } from '../../utils/formatUtils';
import { provinceCheck } from '../../utils/provinceUtils';
import { DatePicker } from 'antd';

function PrimaryDetails({
  accountProvince,
  isFormEdit,
  selectedExpense = {},
  trigger,
  control,
  register,
  errors,
  setValue,
  getValues,
  options,
  itemList,
  gLCode,
  setGLCode,
  companyOptions,
  setDropdownDisabled,
  isDropdownDisabled,
  selectedCompanyOption,
  setSelectedCompanyOption,
  taxRate,
  selectedExpenseTemplate,
  gLCodeList
}) {
  const [disabledtaxRateField, setDisabledTaxRateField] = useState(true);
  const numberCheck = (value) => {
    return value > 0 ? value : 0;
  }

  const setExpenseTax = (e) => {
    if (!isFormEdit) {
      const { taxType } = getValues();
      var currentTaxRate = 0;
      if (taxType !== undefined && !isNaN(taxType)) {
        if (taxType === 5) {
          if (selectedExpense?.taxType === 5) {
            setValue("taxRate", (provinceCheck(accountProvince, taxType, selectedExpense?.taxRate) * 100).toFixed(2));
            currentTaxRate = (provinceCheck(accountProvince, taxType, selectedExpense?.taxRate) * 100).toFixed(2);
          } else {
            setValue("taxRate", (provinceCheck(accountProvince, taxType) * 100).toFixed(2));
            currentTaxRate = (provinceCheck(accountProvince, taxType, selectedExpense?.taxRate) * 100).toFixed(2);
          }
          setDisabledTaxRateField(false)
        } else {
          setDisabledTaxRateField(true)
          setValue("taxRate", (provinceCheck(accountProvince, taxType) * 100).toFixed(2));
          currentTaxRate = (provinceCheck(accountProvince, taxType) * 100).toFixed(2);
        }
      }

      calculateExpense(currentTaxRate);
    }
  }

  const setExpenseFromDropdown = () => {
    if (!isFormEdit) {
      const { expenseTemplate, taxType } = getValues();
      if ((expenseTemplate !== undefined && expenseTemplate !== null) && TAX_TYPES !== undefined) {
        const taxType = parseInt(itemList[expenseTemplate.index]["taxType"]);
        const defaultRate = parseFloat(itemList[expenseTemplate.index]["defaultRate"]);
        const glCodeId = parseFloat(itemList[expenseTemplate.index]["glCodeId"]);
        var currentTaxRate = 0;
        if (taxType !== undefined && !isNaN(taxType)) {
          if (taxType === 5) {
            if (selectedExpense?.taxType === 5) {
              setValue("taxRate", (provinceCheck(accountProvince, taxType, selectedExpense?.taxRate) * 100).toFixed(2));
              currentTaxRate = (provinceCheck(accountProvince, taxType, selectedExpense?.taxRate) * 100).toFixed(2);
            } else {
              setValue("taxRate", (provinceCheck(accountProvince, taxType) * 100).toFixed(2));
              currentTaxRate = (provinceCheck(accountProvince, taxType, selectedExpense?.taxRate) * 100).toFixed(2);
            }
            setDisabledTaxRateField(false)
          } else {
            setDisabledTaxRateField(true)
            setValue("taxRate", (provinceCheck(accountProvince, taxType) * 100).toFixed(2));
            currentTaxRate = (provinceCheck(accountProvince, taxType) * 100).toFixed(2);
          }
        }

        setValue("description", expenseTemplate.label);
        setValue("quantity", 0);
        setValue("rate", 0);
        setValue("taxType", taxType);
        setValue("taxRate", currentTaxRate);
        setValue("glCodeId", glCodeId);
        setValue("discount", 0);
        setValue("subtotal", 0);
        setValue("total", 0);
        setValue("totalTax", 0);
        setValue("discount", 0);

        setGLCode(parseInt(glCodeId));
      } else {
        setValue("description", undefined);
        setValue("quantity", undefined);
        setValue("rate", undefined);
        setValue("taxType", "");
        setValue("taxRate", undefined);
        setValue("discount", undefined);
        setValue("subtotal", 0);
        setValue("total", 0);
        setValue("totalTax", 0);
        setValue("discount", 0);
        // setValue('accountId', accountId);

        setGLCode("");
      }
    }
  }

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setDropdownDisabled(value === "manualExpense");
  };

  const handleCompanyNameChange = (selectedOption) => {
    setSelectedCompanyOption(selectedOption);
    setValue("company", selectedOption?.label);

    // Manually trigger revalidation after setting the value
    trigger("company");
  };

  const calculateExpense = (currentTaxRate = taxRate) => {
    if (!isFormEdit) {
      const { quantity, rate, discount } = getValues();

      const quantityCheck = numberCheck(quantity);
      const rateCheck = numberCheck(parseFloat(removeNumberFormat(rate)));
      const discountCheck = numberCheck(parseFloat(removeNumberFormat(discount)));
      // const discountCheck = numberCheck(parseFloat(discount));

      const taxRateCheck = numberCheck(parseFloat((removeNumberFormat(currentTaxRate) / 100)));

      const subtotal = parseFloat(quantityCheck * rateCheck);

      const totalTax = parseFloat(((quantityCheck * rateCheck) - discountCheck) * taxRateCheck);
      const total = subtotal + totalTax - discountCheck;

      setValue("subtotal", numberCheck(subtotal).toFixed(2));
      setValue("total", numberCheck(total).toFixed(2));
      setValue("totalTax", numberCheck(totalTax).toFixed(2));
    }
  }

  return (
    <>
      <div className='row'>
        <div className="col-12 col-sm-4 mb-4">
          <label htmlFor="expenseDate" className="form-label">Expense Date</label>
          <Controller
            control={control}
            name='expenseDate'
            register={register}
            rules={{ required: "Expense date is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker className="datepicker-field" onChange={onChange} value={value} />
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.payStartDate && errors.payroll?.payStartDate.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="description" className="form-label">
            Company <span className="required-asterisk">*</span>
          </label>
          <div className="custom-selector">
            <Controller
              name="company"
              control={control}
              register={register}
              rules={{ required: "Company is required" }}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  onChange={handleCompanyNameChange}
                  value={selectedCompanyOption}
                  options={companyOptions}
                  className="company-select"
                  classNamePrefix="react-select"
                  placeholder={"Manually Enter Company"}
                  id="company"
                  styles={selectStyle}
                  isClearable
                  isDisabled={isFormEdit}
                  tabIndex={2}
                />
              )}
            />
          </div>
          <small className="form-error-message">
            {errors?.company && errors.company.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="paymentType" className="form-label">Mode of Payment <span className="required-asterisk">*</span></label>
          <select className="form-select" id="paymentType" disabled={isFormEdit} tabIndex={3}
            {...register("paymentType", { valueAsNumber: true, required: "Mode of Payment is required" })}>
            <option key="none" value="" hidden>Select Mode</option>
            {PAYMENT_TYPES.map((paymentType, index) =>
              <option key={index} value={paymentType.value}>{paymentType.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.paymentType && errors.paymentType.message}
          </small>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6 col-sm-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="radioGroup"
              id="radioButtonManualExpense"
              value="manualExpense"
              onChange={handleRadioChange}
              checked={isDropdownDisabled}
              disabled={isFormEdit}
              tabIndex={4}
            />
            <label
              className="form-check-label"
              htmlFor="radioButtonManualExpense"
            >
              Manually Enter Expense
            </label>
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <div className="d-flex flex-row align-items-center">
            <div className="me-1">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radioGroup"
                  id="radioButtonExpenseDropdown"
                  value="selectExpenseTemplate"
                  onChange={handleRadioChange}
                  checked={!isDropdownDisabled}
                  disabled={isFormEdit}
                  tabIndex={5}
                />
                <label
                  className="form-check-label"
                  htmlFor="radioButtonExpenseDropdown"
                ></label>
              </div>
            </div>
            <div className="ms-1 w-100">
              <div className="custom-selector">
                <Controller
                  name="expenseTemplate"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={options}
                      className="expense-select"
                      classNamePrefix="react-select"
                      placeholder={"Select Expense Template"}
                      onChange={(e) => {
                        field.onChange(e);
                        setExpenseFromDropdown(e);
                      }}
                      id="expense"
                      styles={selectStyle}
                      isClearable
                      isDisabled={isDropdownDisabled || isFormEdit}
                      tabIndex={6}
                      value={selectedExpenseTemplate}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="description" className="form-label">Description <span className="required-asterisk">*</span></label>
          <input type="text" className="form-control" id="description" disabled={isFormEdit} tabIndex={5}
            defaultValue={isFormEdit ? selectedExpense?.description || '' : ''}
            {...register("description", { required: "Description is required" })}
          />
          <small className='form-error-message'>
            {errors?.description && errors.description.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="referenceId" className="form-label">Reference ID</label>
          <input type="text" className="form-control" id="referenceId" disabled={isFormEdit} tabIndex={6}
            defaultValue={isFormEdit ? selectedExpense?.referenceId || '' : ''}
            {...register("referenceId")}
          />
          <small className='form-error-message'>
            {errors?.referenceId && errors.referenceId.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="glCodeId" className="form-label">GL Code: <span className="required-asterisk">*</span></label>
          <select className="form-control" id="glCodeId" value={gLCode} disabled={isFormEdit} tabIndex={7} {...register("glCodeId", { valueAsNumber: true, onChange: (e) => setGLCode(e.target.value), required: "GL code is required" })}>
            <option key="none" value="" hidden>GL Code</option>
            {gLCodeList.map((gl, index) =>
              <option key={index} value={gl.glCodeId}>{gl.glCodeName} - {gl.description}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.glCodeId && errors.glCodeId.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="quantity" className="form-label">Quantity <span className="required-asterisk">*</span></label>
          <input type="number" step="any" className="form-control" id="quantity" disabled={isFormEdit} tabIndex={8}
            defaultValue={isFormEdit ? selectedExpense?.quantity : ''}
            {...register("quantity", { valueAsNumber: true, required: "Quantity is required", onChange: calculateExpense })}
          />
          <small className='form-error-message'>
            {errors?.quantity && errors.quantity.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="rate" className="form-label">Unit Price</label>
          <Controller
            control={control}
            name='rate'
            register={register}
            rules={{ required: "Unit Price is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat
                name={name}
                defaultValue={value}
                value={value}
                onValueChange={({ floatValue }) => {
                  // Update the form value using setValue
                  setValue(name, floatValue);
                  calculateExpense();
                }}
                // onChange={onChange}
                onBlur={onBlur}
                className='form-control'
                prefix="$"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                id="rate"
                mask="_"
                decimalScale={2}
                fixedDecimalScale
                disabled={isFormEdit}
                tabIndex={9}
              />
            )}
          />
          <small className='form-error-message'>
            {errors?.rate && errors.rate.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="taxType" className="form-label">Tax Type</label>
          <select
            className="form-select"
            id="taxType"
            disabled={isFormEdit}
            tabIndex={10}
            {...register("taxType", { valueAsNumber: true, required: "Tax Type is required", onChange: (e) => { setExpenseTax(e); } })}
          >
            <option key="none" value="" hidden>Tax Type</option>
            {TAX_TYPES.map((tax, index) =>
              <option key={index} value={tax.value}>{tax.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.taxType && errors.taxType.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="taxRate" className="form-label">Tax Rate</label>
          <Controller
            control={control}
            name='taxRate'
            register={register}
            rules={{ valueAsNumber: true }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat value={value} name={name} onChange={onChange} onBlur={onBlur} className='form-control' suffix="%" thousandsGroupStyle="thousand" thousandSeparator="," id="taxRate" mask="_" disabled={disabledtaxRateField} onValueChange={({ floatValue }) => {
                // Update the form value using setValue
                setValue(name, floatValue);
                calculateExpense();
              }} />
            )}
          />
          <small className='form-error-message'>
            {errors?.taxRate && errors.taxRate.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="discount" className="form-label">Discount</label>
          <Controller
            control={control}
            name='discount'
            register={register}
            rules={{ required: "Discount is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat
                value={value === undefined ? '' : value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                className='form-control'
                prefix="$"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                id="discount"
                mask="_"
                decimalScale={2}
                fixedDecimalScale
                disabled={isFormEdit}
                tabIndex={11}
              />

            )}
          />
          <small className='form-error-message'>
            {errors?.discount && errors.discount.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="subtotal" className="form-label">Subtotal</label>
          <Controller
            control={control}
            name='subtotal'
            register={register}
            rules={{ valueAsNumber: true }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="subtotal" mask="_" decimalScale={2} disabled fixedDecimalScale />
            )}
          />
          <small className='form-error-message'>
            {errors?.subtotal && errors.subtotal.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="totalTax" className="form-label">Total Tax</label>
          <Controller
            control={control}
            name='totalTax'
            register={register}
            rules={{ valueAsNumber: true }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="totalTax" mask="_" decimalScale={2} disabled fixedDecimalScale />
            )}
          />
          <small className='form-error-message'>
            {errors?.accountName && errors.accountName.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="total" className="form-label">Total</label>
          <Controller
            control={control}
            name='total'
            register={register}
            rules={{ valueAsNumber: true }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="total" mask="_" decimalScale={2} disabled fixedDecimalScale />
            )}
          />
          <small className='form-error-message'>
            {errors?.accountName && errors.accountName.message}
          </small>
        </div>
      </div>

    </>
  );
}

export default PrimaryDetails;
