import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Input } from 'reactstrap';

const QuoteItemCategoryEditor = forwardRef((props, ref) => {
  const { value, values, api } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const refInput = useRef(null);

  useEffect(() => {
    setTimeout(() => refInput.current.focus());
  }, []);

  useEffect(() => {
    setSelectedValue(value);

    
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleBlur = () => {
    api.stopEditing();
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedValue;
    },
  }));

  return (
    <div className="cell-editor">
      <Input
        type="select"
        id="category"
        name="select"
        value={selectedValue}
        innerRef={refInput}
        className="cell-editor-input"
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {values.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
    </div>
  );
});

export default QuoteItemCategoryEditor;