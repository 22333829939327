import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Link } from 'react-router-dom';
import { isSuperAdmin, isNTMSUSer } from '../../utils/roleUtils';
import { displayStatus } from '../../utils/quoteUtils'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSelector } from 'react-redux';
import { QUOTE_STATUS_OLD } from '../../constants';

function QuoteContentHeader({ title, updateQuoteStatus, status, editable, args }) {
  const userType = useSelector((state) => state.auth.user.userType);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [currentStatus, setCurrentStatus] = useState(0)

  const onChangeToggle = (event) => {
    const value = event.target.value
    setModal(!modal)
    setCurrentStatus(value)
  }

  const quoteStatusUpdate = () => {
    updateQuoteStatus(currentStatus)
    setModal(!modal)
  }

  const toolTipTitle = () => {
    let title = ""
    if (isSuperAdmin(userType) || isNTMSUSer(userType)) {
      title = "Only account users can edit quotes"
    } else {
      title = `Cannot edit a quote that's already ${displayStatus(status).toLowerCase()}`
    }

    return title
  }

  return (
    <>
      <div className=' me-3'>
        <Link className="back-button" to='/quotes'>
          <i className="fa-solid fa-arrow-left"></i>
        </Link>
      </div>
      <div className="content-title">
        {title}
      </div>
      <form className='form-container ms-auto'>
        {editable ?
          <div className="search-input-field-container">
            <select className="form-select select-header-text search-input-field" id="Header" onChange={onChangeToggle} value={status}>
              {QUOTE_STATUS_OLD.map((status, index) =>
                <option key={index} value={status.value}>{status.label}</option>
              )}
            </select>
          </div>

          :
          <div className={`d-flex flex-row p-2 text-status-${status === 1 ? 'approved' : 'archived'}`}>
            <div className="me-2"> 
            </div>
            <div className="me-3 mr-2">
              {displayStatus(status)}              
            </div>
            <div>
                <i id="quote-status-tooltip" className="fa-solid fa-lock"></i>
              </div>
            <ReactTooltip
              anchorId="quote-status-tooltip"
              className='tooltip-container'
              place="top"
              content={toolTipTitle}
            />
          </div>
        }
      </form>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Change Quote Status</ModalHeader>
        <ModalBody>
          Are you sure you want to change the status of this quote?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={quoteStatusUpdate}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default QuoteContentHeader