import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Empty, Space } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

function SetupSettings({ payrollSettingsIsEmpty, accountingSettingsIsEmpty }) {
  const navigate = useNavigate();
  const getMessage = () => {
    var message = ""

    if (payrollSettingsIsEmpty && accountingSettingsIsEmpty) {
      message = 'Accounting and Payroll settings'
    }
    else if (payrollSettingsIsEmpty) {
      message = 'Payroll settings'
    }
    else {
      message = 'Accounting settings'
    }
    return message;
  }

  const moveToPage = (route) => {
    navigate(route);
  }

  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
      <Empty
        image="images/empty.png"
        imageStyle={{
          height: 150,
        }}
        description={
          <>
            <p className="title color-4 mb-1">
              No Data Available
            </p>
            <p className='content'>
              The {getMessage()} for this account have not been configured.
            </p>
          </>
        }
      >
        <div className='d-flex justify-content-center mt-5'> {/* Center buttons */}
          <div className='d-flex flex-row flex-wrap align-items-center'>
            {accountingSettingsIsEmpty &&
              <Button
                className='btn btn-md btn-primary me-2'
                onClick={() => moveToPage("/settings/accounting-settings")}
              >
                <Space>
                  <PlusOutlined />
                  Accounting Settings
                </Space>
              </Button>
            }
            {payrollSettingsIsEmpty &&
              <Button
                type="primary"
                className='btn btn-md btn-primary'
                onClick={() => moveToPage("/settings/payroll-settings")}
              >
                <Space>
                  <PlusOutlined />
                  Payroll Settings
                </Space>
              </Button>
            }
          </div>
        </div>
      </Empty>
    </div>
  )
}

export default SetupSettings