import React, { useEffect, useState } from "react";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import { SearchOutlined } from "@mui/icons-material";
import { setNotificationData } from "../../../redux/global/globalReducer";
import { useDispatch } from "react-redux";

function CreateOrderModal({
  modal,
  setModal,
  toggle,
  customerOptions,
  customerCreationInformation,
  setCustomerCreationInformation,
  toggleFormCancelDisplay,
  args,
}) {
  const dispatch = useDispatch();
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCustomers = customerOptions.filter((customer) =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openFormAndCloseModal = (bypassValidation = false) => {
    if (customerCreationInformation === null && !bypassValidation) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: `Please select a customer`,
        })
      );
      return;
    }
    toggleFormCancelDisplay();
    toggle();
  };

  const handleSelectCustomer = (customer, index) => {
    setCustomerCreationInformation(customer);
    setSelectedCustomerIndex(index);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedCustomerIndex(null);
  };

  useEffect(() => {
    setSelectedCustomerIndex(null);
    setCustomerCreationInformation(null);
  }, [modal]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Create Order</ModalHeader>
        <ModalBody>
          <form
            className="row form-container justify-content-center align-items-center"
            id="driver-form"
          >
            <div className="col-12 mb-4">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="search-customer"
                  placeholder="Search Customer Name"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <button className="btn btn-search p-0" type="button">
                  <SearchOutlined />
                </button>
              </div>
            </div>
            <div className="col-12 mb-3">
              <ul className="list-group">
                {filteredCustomers.length === 0 ? (
                  <li className="list-group-item">No results found</li>
                ) : (
                  filteredCustomers.map((customer, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelectCustomer(customer, index)}
                      className={`list-group-item list-group-item-action ${index === selectedCustomerIndex ? "active" : ""
                        }`}
                    >
                      {customer.name}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-4">
                <Button color="primary" onClick={() => openFormAndCloseModal(true)}>
                  -
                </Button>
              </div>
              <div className="col-8 d-flex justify-content-end">
                <Button
                  color="primary"
                  onClick={() => openFormAndCloseModal()}
                  className="me-3"
                >
                  Create
                </Button>
                <Button color="secondary" onClick={() => setModal(false)}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CreateOrderModal;
