import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createPayrollReportFile, emailPayrollReport } from '../../services/reportServices';
import { printPreviewReportWithBase64 } from '../../utils/payrollRunUtils';
import _ from 'lodash';
import { setNotificationData } from '../../redux/global/globalReducer';
import { Modal, Space } from 'antd';
import { MODAL_OPTIONS } from '../../constants';
import { PrinterOutlined, MailOutlined } from '@ant-design/icons';

function PayrollReportMenuModal({
  modal,
  toggle,
  payrollRunId,
  args
}) {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const email = useSelector((state) => state.auth.user.email);

  const sendPayrollReport = async () => {
    const payload = {
      payrollRunId: payrollRunId,
      accountId: selectedAccountState.accountId === null ? accountId : selectedAccountState.accountId,
      toEmail: email,
      subject: 'Payroll Report',
      body: 'Report attached here',
      attachmentFileName: 'Payroll Report'
    };

    const response = await emailPayrollReport(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully emailed" : "Failed email"
          } payroll report.`,
      })
    );
  }

  const printPayrollReport = async () => {
    const payload = {
      payrollRunId,
      accountId: selectedAccountState.accountId === null ? accountId : selectedAccountState.accountId
    };

    const response = await createPayrollReportFile(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }
    printPreviewReportWithBase64(data.base64);
  }

  return (
    <Modal
      open={modal}
      className={`ant-modal-color-4`}
      style={{ top: MODAL_OPTIONS.top }}
      title={
        <Space>
          Employer Remittance Report
        </Space>
      }
      onCancel={toggle}
      footer={
        <>
          <div className='modal-header-container d-flex flex-row-reverse align-items-center'>
            <button className="btn btn-md btn-color-4 inverted w-50" onClick={printPayrollReport}><PrinterOutlined /> &nbsp; Print</button>
            <button className="btn btn-md btn-color-4 inverted me-2 w-50" onClick={sendPayrollReport}><MailOutlined /> &nbsp; Email</button>
          </div>
        </>
      }
    >
    </Modal>
  )
}

export default PayrollReportMenuModal