import React from 'react';

function QuoteStatusRenderer(props) {
  const status = props.data["Status"];

  let label = ""
  let statusClass = "";

  switch (status) {
    case 0:
      statusClass = "status-draft";
      label = "Draft";
      break;
    case 1:
      statusClass = "status-committed";
      label = "Approved";
      break;
    default:
      break;
  }

  

  return (
    <div className={`d-flex justify-content-center align-items-center status-container ${statusClass}`}>
      <p className='status-text mb-0 fw-bold'>{label.toUpperCase()}</p>
    </div>
  )
}

export default QuoteStatusRenderer