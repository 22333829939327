import { Collapse } from "antd";
import _ from "lodash";
import MapLibreGL from "maplibre-gl";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_STATUS } from "../../constants";
import {
  setCoordinatesData,
  setFormPendingCompletion,
  setNotificationData,
} from "../../redux/global/globalReducer";
import { setLoadItemsListData } from "../../redux/loadItems/loadItemsReducer";
import { setOrderData } from "../../redux/order/orderReducer";
import { fetchLoadItemsByLoadId } from "../../services/loadServices";
import { updateOrder } from "../../services/orderServices";
import FormTabs from "../Commons/Layouts/FormTabs";
import ModalRender from "../Commons/ModalRender";
import { ErrorModal } from "../Commons/Modals/ErrorModal";
import TerminalModal from "../Commons/Modals/TerminalModal";
import FormCustomerDetails from "./FormCustomerDetails";
import FormReceiverDetails from "./FormReceiverDetails";
import FormShipperDetails from "./FormShipperDetails";
import LoadItems from "./LoadItems";
import MapDetails from "./MapDetails";
import OrderAccessorial from "./OrderAccessorial";
import OrderDetails from "./OrderDetails";
import OrderQuote from "./OrderQuote";
import dayjs from "dayjs";

function OrderForm({
  isFormEdit = false,
  selectedOrder = {},
  selectedAccessorialList = {},
  selectedQuoteList = {},
  setSelectedQuoteList,
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  customerCreationInformation,
  setCustomerCreationInformation,
  accessorialOptions,
  selectedOrderStatus,
  setSelectedOrderStatus,
}) {
  const apiKey = "f06e3dc5296b40c3b600e0fc4aeae197"; // temp
  const mapContainerRef = useRef(null);

  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    formState,
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;

  const customerState = useSelector((state) => state.customer);
  const terminalState = useSelector((state) => state.terminal);
  const coordinatesState = useSelector((state) => state.global.coordinates);

  const [customerOptions, setCustomerOptions] = useState([]);
  const [dirtyLoadOrderColumns, setDirtyLoadOrderColumns] = useState(false);
  const [terminalOptions, setTerminalOptions] = useState([]);
  const [selectTerminalModal, setSelectTerminalModal] = useState(false);
  const [dirtyQuoteItemColumns, setDirtyQuoteItemColumns] = useState(false);
  const [dirtyQuoteItemFields, setDirtyQuoteItemFields] = useState({});
  const [errorModal, setErrorModal] = useState(false);

  // Customer States
  const [selectedCustomerOption, setSelectedCustomerOption] = useState(null);
  const [selectedCustomerAddressValues, setSelectedCustomerAddressValues] =
    useState({});

  // Shipper States
  const [selectedShipperOption, setSelectedShipperOption] = useState(null);
  const [selectedShipperAddressValues, setSelectedShipperAddressValues] =
    useState({});

  // Receiver States
  const [selectedReceiverOption, setSelectedReceiverOption] = useState(null);
  const [selectedReceiverAddressValues, setSelectedReceiverAddressValues] =
    useState({});

  const [receiverDetails, setReceiverDetails] = useState({});
  const [pickupDetails, setPickupDetails] = useState({});
  const [dropoffDetails, setDropoffDetails] = useState({});

  const selectTerminalToggle = () =>
    setSelectTerminalModal(!selectTerminalModal);
  const toggleErrorModal = () => setErrorModal(!errorModal);

  const isInitialRender = useRef(true);
  const orderDetailsRef = useRef(null);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (
      !_.isEmpty(dirtyFields) ||
      dirtyLoadOrderColumns ||
      !_.isEmpty(dirtyQuoteItemFields) ||
      dirtyQuoteItemColumns
    ) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }
  }, [formState]);

  useEffect(() => {
    if (!_.isEmpty(selectedOrder)) {
      setValue("orderNickName", selectedOrder?.orderNickName);
      setValue("customerId", selectedOrder?.customerId);
      setValue("shipperId", selectedOrder?.shipperId);
      setValue("receiverId", selectedOrder?.receiverId);

      dispatch(
        setCoordinatesData({
          property: "origin",
          data: {
            latitude: selectedOrder?.shipperLatitude,
            longitude: selectedOrder?.shipperLongitude,
            isInitialData: true,
          },
        })
      );

      dispatch(
        setCoordinatesData({
          property: "destination",
          data: {
            latitude: selectedOrder?.receiverLatitude,
            longitude: selectedOrder?.receiverLongitude,
            isInitialData: true,
          },
        })
      );

      console.log("Selected Order:", selectedOrder);

      // Fill in Customer Details
      fillCustomerDetails(selectedOrder);

      // Fill in Shipper Details
      fillShipperDetails(selectedOrder);

      // Fill in Receiver Details
      fillReceiverDetails(selectedOrder);

      getLoadItemsData();
    } else {
      setCustomerCreationInformation(null);
      reset();
    }
  }, [selectedOrder["orderId"]]);

  useEffect(() => {
    loadMap();
  }, [coordinatesState]);

  useEffect(() => {
    clearErrors();
    reset();
  }, [isFormHidden]);

  // Load Customer Options for dropdown
  useEffect(() => {
    if (!_.isEmpty(customerState)) {
      const customers = customerState.data.map((customer) => ({
        value: customer.customerId,
        label: customer.customerName,
      }));
      setCustomerOptions(customers);
    }
  }, [customerState]);

  // Load Terminals
  useEffect(() => {
    if (!_.isEmpty(terminalState)) {
      const terminals = terminalState.data.map((terminal) => ({
        value: terminal.terminalId,
        label: terminal.terminalName,
      }));
      setTerminalOptions(terminals);
    }
  }, [terminalState]);

  const fillCustomerDetails = (order) => {
    setValue("customerName", order.customerName);
    setValue("customerContactName", order.customerContactName);
    setValue("customerDefaultEmail", order.customerDefaultEmail);
    setValue("customerPhoneNumber", order.customerPhoneNumber);
    setValue("customerAddress1", order.customerAddress1);
    setValue("customerAddress2", order.customerAddress2);
    setValue("customerState", order.customerState);
    setValue("customerCity", order.customerCity);
    setValue("customerCountry", order.customerCountry);
    setValue("customerPostal", order.customerPostal);
    setValue("customerNotes", order.customerNotes);

    setSelectedCustomerOption({
      value: order.customerId,
      label: order.customerName,
    });

    setSelectedCustomerAddressValues({
      addressLine1: order?.customerAddress1,
      addressLine2: order?.customerAddress2,
      provinceState: order?.customerState,
      city: order?.customerCity,
      country: order?.customerCountry,
      postalCode: order?.customerPostal,
      addressNotes: order?.customerNotes,
    });
  };

  const fillShipperDetails = (order) => {
    setValue("shipperName", order.shipperName || order.customerName);
    setValue(
      "shipperContactName",
      order.shipperContactName || order.customerContactName
    );
    setValue(
      "shipperDefaultEmail",
      order.shipperDefaultEmail || order.customerDefaultEmail
    );
    setValue(
      "shipperPhoneNumber",
      order.shipperPhoneNumber || order.customerPhoneNumber
    );
    setValue(
      "shipperAddress1",
      order.shipperAddress1 || order.customerAddress1
    );
    setValue(
      "shipperAddress2",
      order.shipperAddress2 || order.customerAddress2
    );
    setValue("shipperState", order.shipperState || order.customerState);
    setValue("shipperCity", order.shipperCity || order.customerCity);
    setValue("shipperCountry", order.shipperCountry || order.customerCountry);
    setValue("shipperPostal", order.shipperPostal || order.customerPostal);
    setValue(
      "shipperAddressNotes",
      order.shipperAddressNotes || order.customerNotes
    );
    setValue(
      "shipperOpenTime",
      dayjs(
        `${dayjs().format("YYYY-MM-DD")}T${order.shipperOpenTime || "08:00:00"}`
      )
    );
    setValue(
      "shipperCloseTime",
      dayjs(
        `${dayjs().format("YYYY-MM-DD")}T${
          order.shipperCloseTime || "17:00:00"
        }`
      )
    );

    setSelectedShipperOption({
      value: order.shipperId || order?.customerId,
      label: order.shipperName || order?.customerName,
    });

    setSelectedShipperAddressValues({
      addressLine1: order?.shipperAddress1 || order?.customerAddress1,
      addressLine2: order?.shipperAddress2 || order?.customerAddress2,
      provinceState: order?.shipperState || order?.customerState,
      city: order?.shipperCity || order?.customerCity,
      country: order?.shipperCountry || order?.customerCountry,
      postalCode: order?.shipperPostal || order?.customerPostal,
      addressNotes: order?.shipperNotes || order?.customerNotes,
    });
  };

  const fillReceiverDetails = (order) => {
    setValue("receiverName", order.receiverName);
    setValue("receiverContactName", order.receiverContactName);
    setValue("receiverDefaultEmail", order.receiverDefaultEmail);
    setValue("receiverPhoneNumber", order.receiverPhoneNumber);
    setValue("receiverAddress1", order.receiverAddress1);
    setValue("receiverAddress2", order.receiverAddress2);
    setValue("receiverState", order.receiverState);
    setValue("receiverCity", order.receiverCity);
    setValue("receiverCountry", order.receiverCountry);
    setValue("receiverPostal", order.receiverPostal);
    setValue("receiverAddressNotes", order.receiverAddressNotes);
    setValue(
      "receiverOpenTime",
      dayjs(
        `${dayjs().format("YYYY-MM-DD")}T${
          order.receiverOpenTime || "08:00:00"
        }`
      )
    );
    setValue(
      "receiverCloseTime",
      dayjs(
        `${dayjs().format("YYYY-MM-DD")}T${
          order.receiverCloseTime || "17:00:00"
        }`
      )
    );

    setSelectedReceiverOption({
      value: order.receiverId,
      label: order.receiverName,
    });

    setSelectedReceiverAddressValues({
      addressLine1: order?.receiverAddress1,
      addressLine2: order?.receiverAddress2,
      provinceState: order?.receiverState,
      city: order?.receiverCity,
      country: order?.receiverCountry,
      postalCode: order?.receiverPostal,
      addressNotes: order?.receiverNotes,
    });
  };

  const loadMap = async () => {
    if (mapContainerRef.current !== null) {
      const shipperLatitude = coordinatesState?.origin?.latitude;
      const shipperLongitude = coordinatesState?.origin?.longitude;
      const receiverLatitude = coordinatesState?.destination?.latitude;
      const receiverLongitude = coordinatesState?.destination?.longitude;

      const shipperCoordinates = `${coordinatesState?.origin?.latitude},${coordinatesState?.origin?.longitude}`;
      const receiverCoordinates = `${coordinatesState?.destination?.latitude},${coordinatesState?.destination?.longitude}`;

      MapLibreGL.accessToken = apiKey;

      if (
        !_.isEmpty(shipperLatitude) &&
        !_.isEmpty(shipperLongitude) &&
        !_.isEmpty(receiverLatitude) &&
        !_.isEmpty(receiverLongitude)
      ) {
        const centerLatitude =
          (parseFloat(coordinatesState?.origin?.latitude) +
            parseFloat(coordinatesState?.destination?.latitude)) /
          2;

        const centerLongitude =
          (parseFloat(coordinatesState?.origin?.longitude) +
            parseFloat(coordinatesState?.destination?.longitude)) /
          2;

        const locations = `${shipperCoordinates}|${receiverCoordinates}`;
        const url = `https://api.geoapify.com/v1/routing?waypoints=${locations}&mode=drive&apiKey=${apiKey}`;

        // @TODO: Add api endpoint
        const response = await fetch(url, {
          method: "GET",
        });

        const routeData = await response.json();

        const map = new MapLibreGL.Map({
          container: mapContainerRef.current,
          style: `https://maps.geoapify.com/v1/styles/klokantech-basic/style.json?apiKey=${apiKey}`,
          center: [centerLongitude, centerLatitude],
          zoom: 14,
        });

        new MapLibreGL.Marker()
          .setLngLat([
            coordinatesState?.origin?.longitude,
            coordinatesState?.origin?.latitude,
          ])
          .addTo(map);

        new MapLibreGL.Marker()
          .setLngLat([
            coordinatesState?.destination?.longitude,
            coordinatesState?.destination?.latitude,
          ])
          .addTo(map);

        map.on("load", () => {
          const geojson = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    ...routeData?.features[0]?.geometry?.coordinates[0],
                  ],
                },
              },
            ],
          };

          map.addSource("line", {
            type: "geojson",
            data: geojson,
          });

          map.addLayer({
            id: "line-animation",
            type: "line",
            source: "line",
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#0d6efd",
              "line-width": 5,
              "line-opacity": 0.8,
            },
          });
        });

        // Add navigation control (optional)
        map.addControl(new MapLibreGL.NavigationControl());

        // Clean up the map instance when the component unmounts
        return () => map.remove();
      } else {
        const map = new MapLibreGL.Map({
          container: mapContainerRef.current,
          style: `https://maps.geoapify.com/v1/styles/klokantech-basic/style.json?apiKey=${apiKey}`,
          center: [121.1656697, 14.5657992],
          zoom: 16,
        });

        return () => map.remove();
      }
    }
  };

  const getLoadItemsData = async () => {
    const response = await fetchLoadItemsByLoadId(selectedOrder?.load?.loadId);
    dispatch(setLoadItemsListData(response));
  };

  const onSubmit = async (payload) => {
    if (orderDetailsRef.current) {
      orderDetailsRef.current.handleUpdateLoadItems(false);
    }

    // Set initially undefined values to defaults
    const defaultPayload = _.defaults(payload, {
      orderId: selectedOrder.orderId,
      customerId: -1,
      shipperId: -1,
      receiverId: -1,
    });

    // Set unformatted values
    _.set(payload, "customerCountry", payload?.customerCountry?.value);
    _.set(payload, "customerState", payload?.customerState?.value);

    _.set(payload, "shipperCountry", payload?.shipperCountry?.value);
    _.set(payload, "shipperState", payload?.shipperState?.value);

    _.set(payload, "receiverCountry", payload?.receiverCountry?.value);
    _.set(payload, "receiverState", payload?.receiverState?.value);

    _.set(payload, "pickupCountry", payload?.shipperCountry?.value);
    _.set(payload, "pickupState", payload?.shipperState?.value);

    _.set(payload, "dropoffCountry", payload?.shipperCountry?.value);
    _.set(payload, "dropoffState", payload?.shipperState?.value);

    _.set(payload, "status", selectedOrder?.status);

    var pickupStartDate = moment(payload.pickupStartDate)
      .clone()
      .hour(7)
      .minute(0)
      .second(0)
      .format("YYYY-MM-DD");
    _.set(payload, "pickupStartDate", pickupStartDate);

    var pickupEndDate = moment(payload.pickupEndDate)
      .clone()
      .hour(7)
      .minute(0)
      .second(0)
      .format("YYYY-MM-DD");
    _.set(payload, "pickupEndDate", pickupEndDate);

    var dropoffStartDate = moment(payload.dropoffStartDate)
      .clone()
      .hour(7)
      .minute(0)
      .second(0)
      .format("YYYY-MM-DD");
    _.set(payload, "dropoffStartDate", dropoffStartDate);

    var dropoffEndDate = moment(payload.dropoffEndDate)
      .clone()
      .hour(7)
      .minute(0)
      .second(0)
      .format("YYYY-MM-DD");
    _.set(payload, "dropoffEndDate", dropoffEndDate);

    // Format time data
    if (
      payload.shipperOpenTime !== "" &&
      payload.shipperOpenTime !== undefined
    ) {
      const shipperOpenTime = moment(payload.shipperOpenTime.$d)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "shipperOpenTime", shipperOpenTime);
    }

    if (
      payload.shipperCloseTime !== "" &&
      payload.shipperCloseTime !== undefined
    ) {
      const shipperCloseTime = moment(payload.shipperCloseTime.$d)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "shipperCloseTime", shipperCloseTime);
    }

    if (
      payload.receiverOpenTime !== "" &&
      payload.receiverOpenTime !== undefined
    ) {
      const receiverOpenTime = moment(payload.receiverOpenTime.$d)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "receiverOpenTime", receiverOpenTime);
    }

    if (
      payload.receiverCloseTime !== "" &&
      payload.receiverCloseTime !== undefined
    ) {
      const receiverCloseTime = moment(payload.receiverCloseTime.$d)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "receiverCloseTime", receiverCloseTime);
    }

    if (
      payload.pickupStartTime !== "" &&
      payload.pickupStartTime !== undefined
    ) {
      const pickupStartTime = moment(payload.pickupStartTime)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "pickupStartTime", pickupStartTime);
    }

    if (payload.pickupEndTime !== "" && payload.pickupEndTime !== undefined) {
      const pickupEndTime = moment(payload.pickupEndTime)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "pickupEndTime", pickupEndTime);
    }

    if (
      payload.dropoffStartTime !== "" &&
      payload.dropoffStartTime !== undefined
    ) {
      const dropoffStartTime = moment(payload.dropoffStartTime)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "dropoffStartTime", dropoffStartTime);
    }

    if (
      payload.dropoffEndTime !== "" &&
      payload.dropoffStartTime !== undefined
    ) {
      const dropoffEndTime = moment(payload.dropoffEndTime)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "dropoffEndTime", dropoffEndTime);
    }

    const response = await updateOrder(defaultPayload);
    const orderData = await response.json();

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${
          response.ok ? "Successfully saved" : "Failed to save"
        } order.`,
      })
    );

    dispatch(setOrderData(orderData));
    reset({}, { keepValues: true });
    dispatch(setFormPendingCompletion(false));
    setIsFormDirty(false);
  };

  const checkPageEditable = () => {
    return selectedOrderStatus > 1;
  };

  const tabItems = [
    {
      label: "Customer Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-3-section">
          <div className="row form-container" id="customer-details-form">
            <FormCustomerDetails
              customerOptions={customerOptions}
              selectedOrder={selectedOrder}
              control={control}
              register={register}
              setValue={setValue}
              errors={errors}
              checkPageEditable={checkPageEditable}
              selectedCustomerOption={selectedCustomerOption}
              setSelectedCustomerOption={setSelectedCustomerOption}
              selectedCustomerAddressValues={selectedCustomerAddressValues}
              setSelectedCustomerAddressValues={
                setSelectedCustomerAddressValues
              }
            />
          </div>
        </div>
      ),
    },
    {
      label: "Shipper Details",
      key: 1,
      forceRender: true,
      children: (
        <div className="content-section-container color-3-section">
          <div className="row form-container" id="customer-details-form">
            <FormShipperDetails
              customerOptions={customerOptions}
              control={control}
              register={register}
              setValue={setValue}
              errors={errors}
              checkPageEditable={checkPageEditable}
              selectedShipperOption={selectedShipperOption}
              setSelectedShipperOption={setSelectedShipperOption}
              selectedShipperAddressValues={selectedShipperAddressValues}
              setSelectedShipperAddressValues={setSelectedShipperAddressValues}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Receiver Details",
      key: 2,
      forceRender: true,
      children: (
        <div className="content-section-container color-3-section">
          <div className="row form-container" id="customer-details-form">
            <FormReceiverDetails
              customerOptions={customerOptions}
              control={control}
              register={register}
              setValue={setValue}
              errors={errors}
              checkPageEditable={checkPageEditable}
              selectedReceiverOption={selectedReceiverOption}
              setSelectedReceiverOption={setSelectedReceiverOption}
              selectedReceiverAddressValues={selectedReceiverAddressValues}
              setSelectedReceiverAddressValues={
                setSelectedReceiverAddressValues
              }
            />
          </div>
        </div>
      ),
    },
    {
      label: "Order Details",
      key: 3,
      forceRender: true,
      children: (
        <div className="content-section-container color-3-section">
          <div className="row form-container" id="customer-details-form">
            <OrderDetails
              selectedOrder={selectedOrder}
              isFormHidden={isFormHidden}
              checkPageEditable={checkPageEditable}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              setDirtyLoadOrderColumns={setDirtyLoadOrderColumns}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Freight",
      key: 4,
      forceRender: true,
      children: (
        <div className="content-section-container color-3-section">
          <div className="row form-container" id="customer-details-form">
            <LoadItems
              isFormEdit={isFormEdit}
              setIsFormDirty={setIsFormDirty}
              selectedOrder={selectedOrder}
              isFormHidden={isFormHidden}
              checkPageEditable={checkPageEditable}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              ref={orderDetailsRef}
              dirtyFields={dirtyFields}
              dirtyLoadOrderColumns={dirtyLoadOrderColumns}
              setDirtyLoadOrderColumns={setDirtyLoadOrderColumns}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Accessorials",
      key: 5,
      forceRender: true,
      children: (
        <div className="content-section-container color-3-section">
          <div className="row form-container" id="customer-details-form">
            <OrderAccessorial
              selectedOrder={selectedOrder}
              accessorialOptions={accessorialOptions}
              selectedAccessorialList={selectedAccessorialList}
              isFormHidden={isFormHidden}
              checkPageEditable={checkPageEditable}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Quotes",
      key: 6,
      forceRender: true,
      children: (
        <div className="content-section-container order-section">
          <div className="row form-container" id="customer-details-form">
            <OrderQuote
              selectedOrder={selectedOrder}
              accessorialOptions={accessorialOptions}
              selectedQuoteList={selectedQuoteList}
              selectedAccessorialList={selectedAccessorialList}
              setSelectedQuoteList={setSelectedQuoteList}
              isFormHidden={isFormHidden}
              checkPageEditable={checkPageEditable}
              setSelectedOrderStatus={setSelectedOrderStatus}
              dirtyQuoteItemColumns={dirtyQuoteItemColumns}
              setDirtyQuoteItemColumns={setDirtyQuoteItemColumns}
              dirtyQuoteItemFields={dirtyQuoteItemFields}
              setDirtyQuoteItemFields={setDirtyQuoteItemFields}
              dirtyLoadOrderColumns={dirtyLoadOrderColumns}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Map Details",
      key: 7,
      forceRender: true,
      children: (
        <div className="content-section-container order-section">
          <div className="row form-container" id="customer-details-form">
            <MapDetails
              selectedOrder={selectedOrder}
              accessorialOptions={accessorialOptions}
              selectedQuoteList={selectedQuoteList}
              selectedAccessorialList={selectedAccessorialList}
              setSelectedQuoteList={setSelectedQuoteList}
              isFormHidden={isFormHidden}
              checkPageEditable={checkPageEditable}
              setSelectedOrderStatus={setSelectedOrderStatus}
            />
          </div>
        </div>
      ),
    },
  ];

  const onError = () => {
    // setErrorModal(true);

    dispatch(
      setNotificationData({
        type: `warning`,
        message: `Missing Fields`,
        description: `Please fill in all the required fields.`,
      })
    );
  };

  return (
    <>
      <div className="collapsible-map-container">
        <Collapse>
          <Panel header="Maps Details" key="1" forceRender={true}>
            <div className="row">
              <div className="col-2">
                <div className="row">
                  <div style={{ minHeight: "225px" }} className="col-12 border">
                    <strong>STATUS</strong> <br />
                    {
                      ORDER_STATUS.find(
                        (item) => item.value === selectedOrderStatus
                      )?.label
                    }
                  </div>
                </div>
                <div className="row">
                  <div style={{ minHeight: "225px" }} className="col-12 border">
                    <strong>DOCUMENTS</strong> <br />
                    <button
                      className={`btn btn-md btn-primary w-100 my-3`}
                      type="submit"
                    >
                      View Documents
                    </button>
                  </div>
                </div>
              </div>
              <div
                ref={(element) => (mapContainerRef.current = element)}
                id="my-map"
                className="col-10"
                style={{
                  height: "450px",
                }}
              />
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <strong>Dispatch</strong>
              </div>
            </div>
            <div className="row mt-3">
              <div style={{ minHeight: "100px" }} className="col-2 border">
                <strong>Available</strong>
              </div>
              <div className="col-3 border">
                <strong>Pick:</strong>{" "}
                {`${selectedOrder?.shipperAddress1} ${selectedOrder?.shipperAddress2}`}
              </div>
              <div className="col-3 border">
                <strong>Drop:</strong>{" "}
                {`${selectedOrder?.receiverAddress1} ${selectedOrder?.receiverAddress2}`}
              </div>
              <div className="col-4 border">
                <strong>Other logistics to be determined</strong>
              </div>
            </div>
            <div className="mt-4 d-flex flex-row">
              <button
                className={`btn btn-md btn-primary me-auto`}
                type="submit"
              >
                Dispatch
              </button>
              <button
                className={`btn btn-md btn-primary`}
                type="submit"
                onClick={selectTerminalToggle}
              >
                Split Leg
              </button>
              <button className={`btn btn-md btn-primary ms-3`} type="submit">
                Add to Manifest
              </button>
            </div>
          </Panel>
        </Collapse>
      </div>

      <form id="orders-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <FormTabs
          items={tabItems}
          tabClassName="color-3"
          isFormHidden={isFormHidden}
        />
        {!checkPageEditable() && (
          <div className="row mt-5">
            <div className="col-12">
              <div className="d-flex flex-row align-items-center">
                <button
                  className={`ms-auto btn btn-primary`}
                  form="orders-form"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
      <ErrorModal
        isOpen={errorModal}
        toggle={toggleErrorModal}
        headerText="Error List"
        errors={errors}
        cancelButtonText="Close"
        buttonColor="danger"
        actionType={"warning"}
      />
      <ModalRender
        modal={modal}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setModal={setModal}
        toggle={toggle}
        isFormEdit={isFormEdit}
        formType={"order"}
        toggleFormDisplay={toggleFormDisplay}
      />

      <TerminalModal
        modal={selectTerminalModal}
        setModal={setSelectTerminalModal}
        toggle={selectTerminalToggle}
        terminalOptions={terminalOptions}
      />
    </>
  );
}

export default OrderForm;
