import { TimePicker } from "antd";
import * as dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { COUNTRY, PROVINCE_STATE, TAX_TYPES } from "../../constants";
import { isAccountAdmin, isSuperAdmin } from "../../utils/roleUtils";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedCustomer = {},
  control,
  register,
  errors,
  setValue,
  reset,
}) {
  const today = dayjs();
  const defaultOpeningHour = dayjs(`${today.format("YYYY-MM-DD")}T08:00:00`);
  const defaultClosingHour = dayjs(`${today.format("YYYY-MM-DD")}T17:00:00`);

  const userType = useSelector((state) => state.auth.user.userType);
  const [defaultTax, setDefaultTax] = useState("");
  const [selectedOpeningHour, setSelectedOpeningHour] = useState(
    dayjs("08:00", "H:mm")
  );
  const [selectedClosingHour, setSelectedClosingHour] = useState(
    dayjs("17:00", "H:mm")
  );
  const [selectedCustomerAddressValues, setSelectedCustomerAddressValues] =
    useState({});

  useEffect(() => {
    if (isFormEdit) {
      const provinceOrState = PROVINCE_STATE.find(
        (provinceState) =>
          provinceState.value === selectedCustomer["provinceState"]
      );
      setDefaultTax(parseInt(selectedCustomer["defaultTaxCode"]));
      setValue("provinceState", provinceOrState);
      setValue("defaultTaxCode", parseInt(selectedCustomer["defaultTaxCode"]));
      setValue("active", selectedCustomer["active"]);
      setValue("phone", selectedCustomer["phone"]);
      setValue("fax", selectedCustomer["fax"]);
      setValue("cell", selectedCustomer["cell"]);
      setValue("notes", selectedCustomer["notes"]);
      setValue("country", parseInt(selectedCustomer["country"]));

      setSelectedCustomerAddressValues({
        addressLine1: selectedCustomer.addressLine1,
        addressLine2: selectedCustomer.addressLine2,
        provinceState: selectedCustomer.provinceState,
        city: selectedCustomer.city,
        country: selectedCustomer.country,
        postalCode: selectedCustomer.postalCode,
        addressNotes: selectedCustomer.notes,
      });

      if (selectedCustomer["openingHour"] != null) {
        const openingHourMoment = dayjs(
          `${today.format("YYYY-MM-DD")}T${selectedCustomer["openingHour"]}`
        );
        setValue("openingHour", openingHourMoment);
        setSelectedOpeningHour(openingHourMoment);
      }

      if (selectedCustomer["closingHour"] != null) {
        const closingHourMoment = dayjs(
          `${today.format("YYYY-MM-DD")}T${selectedCustomer["closingHour"]}`
        );
        setValue("closingHour", closingHourMoment);
        setSelectedClosingHour(closingHourMoment);
      }
    } else {
      setDefaultTax("");
      reset();

      setSelectedOpeningHour(defaultOpeningHour);
      setSelectedClosingHour(defaultClosingHour);
      setValue("openingHour", defaultOpeningHour);
      setValue("closingHour", defaultClosingHour);
    }
  }, [selectedCustomer["customerId"]]);

  const getActiveDefaultValue = () => {
    if (selectedCustomer.length <= 0) {
      return "true";
    }

    return selectedCustomer.active ? "true" : "";
  };

  const setAddressValues = (selectedSuggestion) => {
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedShipperProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (selectedShipperProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue("addressline1", selectedSuggestion?.properties?.address_line1);
    setValue("addressline2", selectedSuggestion?.properties?.address_line2);
    setValue("city", selectedSuggestion?.properties?.city);
    setValue("postalCode", selectedSuggestion?.properties?.postcode);
    setValue("provinceState", selectedShipperProvinceState);
    setValue("country", selectedCountry?.value);
    setValue("latitude", `${selectedSuggestion?.properties?.lat}`);
    setValue("longitude", `${selectedSuggestion?.properties?.lon}`);
  };

  const handleOpeningHourOnChange = (time) => {
    const openingHour = dayjs(
      moment(time).set({ seconds: 0 }).format("HH:mm"),
      "H:mm"
    );

    setSelectedOpeningHour(openingHour);
    setValue("openingHour", openingHour);
  };

  const handleClosingHourOnChange = (time) => {
    const closingHour = dayjs(
      moment(time).set({ seconds: 0 }).format("HH:mm"),
      "H:mm"
    );

    setSelectedClosingHour(closingHour);
    setValue("closingHour", closingHour);
  };

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="name" className="form-label">
          Name <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          tabIndex={1}
          defaultValue={isFormEdit ? selectedCustomer?.customerName || "" : ""}
          autoComplete="on"
          {...register("customerName", { required: "Name is required" })}
        />
        <small className="form-error-message">
          {errors?.customerName && errors.customerName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="primaryContact" className="form-label">
          Primary Contact <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="primaryContact"
          tabIndex={2}
          defaultValue={
            isFormEdit ? selectedCustomer?.primaryContact || "" : ""
          }
          autoComplete="on"
          {...register("primaryContact", {
            required: "Primary Contract is required",
          })}
        />
        <small className="form-error-message">
          {errors?.primaryContact && errors.primaryContact.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultEmail" className="form-label">
          Default Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="defaultEmail"
          className="form-control"
          id="defaultEmail"
          tabIndex={3}
          defaultValue={isFormEdit ? selectedCustomer?.defaultEmail || "" : ""}
          autoComplete="on"
          {...register("defaultEmail", {
            required: "defaultEmail is required",
          })}
        />
        <small className="form-error-message">
          {errors?.defaultEmail && errors.defaultEmail.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="billingEmail" className="form-label">
          Billing Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="billingEmail"
          className="form-control"
          id="billingEmail"
          tabIndex={4}
          defaultValue={isFormEdit ? selectedCustomer?.billingEmail || "" : ""}
          autoComplete="on"
          {...register("billingEmail", {
            required: "Billing Email is required",
          })}
        />
        <small className="form-error-message">
          {errors?.billingEmail && errors.billingEmail.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="notificationEmail" className="form-label">
          Notification Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="notificationEmail"
          className="form-control"
          id="notificationEmail"
          tabIndex={5}
          defaultValue={
            isFormEdit ? selectedCustomer?.notificationEmail || "" : ""
          }
          autoComplete="on"
          {...register("notificationEmail", {
            required: "Notification Email is required",
          })}
        />
        <small className="form-error-message">
          {errors?.notificationEmail && errors.notificationEmail.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultTaxCode" className="form-label">
          Default Tax Code: <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={defaultTax}
          tabIndex={6}
          id="defaultTaxCode"
          {...register("defaultTaxCode", {
            valueAsNumber: true,
            onChange: (e) => setDefaultTax(e.target.value),
            required: "Default Tax Code is required",
          })}
        >
          <option key="none" value="" hidden>
            Default Tax Code
          </option>
          {TAX_TYPES.map((tax, index) => (
            <option key={index} value={tax.value}>
              {tax.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.defaultTaxCode && errors.defaultTaxCode.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="taxId" className="form-label">
          Tax ID: <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="taxId"
          tabIndex={7}
          defaultValue={isFormEdit ? selectedCustomer?.taxId || "" : ""}
          {...register("taxId", { required: "Tax ID is required" })}
        />
        <small className="form-error-message">
          {errors?.taxId && errors.taxId.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="phone" className="form-label">
          Phone <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="phone"
          register={register}
          rules={{ required: "Phone is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              value={value === undefined ? "" : value}
              onChange={onChange}
              onBlur={onBlur}
              getInputRef={ref}
              className="form-control"
              tabIndex={8}
              format="(###) ###-####"
              id="phone"
              mask="_"
              allowEmptyFormatting
              autoComplete="on"
            />
          )}
        />
        <small className="form-error-message">
          {errors?.phone && errors.phone.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="fax" className="form-label">
          Fax
        </label>
        <Controller
          control={control}
          name="fax"
          register={register}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              defaultValue={isFormEdit ? selectedCustomer?.fax || "" : ""}
              value={isFormEdit ? selectedCustomer?.fax || "" : ""}
              onChange={onChange}
              tabIndex={9}
              onBlur={onBlur}
              className="form-control"
              format="(###) ###-####"
              id="fax"
              mask="_"
              allowEmptyFormatting
            />
          )}
        />
        <small className="form-error-message">
          {errors?.fax && errors.fax.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="mobile" className="form-label">
          Mobile
        </label>
        <Controller
          control={control}
          name="cell"
          register={register}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              defaultValue={isFormEdit ? selectedCustomer?.cell || "" : ""}
              value={isFormEdit ? selectedCustomer?.cell || "" : ""}
              onChange={onChange}
              tabIndex={10}
              onBlur={onBlur}
              className="form-control"
              format="(###) ###-####"
              id="mobile"
              mask="_"
              allowEmptyFormatting
            />
          )}
        />
        <small className="form-error-message">
          {errors?.cell && errors.cell.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="openingHour" className="form-label">
          Opening Hour
        </label>
        <div>
          <Controller
            control={control}
            name="openingHour"
            register={register}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <TimePicker
                className="antd-time-picker"
                format="H:mm"
                name={name}
                tabIndex={11}
                use12Hours={false}
                value={selectedOpeningHour}
                onChange={(e) => handleOpeningHourOnChange(e.$d)}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.openingHour && errors.openingHour.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="closingHour" className="form-label">
          Closing Hour
        </label>
        <div>
          <Controller
            control={control}
            name="closingHour"
            register={register}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <TimePicker
                className="antd-time-picker"
                name={name}
                tabIndex={12}
                format="H:mm"
                value={selectedClosingHour}
                onChange={(e) => handleClosingHourOnChange(e.$d)}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.closingHour && errors.closingHour.message}
        </small>
      </div>

      <div className="col-12 p-0 mb-3">
        <hr></hr>
      </div>

      <AutocompleteAddressForm
        setAddressValues={setAddressValues}
        selectedOrderAddressValues={selectedCustomerAddressValues}
      />

      {(isSuperAdmin(userType) || isAccountAdmin(userType)) && (
        <div className="col-12 mb-2 mt-3">
          <div className="form-check">
            <input
              className="form-check-input align-middle"
              type="checkbox"
              value=""
              id="active"
              tabIndex={20}
              defaultChecked={getActiveDefaultValue}
              {...register("active")}
            />
            <label className="form-check-label align-middle " htmlFor="active">
              {" "}
              Active
            </label>
            <small className="form-error-message">
              {errors?.active && errors.active.message}
            </small>
          </div>
        </div>
      )}
    </>
  );
}

export default PrimaryDetails;
