import { get, post, patch, remove } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchOrderAccessorials = async () => {
  return get({ path: 'OrderAccessorial' });
}

export const fetchOrderAccessorialsFiltered = async (accountId) => {
  return get({ path: `OrderAccessorial/filtered` });
}

export const addOrderAccessorial = async (payload) => {
  return post({ path: 'OrderAccessorial', body: payload });
}

export const updateOrderAccessorial = async (payload) => {
  return patch({ path: `OrderAccessorial/${payload.accessorialId}`, body: payload });
}

export const deleteOrderAccessorial = async (orderAccessorialId) => {
    return remove({ path: `OrderAccessorial/${orderAccessorialId}` });
  }

export const fetchOrderAccessorialsByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchOrderAccessorialsFiltered(accountId) : await fetchOrderAccessorialsFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchOrderAccessorialsFiltered(accountId);
  }
  
  return await fetchOrderAccessorials();
}