import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { fetchDashboardStats } from '../../services/dashboardServices';
import { getOutstandingInvoicesByYear, getPaidInvoicesByYear, getTotalCustomersByYear, getUnsettledQuotesByYear } from '../../utils/dashboardUtils';
import moment from 'moment';
import Customers from './charts/Customers';
import Quotes from './charts/Quotes';
import MonthlyOrders from './charts/MonthlyOrders';
import TopCustomers from './charts/TopCustomers';
import DeliveryMetrics from './charts/DeliveryMetrics';
import Invoice from './charts/Invoice';
import Sales from './charts/Sales';
import Summary from './charts/Summary';
import CardHeader from '../Commons/Layouts/CardHeader';
import { DASHBOARD_CONSTANTS_1, DASHBOARD_CONSTANTS_2 } from '../../constants';
import {
  ShoppingCartOutlined,
  CalendarMonthOutlined,
  ScheduleOutlined,
  PinDropOutlined
} from "@mui/icons-material";


function Dashboard() {
  const userState = useSelector((state) => state.auth.user);
  const [dashboardData, setDashboardData] = useState({});

  const fillDashboardStats = async () => {
    const currentFiscalYear = moment().year();
    const response = await fetchDashboardStats(userState?.accountId);
    setDashboardData({
      customersCount: getTotalCustomersByYear(response.customer, currentFiscalYear),
      unsettledQuotesCount: getUnsettledQuotesByYear(response.quote, currentFiscalYear),
      outstandingInvoicesCount: getOutstandingInvoicesByYear(response.invoice, currentFiscalYear),
      paidInvoicesCount: getPaidInvoicesByYear(response.invoice, currentFiscalYear),
      customerData: response.customer,
      quotesData: response.quote,
    })
  }

  useEffect(() => {
    fillDashboardStats();
  }, [])

  return (
    <div className='dashboard-container'>
      {/* Summary Stats */}
      <div className='dashboard-stats-container mb-4'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-3 mb-2'>
            <div className="card color-1 shadow-sm">
              <div className="card-body py-3">
                <div className='d-flex align-items-center'>
                  <div className='text-center'>
                    <div className='stats-icon-container color-1 d-flex align-items-center justify-content-center'>
                      <ShoppingCartOutlined fontSize='inherit' />
                    </div>
                  </div>
                </div>
                <div className='ms-0 mt-2'>
                  <p className="stats-count mb-0">{Math.floor(Math.random() * 50)}</p>
                  <p className="stats-label mb-0">Total Orders Created Today</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-2'>
            <div className="card color-5 shadow-sm">
              <div className="card-body py-3">
                <div className='d-flex align-items-center'>
                  <div className='ctext-center'>
                    <div className='stats-icon-container color-5 d-flex align-items-center justify-content-center'>
                      <CalendarMonthOutlined fontSize='inherit' />
                    </div>
                  </div>

                </div>
                <div className='ms-0 mt-2'>
                  <p className="stats-count mb-0">{Math.floor(Math.random() * 70)}</p>
                  <p className="stats-label mb-0">Total Orders Booked Today</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-2'>
            <div className="card color-3 shadow-sm">
              <div className="card-body py-3">
                <div className='d-flex align-items-center'>
                  <div className='text-center'>
                    <div className='stats-icon-container color-3 d-flex align-items-center justify-content-center'>
                      <ScheduleOutlined fontSize='inherit' />
                    </div>
                  </div>
                </div>
                <div className='ms-0 mt-2'>
                  <p className="stats-count mb-0">{Math.floor(Math.random() * 150)}</p>
                  <p className="stats-label mb-0">Total Orders Scheduled Today</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-2'>
            <div className="card color-4 shadow-sm">
              <div className="card-body py-3">
                <div className='d-flex align-items-center'>
                  <div className='text-center'>
                    <div className='stats-icon-container color-4 d-flex align-items-center justify-content-center'>
                      <PinDropOutlined fontSize='inherit' />
                    </div>
                  </div>
                </div>
                <div className='ms-0 mt-2'>
                  <p className="stats-count mb-0">{Math.floor(Math.random() * 100)}</p>
                  <p className="stats-label mb-0">Total Orders Picked Up Today</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Charts */}
      <div className='dashboard-charts-container'>
        <div className='row'>
          <div className='col-12 col-md-8 col-lg-8 mb-4'>
            <div className="card color-4 monthly-orders shadow-sm border border-0">
              <div className="card-body py-3">
                <p className='fw-bold'>Monthly Orders This Year</p>
                <MonthlyOrders customers={dashboardData?.customerData} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-4 mb-4'>
            <div className="card monthly-orders shadow-sm border border-0">
              <div className="card-body py-3">
                <CardHeader 
                  title="Summary"
                  options={DASHBOARD_CONSTANTS_1}
                />
                <Summary customers={dashboardData?.customerData} />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-md-6 col-xl-3 mb-4'>
            <div className="card color-5 monthly-orders shadow-sm border border-0">
              <div className="card-body py-3">
                <CardHeader 
                  title="Top Customers"
                  options={DASHBOARD_CONSTANTS_1}
                />
                <TopCustomers customers={dashboardData?.customerData} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-4'>
            <div className="card color-1 monthly-orders shadow-sm border border-0">
              <div className="card-body py-3">
                <CardHeader 
                  title="Delivery Metrics"
                  options={DASHBOARD_CONSTANTS_1}
                />
                <DeliveryMetrics customers={dashboardData?.customerData} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-4'>
            <div className="card color-3 monthly-orders shadow-sm border border-0">
              <div className="card-body py-3">
                <CardHeader 
                  title="Invoice"
                  options={DASHBOARD_CONSTANTS_1}
                />
                <Invoice customers={dashboardData?.customerData} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-4'>
            <div className="card color-4 monthly-orders shadow-sm border border-0">
              <div className="card-body py-3">
              <CardHeader 
                title="Sales"
                options={DASHBOARD_CONSTANTS_2}
              />
              <Sales customers={dashboardData?.customerData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard
