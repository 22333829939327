import React, { useState } from 'react';
import { getTotalCustomersByMonth } from '../../../utils/dashboardUtils';
import { THEME_COLORS, CHART_CONSTANTS } from '../../../constants';
import { formatCurrency } from '../../../utils/formatUtils';

function Summary({ customers }) {
  // Just for design, will be changed to the actual data
  const [revenue, setRevenue] = useState(Math.floor(Math.random() * 20 - 10));
  const [costs, setCosts] = useState(Math.floor(Math.random() * 20 - 10));
  const [profit, setProfit] = useState(Math.floor(Math.random() * 20 - 10));
  const [avgDeliveryTime, setAvgDeliveryTime] = useState(Math.floor(Math.random() * 20 - 10));
  // Months are zero-indexed, so January starts with 0
  const data = [
    { name: 'Paid', value: Math.floor(Math.random() * 30) },
    { name: 'Unpaid', value: Math.floor(Math.random() * 30) },
    { name: 'Not Sent', value: Math.floor(Math.random() * 30) },
  ];
  const COLORS = [THEME_COLORS.COLOR_1, THEME_COLORS.COLOR_3, THEME_COLORS.COLOR_5];

  return (
    <>
      <div>
        <div className='col-12 mb-2' >
          <div className="card color-1 colored-bg shadow-sm">
            <div className="card-body summary py-3">
              <div className='d-flex align-items-end'>
                <div className='ms-3 me-auto'>
                  <p className="summary-label">Revenue</p>
                  <p className="summary-count">{formatCurrency(Math.floor(Math.random() * 10000))} </p>
                </div>
                <div className='me-3'>
                  <p className={`summary-percentage ${revenue > 0 ? 'positive' : 'negative'}`}>({revenue}%)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 mb-2' >
          <div className="card color-5 colored-bg shadow-sm">
            <div className="card-body summary py-3">
              <div className='d-flex align-items-end'>
                <div className='ms-3 me-auto align-items-center'>
                  <p className="summary-label">Costs</p>
                  <p className="summary-count">{formatCurrency(Math.floor(Math.random() * 5000))} </p>
                </div>
                <div className='me-3 '>
                  <p className={`summary-percentage ${costs > 0 ? 'positive' : 'negative'}`}>({costs}%)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 mb-2' >
          <div className="card color-3 colored-bg shadow-sm">
            <div className="card-body summary py-3">
              <div className='d-flex align-items-end'>
                <div className='ms-3 me-auto align-items-center'>
                  <p className="summary-label">Profit</p>
                  <p className="summary-count">{formatCurrency(Math.floor(Math.random() * 15000))}</p>
                </div>
                <div className='me-3 '>
                  <p className={`summary-percentage ${profit > 0 ? 'positive' : 'negative'}`}>({profit}%)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 mb-2' >
          <div className="card color-4 colored-bg shadow-sm">
            <div className="card-body summary py-3">
              <div className='d-flex align-items-end'>
                <div className='ms-3 me-auto align-items-center'>
                  <p className="summary-label">Avg Delivery Time</p>
                  <p className="summary-count">{Math.floor(Math.random() * 100)}h</p>
                </div>
                <div className='me-3 '>
                  <p className={`summary-percentage ${avgDeliveryTime > 0 ? 'positive' : 'negative'}`}>({avgDeliveryTime}%)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Summary
