import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Cookies from "universal-cookie";
import { isSuperAdmin } from "../../utils/roleUtils";
import ReportsMenu from "./ReportsMenu";
import { formatUserType } from "../../utils/formatUtils";
import { CaretDownOutlined } from "@ant-design/icons";

function NavMenu() {
  const { logout } = useAuth0();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const authState = useSelector((state) => state.auth);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const cookies = new Cookies();

  const clearStorages = () => {
    localStorage.clear();
    sessionStorage.clear();
    cookies.remove("token");
  };

  const handleLogout = (event) => {
    event.preventDefault();
    clearStorages();
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI,
      },
    });
  };

  const onIdle = () => {
    if (authState?.isAuthenticated) {
      clearStorages();
      setIsModalVisible(true);
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 3600 * 1000,
    throttle: 500,
    crossTab: true,
    stopOnIdle: true,
  });

  const handleRedirect = (event) => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <>
      <nav className="navbar bg-white non-printable">
        <div className="container-fluid">
          <a className="navbar-brand" href="/#" onClick={handleRedirect}>
            <img src="/images/logo.png" alt="NTMS Tax Logo" />
          </a>

          {accountState.accountName !== undefined &&
            !isSuperAdmin(authState?.user?.userType) && (
              <div className="me-5 selected-account ms-auto me-2">
                {accountState.accountName}
              </div>
            )}
          {authState?.isAuthenticated && (
            <>
              <div className="navbar-items dropdown-center ms-auto me-3 d-none d-lg-block">
                <div
                  className="dropdown-toggle d-flex"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/images/icon-reports.png"
                    alt="Reports Icon"
                    className="icon circle-rounded me-2"
                  />
                  <div className="reports-info">
                    <div className="reports-label">
                      Reports <CaretDownOutlined />
                    </div>
                  </div>
                </div>
                <ul className="dropdown-menu dropdown-menu-end">
                  <ReportsMenu />
                </ul>
              </div>

              <div className="navbar-items dropdown ms-3 me-4 d-none d-lg-block">
                <div
                  className="dropdown-toggle d-flex align-items-center"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/images/icon-user.png"
                    alt="User Icon"
                    className="icon circle-rounded me-2"
                  />
                  <div className="user-info">
                    <div className="user-name">
                      {`${authState?.user?.firstName} ${authState?.user?.lastName}`} <CaretDownOutlined />
                    </div>
                    <div className="user-type">
                      {formatUserType(authState?.user?.userType)}
                    </div>
                  </div>
                </div>

                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
          <button
            className="navbar-toggler ms-auto d-lg-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseMenuItems"
            aria-controls="collapseDirectory"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
      <Modal isOpen={isModalVisible} backdrop="static" centered>
        <ModalHeader cssModule={{ "modal-title": "w-100 text-center" }}>
          Session Expired
        </ModalHeader>
        <ModalBody>
          <p className="text-center my-3">
            Your session has timedout due to inactivity. <br />
            Please login again to proceed.
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            className={`btn btn-primary`}
            type="submit"
            onClick={handleLogout}
          >
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default NavMenu;
