import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import DeletePayrollRunLineItemModal from '../../../Commons/Modals/DeletePayrollRunLineItemModal';
import EditPayrollRunLineItemModal from '../../../Commons/Modals/EditPayrollRunLineItemModal';
import { useDispatch } from 'react-redux';
import { setPayrollRunLineItem } from '../../../../redux/payrollRunLineItems/payrollRunLineItemsReducer';
import { DeleteOutlined } from '@ant-design/icons';
import { ConfirmationModal } from '../../../Commons/Modals/ConfirmationModal';
import _ from 'lodash';

function ActionRenderer({
  refreshCell,
  editable,
  lineItemGridRef,
  removeToggle
}, args) {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const editToggle = () => setEditModal(!editModal);
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);
  const [body, setBody] = useState("edit")
  const [updatedPayrollRunLineItem, setUpdatedPayrollRunLineItem] = useState([]);
  const selectedNode = lineItemGridRef?.current?.api?.getSelectedNodes();

  const toggleClose = () => {
    setEditModal(!editModal);
    dispatch(setPayrollRunLineItem(updatedPayrollRunLineItem));
  }

  useEffect(() => {
    refreshCell();
  }, [])

  return (
    <div className='cell-renderer-content'>
      <button
        onClick={editToggle}
        className={`btn btn-color-4 ${editable ? "btn-edit" : "btn-sm"}`}>
        <i className={`fa-solid ${editable ? "fa-pen" : "fa-eye"}`}></i>
        {editable && "    Edit"}
      </button>
      {editable === true && <button onClick={deleteToggle} className="btn btn-color-delete btn-sm"><DeleteOutlined /></button>}
      {(!_.isEmpty(selectedNode)) &&
        <EditPayrollRunLineItemModal
          toggle={editToggle}
          modal={editModal}
          toggleClose={toggleClose}
          lineItemGridRef={lineItemGridRef}
          editable={editable}
          onUpdate={setUpdatedPayrollRunLineItem}
        />
      }

      <ConfirmationModal
        isOpen={deleteModal}
        toggle={deleteToggle}
        onClick={removeToggle}
        headerText="Delete Payroll Run Line Item"
        bodyText="Are you sure you want to delete this item?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        actionType="delete"
      />
    </div>
  )
}

export default ActionRenderer