import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactDatePicker from "react-datepicker";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { singleDateCheck } from '../../../utils/reportUtils';
function ReportsModal({
  modal,
  toggle,
  args,
  printReportList,
  dateRange,
  register,
  handleSubmit,
  Controller,
  control,
  setValue,
  reportLabel
}) {
  useEffect(() => {
    if (!singleDateCheck(reportLabel)) {
      setValue("reportDateRange", dateRange)
    }
    else {
      setValue("reportStartDate", dateRange[0])
    }

  }, [reportLabel])
  return (
    <Modal isOpen={modal} toggle={() => toggle(null)} {...args}>
      <ModalHeader toggle={() => toggle(null)}>{reportLabel !== null ? reportLabel + " Report" : ""}</ModalHeader>
      <ModalBody className='d-flex '>
        <form
          className='row form-container justify-content-center align-items-center'
          id='create-report'
          onSubmit={handleSubmit(printReportList)}
        >
          <div className='col-7 my-3'>
            {!singleDateCheck(reportLabel) ?
              <>
                <p htmlFor='reportDateRange' className='form-label'>
                  Date Range
                </p>
                <Controller
                  control={control}
                  name='reportDateRange'
                  id='reportDateRange'
                  register={register}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DateRangePicker
                      id='reportDateRange'
                      name='reportDateRange'
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </>
              :
              <>
                <p htmlFor='reportStartDate' className='form-label'>
                  Start Date
                </p>
                <Controller
                  control={control}
                  name='reportStartDate'
                  register={register}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      className='datepicker-field'
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      id="reportStartDate"
                      dateFormat='yyyy-MM-dd'
                    />
                  )}
                />
              </>
            }
          </div>
          <div className='d-flex flex-row-reverse align-items-center mt-3'>
            <button
              className='btn btn-primary ms-2'
              form='create-report'
              type='submit'
            >
              Ok
            </button>
            <button
              className='btn btn-primary'
              type='button'
              onClick={toggle}
            >
              Cancel
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ReportsModal
