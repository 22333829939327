import React from 'react'
import { formatDate, formatDateOnly, formatCurrency, formatPhoneNumber } from '../../utils/formatUtils';

function InvoiceDetails({
  name,
  email,
  phone,
  address,
  estimateNo,
  estimateName,
  estimateDate,
  subtotal,
  discount,
  total,
  tax,
  printMode,
  balance,
  balanceCheck,
  takePayment,
  printInvoiceItems,
  setIsOpen
}) {
  return (
    <div className='content-header-container quote-details-container row'>
      <div className={`${printMode ? 'col-6' : 'col-12'} col-lg-6 mb-2`}>
        <div className='quote-details-container color-4 shadow-sm'>
          <div className="content-title bolded mb-3">
            Customer
          </div>
          <div className='row quote-details'>
            <div className="col-12">
              <p className='mb-2 fw-bold'>Name: <span className='content'>{name}</span></p>
            </div>
            <div className="col-12">
              <p className='mb-2 fw-bold'>Email: <span className='content'>{email}</span></p>

            </div>
            <div className="col-12">
              <p className='mb-2 fw-bold'>Phone: <span className='content'>{formatPhoneNumber(phone)}</span></p>
            </div>
            <div className="col-12">
              <p className='mb-2 fw-bold'>Address: <span className='content'>{address}</span></p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${printMode ? 'col-6' : 'col-12'} col-lg-6 mb-2`}>
        <div className="quote-details-container color-4 shadow-sm">
          <div className="content-title bolded d-flex flex-row align-items-center mb-3 my-auto">
            Invoice Details - {balance > 0 ? <span className='text-danger'>{formatCurrency(balance)}</span> : 'Paid in Full'}
            <p className='mb-2 fw-bold ms-auto me-2 my-auto'>Created by: <span className='content'>Account User</span></p>
            <button className="btn btn-color-4 me-2" onClick={printInvoiceItems}>Print</button>
            <button className="btn btn-color-4" onClick={() => setIsOpen(true)}>Send Email</button>
          </div>
          <div className='row quote-details'>
            <div className="col-4">
              <p className='mb-2 fw-bold'>Invoice No.: <span className='content'>{estimateNo}</span></p>
            </div>
            <div className="col-4">
              <p className='mb-2 fw-bold'>Subtotal: <span className='content'>{formatCurrency(subtotal)}</span></p>
            </div>
            <div className="col-4"></div>
            <div className="col-4">
              <p className='mb-2 fw-bold'>Invoice Name: <span className='content'>{estimateName}</span></p>
            </div>
            <div className="col-4">
              <p className='mb-2 fw-bold'>Discount: <span className='content'>{formatCurrency(discount)}</span></p>
            </div>
            <div className="col-4"></div>
            <div className="col-4">
              <p className='mb-2 fw-bold'>Invoice Date: <span className='content'>{formatDateOnly(estimateDate)}</span></p>
            </div>
            <div className="col-4">
              <p className='mb-2 fw-bold'>Tax: <span className='content'>{formatCurrency(tax)}</span></p>
            </div>
            <div className="col-4">
              <p className='mb-2 fw-bold total'>Total: {formatCurrency(total)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceDetails