import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import ModalRender from '../Commons/ModalRender';
import { isSuperAdmin, isNTMSUSer, isAccountUser, getUserTypeList } from '../../utils/roleUtils';
import { addUser, updateUser } from '../../services/userServices';
import ReactDatePicker from "react-datepicker";
import moment from 'moment';
import { fetchGLCodeFilteredByAccountAndType } from '../../services/glCodeServices';
import { fetchAccounts } from '../../services/accountServices';
import Select from 'react-select'
import { selectStyle } from '../../utils/styleUtils';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { PROVINCE_STATE, } from '../../constants';
import { fetchPayrollSettingsEmployee } from '../../services/payrollSettingsServices';
import { getAccountIdForEmployee } from '../../utils/userUtils';
import { removePhoneFormat, removeDollar, removePercentage, removeDays } from '../../utils/formatUtils';
import PrimaryDetails from './PrimaryDetails';
import FormTabs from '../Commons/Layouts/FormTabs';
import { setFormPendingCompletion } from "../../redux/global/globalReducer";
import { setNotificationData } from '../../redux/global/globalReducer';
import dayjs from 'dayjs';

function UserForm({
  isFormEdit = false,
  selectedUser = {},
  isFormHidden,
  fillUserList,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton

}) {
  ;
  const { 
    control, 
    register, 
    unregister, 
    handleSubmit, 
    setValue, 
    clearErrors, 
    watch, 
    reset, 
    setFocus, 
    formState
  } = useForm({
    defaultValues: {
      payroll: {
        payStartDate: isFormEdit ? new Date(selectedUser?.payroll?.payStartDate) || '' : ''
      }
    }

  });
  const { dirtyFields, errors, isDirty } = formState;
  var options = []
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.user.userType);
  const accountId = useSelector((state) => state.auth.user.accountId);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [employeeLevel, setEmployeeLevel] = useState("");
  const [emergencyContactRelationship, setEmergencyContactRelationship] = useState("");

  const [provinceState, setProvinceState] = useState("");

  const [tD1Province, setTD1Province] = useState("");
  const [tD2Federal, setTD2Federal] = useState("");
  const [payFrequency, setPayFrequency] = useState("");
  const [vacationType, setVacationType] = useState("");
  const [payMethodType, setPayMethodType] = useState("");
  const [payrollSettings, setPayrollSettings] = useState();
  const [gLCode, setGLCode] = useState("");

  const [isPayrollFormHidden, setIsPayrollFormHidden] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const isInitialRender = useRef(true);
  const vacationTypeWatch = watch("payroll.vacationType")
  const accountIdWatch = watch("accountId");
  const accountIdRef = useRef();
  const provinceStateRef = useRef("null");

  const provinceStateOptions = PROVINCE_STATE;

  useEffect(() => {
    if (isSuperAdmin(userType)) {
      setUserTypeList(getUserTypeList(accountState.accountId, accountIdWatch?.value, userType));
      if (accountIdWatch !== undefined) {
        fillPayrollSettings(accountIdWatch?.value)
        fillGLCode(accountIdWatch?.value);
        if (payrollSettings == null) {
          setDefaultFormPayrollValue();
        }
      }
    }

  }, [accountIdWatch])



  // useStates
  const [userTypeValue, setUserTypeValue] = useState();
  const [accountList, setAccountList] = useState([]);
  const [gLCodeList, setGLCodeList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([])

  if (accountList !== undefined && isSuperAdmin(userType)) {
    options = accountList.map((account, index) => ({
      value: parseInt(account.accountId),
      label: account.accountName
    }));
  }

  // useEffects
  useEffect(() => {
    clearErrors();
    fillGLCode();
    fillAccounts();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (isFormHidden) {
      setValue("payrollEmployee", false)
      setIsPayrollFormHidden(true);
    }
    if (!isFormHidden) {
      setUserTypeList(getUserTypeList(accountState.accountId, accountId, userType));
      setTimeout(() => {
        if (isSuperAdmin(userType)) {
          accountIdRef.current.focus()

        } else {
          setFocus("userType");
        }

      }, 50);
    }
  }, [isFormHidden]);

  // @TODO: Fix for super admin. Not tested with a super admin account yet
  useEffect(() => {
    if (!isSuperAdmin(userType)) {
      fillPayrollSettings(selectedUserAsNTMS() ? accountState.accountId : accountId);
    }
  }, [])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  const fillGLCode = async (currentAccountId = accountId) => {
    let response = null;
    if (isNTMSUSer(userType)) {
      response = await fetchGLCodeFilteredByAccountAndType(accountState.accountId !== null ? accountState.accountId : currentAccountId, 5);
    } else {
      response = await fetchGLCodeFilteredByAccountAndType(currentAccountId, 5);
    }
    setGLCodeList(response);
  }

  const fillAccounts = async () => {
    let response = null

    if (isSuperAdmin(userType) || isNTMSUSer(userType)) {
      response = await fetchAccounts();
    }
    setAccountList(response);
  }

  const fillPayrollSettings = async (id) => {
    const response = await fetchPayrollSettingsEmployee(id, setPayrollSettings)

    setPayrollSettings(response)
  }

  const onSubmit = async (payload) => {
    let response = null;
    setIsLoading(true);
    _.set(payload, 'phone', removePhoneFormat(payload.phone));
    _.set(payload, 'cell', removePhoneFormat(payload.cell));
    _.set(payload, 'emergencyContactNumber', removePhoneFormat(payload.emergencyContactNumber));
    _.set(payload, 'provinceState', payload.provinceState.value)
    var employeeStartDate = dayjs(payload.employeeStartDate).format('YYYY-MM-DD');
    _.set(payload, 'employeeStartDate', employeeStartDate);

    // Remove field masking
    if (!isPayrollFormHidden) {
      _.set(payload, 'payroll.payPerUnit', parseFloat(removeDollar(payload.payroll.payPerUnit.toString())));
      _.set(payload, 'payroll.overtimePaymentRate', parseFloat(removePercentage(payload.payroll.overtimePaymentRate.toString())));
      if (payload.payroll.vacationType === 0) {
        _.set(payload, 'payroll.vacationAmount', parseFloat(removePercentage(payload.payroll.vacationAmount.toString())));
      } else {
        _.set(payload, 'payroll.vacationAmount', parseFloat(removeDays(payload.payroll.vacationAmount.toString())));
      }
    }

    const payloadAccountId = payload.accountId;
    _.set(payload, 'accountId', getAccountIdForEmployee(payloadAccountId, accountState.accountId, accountId, userType));
    _.set(payload, 'payroll.accountId', getAccountIdForEmployee(payloadAccountId, accountState.accountId, accountId, userType));
    _.set(payload, 'payroll.payrollPaidContribution.accountId', getAccountIdForEmployee(payloadAccountId, accountState.accountId, accountId, userType));

    if (isFormEdit) {
      _.set(payload, 'userId', selectedUser.userId);

      if (!isPayrollFormHidden) {
        _.set(payload, 'payroll.payrollId', selectedUser.payroll?.payrollId === undefined ? 0 : selectedUser.payroll?.payrollId);
        _.set(payload, 'payroll.payrollEmployee', true);
        var payStartDate = dayjs(payload.payroll.payStartDate).format('YYYY-MM-DD');
        _.set(payload, 'payroll.payStartDate', payStartDate);
        var paidContributionDate = dayjs(payload.payroll.payrollPaidContribution.date).format('YYYY-MM-DD');
        _.set(payload, 'payroll.payrollPaidContribution', [{
          accountId: accountId,
          payrollPaidContributionId: 0,
          previouslyPaidCPP: parseFloat(removeDollar(payload.payroll.payrollPaidContribution.previouslyPaidCPP.toString())),
          previouslyPaidEI: parseFloat(removeDollar(payload.payroll.payrollPaidContribution.previouslyPaidEI.toString())),
          date: paidContributionDate,
          createdTimeStamp: new Date(),
          lastModifiedTimeStamp: new Date()
        }]);

      } else {
        _.set(payload, 'payroll', null);
      }

      if (isAccountUser(userType)) {
        _.set(payload, 'userType', selectedUser.userType);
      }

      response = await updateUser(payload);
      setIsLoading(false);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } user.`,
        })
      );
    } else {
      _.set(payload, 'userId', 0);
      if (!isPayrollFormHidden) {
        _.set(payload, 'payroll.payrollId', 0);

        _.set(payload, 'payroll.payrollPaidContribution.payrollPaidContributionId', 0);
        var payStartDate = dayjs(payload.payroll.payStartDate).format('YYYY-MM-DD');
        _.set(payload, 'payroll.payStartDate', payStartDate);
        var paidContributionDate = dayjs(payload.payroll.payrollPaidContribution.date).format('YYYY-MM-DD');
        // _.set(payload, 'payroll.payrollPaidContribution.Date', new Date());

        _.set(payload, 'payroll.payrollPaidContribution', [{
          accountId: accountId,
          payrollPaidContributionId: 0,
          previouslyPaidCPP: parseFloat(removeDollar(payload.payroll.payrollPaidContribution.previouslyPaidCPP.toString())),
          previouslyPaidEI: parseFloat(removeDollar(payload.payroll.payrollPaidContribution.previouslyPaidEI.toString())),
          date: paidContributionDate,
          createdTimeStamp: new Date(),
          lastModifiedTimeStamp: new Date()
        }]);
      } else {
        _.set(payload, 'payroll', null);
      }

      if (isAccountUser(userType)) {
        _.set(payload, 'userType', 4);
      }

      response = await addUser(payload);
      setIsLoading(false);
      let errorMessage = await response.text();

      if (errorMessage === 'The user already exists.') {
        errorMessage = 'This email already exists. Please use a different email.'
      }

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : errorMessage}`,
        })
      );
    }

    if (response.ok) {
      fillUserList();
      toggleFormDisplay();

      if (isDirty) {
        setModal(false)
      }
      setIsFormDirty(false)
      dispatch(setFormPendingCompletion(false));
      setIsPayrollFormHidden(true);
      setValue("payrollEmployee", false);
      setValue("payroll.payStartDate", "");
      setValue("payroll.payPerUnit", "");
      setValue("phone", "");
      setValue("cell", "");
      setValue("emergencyContactNumber", "");
      setValue("provinceState", "");
      setValue("payroll.payrollPaidContribution.date", dayjs());
      setProvinceState("");
      setUserTypeValue("");
      setGender("");
      setMaritalStatus("");
      setPreferredLanguage("");
      setEmployeeType("");
      setEmployeeLevel("");
      setEmergencyContactRelationship("");
      provinceStateRef.current.clearValue();
    }
    focusOnOpenOrCloseButton();
  }

  const setDefaultFormPayrollValue = () => {
    setValue("payroll.overtimePaymentRate", (payrollSettings?.overtimePaymentRate * 100))
    setValue("payroll.tD1Province", payrollSettings?.tD1Province)
    setValue("payroll.tD2Federal", payrollSettings?.tD2Federal)
    setValue("payroll.payFrequency", payrollSettings?.payFrequency)
    setValue("payroll.vacationType", payrollSettings?.vacationType)
    setValue("payroll.payMethodType", payrollSettings?.payMethodType)
    setValue("payroll.glCodeId", payrollSettings?.glCodeId)
    setValue("payroll.payPerUnit", "")
    setValue("payroll.payStartDate", "")
    setTD1Province(payrollSettings?.tD1Province);
    setTD2Federal(payrollSettings?.tD2Federal);
    setPayFrequency(payrollSettings?.payFrequency);
    setVacationType(payrollSettings?.vacationType);
    setPayMethodType(payrollSettings?.payMethodType);
    setGLCode(payrollSettings?.glCodeId);
    setValue("payroll.payrollPaidContribution.previouslyPaidCPP", 0);
    setValue("payroll.payrollPaidContribution.previouslyPaidEI", 0);
    setValue("payroll.payrollPaidContribution.date", dayjs());

  }

  const selectedUserAsNTMS = () => {
    return (isNTMSUSer(userType) && accountState.accountId !== null)
  }

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-8-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedUser={selectedUser}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              setProvinceState={setProvinceState}
              setGender={setGender}
              setPreferredLanguage={setPreferredLanguage}
              setMaritalStatus={setMaritalStatus}
              setEmployeeType={setEmployeeType}
              setEmployeeLevel={setEmployeeLevel}
              setEmergencyContactRelationship={setEmergencyContactRelationship}
              setUserTypeValue={setUserTypeValue}
              setTD1Province={setTD1Province}
              setTD2Federal={setTD2Federal}
              setGLCode={setGLCode}
              setPayFrequency={setPayFrequency}
              setVacationType={setVacationType}
              setPayMethodType={setPayMethodType}
              setDefaultFormPayrollValue={setDefaultFormPayrollValue}
              setIsPayrollFormHidden={setIsPayrollFormHidden}
              unregister={unregister}
              payrollSettings={payrollSettings}
              isPayrollFormHidden={isPayrollFormHidden}
              provinceState={provinceState}
              userType={userType}
              accountIdRef={accountIdRef}
              options={options}
              userTypeValue={userTypeValue}
              userTypeList={userTypeList}
              gender={gender}
              maritalStatus={maritalStatus}
              preferredLanguage={preferredLanguage}
              employeeType={employeeType}
              employeeLevel={employeeLevel}
              emergencyContactRelationship={emergencyContactRelationship}
              provinceStateRef={provinceStateRef}
              provinceStateOptions={provinceStateOptions}
              tD1Province={tD1Province}
              tD2Federal={tD2Federal}
              payFrequency={payFrequency}
              vacationType={vacationType}
              vacationTypeWatch={vacationTypeWatch}
              payMethodType={payMethodType}
              gLCode={gLCode}
              gLCodeList={gLCodeList}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <form id="user-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs
          items={tabItems}
          tabClassName="color-8"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default UserForm