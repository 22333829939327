import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { COUNTRY, PROVINCE_STATE } from "../../constants";
import { selectStyle } from "../../utils/styleUtils";
import ReactDatePicker from "react-datepicker";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { fetchSuggestions } from "../../services/mapServices";
import { useDebouncedCallback } from "use-debounce";
import {
  setCoordinatesData,
  setNotificationData,
} from "../../redux/global/globalReducer";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";

function FormReceiverDetails({
  customerOptions,
  control,
  register,
  setValue,
  errors,
  checkPageEditable,
  selectedReceiverOption,
  setSelectedReceiverOption,
  selectedReceiverAddressValues,
  setSelectedReceiverAddressValues,
}) {
  const dispatch = useDispatch();
  const customerState = useSelector((state) => state.customer);

  const setAddressValues = (selectedSuggestion) => {
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedReceiverProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (selectedReceiverProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue("receiverAddress1", selectedSuggestion?.properties?.address_line1);
    setValue("receiverAddress2", selectedSuggestion?.properties?.address_line2);
    setValue("receiverCity", selectedSuggestion?.properties?.city);
    setValue("receiverPostal", selectedSuggestion?.properties?.postcode);
    setValue("receiverState", selectedReceiverProvinceState);
    setValue("receiverCountry", selectedCountry);
    setValue("receiverLatitude", `${selectedSuggestion?.properties?.lat}`);
    setValue("receiverLongitude", `${selectedSuggestion?.properties?.lon}`);
  };

  const setAddressNotes = (addressNotes) => {
    setValue("receiverAddressNotes", addressNotes);
  };

  const handleReceiverNameChange = (selectedOption) => {
    setValue("receiverId", selectedOption.value);
    setSelectedReceiverOption(selectedOption);

    const selectedCustomerData = _.find(customerState.data, (customer) => {
      return customer.customerId === selectedOption?.value;
    });

    if (_.isNil(selectedCustomerData)) {
      return;
    }

    const payload = {
      name: selectedCustomerData.customerName,
      contact: selectedCustomerData.primaryContact,
      email: selectedCustomerData.defaultEmail,
      phone: selectedCustomerData.phone,
      addressLine1: selectedCustomerData.addressLine1,
      addressLine2: selectedCustomerData.addressLine2,
      provinceState: selectedCustomerData.provinceState,
      city: selectedCustomerData.city,
      country: selectedCustomerData.country,
      postalCode: selectedCustomerData.postalCode,
    };

    setSelectedReceiverAddressValues({
      addressLine1: selectedCustomerData.addressLine1,
      addressLine2: selectedCustomerData.addressLine2,
      provinceState: selectedCustomerData.provinceState,
      city: selectedCustomerData.city,
      country: selectedCustomerData.country,
      postalCode: selectedCustomerData.postalCode,
    });

    setValue("receiverLatitude", selectedCustomerData.latitude);
    setValue("receiverLongitude", selectedCustomerData.longitude);

    dispatch(
      setCoordinatesData({
        property: "destination",
        data: {
          latitude: selectedCustomerData.latitude,
          longitude: selectedCustomerData.longitude,
          addressLine1: selectedCustomerData.addressLine1,
          addressLine2: selectedCustomerData.addressLine2,
          provinceState: selectedCustomerData.provinceState,
          city: selectedCustomerData.city,
          country: selectedCustomerData.country,
          postalCode: selectedCustomerData.postalCode,
          isInitialData: false,
        },
      })
    );

    fillReceiverFields(payload);
  };

  const fillReceiverFields = (payload) => {
    const selectedReceiverProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.value === payload.provinceState;
      }
    );

    const selectedReceiverCountry = _.find(COUNTRY, (country) => {
      return country.value === payload.country;
    });

    setValue("receiverName", payload.name);
    setValue("receiverContactName", payload.contact);
    setValue("receiverDefaultEmail", payload.email);
    setValue("receiverPhoneNumber", payload.phone);
    setValue("receiverAddress1", payload.addressLine1);
    setValue("receiverAddress2", payload.addressLine2);
    setValue("receiverState", selectedReceiverProvinceState);
    setValue("receiverCity", payload.city);
    setValue("receiverCountry", selectedReceiverCountry);
    setValue("receiverPostal", payload.postalCode);
  };

  return (
    <>
      {/* Receiver Details */}
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="receiverName" className="form-label">
          Name <span className="required-asterisk">*</span>
        </label>
        <div className="custom-selector">
          <Controller
            name="receiverName"
            control={control}
            register={register}
            rules={{ required: "Name is required" }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                onChange={handleReceiverNameChange}
                value={selectedReceiverOption}
                options={customerOptions}
                className="receiver-name-select"
                classNamePrefix="react-select"
                placeholder={"Select Customer / Create new Customer"}
                id="receiverName"
                styles={selectStyle}
                tabIndex={2}
                isDisabled={checkPageEditable()}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.receiverName && errors.receiverName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="receiverContactName" className="form-label">
          Primary Contact <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="receiverContactName"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("receiverContactName", {
            required: "Primary Contact is required",
          })}
        />
        <small className="form-error-message">
          {errors?.receiverContactName && errors.receiverContactName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="receiverDefaultEmail" className="form-label">
          Default Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="receiverDefaultEmail"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("receiverDefaultEmail", {
            required: "Default Email is required",
          })}
        />
        <small className="form-error-message">
          {errors?.receiverDefaultEmail && errors.receiverDefaultEmail.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="receiverPhoneNumber" className="form-label">
          Phone <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="receiverPhoneNumber"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("receiverPhoneNumber", {
            required: "Phone Number is required",
          })}
        />
        <small className="form-error-message">
          {errors?.receiverPhoneNumber && errors.receiverPhoneNumber.message}
        </small>
      </div>
      <div className="col-12 p-0 mb-3">
        <hr></hr>
      </div>

      <AutocompleteAddressForm
        setAddressValues={setAddressValues}
        setAddressNotes={setAddressNotes}
        selectedOrderAddressValues={selectedReceiverAddressValues}
        coordinatesType="destination"
      />

      <div className="col-6 col-sm-4">
        <div className="row">
          <div className="col-6 col-sm-6 mb-4">
            <label htmlFor="receiverOpenTime" className="form-label">
              Opening Hour
            </label>
            <div>
              <Controller
                control={control}
                name="receiverOpenTime"
                register={register}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <TimePicker
                    className="antd-time-picker"
                    format="H:mm"
                    name={name}
                    tabIndex={11}
                    use12Hours={false}
                    value={value}
                    onChange={onChange}
                    disabled={checkPageEditable()}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.receiverOpenTime && errors.receiverOpenTime.message}
              </small>
            </div>
          </div>
          <div className="col-6 col-sm-6 mb-4">
            <label htmlFor="receiverCloseTime" className="form-label">
              Closing Hour
            </label>
            <div>
              <Controller
                control={control}
                name="receiverCloseTime"
                register={register}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <TimePicker
                    className="antd-time-picker"
                    name={name}
                    tabIndex={12}
                    format="H:mm"
                    value={value}
                    onChange={onChange}
                    disabled={checkPageEditable()}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.receiverCloseTime && errors.receiverCloseTime.message}
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormReceiverDetails;
