import React from 'react';
import { Link } from 'react-router-dom';

function ActionRenderer(props, args) {

  return (
    <div className='cell-renderer-content'>
      <Link to="/invoices/edit-invoice" style={{ textDecoration: 'none' }} state={{ invoiceId: props.data.id }}>
        <button className={`btn btn-color-4 btn-sm`}><i className="fa-solid fa-eye"></i></button>
      </Link>
    </div>
  )
}

export default ActionRenderer