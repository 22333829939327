import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import StatusRenderer from '../Templates/CellRenderers/StatusRenderer';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import ProfilePictureRenderer from '../Templates/CellRenderers/Commons/ProfilePictureRenderer';
import { formatDateOnly, formatCurrency } from '../../utils/formatUtils'
import { fetchInvoices, fetchInvoicesFiltered } from '../../services/invoiceServices';
import { statusValueGetter, statusFilter } from '../../utils/quoteUtils';
import { isSuperAdmin, isNTMSUSer } from '../../utils/roleUtils';
import ActionRenderer from '../Templates/CellRenderers/Invoices/ActionRenderer';
import { getInvoiceBalance } from '../../utils/paymentUtils';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setInvoicesListData } from '../../redux/invoice/invoiceReducer';
import moment from 'moment';
import { GRID_CONSTANTS } from '../../constants';
import NoData from '../Commons/Layouts/NoData';
import LoadingPage from '../Commons/Authorization/LoadingPage';
import { DownloadOutlined } from '@ant-design/icons';
import { Download } from '@mui/icons-material';

function Invoices() {
  const dispatch = useDispatch();
  const gridRef = useRef();

  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const invoiceState = useSelector((state) => state.invoice);

  const [rowData, setRowData] = useState([]);
  const [invoicesList, setInvoicesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [columnDefs] = useState([
    {
      field: "index",
      headerName: "#",
      width: 50,
      suppressSizeToFit: true,
      cellStyle: { fontWeight: "bold" },
    },
    {
      field: "ProfilePicture",
      headerName: "",
      minWidth: 70,
      cellRenderer: ProfilePictureRenderer,
      width: 70,
      suppressSizeToFit: true,
      cellClass: "no-borders",
    },
    {
      field: "Customer",
      autoHeight: true,
      cellClass: 'grid-column',
      minWidth: 120,
      width: 120,
      filter: "agTextColumnFilter",
      sortable: true,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: 'Invoice Id',
      minWidth: 110,
      cellClass: 'grid-column gray',
      width: 120,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Quote Name',
      minWidth: 120,
      autoHeight: true,
      cellClass: 'grid-column gray',
    },
    {
      field: "Invoice Id",
      minWidth: 110,
      cellClass: "no-borders",
      width: 120,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Quote Name",
      minWidth: 120,
      autoHeight: true,
      cellClass: "no-borders",
    },
    {
      field: "Status",
      cellRenderer: StatusRenderer,
      autoHeight: true,
      cellClass: 'grid-column gray',
      minWidth: 120,
      width: 120,
      filter: "agTextColumnFilter",
      valueGetter: statusValueGetter,
      sortable: true,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: 'Discount',
      minWidth: 110,
      cellClass: 'grid-column gray',
      width: 120,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Subtotal',
      minWidth: 110,
      cellClass: 'grid-column gray',
      width: 120,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Total',
      minWidth: 110,
      cellClass: 'grid-column gray',
      width: 120,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Tax',
      minWidth: 110,
      cellClass: 'grid-column gray',
      width: 120, getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Balance',
      minWidth: 110,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Created',
      cellClass: 'grid-column gray',
      sort: 'desc',
      minWidth: 110,
      width: 120,
      sortable: true,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: 'Actions',
      headerName: "",
      minWidth: 110,
      cellRenderer: ActionRenderer,
      cellRendererParams: { gridRef: gridRef, rowData: rowData, setRowData },
      autoHeight: true,
      cellClass: 'd-flex flex-row-reverse'
    },
  ])

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setInvoicesList(invoiceState.data);
  }, [invoiceState.data]);

  const init = async () => {
    const response = await getInvoicesData();
    dispatch(setInvoicesListData(response));

    setIsLoading(false);
  };

  const getInvoicesData = async () => {
    if (isSuperAdmin(userType)) {
      return await fetchInvoices();
    }

    if (isNTMSUSer(userType)) {
      return selectedAccountState.accountId === null
        ? await fetchInvoicesFiltered(accountId)
        : await fetchInvoicesFiltered(selectedAccountState.accountId);
    }

    return await fetchInvoicesFiltered(accountId);
  };

  useEffect(() => {
    let data = [];
    _.each(invoicesList, (currentData, index) => {
      data.push({
        id: currentData.invoiceId,
        "Invoice Id": `INV${String(currentData.invoiceId).padStart(4, "0")}`,
        index: index + 1,
        ProfilePicture: { url: "/images/profile-picture-fallback.png" },
        "Quote Name": currentData.quoteName,
        Customer: currentData.customer.customerName,
        Status: currentData.status,
        Created: formatDateOnly(currentData.invoiceDate),
        Discount: formatCurrency(currentData.discount),
        Subtotal: formatCurrency(currentData.subtotal),
        Balance: formatCurrency(getInvoiceBalance(currentData)),
        Total: formatCurrency(currentData.totalInvoice),
        Tax: formatCurrency(currentData.totalTax),
      });
    });
    setRowData(data);
  }, [invoicesList]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
    statusFilter(gridRef);
  }, []);

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const exportDataAsCSV = useCallback(() => {
    const params = {
      columnKeys: [
        "Customer",
        "Invoice Id",
        "Quote Name",
        "Status",
        "Discount",
        "Subtotal",
        "Total",
        "Tax",
        "Balance",
        "Created",
      ],
      fileName: `invoices-${moment(new Date()).format("YYYY-MM-DD")}.csv`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title="Invoices"
            subtitle={"invoices"}
            dataCount={rowData.length}
            filterData={filterData}
            hideButton={true}
            customButtons={[
              {
                label: "Export",
                icon: <DownloadOutlined />,
                onClick: exportDataAsCSV,
                disabled: false,
                shown: true
              }
            ]}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div className="col-12 mb-2">
          {(invoicesList?.length > 0) ?
            <div className={`ag-theme-alpine content-section-container color-4-grid color-4-section p-0`} >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
                rowSelection={'single'}
              >
              </AgGridReact>
            </div>
            :
            <NoData color='color-4' content='invoice' />
          }
        </div>

      </div>
    </div>
  );
}

export default Invoices;
