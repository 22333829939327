import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES, CURRENCY_TYPE } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedGLCode = {},
  control,
  register,
  errors,
  setValue,
  reset,
  accountTypeList
}) {

  const [currencyType, setCurrencyType] = useState("")
  const [defaultTaxCode, setDefaultTaxCode] = useState("")
  const [accountType, setAccountType] = useState("")

  useEffect(() => {
    if (isFormEdit) {
      setAccountType(parseInt(selectedGLCode['accountTypeId']))
      setCurrencyType(parseInt(selectedGLCode['currencyType']))
      setDefaultTaxCode(parseInt(selectedGLCode['defaultTaxCode']))
      setValue("accountTypeId", parseInt(selectedGLCode['accountTypeId']))
      setValue("currencyType", parseInt(selectedGLCode['currencyType']))
      setValue("defaultTaxCode", parseInt(selectedGLCode['defaultTaxCode']))
    } else {
      setAccountType("")
      setCurrencyType("")
      setDefaultTaxCode("")
      reset()
    }

  }, [selectedGLCode["glCodeId"]])

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="name" className="form-label">Name</label>
        <input type="text" className="form-control" id="name" tabIndex={1}
          defaultValue={isFormEdit ? selectedGLCode?.glCodeName || '' : ''}
          autoComplete='on'
          {...register("glCodeName", { required: "Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.glCodeName && errors.glCodeName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="description" className="form-label">Description</label>
        <input type="text" className="form-control" id="description" tabIndex={2}
          defaultValue={isFormEdit ? selectedGLCode?.description || '' : ''}
          {...register("description", { required: "Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.description && errors.description.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="accountType" className="form-label" >Account Type:</label>
        <select className="form-select" value={accountType} tabIndex={3} id="accountType"{...register("accountTypeId", { valueAsNumber: true, onChange: (e) => setAccountType(e.target.value), required: "Account type is required" })}>
          <option key="none" value="" hidden>Account Type</option>
          {accountTypeList.map((accountType, index) =>
            <option key={index} value={accountType.accountTypeId}>{accountType.accountTypeName} - {accountType.description}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.accountType && errors.accountType.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="currencyType" className="form-label" >Currency Type:</label>
        <select className="form-select" value={currencyType} tabIndex={4} id="currencyType"{...register("currencyType", { valueAsNumber: true, onChange: (e) => setCurrencyType(e.target.value), required: "Currency type is required" })}>
          <option key="none" value="" hidden>Currency Type</option>
          {CURRENCY_TYPE.map((currencyType, index) =>
            <option key={index} value={currencyType.value}>{currencyType.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.currencyType && errors.currencyType.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultTaxCode" className="form-label" >Default Tax Code:</label>
        <select className="form-select" value={defaultTaxCode} tabIndex={5} id="defaultTaxCode"{...register("defaultTaxCode", { valueAsNumber: true, onChange: (e) => setDefaultTaxCode(e.target.value), required: "Default Tax Code is required" })}>
          <option key="none" value="" hidden>Default Tax Code</option>
          {TAX_TYPES.map((taxCode, index) =>
            <option key={index} value={taxCode.value}>{taxCode.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.defaultTaxCode && errors.defaultTaxCode.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="notes" className="form-label">Notes</label>
        <input type="text" className="form-control" id="notes" tabIndex={6}
          defaultValue={isFormEdit ? selectedGLCode?.notes || '' : ''}
          {...register("notes")}
        />
        <small className='form-error-message'>
          {errors?.notes && errors.notes.message}
        </small>
      </div>
    </>
  );
}

export default PrimaryDetails;
