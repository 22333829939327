import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import QuoteItemFormModal from "../Commons/Modals/QuoteItemFormModal";
import { selectStyle } from "../../utils/styleUtils";
import moment from "moment";
import { formatCurrency, formatDateOnly, formatEllipsis, formatQID } from "../../utils/formatUtils";
import QuoteActionRenderer from "../Templates/CellRenderers/Quotes/QuoteActionRenderer";
import { QUOTE_STATUS, GRID_CONSTANTS } from "../../constants";
import { enumValueFormatter } from "../../utils/formatUtils";
import _ from 'lodash';
import { addTMSQuote, updateTMSQuote, } from "../../services/tmsQuoteServices";
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { addOrderTMSQuoteData, updateOrderTMSQuoteData, updateOrderTMSQuoteItemsData, addOrderTMSQuoteItemData, deleteOrderTMSQuoteItemData, } from "../../redux/order/orderReducer";
import { setNotificationData } from "../../redux/global/globalReducer";
import QuoteStatusRenderer from "../Templates/CellRenderers/Orders/QuoteStatusRenderer";
import { updateTMSQuoteItems, addTMSQuoteItem, deleteTMSQuoteItem } from "../../services/tmsQuoteItemServices";


function OrderQuote({
  selectedOrder,
  isFormHidden,
  accessorialOptions,
  selectedQuoteList,
  selectedAccessorialList,
  setSelectedQuoteList,
  checkPageEditable,
  setSelectedOrderStatus,
  dirtyQuoteItemColumns,
  setDirtyQuoteItemColumns,
  dirtyQuoteItemFields,
  setDirtyQuoteItemFields,
  dirtyLoadOrderColumns,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    watch,
    control,
    trigger,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const dispatch = useDispatch();
  const orderState = useSelector((state) => state.order);
  const currentOrder = _.find(orderState?.data, { orderId: selectedOrder?.orderId })
  const [rowData, setRowData] = useState([]);

  const formData = watch();

  useEffect(() => {
    if (!_.isEmpty(dirtyFields)) {
      setDirtyQuoteItemFields(dirtyFields);
    } else {
      setDirtyQuoteItemFields({});
    }
  }, [watch("quoteName")])

  const toggleQuoteModalDisplay = async (isNew = true, quoteId = null) => {
    if (!isNew) {
      setSelectedQuoteId(quoteId)
      setIsQuoteEdit(isNew);
      setModal(!modal)
    } else {
      const createdQuote = await createQuote();
      setIsQuoteEdit(true);
      setSelectedQuoteId(createdQuote.tmsQuoteId);
      fillSelectedQuote(createdQuote.tmsQuoteId);
      setModal(!modal);
    }
  }

  const createQuote = async () => {
    var quoteItems = [];
    var freightItem = {
      "type": 1,
      "category": 1,
      "description": "Freight",
      "quantity": 1,
      "rate": 0,
      "cost": 0,
      "notes": "",
      "accountId": selectedOrder?.accountId,
      "orderId": selectedOrder?.orderId,
    }
    quoteItems.push(freightItem)
    var fuelItem = {
      "type": 2,
      "category": 1,
      "description": "Fuel",
      "quantity": 1,
      "rate": 0,
      "cost": 0,
      "notes": "",
      "accountId": selectedOrder?.accountId,
      "orderId": selectedOrder?.orderId,
    }
    quoteItems.push(fuelItem)
    currentOrder?.orderAccessorial.map((accessorial, index) => {
      var currentAccessorial = {
        'type': 4,
        'category': 0,
        'description': accessorial.accessorialName,
        'quantity': 1,
        'rate': 0,
        'total': 0,
        "accountId": selectedOrder?.accountId,
        "orderId": selectedOrder?.orderId,
      }
      quoteItems.push(currentAccessorial)
    });
    const payload = {
      "quoteName": "Name",
      "total": 0,
      "quoteDate": moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD'),
      "status": 0,
      "accountId": selectedOrder?.accountId,
      "orderId": selectedOrder?.orderId,
      "tmsQuoteItem": quoteItems
    }

    const response = await addTMSQuote(payload);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } item.`,
      })
    );

    const returnedData = await response.json()

    if (response.ok) {
      const newItem = {
        "#": rowData.length + 1,
        Name: returnedData.quoteName,
        Date: returnedData.quoteDate,
        Quote: returnedData.total,
        Status: returnedData.status,
        Notes: returnedData.notes,
        QuoteId: returnedData.tmsQuoteId,
        Id: returnedData.tmsQuoteId,
        OrderId: returnedData.orderId,
        AccountId: returnedData.accountId,
        QuoteItems: returnedData.tmsQuoteItem,
        QID: returnedData.tmsQuoteItem,
      };

      const transaction = {
        add: [newItem],
      };

      quoteGridRef.current.api.applyTransaction(transaction);

      setRowData(prevRowData => [...prevRowData, newItem]);

      dispatch(addOrderTMSQuoteData({ orderId: selectedOrder?.orderId, tmsQuote: returnedData }));
      return returnedData;
    }
    return;
  }

  const [columnDefs, setColumnDefs] = useState();

  const columnDefinitions = (isEditable) => {
    const defs = [
      {
        field: "QID",
        minWidth: 150,
        valueFormatter: (params) => { return formatQID(params.value) },
        cellClass: "grid-column",
      },
      {
        field: "Name",
        minWidth: 150,
        cellClass: "grid-column gray",
      },
      {
        field: "Date",
        minWidth: 150,
        cellClass: "grid-column gray",
      },
      {
        field: "Quote",
        minWidth: 150,
        valueFormatter: (params) => { return formatCurrency(params.value) },
        cellClass: "grid-column gray",
      },
      {
        field: "Status",
        minWidth: 150,
        cellClass: "grid-column gray",
        cellRenderer: QuoteStatusRenderer,
        valueFormatter: (params) => {
          return enumValueFormatter(params, QUOTE_STATUS);
        }
      },
      {
        field: "Notes",
        minWidth: 150,
        cellClass: "grid-column gray",
        tooltipValueGetter: (params) => params.value,
        valueFormatter: (params) => formatEllipsis(params, 40)
      },
      {
        field: "Edit",
        minWidth: 150,
        headerName: "",
        cellClass: "d-flex flex-row-reverse",
        autoHeight: true,
        cellRenderer: QuoteActionRenderer,
        cellRendererParams:
        {
          toggleQuoteModalDisplay,
          checkPageEditable: isEditable,
          setColumnDefs: setColumnDefs,
          columnDefinitions: columnDefinitions,
          setSelectedOrderStatus: setSelectedOrderStatus,
          dirtyLoadOrderColumns: dirtyLoadOrderColumns,
        },
      },
    ];

    return defs;
  }


  useEffect(() => {
    let data = [];
    _.each(currentOrder?.tmsQuote, (quote, index) => {

      const total = quote.tmsQuoteItem.reduce((sum, item) => sum + item.cost, 0);
      data.push({
        '#': index + 1,
        QID: quote.quoteSequenceId,
        Name: quote.quoteName,
        Date: formatDateOnly(quote.quoteDate),
        Quote: total,
        Status: quote.status,
        Notes: quote.notes,
        QuoteId: quote.tmsQuoteId,
        Id: quote.tmsQuoteId,
        OrderId: quote.orderId,
        AccountId: quote.accountId,
        QuoteItems: quote.tmsQuoteItem
      });
    });
    setRowData(data);
  }, [currentOrder?.tmsQuote])

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const fillSelectedQuote = (id) => {
    const quote = _.find(rowData, { QuoteId: id });
    setSelectedQuote(quote);
    fillQuoteItemTable(quote?.QuoteItems)
  }

  const onGridReady = useCallback((params) => {
    if (!isFormHidden) {
      quoteGridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    if (!isFormHidden) {
      quoteGridRef.current.api.sizeColumnsToFit();
    }
  };

  const onGridSizeChanged = (params) => {
    if (!isFormHidden && params.clientHeight > 0 && params.clientWidth > 0) {
      quoteGridRef.current.api.sizeColumnsToFit();
    }
  };

  const [selectedQuote, setSelectedQuote] = useState({});
  const [selectedQuoteId, setSelectedQuoteId] = useState(null);
  const [isQuoteEdit, setIsQuoteEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedQuoteItemList, setSelectedQuoteItemList] = useState([]);
  const toggle = () => setModal(!modal);
  const quoteGridRef = useRef();
  const quoteItemGridRef = useRef();

  useEffect(() => {
    fillSelectedQuote(selectedQuoteId);
  }, [selectedQuoteId, modal]);

  const onSubmit = async (
    payload,
    showNotification = true,
    addNewQuoteItem = false,
    updateQuoteItems = false,
    deleteQuoteItem = false,
    showNewQuoteItemNotification = false,
    showUpdateNewQuoteItemNotification = false,
    showDeleteQuoteItemNotifcation = false,
    currentData = null
  ) => {
    await handleUpdateQuoteItems(false);
    let response = null

    response = await updateTMSQuote(payload);

    if (showNotification) {
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } item.`,
        })
      );
    }

    const returnedData = await response.json()

    const rowIndex = quoteGridRef.current.api.getRowNode(returnedData.tmsQuoteId).rowIndex;

    if (response.ok) {
      const updatedItem = {
        '#': rowIndex + 1,
        Name: returnedData.quoteName,
        Date: formatDateOnly(returnedData.quoteDate),
        Quote: returnedData.total,
        Status: returnedData.status,
        Notes: returnedData.notes,
        QuoteId: returnedData.tmsQuoteId,
        Id: returnedData.tmsQuoteId,
        OrderId: returnedData.orderId,
        AccountId: returnedData.accountId,
        QuoteItems: returnedData.tmsQuoteItem
      };

      const transaction = {
        update: [updatedItem],
      };

      quoteGridRef.current.api.applyTransaction(transaction);

      dispatch(updateOrderTMSQuoteData({ orderId: selectedOrder?.orderId, updatedTMSQuote: returnedData }));
      reset({}, {keepValues: true,});
      setDirtyQuoteItemFields({});
      setSelectedQuoteId(returnedData?.tmsQuoteId);

      if (updateQuoteItems) {
        await handleUpdateQuoteItems(showUpdateNewQuoteItemNotification);
      }

      if (addNewQuoteItem) {
        await handleAddQuoteItem(showNewQuoteItemNotification);
      }

      if (deleteQuoteItem) {
        await handleDeleteQuoteItem(currentData, showDeleteQuoteItemNotifcation)
      }
    }
  };

  const handleAddQuoteItem = async (showNotification = true) => {
    const payload = {
      "type": 3,
      "category": 0,
      "description": "Other",
      "quantity": 1,
      "rate": 0,
      "cost": 0,
      "notes": "",
      "accountId": selectedOrder?.accountId,
      "orderId": selectedOrder?.orderId,
      "tmsQuoteId": selectedQuote?.QuoteId,
    }

    let response = await addTMSQuoteItem(payload);

    if (showNotification) {
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } item.`,
        })
      );
    }

    const returnedData = await response.json();

    const newItem = {
      '#': rowData.length + 1,
      'Type': returnedData.type,
      'Category': 0,
      'Description': returnedData.description,
      'Quantity': returnedData.quantity,
      'Rate': returnedData.rate,
      'Total': returnedData.cost,
      'Notes': returnedData.notes,
      'QuoteItemId': returnedData.tmsQuoteItemId,
      'Id': returnedData.tmsQuoteItemId,
      'QuoteId': returnedData.tmsQuoteId,
      'AccountId': selectedOrder?.accountId,
      'OrderId': selectedOrder?.orderId,
    };

    const transaction = {
      add: [newItem],
    };

    quoteItemGridRef.current.api.applyTransaction(transaction);
    setRowData(prevRowData => [...prevRowData, newItem]);
    dispatch(addOrderTMSQuoteItemData({
      orderId: selectedOrder?.orderId,
      quoteId: selectedQuote?.QuoteId,
      tmsQuoteItem: returnedData
    }));
  };

  const handleDeleteQuoteItem = async (currentData, showNotification = true) => {
    const orderId = currentData.node.data.OrderId;
    const itemId = currentData?.node.data.Id;
    const quoteId = currentData?.node.data.QuoteId;

    const response = await deleteTMSQuoteItem(itemId)

    if (showNotification) {
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully deleted" : "Failed to delete"
            } item.`,
        })
      );
    }

    if (response.ok) {
      currentData.api.applyTransaction({ remove: [currentData.node.data] });
      dispatch(deleteOrderTMSQuoteItemData({ orderId, quoteId, itemId }));
    }

  }

  const fillQuoteItemTable = (quoteItemList) => {
    let data = [];
    if (modal) {
      _.each(quoteItemList, (quoteItem, index) => {
        data.push({
          '#': index + 1,
          Type: quoteItem.type,
          Category: quoteItem.category,
          Description: quoteItem.description,
          Quantity: quoteItem.quantity,
          Rate: quoteItem.rate,
          Total: quoteItem.cost,
          Notes: quoteItem.notes,
          QuoteItemId: quoteItem.tmsQuoteItemId,
          Id: quoteItem.tmsQuoteItemId,
          QuoteId: quoteItem.tmsQuoteId,
          OrderId: quoteItem.orderId,
          AccountId: quoteItem.accountId,
        });
      });
    }

    setSelectedQuoteItemList(data);
  }

  const handleUpdateQuoteItems = async (showNotification = true) => {
    var quoteItems = [];

    if (quoteItemGridRef.current.api.getDisplayedRowCount() === 0) {
      if (showNotification) {
        dispatch(
          setNotificationData({
            type: "error",
            message: "Error!",
            description: "Please add a load item.",
          })
        );
      }
      return;
    }

    quoteItemGridRef.current.api.forEachNode(node => {
      const quoteItem = node.data;
      var payloadData = {
        accountId: selectedOrder?.accountId,
        orderId: selectedOrder?.orderId,
        description: quoteItem?.Description,
        quantity: parseFloat(quoteItem?.Quantity),
        rate: parseFloat(quoteItem?.Rate),
        cost: parseFloat(quoteItem?.Total),
        notes: quoteItem?.notes,
        "type": quoteItem?.Type,
        "category": quoteItem?.Category,
        "tmsQuoteId": quoteItem?.QuoteId,
        "tmsQuoteItemId": quoteItem?.QuoteItemId,
      }
      quoteItems.push(payloadData);
    });

    let response = await updateTMSQuoteItems(quoteItems);
    if (showNotification) {
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } quote items.`,
        })
      );
    }

    if (response.ok) {
      const returnedData = await response.json();
      setDirtyQuoteItemColumns(false);
      dispatch(updateOrderTMSQuoteItemsData({
        orderId: selectedOrder?.orderId,
        quoteId: selectedQuote?.QuoteId,
        updatedTMSQuoteItems: returnedData
      }));
    }
  }

  const getRowId = (params) => params.data.QuoteId;

  const gridOptions = {
    tooltipShowDelay: 500,
  }

  useEffect(() => {
    setColumnDefs(columnDefinitions(checkPageEditable()));
  }, [checkPageEditable()])

  return (
    <div className="">
      <div className="col-12 mb-3">
        {!checkPageEditable() && <div className="d-flex flex-row align-items-center">
          <div className="ms-auto">
            <button
              type="button"
              className="btn btn-color-3"
              style={{ width: 150 }}
              onClick={toggleQuoteModalDisplay}
            >
              Add Quote
            </button>
          </div>
        </div>}
      </div>
      <div className={`col-12 mb-2`}>
        <div className={`ag-theme-alpine form-content-section-container color-3-grid p-0`}>
          <AgGridReact
            className="no-header"
            rowData={rowData}
            columnDefs={columnDefs}
            ref={quoteGridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onColumnSizeChanged={onColumnsSizeChanged}
            domLayout="autoHeight"
            onGridSizeChanged={onGridSizeChanged}
            rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
            getRowId={getRowId}
            gridOptions={gridOptions}
          ></AgGridReact>
        </div>
      </div>
      <QuoteItemFormModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        isQuoteEdit={isQuoteEdit}
        onSubmit={onSubmit}
        selectedOrder={selectedOrder}
        selectedQuote={selectedQuote}
        quoteItemGridRef={quoteItemGridRef}
        selectedQuoteItemList={selectedQuoteItemList}
        register={register}
        handleSubmit={handleSubmit}
        setValue={setValue}
        errors={errors}
        reset={reset}
        checkPageEditable={checkPageEditable}
        handleUpdateQuoteItems={handleUpdateQuoteItems}
        dirtyQuoteItemColumns={dirtyQuoteItemColumns}
        setDirtyQuoteItemColumns={setDirtyQuoteItemColumns}
        dirtyQuoteItemFields={dirtyQuoteItemFields}
        setDirtyQuoteItemFields={setDirtyQuoteItemFields}
        dirtyLoadOrderColumns={dirtyLoadOrderColumns}
        setSelectedQuoteId={setSelectedQuoteId}
      />
    </div>
  );
}

export default OrderQuote;
