import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { addTruck, updateTruck } from "../../services/truckServices";
import { showToast } from "../../utils/toastUtils";
import {
  isNTMSUSer,
  isAccountAdmin,
  isSuperAdmin,
} from "../../utils/roleUtils";
import ModalRender from "../Commons/ModalRender";
import { removePhoneFormat } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  addTruckData,
  setTruckData,
} from "../../redux/truck/truckReducer";
import moment from "moment";
import PrimaryDetails from "./PrimaryDetails";
import FormTabs from "../Commons/Layouts/FormTabs";
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";
import dayjs from "dayjs";

function TruckForm({
  isFormEdit = false,
  selectedTruck = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const isInitialRender = useRef(true);

  // form values
  setValue("truckId", 0);
  setValue("accountId", accountId);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("vin");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  const onSubmit = async (payload) => {
    let response = null;

    _.set(payload, "year", dayjs(payload.year).format("YYYY-MM-DD"));
    _.set(
      payload,
      "truckFinancials.purchaseDate",
      dayjs(payload.truckFinancials.purchaseDate).format("YYYY-MM-DD")
    );
    _.set(payload, "truckFinancials.accountId", accountId);

    if (isFormEdit) {
      _.set(payload, "truckId", selectedTruck.truckId);
      _.set(payload, "accountId", selectedTruck.accountId);
      _.set(
        payload,
        "truckFinancials.truckFinancialsId",
        selectedTruck.truckFinancials.truckFinancialsId
      );

      response = await updateTruck(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } truck.`,
        })
      );
    } else {
      _.set(payload, "truckId", 0);
      _.set(payload, "truckFinancials.truckFinancialsId", 0);

      if (isNTMSUSer(userType)) {
        _.set(
          payload,
          "accountId",
          accountState.accountId != null ? accountState.accountId : accountId
        );
      } else {
        _.set(payload, "accountId", accountId);
      }
      response = await addTruck(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } truck.`,
        })
      );
    }

    if (response.ok) {
      const truckData = await response.json();
      dispatch(
        isFormEdit ? setTruckData(truckData) : addTruckData(truckData)
      );

      toggleFormDisplay();

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);
      dispatch(setFormPendingCompletion(false));
      reset();
      focusOnOpenOrCloseButton();
    }
  };

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-7-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedTruck={selectedTruck}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <>
        <form id="truck-form" onSubmit={handleSubmit(onSubmit)}>
          <FormTabs
            items={tabItems}
            tabClassName="color-7"
            isFormHidden={isFormHidden}
          />

          <div className="row mt-5">
            <div className="col-12">
              <div className="d-flex flex-row align-items-center">
                <button className={`ms-auto btn btn-primary`} type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    </>
  );
}

export default TruckForm;
