import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContactForm from "./ContactForm";
import "./css/contacts.css";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import NameAndEmailRenderer from "../Templates/CellRenderers/Contacts/NameAndEmailRenderer";
import ProfilePictureRenderer from "../Templates/CellRenderers/Commons/ProfilePictureRenderer";
import PhoneAndMobileRenderer from "../Templates/CellRenderers/Commons/PhoneAndMobileRenderer";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import {
  fetchContacts,
  fetchContactsFiltered,
} from "../../services/contactServices";
import { formatPhoneNumber } from "../../utils/formatUtils";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { isSuperAdmin, isNTMSUSer } from "../../utils/roleUtils";
import { setContactsListData } from "../../redux/contact/contactReducer";
import { setCustomersListData } from "../../redux/customer/customerReducer";
import { fetchCustomersByAccount } from "../../services/customerServices";
import ContactModal from "../Commons/Modals/ContactModal";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Contacts() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const contactState = useSelector((state) => state.contact);
  const [createContactModal, setCreateContactModal] = useState(false);
  const createContactToggle = () => setCreateContactModal(!createContactModal);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerCreationInformation, setCustomerCreationInformation] =
    useState(null);

  const toggleFormDisplay = (isEdit = false, contactId = null) => {
    if (isFormHidden) {
      setSelectedContactId(contactId);
    } else {
      setSelectedContactId(null);
    }

    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "ProfilePicture",
      minWidth: 70,
      headerName: "",
      cellRenderer: ProfilePictureRenderer,
      width: 80,
      suppressSizeToFit: true,
      resizable: false,
    },
    {
      field: "Name",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Customer",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Phone",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Mobile",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Email",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      resizable: false,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [contactList, setContactList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [customersFetched, setCustomersFetched] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    getCustomersData();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setContactList(contactState.data);
  }, [contactState.data]);

  useEffect(() => {
    let data = [];
    _.each(contactList, (contact, index) => {
      data.push({
        "#": index + 1,
        ProfilePicture: { url: `images/profile-picture-fallback.png` },
        Name: `${contact.firstName} ${contact.lastName}`,
        Email: contact.email,
        Phone: contact.phone,
        Mobile: contact.cell,
        Customer: contact.customer.customerName,
        PhoneAndMobile: { phone: contact.phone, mobile: contact.cell },
        NameAndEmail: {
          name: `${contact.firstName} ${contact.lastName}`,
          email: contact.email,
        },
        Edit: { id: contact.contactId, btnClass: "btn-color-1" },
      });
    });
    setRowData(data);
  }, [contactList]);

  useEffect(() => {
    fillSelectedContact(selectedContactId);
  }, [selectedContactId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getContactsData();
    dispatch(setContactsListData(response));
    setIsLoading(false);
  };

  const getContactsData = async () => {
    if (isSuperAdmin(userType)) {
      return await fetchContacts();
    }

    if (isNTMSUSer(userType)) {
      return selectedAccountState.accountId === null
        ? await fetchContactsFiltered(accountId)
        : await fetchContactsFiltered(selectedAccountState.accountId);
    }

    return await fetchContactsFiltered(accountId);
  };

  const getCustomersData = async () => {
    const response = await fetchCustomersByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
    setCustomerOptions(
      response.map((customer) => ({
        label: customer.customerName,
        value: customer.customerId,
        email: customer.defaultEmail,
      }))
    );
  };

  const fillSelectedContact = (id) => {
    const contact = _.find(contactList, { contactId: id });
    setSelectedContact(contact);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Contacts"
                : isFormEdit
                  ? "Edit Contact"
                  : "New Contact"
            }
            subtitle={"contacts"}
            dataCount={contactList.length}
            filterData={filterData}
            onClickAdd={createContactToggle}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">

        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(contactList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-1-grid color-1-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-1' content='contact' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <ContactForm
            isFormEdit={isFormEdit}
            selectedContact={selectedContact}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
            customerCreationInformation={customerCreationInformation}
            setCustomerCreationInformation={setCustomerCreationInformation}
          />
        </div>
      </div>
      <ContactModal
        modal={createContactModal}
        setModal={setCreateContactModal}
        toggle={createContactToggle}
        customerOptions={customerOptions}
        customerCreationInformation={customerCreationInformation}
        setCustomerCreationInformation={setCustomerCreationInformation}
        toggleFormCancelDisplay={toggleFormCancelDisplay}
      />
    </div>
  );
}

export default Contacts;
