import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import ProfilePictureRenderer from '../Templates/CellRenderers/Commons/ProfilePictureRenderer';
import { formatCurrency, formatDateOnly } from '../../utils/formatUtils';
import _ from 'lodash';
import { fetchInvoices, fetchInvoicesFiltered } from '../../services/invoiceServices';
import InvoiceDetails from './InvoiceDetails';
import PaymentsForm from './PaymentsForm';
import { fetchPayments } from '../../services/paymentServices';
import { getInvoiceBalance } from '../../utils/paymentUtils';
import { useLocation } from 'react-router-dom';
import TwoRowLabelRenderer from '../Templates/CellRenderers/Commons/TwoRowLabelRenderer';
import { isSuperAdmin, isNTMSUSer } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import PaymentTypeRenderer from '../Templates/CellRenderers/Payments/PaymentTypeRenderer';
import { Height } from '@mui/icons-material';

function Payments() {
  const { state } = useLocation();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );

  const defaultInvoiceColumnDefs = [
    {
      field: 'ProfilePicture',
      minWidth: 70,
      headerName: '',
      cellRenderer: ProfilePictureRenderer,
      width: 80,
      suppressSizeToFit: true
    },
    {
      field: 'Customer',
      cellClass: 'grid-column',
      minWidth: 150,
      cellRenderer: TwoRowLabelRenderer,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Total',
      cellClass: 'grid-column gray',
      minWidth: 110,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Balance',
      cellClass: 'grid-column gray',
      minWidth: 110,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'DueDate',
      cellClass: 'grid-column gray',
      minWidth: 100,
      sortable: true,
      getQuickFilterText: params => { return params.value; }
    },
  ]

  const defaultPaymentColumnDefs = [
    {
      field: 'Amount',
      cellClass: 'grid-column',
      minWidth: 110,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Payment Type',
      headerName: "Mode of Payment",
      cellClass: 'grid-column gray',
      minWidth: 110,
      cellRenderer: PaymentTypeRenderer,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'Description',
      cellClass: 'grid-column gray',
      minWidth: 110,
      getQuickFilterText: params => { return params.value; }
    },
    {
      field: 'DatePaid',
      cellClass: 'grid-column gray',
      minWidth: 110,
      getQuickFilterText: params => { return params.value; }
    },
  ]

  // useStates
  const [invoiceRowData, setInvoiceRowData] = useState([]);
  const [invoiceColumnDefs] = useState(defaultInvoiceColumnDefs);
  const [paymentsRowData, setPaymentsRowData] = useState([]);
  const [paymentsColumnDefs] = useState(defaultPaymentColumnDefs);
  const [paymentList, setPaymentList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useRefs
  const invoicesGridRef = useRef();
  const paymentsGridRef = useRef();

  // useEffects
  useEffect(() => {
    fillInvoiceList();
  }, []);

  useEffect(() => {
    let data = [];
    _.each(invoiceList, (invoice, index) => {
      data.push({
        "#": index + 1,
        InvoiceId: invoice.invoiceId,
        ProfilePicture: { url: `/images/profile-picture-fallback.png` },
        Customer: {
          label1: invoice.customer.customerName,
          label2: invoice.quoteName,
        },
        Total: formatCurrency(invoice.totalInvoice),
        Balance: formatCurrency(getInvoiceBalance(invoice)),
        DueDate: formatDateOnly(invoice.paymentDueDate),
      });
    });
    setInvoiceRowData(data);

    if (!_.isNull(_.get(state, "invoiceId", null))) {
      fillSelectedInvoice(state.invoiceId);
      fillPaymentList(state.invoiceId);
    }
  }, [invoiceList]);

  useEffect(() => {
    if (!_.isNull(_.get(state, "invoiceId", null))) {
      invoicesGridRef?.current?.api?.forEachNode((node) => {
        if (node?.data?.InvoiceId === state.invoiceId) {
          node.setSelected(true);
        }
      });
    }

    if (!_.isNull(selectedRow)) {
      invoicesGridRef?.current?.api?.forEachNode((node) => {
        if (node?.rowIndex === selectedRow) {
          node.setSelected(true);
        }
      });
    }
  }, [invoiceRowData]);

  useEffect(() => {
    // @TODO: Calculations for Balance (totalInvoice - paymentsDone)
    let data = [];
    _.each(paymentList, (payment, index) => {
      data.push({
        "#": index + 1,
        Amount: formatCurrency(payment.paymentAmount),
        Description: payment.paymentDescription,
        "Payment Type": payment.paymentType,
        DatePaid: formatDateOnly(payment.paymentDate),
      });
    });
    setPaymentsRowData(data);
  }, [paymentList]);

  useEffect(() => {
    if (!_.isNull(selectedInvoiceId)) {
      fillSelectedInvoice(selectedInvoiceId);
      fillPaymentList(selectedInvoiceId);
    }
  }, [selectedInvoiceId]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    invoicesGridRef.current.api.sizeColumnsToFit();
    paymentsGridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillInvoiceList = async () => {
    let response = null;
    if (isSuperAdmin(userType)) {
      response = await fetchInvoices();
    } else if (isNTMSUSer(userType)) {
      if (selectedAccountState.accountId === null) {
        response = await fetchInvoicesFiltered(accountId);
      } else {
        response = await fetchInvoicesFiltered(selectedAccountState.accountId);
      }
    } else {
      response = await fetchInvoicesFiltered(accountId);
    }

    setInvoiceList(response);
  };

  const fillPaymentList = async (invoiceId) => {
    const response = await fetchPayments(invoiceId);
    setPaymentList(response);
  };

  const fillSelectedInvoice = (id) => {
    const invoice = _.find(invoiceList, { invoiceId: id });
    setSelectedInvoice(invoice);
  };

  const filterData = (searchQuery) => {
    invoicesGridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onCellClicked = (event) => {
    setSelectedRow(event.node.rowIndex);
    event.node.setSelected(true);
    paymentsGridRef.current.api.refreshCells();
    setSelectedInvoiceId(event.data.InvoiceId);
  };

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title="Invoice Payments"
            filterData={filterData}
            hideButton={true}
            hideTotal={true}
            hideSubtitle={true}
          />
        </div>
      </div>
      <div className="content-body-container row">
        <div className={`col-12 col-lg-5 min-vh-25 mb-5`}>
          <div className='form-title mb-2'>
            Invoices
          </div>
          <div className={`ag-theme-alpine content-section-container color-4-grid color-4-section`}>
            <AgGridReact
              className="no-header"
              rowData={invoiceRowData}
              columnDefs={invoiceColumnDefs}
              ref={invoicesGridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              onCellClicked={onCellClicked}
              rowHeight={70}
            ></AgGridReact>
          </div>
        </div>
        <div className={`col-12 col-lg-7 mb-2`}>
          <div className='row mb-2'>

            <div className='col-12'>
              <div className={`content-section-container color-4-section`}>
                <div className='form-title mb-2 d-flex flex-row align-items-center'>
                  Invoice Details
                  <p className='fw-bold ms-auto my-auto'>Customer: {selectedInvoice?.customer?.customerName ?? '-'}</p>
                </div>
                <InvoiceDetails selectedInvoice={selectedInvoice} />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className={`col-12 min-vh-25 mb-5`}>
              <div className='form-title mb-2'>
                Existing Payments
              </div>
              <div className={`ag-theme-alpine content-section-container color-4-grid color-4-section`}>

                <AgGridReact
                  className="no-header"
                  rowData={paymentsRowData}
                  columnDefs={paymentsColumnDefs}
                  ref={paymentsGridRef}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  onColumnSizeChanged={onColumnsSizeChanged}
                  onGridSizeChanged={onGridSizeChanged}
                  rowHeight={50}
                  overlayNoRowsTemplate={_.isEmpty(selectedInvoice) ? 'No payments on this invoice' : 'No payments on this invoice'}
                >
                </AgGridReact>
              </div>
            </div>
          </div>
          <div className='row mb-2'>

            <div className='col-12'>
              <div className={`content-section-container min-vh-25 color-4-section`}>
                <div className='form-title mb-2'>
                  Payment Details
                </div>
                <PaymentsForm
                  setIsFormDirty={setIsFormDirty}
                  selectedInvoice={selectedInvoice}
                  fillPaymentList={fillPaymentList}
                  fillInvoiceList={fillInvoiceList}
                  modal={modal}
                  setModal={setModal}
                  toggle={toggle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payments;
