import { TimePicker } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { COUNTRY, PROVINCE_STATE } from "../../constants";
import { selectStyle } from "../../utils/styleUtils";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";
import { setCoordinatesData } from "../../redux/global/globalReducer";

function FormShipperDetails({
  customerOptions,
  control,
  register,
  setValue,
  errors,
  checkPageEditable,
  selectedShipperOption,
  setSelectedShipperOption,
  selectedShipperAddressValues,
  setSelectedShipperAddressValues,
}) {
  const dispatch = useDispatch();
  const customerState = useSelector((state) => state.customer);

  const setAddressValues = (selectedSuggestion) => {
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedShipperProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (selectedShipperProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue("shipperAddress1", selectedSuggestion?.properties?.address_line1);
    setValue("shipperAddress2", selectedSuggestion?.properties?.address_line2);
    setValue("shipperCity", selectedSuggestion?.properties?.city);
    setValue("shipperPostal", selectedSuggestion?.properties?.postcode);
    setValue("shipperState", selectedShipperProvinceState);
    setValue("shipperCountry", selectedCountry);
    setValue("shipperLatitude", `${selectedSuggestion?.properties?.lat}`);
    setValue("shipperLongitude", `${selectedSuggestion?.properties?.lon}`);
  };

  const setAddressNotes = (addressNotes) => {
    setValue("shipperAddressNotes", addressNotes);
  };

  const handleShipperNameChange = (selectedOption) => {
    setValue("shipperId", selectedOption.value);
    setSelectedShipperOption(selectedOption);

    const selectedCustomerData = _.find(customerState.data, (customer) => {
      return customer.customerId === selectedOption?.value;
    });

    if (_.isNil(selectedCustomerData)) {
      return;
    }

    const payload = {
      name: selectedCustomerData.customerName,
      contact: selectedCustomerData.primaryContact,
      email: selectedCustomerData.defaultEmail,
      phone: selectedCustomerData.phone,
      addressLine1: selectedCustomerData.addressLine1,
      addressLine2: selectedCustomerData.addressLine2,
      provinceState: selectedCustomerData.provinceState,
      city: selectedCustomerData.city,
      country: selectedCustomerData.country,
      postalCode: selectedCustomerData.postalCode,
    };

    setSelectedShipperAddressValues({
      addressLine1: selectedCustomerData.addressLine1,
      addressLine2: selectedCustomerData.addressLine2,
      provinceState: selectedCustomerData.provinceState,
      city: selectedCustomerData.city,
      country: selectedCustomerData.country,
      postalCode: selectedCustomerData.postalCode,
    });

    setValue("shipperLatitude", selectedCustomerData.latitude);
    setValue("shipperLongitude", selectedCustomerData.longitude);

    dispatch(
      setCoordinatesData({
        property: "origin",
        data: {
          latitude: selectedCustomerData.latitude,
          longitude: selectedCustomerData.longitude,
          addressLine1: selectedCustomerData.addressLine1,
          addressLine2: selectedCustomerData.addressLine2,
          provinceState: selectedCustomerData.provinceState,
          city: selectedCustomerData.city,
          country: selectedCustomerData.country,
          postalCode: selectedCustomerData.postalCode,
          isInitialData: false
        },
      })
    );

    fillShipperFields(payload);
  };

  const fillShipperFields = (payload) => {
    const selectedShipperProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.value === payload.provinceState;
      }
    );

    const selectedShipperCountry = _.find(COUNTRY, (country) => {
      return country.value === payload.country;
    });

    setValue("shipperName", payload.name);
    setValue("shipperContactName", payload.contact);
    setValue("shipperDefaultEmail", payload.email);
    setValue("shipperPhoneNumber", payload.phone);
    setValue("shipperAddress1", payload.addressLine1);
    setValue("shipperAddress2", payload.addressLine2);
    setValue("shipperState", selectedShipperProvinceState);
    setValue("shipperCity", payload.city);
    setValue("shipperCountry", selectedShipperCountry);
    setValue("shipperPostal", payload.postalCode);
  };

  return (
    <>
      {/* Shipper Details */}
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="shipperName" className="form-label">
          Name <span className="required-asterisk">*</span>
        </label>
        <div className="custom-selector">
          <Controller
            name="shipperName"
            control={control}
            register={register}
            rules={{ required: "Name is required" }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                onChange={handleShipperNameChange}
                value={selectedShipperOption}
                options={customerOptions}
                className="shipper-name-select"
                classNamePrefix="react-select"
                placeholder={"Select Customer / Create new Customer"}
                id="shipperName"
                styles={selectStyle}
                tabIndex={2}
                isDisabled={checkPageEditable()}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.shipperName && errors.shipperName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="shipperContactName" className="form-label">
          Primary Contact <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="shipperContactName"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("shipperContactName", {
            required: "Primary Contact is required",
          })}
        />
        <small className="form-error-message">
          {errors?.shipperContactName && errors.shipperContactName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="shipperDefaultEmail" className="form-label">
          Default Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="shipperDefaultEmail"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("shipperDefaultEmail", {
            required: "Default Email is required",
          })}
        />
        <small className="form-error-message">
          {errors?.shipperDefaultEmail && errors.shipperDefaultEmail.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="shipperPhoneNumber" className="form-label">
          Phone <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="shipperPhoneNumber"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("shipperPhoneNumber", {
            required: "Phone Number is required",
          })}
        />
        <small className="form-error-message">
          {errors?.shipperPhoneNumber && errors.shipperPhoneNumber.message}
        </small>
      </div>
      <div className="col-12 p-0 mb-3">
        <hr></hr>
      </div>

      <AutocompleteAddressForm
        setAddressValues={setAddressValues}
        setAddressNotes={setAddressNotes}
        selectedOrderAddressValues={selectedShipperAddressValues}
      />

      <div className="col-6 col-sm-4">
        <div className="row">
          <div className="col-6 col-sm-6 mb-4">
            <label htmlFor="shipperOpenTime" className="form-label">
              Opening Hour
            </label>
            <div>
              <Controller
                control={control}
                name="shipperOpenTime"
                register={register}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <TimePicker
                    className="antd-time-picker"
                    format="H:mm"
                    name={name}
                    tabIndex={11}
                    use12Hours={false}
                    value={value}
                    onChange={onChange}
                    disabled={checkPageEditable()}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.shipperOpenTime && errors.shipperOpenTime.message}
              </small>
            </div>
          </div>

          <div className="col-6 col-sm-6 mb-4">
            <label htmlFor="shipperCloseTime" className="form-label">
              Closing Hour
            </label>
            <div>
              <Controller
                control={control}
                name="shipperCloseTime"
                register={register}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <TimePicker
                    className="antd-time-picker"
                    name={name}
                    tabIndex={12}
                    format="H:mm"
                    value={value}
                    onChange={onChange}
                    disabled={checkPageEditable()}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.shipperCloseTime && errors.shipperCloseTime.message}
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormShipperDetails;
