import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchAccessorials = async () => {
  return get({ path: 'Accessorial' });
}

export const fetchAccessorialsFiltered = async (accountId) => {
  return get({ path: `Accessorial/filtered` });
}

export const addAccessorial = async (payload) => {
  return post({ path: 'Accessorial', body: payload });
}

export const updateAccessorial = async (payload) => {
  return patch({ path: `Accessorial/${payload.accessorialId}`, body: payload });
}

export const fetchAccessorialsByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchAccessorialsFiltered(accountId) : await fetchAccessorialsFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchAccessorialsFiltered(accountId);
  }
  
  return await fetchAccessorials();
}