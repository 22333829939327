import React from 'react'
import { formatCurrency } from '../../utils/formatUtils';
function InvoiceDetailFooter({
  note,
  subtotal,
  discount,
  total,
  tax
}) {

  const isNonEmptyString = (value) => typeof (value) == 'string' && value.length > 0
  return (
    <div className="row quote-footer">
      <div className='col-12'>
        <></>
      </div>
      <p className='mb-0 fw-bold non-printable'>Notes:</p>
      <div className="col-6">
        <div className="form-group">
          <div className='notes-container'>
            {note || '-'}
          </div>
        </div>
      </div>
      <div className="col-6 d-flex justify-content-end">
        <div className='row text-end'>
          <div className="col-12">
            <p className='mb-2 fw-bold label'>Subtotal: {formatCurrency(subtotal)}</p>
            <p className='mb-2 fw-bold label'>Discount: {formatCurrency(discount)}</p>
            <p className='mb-3 fw-bold label'>Tax: {formatCurrency(tax)}</p>
            <p className='mb-0 fw-bold label total'>Total: {formatCurrency(total)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceDetailFooter