import React from 'react'
import { formatDateOnly, formatCurrency, formatTaxType } from '../../utils/formatUtils';
import { getInvoiceBalance } from '../../utils/paymentUtils';

function InvoiceDetails({ selectedInvoice }) {
  return (
    <div className='row invoice-details-container'>
      <div className="col-6 col-lg-4">
        <p className='fw-bold'>Invoice No: <span className='content'>{selectedInvoice?.invoiceId ?? '-'}</span></p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='fw-bold'>SubTotal: <span className='content'>{selectedInvoice?.subtotal !== undefined ? formatCurrency(selectedInvoice?.subtotal) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-4"></div>
      <div className="col-6 col-lg-4">
        <p className='fw-bold'>Invoice Name: <span className='content'>{selectedInvoice?.quoteName ?? '-'}</span></p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='fw-bold'>Discount: <span className='content'>{selectedInvoice?.discount !== undefined ? formatCurrency(selectedInvoice?.discount) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-4"></div>
      <div className="col-6 col-lg-4">
        <p className='fw-bold'>Invoice Date: <span className='content'>{selectedInvoice?.invoiceDate !== undefined ? formatDateOnly(selectedInvoice?.invoiceDate) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='fw-bold'>Tax: <span className='content'>{selectedInvoice?.totalTax !== undefined ? formatCurrency(selectedInvoice?.totalTax) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-4"></div>
      <div className="col-6 col-lg-4">
        <p className='fw-bold'>Due Date: <span className='content'>{selectedInvoice?.paymentDueDate !== undefined ? formatDateOnly(selectedInvoice?.paymentDueDate) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-4"></div>
      <div className="col-6 col-lg-4">
        <p className='fw-bold total'>Total: {selectedInvoice?.totalInvoice !== undefined ? formatCurrency(selectedInvoice?.totalInvoice) : '-'}</p>
      </div>
      {/* <div className="col-6 col-lg-4">
        <p className='fw-bold'>Tax Type: {selectedInvoice?.taxType !== undefined ? formatTaxType(selectedInvoice?.taxType) : '-'}</p>
        
      </div> */}
      {/* <div className="col-6 col-lg-4">
        <p className='fw-bold'>Balance: {formatCurrency(getInvoiceBalance(selectedInvoice))}</p>
      </div> */}
    </div>
  )
}

export default InvoiceDetails