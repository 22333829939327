import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const trailerMaintenanceReducer = createSlice({
  name: "trailerMaintenance",
  initialState,
  reducers: {
    setTrailerMaintenanceListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setTrailerMaintenanceData: (state, action) => {
      const index = state.data.findIndex(trailerMaintenance => trailerMaintenance.trailerMaintenanceId === action.payload.trailerMaintenanceId);
      const trailerMaintenanceListData = [...state.data];
      trailerMaintenanceListData[index] = action.payload;
      return {
        ...state,
        data: trailerMaintenanceListData
      }
    },
    addTrailerMaintenanceData: (state, action) => {
      const trailerMaintenanceListData = [...state.data];
      trailerMaintenanceListData.push(action.payload);
      return {
        ...state,
        data: trailerMaintenanceListData
      }
    },
  },
});

export const {
  setTrailerMaintenanceListData,
  setTrailerMaintenanceData,
  addTrailerMaintenanceData,
} = trailerMaintenanceReducer.actions;

export default trailerMaintenanceReducer.reducer;