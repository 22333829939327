import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate('/dashboard');
    }
  }, [])

  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>

      <div className='small-container login shadow rounded'>
        {/* <div className='title text-center mb-4'>
          <img
            src="/images/authentication.png"
            alt="Authentication"
            className="icon circle-rounded me-2"
            width="100" // Set the width to the desired value, for example, 50 pixels
            height="100"
          />
        </div> */}

        <div className='title text-center mb-4'>
          Authentication Required
        </div>
        <p className='content text-center'>
          You need to be authenticated to proceed. <br />
          {/* Please login <span className='text-link' onClick={() => loginWithRedirect()}>here.</span> */}
        </p>
        <div className='title text-center mb-4'>
        <button className="btn btn-lg btn-primary mt-3" onClick={loginWithRedirect}>Log In</button>
        </div>
      </div>
    </div>
  )
}

export default Login
