import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchTruckMaintenanceByAccount } from "../../services/truckMaintenanceServices";
import { setTruckMaintenanceListData } from "../../redux/truckMaintenance/truckMaintenanceReducer";
import TrucksMaintenanceForm from "./TrucksMaintenanceForm";
import TruckMaintenanceModal from "./TruckMaintenanceModal";
import { fetchTrucksFiltered } from "../../services/truckServices";
import { GRID_CONSTANTS } from "../../constants";
import { formatDateOnly } from "../../utils/formatUtils";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function TrucksMaintenance() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const trucksMaintenanceState = useSelector((state) => state.truckMaintenance);
  const [createTruckMaintenanceModal, setCreateTruckMaintenanceModal] = useState(false);
  const createTruckMaintenanceToggle = () => setCreateTruckMaintenanceModal(!createTruckMaintenanceModal);
  const [truckOptions, setTruckOptions] = useState([]);
  const [truckCreationInformation, setTruckCreationInformation] = useState(null);

  const toggleFormDisplay = (isEdit = false, truckMaintenanceId = null) => {
    if (isFormHidden) {
      setSelectedTrucksMaintenanceId(truckMaintenanceId);
    } else {
      setSelectedTrucksMaintenanceId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && trucksMaintenanceList?.length > 0) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "VIN",
      minWidth: 150,
      cellClass: 'grid-column',
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Date",
      minWidth: 150,
      cellClass: 'grid-column gray',
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Last Maintenance Date",
      minWidth: 185,
      cellClass: 'grid-column gray',
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Next Maintenance Date",
      minWidth: 185,
      cellClass: 'grid-column gray',
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Last Inspection Date",
      minWidth: 185,
      cellClass: 'grid-column gray',
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Next Inspection Date",
      minWidth: 185,
      cellClass: 'grid-column gray',
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Odometer Reading",
      minWidth: 185,
      cellClass: 'grid-column gray',
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      resizable: false,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [trucksMaintenanceList, setTrucksMaintenanceList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedTrucksMaintenance, setSelectedTrucksMaintenance] = useState({});
  const [selectedTrucksMaintenanceId, setSelectedTrucksMaintenanceId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    getTrucksData();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setTrucksMaintenanceList(trucksMaintenanceState.data);
  }, [trucksMaintenanceState.data]);

  useEffect(() => {
    let data = [];
    _.each(trucksMaintenanceList, (truckMaintenance, index) => {
      data.push({
        "#": index + 1,
        "VIN": truckMaintenance.vin,
        "Date": formatDateOnly(truckMaintenance.date),
        "Last Maintenance Date": formatDateOnly(truckMaintenance.lastMaintenanceDate),
        "Next Maintenance Date": formatDateOnly(truckMaintenance.nextMaintenanceDate),
        "Last Inspection Date": formatDateOnly(truckMaintenance.lastInspectionDate),
        "Next Inspection Date": formatDateOnly(truckMaintenance.nextInspectionDate),
        "Odometer Reading": truckMaintenance.odometerReading,
        "Edit": { id: truckMaintenance.truckMaintenanceId, btnClass: "btn-color-7" },
      });
    });

    setRowData(data);
  }, [trucksMaintenanceList]);

  useEffect(() => {
    fillSelectedTruckMaintenance(selectedTrucksMaintenanceId);
  }, [selectedTrucksMaintenanceId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getTrucksMaintenanceData();
    dispatch(setTruckMaintenanceListData(response));

    setIsLoading(false);
  };

  const getTrucksMaintenanceData = async () => {
    return await fetchTruckMaintenanceByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
  };

  const getTrucksData = async () => {
    const response = await fetchTrucksFiltered(accountId);
    setTruckOptions(response.map((truck) => ({
      label: truck.vin,
      value: truck.truckId,
      make: truck.make,
      model: truck.model,
      year: truck.year,
    })));
  }

  const fillSelectedTruckMaintenance = (id) => {
    const truckMaintenance = _.find(trucksMaintenanceList, { truckMaintenanceId: id });
    setSelectedTrucksMaintenance(truckMaintenance);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={isFormHidden ? "Trucks Maintenance" : isFormEdit ? "Edit Truck Maintenance" : "New Truck Maintenance"}
            subtitle={"trucks maintenance"}
            dataCount={trucksMaintenanceList.length}
            filterData={filterData}
            onClickAdd={createTruckMaintenanceToggle}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(trucksMaintenanceList?.length > 0) ?
            <div className={`ag-theme-alpine content-section-container color-7-grid color-7-section p-0`}>
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-7' content='truck maintenance' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <TrucksMaintenanceForm
            isFormEdit={isFormEdit}
            selectedTruckMaintenance={selectedTrucksMaintenance}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
            truckCreationInformation={truckCreationInformation}
            setTruckCreationInformation={setTruckCreationInformation}
          />
        </div>
      </div>
      <TruckMaintenanceModal
        modal={createTruckMaintenanceModal}
        setModal={setCreateTruckMaintenanceModal}
        toggle={createTruckMaintenanceToggle}
        truckOptions={truckOptions}
        truckCreationInformation={truckCreationInformation}
        setTruckCreationInformation={setTruckCreationInformation}
        toggleFormCancelDisplay={toggleFormCancelDisplay}
      />
    </div>
  );
}

export default TrucksMaintenance;
