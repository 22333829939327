import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const accessorialReducer = createSlice({
  name: "accessorial",
  initialState,
  reducers: {
    setAccessorialsListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setAccessorialData: (state, action) => {
      const index = state.data.findIndex(accessorial => accessorial.accessorialId === action.payload.accessorialId);
      const accessorialsListData = [...state.data];
      accessorialsListData[index] = action.payload;
      return {
        ...state,
        data: accessorialsListData
      }
    },
    addAccessorialData: (state, action) => {
      const accessorialsListData = [...state.data];
      accessorialsListData.push(action.payload);
      return {
        ...state,
        data: accessorialsListData
      }
    },
  },
});

export const {
  setAccessorialsListData,
  setAccessorialData,
  addAccessorialData,
} = accessorialReducer.actions;

export default accessorialReducer.reducer;