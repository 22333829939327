import React, { useCallback, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrderAccessorial } from '../../../../services/orderAccessorialServices';
import { update } from 'lodash';
import { setNotificationData } from '../../../../redux/global/globalReducer';
import { DeleteOutlined } from '@ant-design/icons';
import { ConfirmationModal } from '../../../Commons/Modals/ConfirmationModal';

function DeleteItemActionRenderer({
  api,
  data,
  header,
  parentType,
  deleteFunction,
  deleteReduxFunction,
  saveData = null,
  handleDeleteFunction = null
}, args) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleDeleteItem = async () => {
    await handleDeleteFunction();
  }

  const quoteItemCategoryCheck = () => {
    if(parentType === "Quote") {
      return data?.Type === 3 || data?.Type === 4;
    }
    return true;
  }

  return (
    <div className='cell-renderer-content'>
      {quoteItemCategoryCheck() &&
        <>
          <button onClick={toggle} type='button' className={`btn btn-color-delete btn-sm`}><DeleteOutlined /></button>
          <ConfirmationModal
            isOpen={modal}
            toggle={toggle}
            onClick={handleDeleteItem}
            headerText={`Delete ${header}`}
            bodyText="Are you sure you want to delete this item?"
            confirmButtonText="Yes"
            cancelButtonText="No"
            buttonColor="primary"
            actionType={"delete"}
          />
        </>
      }

    </div>
  )
}

export default DeleteItemActionRenderer
