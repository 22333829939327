import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { NumericFormat } from 'react-number-format';

const DollarEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const refInput = useRef(null);

  useEffect(() => {
    if (refInput.current) {
      refInput.current.focus();
      setTimeout(() => refInput.current.select(), 50);
    }
  }, []);

  const onValueChange = (values) => {
    const { floatValue } = values;
    setValue(floatValue);
  };

  const getValue = () => {
    return value;
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return value;
    },
  }));

  return (
    <NumericFormat
      value={value}
      thousandSeparator={true}
      prefix={'$'}
      onValueChange={onValueChange}
      getInputRef={(input) => (refInput.current = input)}
      className="percent-editor"
    />
  );
});

export default DollarEditor;