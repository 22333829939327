import { AccessTime, CalendarMonth } from "@mui/icons-material";
import _ from "lodash";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setLoadItemsListData } from "../../redux/loadItems/loadItemsReducer";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";
import { COUNTRY, PROVINCE_STATE } from "../../constants";

const OrderDetails = forwardRef(
  (
    {
      isFormHidden,
      selectedOrder = {},
      checkPageEditable,
      setValue,
      control,
      register,
      errors,
      setDirtyLoadOrderColumns,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const coordinatesState = useSelector((state) => state.global.coordinates);

    const [selectedPickupAddressValues, setSelectedPickupAddressValues] =
      useState({});
    const [selectedDropoffAddressValues, setSelectedDropoffAddressValues] =
      useState({});

    useEffect(() => {
      if (isFormHidden) {
        setDirtyLoadOrderColumns(false);
        dispatch(setLoadItemsListData([]));
      }
    }, [isFormHidden]);

    useEffect(() => {
      if (!_.isEmpty(selectedOrder)) {
        setValue("instructions", selectedOrder.instructions);
        setValue(
          "pickupStartDate",
          moment(selectedOrder.pickupStartDate)
            .clone()
            .hour(7)
            .minute(0)
            .second(0)
            .toDate()
        );
        setValue(
          "pickupEndDate",
          moment(selectedOrder.pickupEndDate)
            .clone()
            .hour(7)
            .minute(0)
            .second(0)
            .toDate()
        );
        setValue(
          "dropoffStartDate",
          moment(selectedOrder.dropoffStartDate)
            .clone()
            .hour(7)
            .minute(0)
            .second(0)
            .toDate()
        );
        setValue(
          "dropoffEndDate",
          moment(selectedOrder.dropoffEndDate)
            .clone()
            .hour(7)
            .minute(0)
            .second(0)
            .toDate()
        );

        setValue(
          "pickupStartTime",
          moment(selectedOrder.pickupStartTime, "HH:mm:ss").toDate()
        );
        setValue(
          "pickupEndTime",
          moment(selectedOrder.pickupEndTime, "HH:mm:ss").toDate()
        );
        setValue(
          "dropoffStartTime",
          moment(selectedOrder.dropoffStartTime, "HH:mm:ss").toDate()
        );
        setValue(
          "dropoffEndTime",
          moment(selectedOrder.dropoffEndTime, "HH:mm:ss").toDate()
        );

        setSelectedPickupAddressValues({
          addressLine1: selectedOrder.pickupAddress1,
          addressLine2: selectedOrder.pickupAddress2,
          provinceState: selectedOrder.pickupState,
          city: selectedOrder.pickupCity,
          country: selectedOrder.pickupCountry,
          postalCode: selectedOrder.pickupPostal,
          addressNotes: selectedOrder.pickupAddressNotes,
          isPreFilled: !_.isNil(selectedOrder?.pickupAddress1),
        });

        setSelectedDropoffAddressValues({
          addressLine1: selectedOrder.dropoffAddress1,
          addressLine2: selectedOrder.dropoffAddress2,
          provinceState: selectedOrder.dropoffState,
          city: selectedOrder.dropoffCity,
          country: selectedOrder.dropoffCountry,
          postalCode: selectedOrder.dropoffPostal,
          addressNotes: selectedOrder.dropoffAddressNotes,
          isPreFilled: !_.isNil(selectedOrder?.pickupAddress1),
        });
      }
    }, [selectedOrder]);

    useEffect(() => {
      if (
        !coordinatesState?.origin?.isInitialData &&
        !selectedPickupAddressValues?.isPreFilled
      ) {
        setSelectedPickupAddressValues({
          addressLine1: coordinatesState?.origin?.addressLine1,
          addressLine2: coordinatesState?.origin?.addressLine2,
          provinceState: coordinatesState?.origin?.provinceState,
          city: coordinatesState?.origin?.city,
          country: coordinatesState?.origin?.country,
          postalCode: coordinatesState?.origin?.postalCode,
        });
      }

      if (
        !coordinatesState?.destination?.isInitialData &&
        !selectedDropoffAddressValues?.isPreFilled
      ) {
        setSelectedDropoffAddressValues({
          addressLine1: coordinatesState?.destination?.addressLine1,
          addressLine2: coordinatesState?.destination?.addressLine2,
          provinceState: coordinatesState?.destination?.provinceState,
          city: coordinatesState?.destination?.city,
          country: coordinatesState?.destination?.country,
          postalCode: coordinatesState?.destination?.postalCode,
        });
      }
    }, [coordinatesState]);

    const setPickupAddressValues = (selectedSuggestion) => {
      const firstUSState = PROVINCE_STATE.find(
        (provinceState) => provinceState.label === "Alabama"
      ).value;

      const selectedPickupProvinceState = _.find(
        PROVINCE_STATE,
        (provinceState) => {
          return provinceState.label === selectedSuggestion?.properties?.state;
        }
      );

      const selectedCountry = _.find(COUNTRY, (country) => {
        return (
          country.value ===
          (selectedPickupProvinceState?.value >= firstUSState ? 2 : 1)
        );
      });

      setValue("pickupAddress1", selectedSuggestion?.properties?.address_line1);
      setValue("pickupAddress2", selectedSuggestion?.properties?.address_line2);
      setValue("pickupCity", selectedSuggestion?.properties?.city);
      setValue("pickupPostal", selectedSuggestion?.properties?.postcode);
      setValue("pickupState", selectedPickupProvinceState);
      setValue("pickupCountry", selectedCountry);
      setValue("pickupLatitude", `${selectedSuggestion?.properties?.lat}`);
      setValue("pickupLongitude", `${selectedSuggestion?.properties?.lon}`);
    };

    const setPickupAddressNotes = (addressNotes) => {
      setValue("pickupAddressNotes", addressNotes);
    };

    const setDropoffAddressValues = (selectedSuggestion) => {
      const firstUSState = PROVINCE_STATE.find(
        (provinceState) => provinceState.label === "Alabama"
      ).value;

      const selectedDropoffProvinceState = _.find(
        PROVINCE_STATE,
        (provinceState) => {
          return provinceState.label === selectedSuggestion?.properties?.state;
        }
      );

      const selectedCountry = _.find(COUNTRY, (country) => {
        return (
          country.value ===
          (selectedDropoffProvinceState?.value >= firstUSState ? 2 : 1)
        );
      });

      setValue(
        "dropoffAddress1",
        selectedSuggestion?.properties?.address_line1
      );
      setValue(
        "dropoffAddress2",
        selectedSuggestion?.properties?.address_line2
      );
      setValue("dropoffCity", selectedSuggestion?.properties?.city);
      setValue("dropoffPostal", selectedSuggestion?.properties?.postcode);
      setValue("dropoffState", selectedDropoffProvinceState);
      setValue("dropoffCountry", selectedCountry);
      setValue("dropoffLatitude", `${selectedSuggestion?.properties?.lat}`);
      setValue("dropoffLongitude", `${selectedSuggestion?.properties?.lon}`);
    };

    const setDropoffAddressNotes = (addressNotes) => {
      setValue("dropoffAddressNotes", addressNotes);
    };

    return (
      <>
        <div className="row mb-3">
          {/* Pickup Details */}
          <div className="col-12 col-sm-6">
            <div className="row">
              <div className="col-12">
                <label className="form-label">Pickup Details</label>
              </div>
            </div>

            <div className="row mb-4">
              <AutocompleteAddressForm
                setAddressValues={setPickupAddressValues}
                setAddressNotes={setPickupAddressNotes}
                selectedOrderAddressValues={selectedPickupAddressValues}
              />
            </div>

            {/* Pickup Start Date - Time */}
            <div className="row mb-2">
              <div className="col-12 mb-1">
                <div className="d-flex flex-row align-items-center">
                  <label className="form-label simple mb-0">
                    Start Date / Start Time:
                  </label>
                  <div className="ms-3">
                    <Controller
                      control={control}
                      name="pickupStartDate"
                      register={register}
                      rules={{ required: "Pickup Start Date is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          className="datepicker-field"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="yyyy-MM-dd"
                          id="pickupStartDate"
                          tabIndex={8}
                          disabled={checkPageEditable()}
                        />
                      )}
                    />
                    <small className="form-error-message">
                      {errors?.pickupStartDate &&
                        errors.pickupStartDate.message}
                    </small>
                    <small className="form-error-message">
                      {errors?.pickupStartTime &&
                        errors.pickupStartTime.message}
                    </small>
                  </div>
                  <div className="ms-2">
                    <CalendarMonth />
                  </div>
                  <div className="ms-4">
                    <Controller
                      control={control}
                      name="pickupStartTime"
                      register={register}
                      rules={{ required: "Pickup Start Time is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          className="datepicker-field"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          id="pickupStartTime"
                          tabIndex={8}
                          disabled={checkPageEditable()}
                        />
                      )}
                    />
                  </div>
                  <div className="ms-2">
                    <AccessTime />
                  </div>
                </div>
              </div>
            </div>

            {/* Pickup End Date - Time */}
            <div className="row">
              <div className="col-12 mb-1">
                <div className="d-flex flex-row align-items-center">
                  <label className="form-label simple mb-0 me-2">
                    End Date / End Time:
                  </label>
                  <div className="ms-4">
                    <Controller
                      control={control}
                      name="pickupEndDate"
                      register={register}
                      rules={{ required: "Pickup End Date is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          className="datepicker-field"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="yyyy-MM-dd"
                          id="pickupEndDate"
                          tabIndex={8}
                          disabled={checkPageEditable()}
                        />
                      )}
                    />
                    <small className="form-error-message">
                      {errors?.pickupEndDate && errors.pickupEndDate.message}
                    </small>
                    <small className="form-error-message">
                      {errors?.pickupEndTime && errors.pickupEndTime.message}
                    </small>
                  </div>
                  <div className="ms-2">
                    <CalendarMonth />
                  </div>
                  <div className="ms-4">
                    <Controller
                      control={control}
                      name="pickupEndTime"
                      register={register}
                      rules={{ required: "Pickup End Time is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          className="datepicker-field"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          id="pickupEndTime"
                          tabIndex={8}
                          disabled={checkPageEditable()}
                        />
                      )}
                    />
                  </div>
                  <div className="ms-2">
                    <AccessTime />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Dropoff Details */}
          <div className="col-12 col-sm-6">
            <div className="row">
              <div className="col-12">
                <label className="form-label">Dropoff Details</label>
              </div>
            </div>

            <div className="row mb-4">
              <AutocompleteAddressForm
                setAddressValues={setDropoffAddressValues}
                setAddressNotes={setDropoffAddressNotes}
                selectedOrderAddressValues={selectedDropoffAddressValues}
              />
            </div>

            {/* Dropoff Start Date - Time */}
            <div className="row mb-2">
              <div className="col-12 mb-1">
                <div className="d-flex flex-row align-items-center">
                  <label className="form-label simple mb-0">
                    Start Date / Start Time:
                  </label>
                  <div className="ms-3">
                    <Controller
                      control={control}
                      name="dropoffStartDate"
                      register={register}
                      rules={{ required: "dropoff Start Date is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          className="datepicker-field"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="yyyy-MM-dd"
                          id="dropoffStartDate"
                          tabIndex={8}
                          disabled={checkPageEditable()}
                        />
                      )}
                    />
                    <small className="form-error-message">
                      {errors?.pickupStartDate &&
                        errors.pickupStartDate.message}
                    </small>
                    <small className="form-error-message">
                      {errors?.pickupStartTime &&
                        errors.pickupStartTime.message}
                    </small>
                  </div>
                  <div className="ms-2">
                    <CalendarMonth />
                  </div>
                  <div className="ms-4">
                    <Controller
                      control={control}
                      name="dropoffStartTime"
                      register={register}
                      rules={{ required: "dropoff Start Time is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          className="datepicker-field"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          id="dropoffStartTime"
                          tabIndex={8}
                          disabled={checkPageEditable()}
                        />
                      )}
                    />
                  </div>
                  <div className="ms-2">
                    <AccessTime />
                  </div>
                </div>
              </div>
            </div>

            {/* dropoff End Date - Time */}
            <div className="row">
              <div className="col-12 mb-1">
                <div className="d-flex flex-row align-items-center">
                  <label className="form-label simple mb-0 me-2">
                    End Date / End Time:
                  </label>
                  <div className="ms-4">
                    <Controller
                      control={control}
                      name="dropoffEndDate"
                      register={register}
                      rules={{ required: "dropoff End Date is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          className="datepicker-field"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="yyyy-MM-dd"
                          id="dropoffEndDate"
                          tabIndex={8}
                          disabled={checkPageEditable()}
                        />
                      )}
                    />
                    <small className="form-error-message">
                      {errors?.pickupEndDate && errors.pickupEndDate.message}
                    </small>
                    <small className="form-error-message">
                      {errors?.pickupEndTime && errors.pickupEndTime.message}
                    </small>
                  </div>
                  <div className="ms-2">
                    <CalendarMonth />
                  </div>
                  <div className="ms-4">
                    <Controller
                      control={control}
                      name="dropoffEndTime"
                      register={register}
                      rules={{ required: "dropoff End Time is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          className="datepicker-field"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          id="dropoffEndTime"
                          tabIndex={8}
                          disabled={checkPageEditable()}
                        />
                      )}
                    />
                  </div>
                  <div className="ms-2">
                    <AccessTime />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Order Instructions */}

        <div className="col-12 mb-2">
          <label className="form-label">Instructions</label>
        </div>

        <div className="col-12">
          <textarea
            className="form-control"
            id="instructions"
            rows="3"
            {...register("instructions")}
            maxLength={250}
            tabIndex={19}
            disabled={checkPageEditable()}
          />
          <small className="form-error-message">
            {errors?.pickupStartDate && errors.pickupStartDate.message}
          </small>
        </div>
      </>
    );
  }
);

export default OrderDetails;
