import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { addTrailerMaintenance, updateTrailerMaintenance } from "../../services/trailerMaintenanceServices";
import {
  isNTMSUSer,
  isAccountAdmin,
  isSuperAdmin,
} from "../../utils/roleUtils";
import ModalRender from "../Commons/ModalRender";
import { removePhoneFormat } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPLIANCE
} from "../../constants";
import {
  addTrailerMaintenanceData,
  setTrailerMaintenanceData,
} from "../../redux/trailerMaintenance/trailerMaintenanceReducer";
import moment from "moment";
import PrimaryDetails from "./PrimaryDetails";
import FormTabs from "../Commons/Layouts/FormTabs";
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";
import dayjs from "dayjs";

function TrailerMaintenanceForm({
  isFormEdit = false,
  selectedTrailerMaintenance = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
  trailerCreationInformation,
  setTrailerCreationInformation
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const [trailerMaintenanceType, setTrailerMaintenanceType] = useState(1);
  const [compliances, setCompliances] = useState(1);
  const isInitialRender = useRef(true);

  const [trailerVIN, setTrailerVIN] = useState("");
  const [trailerMake, setTrailerMake] = useState("");
  const [trailerModel, setTrailerModel] = useState("");
  const [trailerYear, setTrailerYear] = useState();
  const [trailerId, setTrailerId] = useState("");

  // form values
  setValue("trailerMaintenanceId", 0);
  setValue("accountId", accountId);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("vin");
      }, 50);

      if (trailerCreationInformation !== null) {
        setTrailerVIN(trailerCreationInformation.label);
        setTrailerMake(trailerCreationInformation.make);
        setTrailerModel(trailerCreationInformation.model);
        setTrailerYear(moment(trailerCreationInformation.year).clone().hour(7).minute(0).second(0).toDate());
        setTrailerId(trailerCreationInformation.value);
        setValue("vin", trailerCreationInformation.label)
        setTrailerCreationInformation(null);
      }
    } else {
      setTrailerVIN("");
      setTrailerMake("");
      setTrailerModel("");
      setTrailerYear("");
      setTrailerId("");
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  const onSubmit = async (payload) => {
    let response = null;

    if (trailerVIN !== payload.vin) {
      setError("vin", { message: "Invalid Vehicle Insurance Number" });
      return;
    }

    _.set(payload, "date", dayjs(payload.date).format("YYYY-MM-DD"));
    _.set(payload, "lastMaintenanceDate", dayjs(payload.lastMaintenanceDate).format("YYYY-MM-DD"));
    _.set(payload, "nextMaintenanceDate", dayjs(payload.nextMaintenanceDate).format("YYYY-MM-DD"));
    _.set(payload, "lastInspectionDate", dayjs(payload.lastInspectionDate).format("YYYY-MM-DD"));
    _.set(payload, "nextInspectionDate", dayjs(payload.nextInspectionDate).format("YYYY-MM-DD"));

    if (isFormEdit) {
      _.set(payload, "trailerMaintenanceId", selectedTrailerMaintenance.trailerMaintenanceId);
      _.set(payload, "accountId", selectedTrailerMaintenance.accountId);
      _.set(payload, "trailerId", selectedTrailerMaintenance.trailer.trailerId);

      response = await updateTrailerMaintenance(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully updated" : "Failed to update"
            } Trailer Maintenance.`,
        })
      );
    } else {
      _.set(payload, "trailerMaintenanceId", 0);

      if (isNTMSUSer(userType)) {
        _.set(
          payload,
          "accountId",
          accountState.accountId != null ? accountState.accountId : accountId
        );
      } else {
        _.set(payload, "accountId", accountId);
      }
      _.set(payload, 'trailerId', trailerId);
      response = await addTrailerMaintenance(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } Trailer Maintenance.`,
        })
      );
    }

    if (response.ok) {
      const trailerMaintenanceData = await response.json();
      dispatch(
        isFormEdit ? setTrailerMaintenanceData(trailerMaintenanceData) : addTrailerMaintenanceData(trailerMaintenanceData)
      );

      toggleFormDisplay();

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);
      reset();
      focusOnOpenOrCloseButton();
    }
  };

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-7-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedTrailerMaintenance={selectedTrailerMaintenance}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              setTrailerVIN={setTrailerVIN}
              setTrailerMake={setTrailerMake}
              setTrailerModel={setTrailerModel}
              setTrailerYear={setTrailerYear}
              setTrailerId={setTrailerId}
              setTrailerMaintenanceType={setTrailerMaintenanceType}
              setCompliances={setCompliances}
              trailerVIN={trailerVIN}
              trailerMake={trailerMake}
              trailerModel={trailerModel}
              trailerYear={trailerYear}
              compliances={compliances}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <>
        <form id="trailer-maintenance-form" onSubmit={handleSubmit(onSubmit)}>
          <FormTabs
            items={tabItems}
            tabClassName="color-7"
            isFormHidden={isFormHidden}
          />

          <div className="row mt-5">
            <div className="col-12">
              <div className="d-flex flex-row align-items-center">
                <button className={`ms-auto btn btn-primary`} type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    </>
  );
}

export default TrailerMaintenanceForm;
