import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useDebouncedCallback } from "use-debounce";
import { COUNTRY, PROVINCE_STATE } from "../../../constants";
import {
  setCoordinatesData,
  setNotificationData,
} from "../../../redux/global/globalReducer";
import { fetchSuggestions } from "../../../services/mapServices";
import { selectStyle } from "../../../utils/styleUtils";

function AutocompleteAddressForm({
  setAddressValues,
  setAddressNotes,
  selectedOrderAddressValues,
  coordinatesType = "origin",
}) {
  const { control, formState } = useForm();
  const { errors } = formState;

  const dispatch = useDispatch();

  const coordinatesState = useSelector((state) => state.global.coordinates);

  // Address Details
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postal, setPostal] = useState("");
  const [notes, setNotes] = useState("");

  const [userInput, setUserInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [formattedSuggestions, setFormattedSuggestions] = useState([]);

  const handleChange = useDebouncedCallback((value) => {
    setUserInput(value);
  }, 1000);

  const handleSelect = (selected) => {
    if (_.isNull(selected)) {
      return;
    }

    try {
      const selectedSuggestion = suggestions.find(
        (suggestion) => suggestion?.properties?.place_id === selected?.value
      );

      const firstUSState = PROVINCE_STATE.find(
        (provinceState) => provinceState.label === "Alabama"
      ).value;

      const selectedShipperProvinceState = _.find(
        PROVINCE_STATE,
        (provinceState) => {
          return provinceState.label === selectedSuggestion?.properties?.state;
        }
      );

      const selectedCountry = _.find(COUNTRY, (country) => {
        return (
          country.value ===
          (selectedShipperProvinceState?.value >= firstUSState ? 2 : 1)
        );
      });

      setAddress1(selectedSuggestion?.properties?.address_line1);
      setAddress2(selectedSuggestion?.properties?.address_line2);
      setCity(selectedSuggestion?.properties?.city);
      setPostal(selectedSuggestion?.properties?.postcode);
      setState(selectedShipperProvinceState);
      setCountry(selectedCountry);

      setAddressValues(selectedSuggestion);

      dispatch(
        setCoordinatesData({
          property: coordinatesType,
          data: {
            latitude: selectedSuggestion?.properties?.lat,
            longitude: selectedSuggestion?.properties?.lon,
            addressLine1: selectedSuggestion?.properties?.address_line1,
            addressLine2: selectedSuggestion?.properties?.address_line2,
            provinceState: selectedShipperProvinceState.value,
            city: selectedSuggestion?.properties?.city,
            country: selectedCountry.value,
            postalCode: selectedSuggestion?.properties?.postcode,
            isInitialData: false
          },
        })
      );
    } catch (error) {
      dispatch(
        setNotificationData({
          type: "error",
          message: "Error selecting address!",
          description: error,
        })
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(selectedOrderAddressValues)) {
      const firstUSState = PROVINCE_STATE.find(
        (provinceState) => provinceState.label === "Alabama"
      ).value;

      const selectedShipperProvinceState = _.find(
        PROVINCE_STATE,
        (provinceState) => {
          return (
            provinceState.value === selectedOrderAddressValues.provinceState
          );
        }
      );

      const selectedCountry = _.find(COUNTRY, (country) => {
        return (
          country.value ===
          (selectedShipperProvinceState?.value >= firstUSState ? 2 : 1)
        );
      });

      setAddress1(selectedOrderAddressValues.addressLine1);
      setAddress2(selectedOrderAddressValues.addressLine2);
      setCity(selectedOrderAddressValues.city);
      setPostal(selectedOrderAddressValues.postalCode);
      setNotes(selectedOrderAddressValues.addressNotes);
      setState(selectedShipperProvinceState);
      setCountry(selectedCountry);
    }
  }, [selectedOrderAddressValues]);

  useEffect(() => {
    const fetchAsyncSuggestions = async () => {
      if (userInput.length > 2) {
        const results = await fetchSuggestions(userInput);
        setSuggestions(results);

        const formattedSuggestions = results.map((suggestion) => ({
          value: suggestion?.properties?.place_id,
          label: suggestion?.properties?.formatted,
        }));

        setFormattedSuggestions(formattedSuggestions);
      }
    };

    fetchAsyncSuggestions();
  }, [userInput]);

  const stateRef = useRef("null");
  const countryStateRef = useRef("null");

  const handleShipperProvinceChange = (e) => {
    const provinceStateValue = e?.value;
    setState(e);
    if (provinceStateValue != null) {
      const firstUSState = PROVINCE_STATE.find(
        (provinceState) => provinceState.label === "Alabama"
      ).value;

      setCountry({
        value: provinceStateValue >= firstUSState ? 2 : 1,
        label: provinceStateValue >= firstUSState ? "US" : "Canada",
      });
    }
  };

  return (
    <>
      <div className="col-12 mb-3">
        <div className="custom-selector">
          <Controller
            name="searchAddress"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={formattedSuggestions}
                className="country-select"
                classNamePrefix="react-select"
                placeholder={"Search Address"}
                id="searchAddress"
                styles={selectStyle}
                isClearable
                tabIndex={15}
                onInputChange={handleChange}
                onChange={handleSelect}
                filterOption={() => true} // always show options
              />
            )}
          />
        </div>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="address1" className="form-label">
          Address Line 1 <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="address1"
          tabIndex={1}
          value={address1}
          disabled={true}
          onChange={(e) => setAddress1(e.target.value)}
        />
        <small className="form-error-message">
          {errors?.address1 && errors.address1.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="address2" className="form-label">
          Address Line 2
        </label>
        <input
          type="text"
          className="form-control"
          id="address2"
          tabIndex={1}
          defaultValue={address2}
          disabled={true}
          onChange={(e) => setAddress2(e.target.value)}
        />
        <small className="form-error-message">
          {errors?.address2 && errors.address2.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="city" className="form-label">
          City
        </label>
        <input
          type="text"
          className="form-control"
          id="city"
          tabIndex={1}
          defaultValue={city}
          disabled={true}
          onChange={(e) => setCity(e.target.value)}
        />
        <small className="form-error-message">
          {errors?.city && errors.city.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="state" className="form-label">
          Province/State
        </label>
        <div className="custom-selector">
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleShipperProvinceChange(e);
                }}
                ref={stateRef}
                value={state}
                options={PROVINCE_STATE}
                className="province-state-select"
                classNamePrefix="react-select"
                placeholder={"Province/State"}
                id="state"
                styles={selectStyle}
                isClearable
                tabIndex={15}
                isDisabled={true}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.state && errors.state.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="postal" className="form-label">
          Postal Code <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="postal"
          tabIndex={1}
          defaultValue={postal}
          disabled={true}
          onChange={(e) => setPostal(e.target.value)}
        />
        <small className="form-error-message">
          {errors?.postal && errors.postal.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="country" className="form-label">
          Country <span className="required-asterisk">*</span>
        </label>
        <div className="custom-selector">
          <Controller
            name="country"
            control={control}
            rules={{ required: "Country is required" }}
            render={({ field }) => (
              <Select
                {...field}
                ref={countryStateRef}
                value={country}
                options={COUNTRY}
                className="country-select"
                classNamePrefix="react-select"
                placeholder={"Country"}
                id="country"
                styles={selectStyle}
                isClearable
                isDisabled={true}
                tabIndex={15}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.country && errors.country.message}
        </small>
      </div>

      <div className="col-12 mb-2">
        <label htmlFor="shipperAddressNotes" className="form-label">
          Address Notes
        </label>
        <textarea
          className="form-control"
          id="engineHealth"
          rows="5"
          maxLength={1000}
          tabIndex={15}
          defaultValue={notes}
          onChange={(e) => setAddressNotes(e?.target?.value)}
        />
        <small className="form-error-message">
          {errors?.shipperAddressNotes && errors.shipperAddressNotes.message}
        </small>
      </div>
    </>
  );
}

export default AutocompleteAddressForm;
