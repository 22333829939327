import React from 'react';

function OrderStatusRenderer(props) {
  const status = props.data["Status"];

  let label = ""
  let statusClass = "";

  switch (status) {
    case 0:
      statusClass = "";
      label = '-';
      break;
    case 1:
      statusClass = "status-draft";
      label = "Draft";
      break;
    case 2:
      statusClass = "status-quoted";
      label = "Quoted";
      break;
    case 3:
      statusClass = "status-booked";
      label = "Booked";
      break;
    case 4:
      statusClass = "status-picked-up";
      label = "Picked Up";
      break;
    case 5:
      statusClass = "status-in-transit";
      label = "In Transit";
      break;
    case 6:
      statusClass = "status-delivered";
      label = "Delivered";
      break;
    case 7:
      statusClass = "status-invoiced";
      label = "Invoiced";
      break;
    case 8:
      statusClass = "status-paid";
      label = "Paid";
      break;
    default:
      break;
  }



  return (
    <>
      <div className={`d-flex justify-content-center align-items-center status-container ${statusClass}`}>
        <p className='status-text mb-0 fw-bold'>{label.toUpperCase()}</p>
      </div>
      
    </>

  )
}

export default OrderStatusRenderer