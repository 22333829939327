import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES, CURRENCY_TYPE } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedAccessorial = {},
  control,
  register,
  errors,
  setValue,
  reset,
  accountTypeList
}) {

  useEffect(() => {
    if (!isFormEdit) {
      reset()
    }
  }, [selectedAccessorial["accessorialId"]])

  // form values
  setValue('accessorialId', 0);
  // setValue('accountId', accountId);

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="accessorialName" className="form-label">Name</label>
        <input type="text" className="form-control" id="accessorialName" tabIndex={2}
          defaultValue={isFormEdit ? selectedAccessorial?.accessorialName || '' : ''}
          disabled={selectedAccessorial?.master == ! false}
          {...register("accessorialName", { required: "Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.accessorialName && errors.accessorialName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="description" className="form-label">Description</label>
        <input type="text" className="form-control" id="description" tabIndex={2}
          defaultValue={isFormEdit ? selectedAccessorial?.description || '' : ''}
          disabled={selectedAccessorial?.master == ! false}
          {...register("description", { required: "Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.description && errors.description.message}
        </small>
      </div>
    </>
  );
}

export default PrimaryDetails;
