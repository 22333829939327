import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { isAccountUser } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import { SETTINGS_ITEMS } from '../../constants';
import ContentHeader from '../Commons/Layouts/ContentHeader';

function Settings() {
  const userType = useSelector((state) => state.auth.user.userType);

  return (
    <div>
      <div className="row mb-0">
            <div className="col-12">
              <ContentHeader
                title="Settings"
                hideSearch="true"
                hideTotal="true"
                hideButton={true}
                hideSubtitle={true}
              />
            </div>
          </div>
      <div className="content-body-container">
        <div className="content-section-container no-bg min-vh-65">
          <div className='settings-container'>
            <div className='settings-stats-container mb-4'>
              <div className='row'>
                {SETTINGS_ITEMS.map((item, index) =>
                (((!item.authorizationCheck || (item.authorizationCheck && !isAccountUser(userType))) &&
                  (<Fragment key={index}>
                    <div className='col-12 col-md-6 col-xl-3 mb-4' >
                      <Link to={item.link} style={{ textDecoration: 'none' }}>
                        <div className={`card ${item.color} shadow-sm`}>
                          <div className="card-body py-3">
                            <div className='d-flex align-items-center'>
                              <div className='text-center'>
                                <div className={`stats-icon-container ${item.color} d-flex align-items-center justify-content-center`}>
                                  {item.icon}
                                </div>
                              </div>

                            </div>
                            <div className='ms-0 mt-2'>
                              <span className='setting-label mb-0'>{item.label}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Fragment>)
                )))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Settings;
