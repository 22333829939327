import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrderData,
  setOrdersListData,
  deleteOrderData
} from "../../redux/order/orderReducer";
import { fetchAccessorialsByAccount } from "../../services/accessorialServices";
import { fetchCustomersByAccount } from "../../services/customerServices";
import { addOrder, fetchOrdersFiltered, deleteOrder } from "../../services/orderServices";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import CreateOrderModal from "../Commons/Modals/CreateOrderModal";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import OrderForm from "./OrderForm";
import { formatDateOnly, enumValueFormatter } from "../../utils/formatUtils";
import { ORDER_STATUS, GRID_CONSTANTS } from "../../constants";
import OrderStatusRenderer from "../Templates/CellRenderers/Orders/OrderStatusRenderer";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Orders() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const orderState = useSelector((state) => state.order);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );

  // Modal Config
  const [createOrderModal, setCreateOrderModal] = useState(false);
  const createOrderToggle = () => setCreateOrderModal(!createOrderModal);

  const [userOptions, setUserOptions] = useState([]);
  const [customerCreationInformation, setCustomerCreationInformation] =
    useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [accessorialOptions, setAccessorialOptions] = useState([]);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(0);

  const toggleFormDisplay = (isEdit = false, orderId = null) => {
    if (!isEdit && isFormHidden) {
      addInitialOrder();
    }

    if (isFormHidden) {
      setSelectedOrderId(orderId);
    } else {
      setSelectedOrderId(null);
    }

    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = () => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "Load ID",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Customer",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Shipper",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Receiver",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Status",
      minWidth: 150,
      cellClass: "grid-column gray",
      cellRenderer: OrderStatusRenderer,
      valueFormatter: (params) => {
        return enumValueFormatter(params, ORDER_STATUS);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Load",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: {
        toggleFormDisplay,
        deleteFunction: deleteOrder,
        deleteReduxFunction: deleteOrderData,
        header: "Order"
      },
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [orderList, setOrderList] = useState([]);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedAccessorialList, setSelectedAccessorialList] = useState([]);
  const [selectedQuoteList, setSelectedQuoteList] = useState([]);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setOrderList(orderState.data);
  }, [orderState.data]);

  useEffect(() => {
    let data = [];
    _.each(orderList, (order, index) => {
      data.push({
        "Load ID": order.load.loadId,
        Customer: order.customerName,
        Shipper: order.shipperName,
        Receiver: order.receiverName,
        Status: order.status,
        Load: "-",
        Id: order.orderId,
        Edit: { id: order.orderId, btnClass: "btn-color-3", formType: "Order", status: order.status },
      });
    });
    setRowData(data);
  }, [orderList]);

  useEffect(() => {
    fillSelectedOrder(selectedOrderId);
  }, [selectedOrderId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  const init = () => {
    getOrdersData();
    getCustomersData();
    getAccessorialData();
    focusOnOpenOrCloseButton();
    setIsLoading(false);
  };

  const getOrdersData = async () => {
    const response =
      selectedAccountState.accountId === null
        ? await fetchOrdersFiltered(accountId)
        : await fetchOrdersFiltered(selectedAccountState.accountId);

    dispatch(setOrdersListData(response));
  };

  const getCustomersData = async () => {
    const response = await fetchCustomersByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
    setCustomerOptions(
      response.map((customer) => ({
        id: customer.customerId,
        name: customer.customerName,
        contact: customer.primaryContact,
        email: customer.defaultEmail,
        addressLine1: customer.addressLine1,
        addressLine2: customer.addressLine2,
        phone: customer.phone,
        city: customer.city,
        provinceState: customer.provinceState,
        country: customer.country,
        postalCode: customer.postalCode,
      }))
    );
  };

  const getAccessorialData = async () => {
    const response = await fetchAccessorialsByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
    setAccessorialOptions(
      response.map((accessorial) => ({
        value: accessorial.accessorialId,
        label: accessorial.accessorialName,
        description: accessorial.description,
        accountId: accessorial.accountId,
      }))
    );
  };

  const fillSelectedOrder = (id) => {
    const order = _.find(orderList, { orderId: id });
    setSelectedOrder(order);
    fillAccessorialTable(order?.orderAccessorial);
    fillQuoteTable(order?.tmsQuote);
    setSelectedOrderStatus(id !== null ? order?.status : 0)
  };

  const fillAccessorialTable = (accessorialList) => {
    let data = [];
    _.each(accessorialList, (accessorial, index) => {
      data.push({
        "#": index + 1,
        Name: accessorial.accessorialName,
        Description: accessorial.description,
        AccessorialId: accessorial.accessorialId,
        OrderAccessorialId: accessorial.orderAccessorialId,
        Id: accessorial.orderAccessorialId,
        AccountId: accessorial.accountId,
        OrderId: accessorial.orderId,
      });
    });
    setSelectedAccessorialList(data);
  };

  const fillQuoteTable = (quoteList) => {
    let data = [];
    _.each(quoteList, (quote, index) => {
      data.push({
        "#": index + 1,
        QID: quote.quoteSequenceId,
        Name: quote.quoteName,
        Date: formatDateOnly(quote.quoteDate),
        Quote: quote.total,
        Status: quote.status,
        Notes: quote.notes,
        QuoteId: quote.tmsQuoteId,
        Id: quote.tmsQuoteId,
        OrderId: quote.orderId,
        AccountId: quote.accountId,
        QuoteItems: quote.tmsQuoteItem,
      });
    });
    setSelectedQuoteList(data);
  };

  const removeUserFromOptions = (userId) => {
    const updatedUserOptions = userOptions.filter(
      (item) => item.value !== userId
    );
    setUserOptions(updatedUserOptions);
  };

  const addInitialOrder = async () => {
    // Initialize empty order payload
    const payload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      orderId: 0,
      status: 1,
      customerId: -1,
      shipperId: -1,
      receiverId: -1,
      orderNickName: "",
      orderStatus: 1,
      taxType: 1,
      discount: 0,
      deposit: 0,
      totalTax: 0,
      subTotal: 0,
      totalQuote: 0,
      paymentDueDate: moment().add(14, "days").clone().hour(7).minute(0).second(0).format("YYYY-MM-DD"), // @TODO: Current date + New setting Payment Due Offset
      terms: "",
      active: true,
      orderDate: moment().clone().hour(7).minute(0).second(0).format("YYYY-MM-DD"),
      pickupStartDate: moment().clone().hour(7).minute(0).second(0).format("YYYY-MM-DD"),
      pickupStartTime: moment("09:00:00", "HH:mm:ss")
        .set({ seconds: 0 })
        .format("HH:mm:ss"),
      pickupEndDate: moment().clone().hour(7).minute(0).second(0).format("YYYY-MM-DD"),
      pickupEndTime: moment("17:00:00", "HH:mm:ss")
        .set({ seconds: 0 })
        .format("HH:mm:ss"),
      dropoffStartDate: moment().clone().hour(7).minute(0).second(0).add(1, "days").format("YYYY-MM-DD"),
      dropoffStartTime: moment("9:00:00", "HH:mm:ss")
        .set({ seconds: 0 })
        .format("HH:mm:ss"),
      dropoffEndDate: moment().clone().hour(7).minute(0).second(0).add(1, "days").format("YYYY-MM-DD"),
      dropoffEndTime: moment("17:00:00", "HH:mm:ss").set({ seconds: 0 }).format("HH:mm:ss"),
    };

    // Add in Customer details if available
    if (!_.isNull(customerCreationInformation)) {
      payload.customerId = customerCreationInformation.id;
      payload.customerName = customerCreationInformation.name;
      payload.customerContactName = customerCreationInformation.contact;
      payload.customerDefaultEmail = customerCreationInformation.email;
      payload.customerPhoneNumber = customerCreationInformation.phone;
      payload.customerAddress1 = customerCreationInformation.addressLine1;
      payload.customerAddress2 = customerCreationInformation.addressLine2;
      payload.customerCity = customerCreationInformation.city;
      payload.customerPostal = customerCreationInformation.postalCode;
      payload.customerState = customerCreationInformation.provinceState;
      payload.customerCountry = customerCreationInformation.country;
    }

    const orderResponse = await addOrder(payload);
    const data = await orderResponse.json();
    dispatch(addOrderData(data));
    setSelectedOrderId(data.orderId);
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden ? "Orders" : isFormEdit ? "Edit Order" : "New Order"
            }
            subtitle={"orders"}
            dataCount={orderList.length}
            filterData={filterData}
            onClickAdd={createOrderToggle}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">

        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {orderList?.length > 0 ?
            <div
              className={`ag-theme-alpine content-section-container no-header-cell-resize order-grid order-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-3' content='order' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <OrderForm
            isFormEdit={isFormEdit}
            selectedOrder={selectedOrder}
            selectedAccessorialList={selectedAccessorialList}
            selectedQuoteList={selectedQuoteList}
            setSelectedQuoteList={setSelectedQuoteList}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
            customerCreationInformation={customerCreationInformation}
            setCustomerCreationInformation={setCustomerCreationInformation}
            removeUserFromOptions={removeUserFromOptions}
            accessorialOptions={accessorialOptions}
            selectedOrderStatus={selectedOrderStatus}
            setSelectedOrderStatus={setSelectedOrderStatus}
          />
        </div>
      </div>
      <CreateOrderModal
        modal={createOrderModal}
        setModal={setCreateOrderModal}
        toggle={createOrderToggle}
        customerOptions={customerOptions}
        customerCreationInformation={customerCreationInformation}
        setCustomerCreationInformation={setCustomerCreationInformation}
        toggleFormCancelDisplay={toggleFormCancelDisplay}
      />
    </div>
  );
}

export default Orders;
