import React, { useState } from "react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import { isAccountUser } from "../../utils/roleUtils";
import { useSelector } from "react-redux";
import OrdersSettingsForm from "./OrdersSettingsForm";
import AuthorizationError from "../Commons/Authorization/AuthorizationError";
function OrdersSettings() {
  const userType = useSelector((state) => state.auth.user.userType);
  const parentNavItem = {
    title: "Settings",
    link: "/settings",
  };

  // useStates
  const [accountList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(true);

  return (
    <div>
      {isAccountUser(userType) && <AuthorizationError />}
      {!isAccountUser(userType) && (
        <>
          <div className="row mb-0">
            <div className="col-12">
              <ContentHeader
                title="Orders Settings"
                subtitle="orders settings"
                hideSearch="true"
                hideTotal="true"
                hideButton={true}
                hideSubtitle={true}
                parentNavItem={parentNavItem}
              />
            </div>
          </div>
          <div className="content-body-container row">
            <div className={`col-12 mb-2`}>
              <OrdersSettingsForm
                isFormEdit={isFormEdit}
                setIsFormEdit={setIsFormEdit}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OrdersSettings;
