import { get, post, patch, remove } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

// Load
export const fetchLoads = async () => {
  return get({ path: 'Load' });
}

export const fetchLoadsFiltered = async () => {
  return get({ path: `Load/filtered` });
}

export const addLoad = async (payload) => {
  return post({ path: 'Load', body: payload });
}

export const updateLoad = async (payload) => {
  return patch({ path: `Load/${payload.loadId}`, body: payload });
}

export const fetchLoadsByAccount = async (userType, selectedAccountId, accountId) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchLoadsFiltered(accountId) : await fetchLoadsFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchLoadsFiltered(accountId);
  }
  
  return await fetchLoads();
}

// Load Items
export const fetchLoadItemsByLoadId = async (loadId) => {
  return get({ path: `LoadItem/Load/${loadId}` });
}

export const addLoadItem = async (payload) => {
  return post({ path: 'LoadItem', body: payload });
}

export const updateLoadItem = async (payload) => {
  return patch({ path: `LoadItem/update/${payload.loadItemId}`, body: payload });
}

export const updateLoadItems = async (payload) => {
  return patch({ path: `LoadItem/updateLoadItems`, body: payload });
}

export const deleteLoadItem = async (loadItemId) => {
  return remove({ path: `LoadItem/${loadItemId}` });
}