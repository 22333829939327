import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const truckMaintenanceReducer = createSlice({
  name: "truck",
  initialState,
  reducers: {
    setTruckMaintenanceListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setTruckMaintenanceData: (state, action) => {
      const index = state.data.findIndex(truck => truck.truckMaintenanceId === action.payload.truckMaintenanceId);
      const trucksMaintenanceListData = [...state.data];
      trucksMaintenanceListData[index] = action.payload;
      return {
        ...state,
        data: trucksMaintenanceListData
      }
    },
    addTruckMaintenanceData: (state, action) => {
      const trucksMaintenanceListData = [...state.data];
      trucksMaintenanceListData.push(action.payload);
      return {
        ...state,
        data: trucksMaintenanceListData
      }
    },
  },
});

export const {
  setTruckMaintenanceListData,
  setTruckMaintenanceData,
  addTruckMaintenanceData,
} = truckMaintenanceReducer.actions;

export default truckMaintenanceReducer.reducer;