import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { printPreviewReportWithBase64 } from '../../utils/payrollRunUtils'
import ReportsModal from '../Commons/Modals/ReportsModal'
import { useForm, Controller } from 'react-hook-form'
import { QUOTE_REPORTS, INVOICE_REPORTS, INCOME_REPORTS, ACCOUNTS_RECEIVABLE_REPORTS, ACCOUNTS_PAYABLE_REPORTS, PAYROLL_REPORTS } from '../../constants'
import { getReportStatus, createReport, singleDateCheck } from '../../utils/reportUtils'
import moment from 'moment';
import { setNotificationData } from '../../redux/global/globalReducer'
import EmployeePaystubReport from '../Commons/Modals/EmployeePaystubReport'

function ReportsMenu() {
  const dispatch = useDispatch();
  const accountId = useSelector(state => state.auth.user.accountId)
  const selectedAccountState = useSelector(
    state => state.account.selectedAccount
  )
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    control,
    formState: { errors }
  } = useForm()
  const [mainModal, setMainModal] = useState(false);
  const [employeePaystubModal, setEmployeePaystubModal] = useState(false)
  const [reportLabel, setReportLabel] = useState(null);
  var [showUserField, setShowUserField] = useState(false);

  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), 0, 1),
    new Date()
  ])

  const printReportList = async payload => {
    var reportStartDate = payload.reportDateRange != null ? moment(payload.reportDateRange[0]).format('YYYY-MM-DD') : null;
    var reportEndDate = payload.reportDateRange != null ? moment(payload.reportDateRange[1]).format('YYYY-MM-DD') : null;
    var currentDate = moment().format('YYYY-MM-DD');

    if (singleDateCheck(reportLabel)) {
      reportStartDate = payload.reportStartDate != null ? moment(payload.reportStartDate).format('YYYY-MM-DD') : null;
      reportEndDate = null;
    }

    const reportPayload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      reportLabel: reportLabel,
      reportStatus: getReportStatus(reportLabel),
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      currentDate: currentDate
    }

    const response = await createReport(reportLabel, reportPayload)

    try {
      const data = await response.json()

      if (!_.isNil(data.errorMessage)) {
        dispatch(
          setNotificationData({
            type: `error`,
            message: `Error!`,
            description: data.errorMessage,
          })
        );
        return
      }

      printPreviewReportWithBase64(data.base64)
      setMainModal(false);
      setValue("reportDateRange", dateRange)
    } catch (error) {

      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: `Failed to create report.`,
        })
      );
    }
  }

  const toggleMainModal = (label, userFieldVisible = false) => {
    setReportLabel(label)
    setShowUserField(userFieldVisible)
    setMainModal(!mainModal)
    if (mainModal === false) {
      reset();
    }
  }

  const toggleEmployeePaystubModal = (label) => {
    setReportLabel(label)
    setEmployeePaystubModal(!employeePaystubModal);
  }

  const disableOnClickAction = event => {
    event.stopPropagation()
  }

  return (
    <>
      <li>
        <button className='dropdown-item disabled-action' onClick={disableOnClickAction}>
          Quote Reports
        </button>
        <ul className='dropdown-menu dropdown-submenu'>
          {QUOTE_REPORTS.map((quoteReport, index) => (
            <li key={index}>
              <button
                className='dropdown-item'
                onClick={() => toggleMainModal(quoteReport.label)}
              >
                {quoteReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button className='dropdown-item disabled-action' onClick={disableOnClickAction}>
          Invoice Reports
        </button>
        <ul className='dropdown-menu dropdown-submenu'>
          {INVOICE_REPORTS.map((invoiceReport, index) => (
            <li key={index}>
              <button
                className='dropdown-item'
                onClick={() => toggleMainModal(invoiceReport.label)}
              >
                {invoiceReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button className='dropdown-item disabled-action' onClick={disableOnClickAction}>
          Income Reports
        </button>
        <ul className='dropdown-menu dropdown-submenu'>
          {INCOME_REPORTS.map((incomeReport, index) => (
            <li key={index}>
              <button
                className='dropdown-item'
                onClick={() => toggleMainModal(incomeReport.label)}
              >
                {incomeReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button className='dropdown-item disabled-action' onClick={disableOnClickAction}>
          A/R Reports
        </button>
        <ul className='dropdown-menu dropdown-submenu'>
          {ACCOUNTS_RECEIVABLE_REPORTS.map((accountsReceivableReport, index) => (
            <li key={index}>
              <button
                className='dropdown-item'
                onClick={() => toggleMainModal(accountsReceivableReport.label)}
              >
                {accountsReceivableReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button className='dropdown-item disabled-action' onClick={disableOnClickAction}>
          A/P Reports
        </button>
        <ul className='dropdown-menu dropdown-submenu'>
          {ACCOUNTS_PAYABLE_REPORTS.map((accountsPayableReport, index) => (
            <li key={index}>
              <button
                className='dropdown-item'
                onClick={() => toggleMainModal(accountsPayableReport.label)}
              >
                {accountsPayableReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button className='dropdown-item disabled-action' onClick={disableOnClickAction}>
          Payroll Reports
        </button>
        <ul className='dropdown-menu dropdown-submenu'>
          {PAYROLL_REPORTS.map((payrollReport, index) => (
            <li key={index}>
              <button
                className='dropdown-item'
                onClick={() => { payrollReport.label === "Employee Paystubs" ? toggleEmployeePaystubModal(payrollReport.label, true) : toggleMainModal(payrollReport.label) }}
              >
                {payrollReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <ReportsModal
        modal={mainModal}
        toggle={toggleMainModal}
        printReportList={printReportList}
        dateRange={dateRange}
        register={register}
        handleSubmit={handleSubmit}
        Controller={Controller}
        control={control}
        setValue={setValue}
        reportLabel={reportLabel}
      ></ReportsModal>
      <EmployeePaystubReport
        modal={employeePaystubModal}
        toggle={toggleEmployeePaystubModal}
        printReportList={printReportList}
        dateRange={dateRange}
        register={register}
        handleSubmit={handleSubmit}
        Controller={Controller}
        control={control}
        setValue={setValue}
        reportLabel={reportLabel}
        showUserField={showUserField}
        setShowUserField={setShowUserField}
      ></EmployeePaystubReport>
    </>
  )
}

export default ReportsMenu
