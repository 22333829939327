import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const legReducer = createSlice({
  name: "leg",
  initialState,
  reducers: {
    setLegsListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setLegData: (state, action) => {
      const index = state.data.findIndex(leg => leg.legId === action.payload.legId);
      const legsListData = [...state.data];
      legsListData[index] = action.payload;
      return {
        ...state,
        data: legsListData
      }
    },
    addLegData: (state, action) => {
      const legsListData = [...state.data];
      legsListData.push(action.payload);
      return {
        ...state,
        data: legsListData
      }
    },
  },
});

export const {
  setLegsListData,
  setLegData,
  addLegData,
} = legReducer.actions;

export default legReducer.reducer;