import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import AccountForm from "./AccountForm";
import "./css/accounts.css";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import {
  fetchAccountById,
  fetchAccounts,
} from "../../services/accountServices";
import PhoneAndMobileRenderer from "../Templates/CellRenderers/Commons/PhoneAndMobileRenderer";
import _ from "lodash";
import {
  isNTMSUSer,
  isAccountAdmin,
  isAccountUser,
  isSuperAdmin,
} from "../../utils/roleUtils";
import { formatPhoneNumber } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedAccountData,
  deleteSelectedAccountData,
  setAccountsListData,
} from "../../redux/account/accountReducer";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Accounts() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const accountState = useSelector((state) => state.account);

  const toggleFormDisplay = (isEdit = false, accountId = null) => {
    if (isFormHidden) {
      setSelectedAccountId(accountId);
    } else {
      setSelectedAccountId(null);
    }

    if (modal) {
      setModal(!modal);
    }
    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    // setTimeout(() => {
    //   gridRef.current.api.sizeColumnsToFit();
    // }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "Account Name",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Phone",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column gray",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return formatPhoneNumber(params.value);
      },
    },
    {
      field: "Mobile",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column gray",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return formatPhoneNumber(params.value);
      },
    },
    {
      field: "TaxId",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      headerName: "",
      minWidth: 150,
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      resizable: false,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setAccountList(accountState.data);
  }, [accountState.data]);

  useEffect(() => {
    let data = [];
    _.each(accountList, (account, index) => {
      data.push({
        "#": index + 1,
        "Account Id": account.accountId,
        "Account Name": account.accountName,
        Phone: account.phone,
        Mobile: account.cell,
        TaxId: account.taxId,
        PhoneAndMobile: { phone: account.phone, mobile: account.cell },
        Edit: { id: account.accountId, btnClass: "btn-color-1" },
      });
    });
    setRowData(data);
  }, [accountList]);

  useEffect(() => {
    fillSelectedAccount(selectedAccountId);
  }, [selectedAccountId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef();

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    sizeColumnsToFit(gridRef.current);
  }, []);

  const init = async () => {
    const response = await getAccountsData();
    dispatch(setAccountsListData(_.isArray(response) ? response : [response]));

    if (isAccountAdmin(userType) || isAccountUser(userType)) {
      setSelectedAccountId(accountId);
      setIsFormEdit(true);
      setIsFormHidden(false);
    }

    setIsLoading(false);
  };

  const getAccountsData = async () => {
    if (isAccountAdmin(userType) || isAccountUser(userType)) {
      return await fetchAccountById(accountId);
    }

    return await fetchAccounts();
  };

  const fillSelectedAccount = (id) => {
    const account = _.find(accountList, { accountId: id });
    setSelectedAccount(account);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      sizeColumnsToFit(params);
    }
  };

  const onCellClicked = (event) => {
    if (event.colDef.field === "Edit") {
      return;
    }

    if (isNTMSUSer(userType)) {
      event.node.setSelected(true);
      dispatch(
        setSelectedAccountData({
          accountId: event.data["Account Id"],
          accountName: event.data["Account Name"],
        })
      );
    }
  };

  const onRowDataUpdated = (event) => {
    gridRef.current.api.forEachNode((rowNode, index) => {
      if (isNTMSUSer(userType))
        if (rowNode.data["Account Id"] === selectedAccountState.accountId) {
          rowNode.setSelected(true);
        }
    });
  };

  const clearAccountFilter = (event) => {
    dispatch(deleteSelectedAccountData());
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.deselectAll();
    }
  };

  const sizeColumnsToFit = (params) => {
    if (isSuperAdmin(userType) || isNTMSUSer(userType)) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isLoading) {
      openOrCloseButtonRef?.current?.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Accounts"
                : isFormEdit
                  ? "Edit Account"
                  : "New Account"
            }
            subtitle={"accounts"}
            dataCount={accountList?.length}
            filterData={filterData}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            onClickFilter={clearAccountFilter}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
            hideAccountFilter={
              selectedAccountState?.accountName == "" && isSuperAdmin(userType)
            }
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div
          className={`${isFormHidden ? "col-12" : "d-none"} ${isSuperAdmin(userType) || isNTMSUSer(userType) ? "" : "d-none"
            } mb-2`}
        >
          {(accountList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-1-grid color-1-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
                onCellClicked={onCellClicked}
                onRowDataUpdated={onRowDataUpdated}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-1' content='user' />
          }
        </div>
        <div
          className={`${isSuperAdmin(userType) || isNTMSUSer(userType) ? "col-12" : "col-12"
            } ${isFormHidden ? "d-none" : ""} mb-2`}
        >
          <AccountForm
            isFormEdit={isFormEdit}
            selectedAccount={selectedAccount}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  );
}

export default Accounts;
