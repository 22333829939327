import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const orderReducer = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrdersListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setOrderData: (state, action) => {
      const index = state.data.findIndex(order => order.orderId === action.payload.orderId);
      const ordersListData = [...state.data];
      ordersListData[index] = action.payload;
      return {
        ...state,
        data: ordersListData
      }
    },
    addOrderData: (state, action) => {
      const ordersListData = [...state.data];
      ordersListData.push(action.payload);
      return {
        ...state,
        data: ordersListData
      }
    },
    deleteOrderData: (state, action) => {
      const updatedData = state.data.filter(order => order.orderId !== action.payload.itemId);
      return {
        ...state,
        data: updatedData
      };
    },
    updateOrderDataStatus: (state, action) => {
      const { orderId, newStatus } = action.payload;

      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            status: newStatus,
          }
          : order
      );
      return {
        ...state,
        data: updatedData,
      };
    },
    addOrderAccessorialData: (state, action) => {
      const { orderId, orderAccessorial } = action.payload;
      const updatedData = state.data.map(order =>
        order.orderId === orderId
          ? { ...order, orderAccessorial: [...order.orderAccessorial, orderAccessorial] }
          : order
      );
      return {
        ...state,
        data: updatedData,
      };
    },
    deleteOrderAccessorialData: (state, action) => {
      const { orderId, itemId } = action.payload;

      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            orderAccessorial: order.orderAccessorial.filter(
              (accessorial) => accessorial.orderAccessorialId !== itemId
            ),
          }
          : order
      );

      return {
        ...state,
        data: updatedData,
      };
    },
    addOrderTMSQuoteData: (state, action) => {
      const { orderId, tmsQuote } = action.payload;
      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? { ...order, tmsQuote: [...order.tmsQuote, tmsQuote] }
          : order
      );
      return {
        ...state,
        data: updatedData,
      };
    },
    updateOrderTMSQuoteData: (state, action) => {
      const { orderId, updatedTMSQuote } = action.payload;

      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            tmsQuote: order.tmsQuote.map((quote) =>
              quote.tmsQuoteId === updatedTMSQuote.tmsQuoteId ? updatedTMSQuote : quote
            ),
          }
          : order
      );
      return {
        ...state,
        data: updatedData,
      };
    },
    updateOrderTMSQuoteDataStatus: (state, action) => {
      const { orderId, tmsQuoteId, newStatus } = action.payload;

      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            tmsQuote: order.tmsQuote.map((quote) => ({
              ...quote,
              status: quote.tmsQuoteId === tmsQuoteId ? newStatus : 0,
            })),
          }
          : order
      );

      return {
        ...state,
        data: updatedData,
      };
    },
    deleteOrderTMSQuoteData: (state, action) => {
      const { orderId, itemId } = action.payload;

      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            tmsQuote: order.tmsQuote.filter((quote) => quote.tmsQuoteId !== itemId),
          }
          : order
      );

      return {
        ...state,
        data: updatedData,
      };
    },
    addOrderTMSQuoteItemData: (state, action) => {
      const { orderId, quoteId, tmsQuoteItem } = action.payload;

      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            tmsQuote: order.tmsQuote.map((quote) =>
              quote.tmsQuoteId === quoteId
                ? {
                  ...quote,
                  tmsQuoteItem: [...quote.tmsQuoteItem, tmsQuoteItem],
                }
                : quote
            ),
          }
          : order
      );
      return {
        ...state,
        data: updatedData,
      };
    },
    updateOrderTMSQuoteItemData: (state, action) => {
      const { orderId, quoteId, updatedTMSQuoteItem } = action.payload;

      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            tmsQuote: order.tmsQuote.map((quote) =>
              quote.tmsQuoteId === quoteId
                ? {
                  ...quote,
                  tmsQuoteItem: quote.tmsQuoteItem.map((quoteItem) =>
                    quoteItem.tmsQuoteItemId === updatedTMSQuoteItem.tmsQuoteItemId
                      ? updatedTMSQuoteItem
                      : quoteItem
                  ),
                }
                : quote
            ),
          }
          : order
      );
      return {
        ...state,
        data: updatedData,
      };
    },
    updateOrderTMSQuoteItemsData: (state, action) => {
      const { orderId, quoteId, updatedTMSQuoteItems } = action.payload;
      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            tmsQuote: order.tmsQuote.map((quote) =>
              quote.tmsQuoteId === quoteId
                ? {
                  ...quote,
                  tmsQuoteItem: quote.tmsQuoteItem.map((quoteItem) =>
                    updatedTMSQuoteItems.some(
                      (updatedItem) => updatedItem.tmsQuoteItemId === quoteItem.tmsQuoteItemId
                    )
                      ? updatedTMSQuoteItems.find(
                        (updatedItem) => updatedItem.tmsQuoteItemId === quoteItem.tmsQuoteItemId
                      )
                      : quoteItem
                  ),
                }
                : quote
            ),
          }
          : order
      );
      return {
        ...state,
        data: updatedData,
      };
    },
    deleteOrderTMSQuoteItemData: (state, action) => {
      const { orderId, quoteId, itemId } = action.payload;

      const updatedData = state.data.map((order) =>
        order.orderId === orderId
          ? {
            ...order,
            tmsQuote: order.tmsQuote.map((quote) =>
              quote.tmsQuoteId === quoteId
                ? {
                  ...quote,
                  tmsQuoteItem: quote.tmsQuoteItem.filter(
                    (item) => item.tmsQuoteItemId !== itemId
                  ),
                }
                : quote
            ),
          }
          : order
      );

      return {
        ...state,
        data: updatedData,
      };
    },
  },
});

export const {
  setOrdersListData,
  setOrderData,
  addOrderData,
  deleteOrderData,
  updateOrderDataStatus,
  addOrderAccessorialData,
  deleteOrderAccessorialData,
  addOrderTMSQuoteData,
  updateOrderTMSQuoteData,
  updateOrderTMSQuoteDataStatus,
  deleteOrderTMSQuoteData,
  addOrderTMSQuoteItemData,
  updateOrderTMSQuoteItemData,
  updateOrderTMSQuoteItemsData,
  deleteOrderTMSQuoteItemData
} = orderReducer.actions;

export default orderReducer.reducer;