import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { REPORT_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";
import { reportTypeRenderer } from '../../utils/formatUtils';

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedReportTemplate = {},
  control,
  register,
  errors,
  setValue,
  reset,
}) {

  const [reportType, setReportType] = useState("");
  
  useEffect(() => {
    if (isFormEdit) {
      setReportType(parseInt(selectedReportTemplate['reportType']))
      setValue("reportType", parseInt(selectedReportTemplate['reportType']))
      setValue("defaultTemplate", selectedReportTemplate['defaultTemplate']);
      setValue("useHeader", selectedReportTemplate['useHeader']);
      setValue("useFooter", selectedReportTemplate['useFooter']);
      setValue("useDefaultSpacer", selectedReportTemplate['useDefaultSpacer']);
      setValue("header", selectedReportTemplate['header'], { shouldDirty: false });
      setValue("footer", selectedReportTemplate['footer']);
      setValue("useLogo", selectedReportTemplate['useLogo']);
      setValue("terms", selectedReportTemplate['terms'])
    } else {
      setReportType("");

      reset();
    }

  }, [selectedReportTemplate["reportTemplateId"]])

  const getDefaultValue = () => {
    if (selectedReportTemplate.length <= 0) {
      return 'true';
    }

    return selectedReportTemplate.defaultTemplate ? 'true' : '';
  }

  const getUseHeaderValue = () => {
    if (selectedReportTemplate.length <= 0) {
      return 'true';
    }

    return selectedReportTemplate.useHeader ? 'true' : '';
  }

  const getUseLogoValue = () => {
    if (selectedReportTemplate.length <= 0) {
      return 'true';
    }

    return selectedReportTemplate.useLogo ? 'true' : '';
  }

  const getUseFooterValue = () => {
    if (selectedReportTemplate.length <= 0) {
      return 'true';
    }

    return selectedReportTemplate.useFooter ? 'true' : '';
  }

  const getUseDefaultSpacerValue = () => {
    if (selectedReportTemplate.length <= 0) {
      return 'true';
    }

    return selectedReportTemplate.useDefaultSpacer ? 'true' : '';
  }

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="templateName" className="form-label">Template Name</label>
        <input type="text" className="form-control" id="templateName" tabIndex={1}
          defaultValue={isFormEdit ? selectedReportTemplate?.templateName || '' : ''}
          {...register("templateName", { required: "Template Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.templateName && errors.templateName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="reportType" className="form-label">Report Type:</label>
        <select className="form-control" id="reportType" value={reportType} tabIndex={2} {...register("reportType", { valueAsNumber: true, onChange: (e) => setReportType(e.target.value), required: "Report type is required" })}>
          <option key="none" value="" hidden>Report Type</option>
          {REPORT_TYPES.map((report, index) =>
            <option key={index} value={report.value}>{report.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.reportType && errors.reportType.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="notes" className="form-label">Notes:</label>
        <input type="text" className="form-control" id="notes" tabIndex={3}
          defaultValue={isFormEdit ? selectedReportTemplate?.notes || '' : ''}
          {...register("notes")}
        />
        <small className='form-error-message'>
          {errors?.notes && errors.notes.message}
        </small>
      </div>
      <div className="col-12 mb-2 mt-4">
        <div className="row">
          <div className="col-4">
            <label htmlFor="header" className="form-label">Header:</label>
          </div>
          <div className="col-4 form-check">
            <input className="form-check-input" type="checkbox" defaultChecked={getUseHeaderValue} value="" id="headerCheckbox" tabIndex={4} {...register("useHeader")}></input>
            <label className="form-check-label" htmlFor="headerCheckbox">Include</label>
          </div>
          {(reportType !== "" || isFormEdit) &&
            <div className="col-4 form-check">
              <input className="form-check-input" type="checkbox" defaultChecked={getDefaultValue} id="defaultCheckbox" tabIndex={5} {...register("defaultTemplate")}></input>
              <label className="form-check-label" htmlFor="defaultCheckbox">Default for {reportTypeRenderer(isFormEdit ? selectedReportTemplate.reportType : reportType)}</label>

            </div>
          }
        </div>

        <label htmlFor="header" className="form-label">Header Text</label>
        <textarea className="form-control not-resizeable" id="header" rows="6" tabIndex={6} {...register("header")} />
        <small className='form-error-message'>
          {errors?.header && errors.header.message}
        </small>

        <small className='form-error-message'>
          {errors?.header && errors.header.message}
        </small>
      </div>
      <div className="col-12 mb-2 mt-4">
        <div className="col-4 form-check">
          <input className="form-check-input" type="checkbox" defaultChecked={getUseLogoValue} value="" id="logoCheckbox" tabIndex={7}  {...register("useLogo")}></input>
          <label className="form-check-label" htmlFor="logoCheckbox">Include Logo</label>
        </div>
      </div>

      <div className="col-12 my-2">
        <hr></hr>
      </div>

      <div className="col-12 mb-2 mt-4">
        <div className="row">
          <div className="col-4">
            <label htmlFor="footer" className="form-label">Footer:</label>
          </div>
          <div className="col-4 form-check">
            <input className="form-check-input" type="checkbox" defaultChecked={getUseFooterValue} value="" tabIndex={8} {...register("useFooter")} id="footerCheckbox"></input>
            <label className="form-check-label" htmlFor="footerCheckbox">Include</label>
          </div>
          <div className="col-4 form-check">
            <input className="form-check-input" type="checkbox" defaultChecked={getUseDefaultSpacerValue} value="" tabIndex={9} {...register("useDefaultSpacer")} id="defaultSpacerCheckbox"></input>
            <label className="form-check-label" htmlFor="defaultSpacerCheckbox">Apply Default Spacer</label>
          </div>
        </div>
        <div className="col-12 mb-2 mt-2">
          <label htmlFor="terms" className="form-label">Terms</label>
          <input type="text" className="form-control" id="terms" tabIndex={10}
            defaultValue={isFormEdit ? selectedReportTemplate?.terms || '' : ''}
            {...register("terms", { required: "Terms is required" })}
          />
          <small className='form-error-message'>
            {errors?.terms && errors.terms.message}
          </small>
        </div>

        <div className="col-12 mb-2 mt-2">
          <label htmlFor="footer" className="form-label">Footer</label>
          <input type="text" className="form-control" id="footer" tabIndex={11}
            defaultValue={isFormEdit ? selectedReportTemplate?.footer || '' : ''}
            {...register("footer", { required: "Footer is required" })}
          />
          <small className='form-error-message'>
            {errors?.footer && errors.footer.message}
          </small>
        </div>
      </div>
    </>
  );
}

export default PrimaryDetails;
