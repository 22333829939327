import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import { store } from '../redux/store';
import { CompareSharp } from '@mui/icons-material';
import { getAccountIdOnSubmit } from '../utils/accountUtils';
import { isNTMSUSer } from '../utils/roleUtils';

const cookies = new Cookies();

const executeApiCall = async ({path, body = null, method }) => {
  const state = store.getState();
  var accountId = getAccountIdOnSubmit(state.auth.user.userType, state.auth.user.accountId, state.account.selectedAccount.accountId); 
  const userId = state.auth.user.userId; 
  
  // Special case for account updates. Accounts are masqueraded and if they're editing an account they have not selected, it should use the correct accountId
  if(path == 'Account/update' && isNTMSUSer(state.auth.user.userType)) {
    accountId = JSON.parse(body).accountId;
  }

  const token = cookies.get('token');
  const response = await fetch(userId != null ? `/api/user/${userId}/account/${accountId}/${path}` : `/api/user/0/account/0/${path}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body,
  });

  if (response.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    cookies.remove('token');
    window.location.href = '/login';
  }

  return response;
}

export const get = async ({path}) => {
  const response = await executeApiCall({ path, method: 'GET'});
  if(response.status === 204) {
    return [];
  }
  const data = await response.json();
  return (data);
}

export const post = async ({path, body}) => {
  const response = await executeApiCall({ path, body: JSON.stringify(body), method: 'POST'});
  return (response);
}

export const patch = async ({ path, body }) => {
  const response = await executeApiCall({ path, body: JSON.stringify(body), method: 'PATCH'});
  return (response);
}

export const remove = async ({path}) => {
  const response = await executeApiCall({ path, method: 'DELETE'});
  return (response);
}