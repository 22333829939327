import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  COMPLIANCE
} from "../../constants";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { DatePicker } from 'antd';
import dayjs from "dayjs";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedTruckMaintenance = {},
  control,
  register,
  errors,
  setValue,
  reset,
  setTruckVIN,
  setTruckMake,
  setTruckModel,
  setTruckYear,
  setTruckId,
  setTruckMaintenanceType,
  setCompliances,
  truckVIN,
  truckMake,
  truckModel,
  truckYear,
  compliances
}) {

  useEffect(() => {
    if (isFormEdit) {
      setTruckVIN(selectedTruckMaintenance.truck["vin"]);
      setTruckMake(selectedTruckMaintenance.truck["make"]);
      setTruckModel(selectedTruckMaintenance.truck["model"]);
      setTruckYear(moment(selectedTruckMaintenance.truck["year"]).clone().hour(7).minute(0).second(0).toDate());
      setTruckId(selectedTruckMaintenance.truck["truckId"]);
      setValue("date", dayjs(selectedTruckMaintenance["date"]));
      setValue("lastMaintenanceDate", dayjs(selectedTruckMaintenance["lastMaintenanceDate"]));
      setValue("nextMaintenanceDate", dayjs(selectedTruckMaintenance["nextMaintenanceDate"]));
      setValue("lastInspectionDate", dayjs(selectedTruckMaintenance["lastInspectionDate"]));
      setValue("nextInspectionDate", dayjs(selectedTruckMaintenance["nextInspectionDate"]));
      setValue("compliances", selectedTruckMaintenance["compliances"]);
      setValue("engineHealth", selectedTruckMaintenance["engineHealth"]);
      setValue("warranty", selectedTruckMaintenance["warranty"]);
      setValue("comments", selectedTruckMaintenance["comments"]);
      setValue("vin", selectedTruckMaintenance["vin"]);
    } else {
      setTruckVIN();
      setTruckMake();
      setTruckModel();
      setTruckYear();
      setTruckId();
      setTruckMaintenanceType(1);
      reset();
    }
  }, [selectedTruckMaintenance["truckMaintenanceId"]]);

  return (
    <>
      <div className="col-12 form-section-title">Truck Details</div>
      <div className="col-12">
        <hr className="mt-1"></hr>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="vin" className="form-label">
          Vehicle Identification Number
        </label>
        <input
          type="text"
          className="form-control"
          id="vin"
          tabIndex={1}
          defaultValue={truckVIN}
          autoComplete="on"
          disabled
        />
        <small className="form-error-message">
          {errors?.truckVIN && errors.truckVIN.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="make" className="form-label">
          Make
        </label>
        <input
          type="text"
          className="form-control"
          id="make"
          tabIndex={2}
          defaultValue={truckMake}
          autoComplete="on"
          disabled
        />
        <small className="form-error-message">
          {errors?.truckMake && errors.truckMake.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="model" className="form-label">
          Model
        </label>
        <input
          type="text"
          className="form-control"
          id="model"
          tabIndex={3}
          defaultValue={truckModel}
          disabled
          autoComplete="on"
        />
        <small className="form-error-message">
          {errors?.truckModel && errors.truckModel.message}
        </small>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="year" className="form-label">
          Year
        </label>
        <ReactDatePicker
          className="datepicker-field"
          selected={truckYear}
          tabIndex={4}
          dateFormat="yyyy"
          id="year"
          disabled
          showYearPicker
        />
        <small className="form-error-message">
          {errors?.truckYear && errors.truckYear.message}
        </small>
      </div>




      <div className="col-12 form-section-title mt-5">Financials</div>
      <div className="col-12">
        <hr className="mt-1"></hr>
      </div>
      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="vin" className="form-label">
          Vehicle Identification Number
        </label>
        <input
          type="text"
          className="form-control"
          id="vin"
          tabIndex={5}
          defaultValue={isFormEdit ? selectedTruckMaintenance?.vin || "" : ""}
          autoComplete="on"
          disabled
          {...register("vin", {
            required: "Vehicle Identification Number is required",
          })}
        />
        <small className="form-error-message">
          {errors?.vin && errors.vin.message}
        </small>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="date"
          className="form-label"
        >
          Date <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="date"
          register={register}
          rules={{ required: "Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className="form-error-message">
          {errors?.date && errors.date.message}
        </small>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="lastMaintenanceDate"
          className="form-label"
        >
          Last Maintenance Date <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="lastMaintenanceDate"
          register={register}
          rules={{ required: "Last Maintenance Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className="form-error-message">
          {errors?.lastMaintenanceDate && errors.lastMaintenanceDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="nextMaintenanceDate"
          className="form-label"
        >
          Next Maintenance Date <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="nextMaintenanceDate"
          register={register}
          rules={{ required: "Next Maintenance Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className="form-error-message">
          {errors?.nextMaintenanceDate && errors.nextMaintenanceDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="lastInspectionDate"
          className="form-label"
        >
          Last Inspection Date <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="lastInspectionDate"
          register={register}
          rules={{ required: "Last Inspection Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className="form-error-message">
          {errors?.lastInspectionDate && errors.lastInspectionDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label
          htmlFor="nextInspectionDate"
          className="form-label"
        >
          Next Inspection Date <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="nextInspectionDate"
          register={register}
          rules={{ required: "Next Inspection Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className="form-error-message">
          {errors?.nextInspectionDate && errors.nextInspectionDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="odometerReading" className="form-label">
          Odometer Reading <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="odometerReading"
          tabIndex={12}
          defaultValue={isFormEdit ? selectedTruckMaintenance?.odometerReading || "" : ""}
          autoComplete="on"
          {...register("odometerReading", {
            required: "Odometer Reading is required",
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/, // Regular expression for numbers or decimals
              message: "Please enter a valid odometer reading",
            },
          })}
        />
        <small className="form-error-message">
          {errors?.odometerReading && errors.odometerReading.message}
        </small>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="tireCondition" className="form-label">
          Tire Condition <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="tireCondition"
          tabIndex={13}
          defaultValue={isFormEdit ? selectedTruckMaintenance?.tireCondition || "" : ""}
          autoComplete="on"
          {...register("tireCondition", {
            required: "Tire Condition is required",
          })}
        />
        <small className="form-error-message">
          {errors?.tireCondition && errors.tireCondition.message}
        </small>
      </div>

      <div className="col-6 col-sm-3 mb-2">
        <label htmlFor="compliances" className="form-label">
          Compliances <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={compliances}
          tabIndex={4}
          id="compliances"
          {...register("compliances", {
            valueAsNumber: true,
            onChange: (e) => setCompliances(e.target.value),
            required: "Compliances is required",
          })}
        >
          <option key="none" value="" hidden>
            Compliances
          </option>
          {COMPLIANCE.map((compliances, index) => (
            <option key={index} value={compliances.value}>
              {compliances.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.compliances && errors.compliances.message}
        </small>
      </div>

      <div className="col-12 mb-2">
        <label htmlFor="engineHealth" className="form-label">
          Engine Health <span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          id="engineHealth"
          rows="5"
          maxLength={1000}
          tabIndex={15}
          {...register("engineHealth", {
            required: "Engine Health are required",
          })}
        />
        <small className="form-error-message">
          {errors?.engineHealth && errors.engineHealth.message}
        </small>
      </div>

      <div className="col-12 mb-2">
        <label htmlFor="comments" className="form-label">
          Comments <span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          id="comments"
          rows="5"
          maxLength={1000}
          tabIndex={16}
          {...register("comments", {
            required: "Comments are required",
          })}
        />
        <small className="form-error-message">
          {errors?.comments && errors.comments.message}
        </small>
      </div>

      <div className="col-12 mb-2">
        <label htmlFor="warranty" className="form-label">
          Warranty <span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          id="warranty"
          rows="5"
          maxLength={1000}
          tabIndex={17}
          {...register("warranty")}
        />
        <small className="form-error-message">
          {errors?.warranty && errors.warranty.message}
        </small>
      </div>
    </>
  );
}

export default PrimaryDetails;
