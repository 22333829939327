import numberRenderer from '../components/Templates/CellRenderers/Quotes/numberRenderer';

export const displayStatus = (status) => {
  const obj = {
    0: "Draft",
    1: "Approved",
    2: "Archived"
  }
  return obj[status]
}

export const statusValueGetter = (params) => {
  const obj = {
    0: "Draft",
    1: "Approved",
    2: "Archived"
  }
  return obj[params.data.Status]
}

export function statusFilter(gridRef) {
  var filterComponent = gridRef.current.api.getFilterInstance('Status');
  filterComponent.setModel({
    filterType: 'text',
    type: 'notContains',
    filter: 'archived',
  });
  gridRef.current.api.onFilterChanged();
}

export const currencyRendererSelector = (params) => {
  const title = params.colDef.field
  const selector = params.data[title] !== undefined ? { component: numberRenderer } : undefined
  return selector
}

export const calculateQuoteItem = (params) => {
  const header = params.colDef.field

  if (header === "Quantity") {
    params.data.Quantity = params.newValue || 0;
  }

  if (header === "Rate") {
    params.data.Rate = params.newValue || 0;
  }

  const isFreight = params.data.Type === 1;
  const isFuel = params.data.Type === 2;
  const isOther = params.data.Type === 3;
  const isAccessorial = params.data.Type === 4;
  const isFlatRate = params.data.Category === 1;
  const isPerMile = params.data.Category === 2;
  const isSurCharge = params.data.Category === 3;

  if (isFreight) {
    if (isFlatRate) {
      params.data.Total = params.data.Rate * params.data.Quantity;
    }

    if (isPerMile) {
      params.data.Total = params.data.Rate * params.data.Quantity;
    }
  }

  if (isFuel) {
    if (isFlatRate) {
      params.data.Total = params.data.Rate * params.data.Quantity;
    }

    if (isPerMile) {
      params.data.Total = params.data.Rate * params.data.Quantity;
    }

    if (isSurCharge) {
      params.data.Total = (params.data.Rate / 100) * params.data.Quantity;
    }
  }

  if (isOther) {
    params.data.Total = params.data.Rate * params.data.Quantity;
  }

  if (isAccessorial) {
    params.data.Total = params.data.Rate * params.data.Quantity;
  }
  return true
}

export const quantityEditableSettings = (params) => {
  const isFreightOrFuel = params.data.Type === 1 || params.data.Type === 2;
  const isFlatRate = params.data.Category === 1

  if (isFreightOrFuel && isFlatRate) {
    return false;
  }

  return true;
}

