import { CheckCircleFilled, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { Modal, Space } from 'antd';
import React from 'react';
import _ from 'lodash';

export const ErrorModal = ({
  isOpen,
  toggle,
  onClick,
  headerText,
  bodyText,
  confirmButtonText,
  cancelButtonText,
  actionType,
  errors,
}) => {
  let iconComponent;

  // Determine which icon to use based on actionType
  if (actionType === 'progress') {
    iconComponent = <CheckCircleFilled />;
  } else if (actionType === 'delete') {
    iconComponent = <ExclamationCircleFilled />;
  } else if (actionType === 'warning') {
    iconComponent = <QuestionCircleFilled />;
  }


  return (
    <Modal
      open={isOpen}
      className={`ant-modal-${actionType}`}
      title={
        <Space>
          {iconComponent}
          {headerText}
        </Space>
      }
      onOk={onClick}
      onCancel={toggle}
      footer={
        <div className='d-flex justify-content-end'>
          <button className='btn btn-modal-cancel me-2' onClick={toggle}>
            {cancelButtonText}
          </button>
        </div>
      }
    >
      <div className='row'>
        {Object.entries(errors).map(([fieldName, fieldError], index) => (
          <div className='col-6' key={fieldName + index}>
            <p className='mb-4' key={fieldName}>
              {typeof fieldError === 'object' ? fieldError.message : fieldError}
            </p>
          </div>
        ))}
      </div>
    </Modal>
  );
};