import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import _ from 'lodash';
import Cookies from 'universal-cookie';
import { fetchUserByEmail } from '../../services/userServices';
import { useDispatch } from 'react-redux';
import { setAuthenticatedStatus, setUserData } from '../../redux/auth/authReducer';
import { setSelectedAccountData } from '../../redux/account/accountReducer';
import { isAccountAdmin, isAccountUser } from '../../utils/roleUtils';

function LoginSuccess() {
  const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [authUser, setAuthUser] = useState([]);

  useEffect(() => {
    if (!_.isNil(user)) {
      storeUserData(user);
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      sessionStorage.setItem('isAuthenticated', isAuthenticated);
    }
  }, [isAuthenticated])

  // @TODO: Store user data and implement role application.
  const storeUserData = async (user) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_API_AUDIENCE
        },
      });

      await cookies.set('token', token, { path: '/' });

      const response = await fetchUserByEmail(user?.email);
      setAuthUser(response);
      setIsLoading(false);

      if (_.isEmpty(response)) {
        setTimeout(() => {
          logout({
            logoutParams: {
              returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI
            }
          })
        }, 6000);
        return;
      }

      dispatch(setUserData(response));

      if (isAccountAdmin(response.userType) || isAccountUser(response.userType)) {
        dispatch(setSelectedAccountData(
          {
            accountId: parseInt(response.accountId),
            accountName: response.account.accountName
          }
        ));
      }

      sessionStorage.setItem('accountId', parseInt(response.accountId));
      sessionStorage.setItem('userType', parseInt(response.userType));

      setTimeout(() => {
        dispatch(setAuthenticatedStatus(true));
        navigate('/dashboard');
      }, 3000);

    } catch (error) {
      setIsLoading(false);
      setTimeout(() => {
        logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI
          }
        })
      }, 6000);
    }
  }

  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
      <div className='small-container login shadow rounded'>
        {isLoading && (
          <>
            <div className='title text-center mb-4'>
              Authenticating
            </div>
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        )}
        {!isLoading && (
          <>
            <div className='title text-center mb-4'>
              {`Login ${_.isEmpty(authUser) ? 'Failed' : 'Success'}`}
            </div>
            <p className='content text-center'>
              {_.isEmpty(authUser)
                ? 'User not found, please try again.\nYou will be redirected back to the login page.'
                : 'You will be redirected in a few seconds.'
              }
            </p>
            <div className={`text-center login-status-icon`}>
              <i className={`fa-solid ${_.isEmpty(authUser) ? 'fa-xmark' : 'fa-check'}`}></i>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default LoginSuccess
