import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import QuoteItemFormModal from "../Commons/Modals/QuoteItemFormModal";
import { selectStyle } from "../../utils/styleUtils";
import moment from "moment";
import { formatCurrency, formatDateOnly, formatEllipsis } from "../../utils/formatUtils";
import QuoteActionRenderer from "../Templates/CellRenderers/Quotes/QuoteActionRenderer";
import { QUOTE_STATUS } from "../../constants";
import { enumValueFormatter } from "../../utils/formatUtils";
import _ from 'lodash';
import { addTMSQuote, updateTMSQuote } from "../../services/tmsQuoteServices";
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { addOrderTMSQuoteData, updateOrderTMSQuoteData } from "../../redux/order/orderReducer";
import { setNotificationData } from "../../redux/global/globalReducer";
import QuoteStatusRenderer from "../Templates/CellRenderers/Orders/QuoteStatusRenderer";

function MapDetails({
  selectedOrder,
  isFormHidden,
  accessorialOptions,
  selectedQuoteList,
  selectedAccessorialList,
  setSelectedQuoteList,
  checkPageEditable,
  setSelectedOrderStatus
}) {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    watch,
    control,
    trigger,
    formState:
    {
      errors,
      isDirty
    }
  } = useForm();
  const dispatch = useDispatch();
  const orderState = useSelector((state) => state.order);
  const currentOrder = _.find(orderState?.data, { orderId: selectedOrder?.orderId })
  const [rowData, setRowData] = useState([]);
  

  const toggleQuoteModalDisplay = async (isNew = true, quoteId = null) => {
    if (!isNew) {
      setSelectedQuoteId(quoteId)
      setIsQuoteEdit(isNew);
      setModal(!modal)
    } else {
      // setSelectedQuoteId(null)
      // fillSelectedQuote(selectedQuoteId);
      const createdQuote = await createQuote();
      setIsQuoteEdit(true);
      setSelectedQuoteId(createdQuote.tmsQuoteId);
      fillSelectedQuote(createdQuote.tmsQuoteId);
      setModal(!modal);
    }
  }

  const createQuote = async () => {
    var quoteItems = [];
    var freightItem = {
      "type": 1,
      "category": 1,
      "description": "Freight",
      "quantity": 1,
      "rate": 0,
      "cost": 0,
      "notes": "",
      "accountId": selectedOrder?.accountId,
      "orderId": selectedOrder?.orderId,
    }
    quoteItems.push(freightItem)
    var fuelItem = {
      "type": 2,
      "category": 1,
      "description": "Fuel",
      "quantity": 1,
      "rate": 0,
      "cost": 0,
      "notes": "",
      "accountId": selectedOrder?.accountId,
      "orderId": selectedOrder?.orderId,
    }
    quoteItems.push(fuelItem)
    currentOrder?.orderAccessorial.map((accessorial, index) => {
      var currentAccessorial = {
        'type': 4,
        'category': 0,
        'description': accessorial.accessorialName,
        'quantity': 1,
        'rate': 0,
        'total': 0,
        "accountId": selectedOrder?.accountId,
        "orderId": selectedOrder?.orderId,
      }
      quoteItems.push(currentAccessorial)
    });
    const payload = {
      "quoteName": "Name",
      "total": 0,
      "quoteDate": moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD'),
      "status": 0,
      "accountId": selectedOrder?.accountId,
      "orderId": selectedOrder?.orderId,
      "tmsQuoteItem": quoteItems
    }

    const response = await addTMSQuote(payload);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } item.`,
      })
    );

    const returnedData = await response.json()

    if (response.ok) {
      const newItem = {
        "#": rowData.length + 1,
        Name: returnedData.quoteName,
        Date: returnedData.quoteDate,
        Quote: returnedData.total,
        Status: returnedData.status,
        Notes: returnedData.notes,
        QuoteId: returnedData.tmsQuoteId,
        Id: returnedData.tmsQuoteId,
        OrderId: returnedData.orderId,
        AccountId: returnedData.accountId,
        QuoteItems: returnedData.tmsQuoteItem
      };

      const transaction = {
        add: [newItem],
      };

      quoteGridRef.current.api.applyTransaction(transaction);

      setRowData(prevRowData => [...prevRowData, newItem]);

      dispatch(addOrderTMSQuoteData({ orderId: selectedOrder?.orderId, tmsQuote: returnedData }));
      return returnedData;
    }
    return;
  }

  const [columnDefs, setColumnDefs] = useState();

  const columnDefinitions = (isEditable) => {
    const defs = [
      {
        field: "Name",
        minWidth: 150,
        cellClass: "grid-column",
      },
      {
        field: "Date",
        minWidth: 150,
        cellClass: "grid-column gray",
      },
      {
        field: "Quote",
        minWidth: 150,
        valueFormatter: (params) => { return formatCurrency(params.value) },
        cellClass: "grid-column gray",
      },
      {
        field: "Status",
        minWidth: 150,
        cellClass: "grid-column gray",
        cellRenderer: QuoteStatusRenderer,
        valueFormatter: (params) => {
          return enumValueFormatter(params, QUOTE_STATUS);
        }
      },
      {
        field: "Notes",
        minWidth: 150,
        cellClass: "grid-column gray",
        tooltipValueGetter: (params) => params.value,
        valueFormatter: (params) => formatEllipsis(params, 40)
      },
      {
        field: "Edit",
        minWidth: 150,
        headerName: "",
        cellClass: "d-flex flex-row-reverse",
        autoHeight: true,
        cellRenderer: QuoteActionRenderer,
        cellRendererParams:
        {
          toggleQuoteModalDisplay,
          checkPageEditable: isEditable,
          setColumnDefs: setColumnDefs,
          columnDefinitions: columnDefinitions,
          setSelectedOrderStatus: setSelectedOrderStatus
        },
      },
    ];

    return defs;
  }


  useEffect(() => {
    let data = [];
    _.each(currentOrder?.tmsQuote, (quote, index) => {

      const total = quote.tmsQuoteItem.reduce((sum, item) => sum + item.cost, 0);
      data.push({
        '#': index + 1,
        Name: quote.quoteName,
        Date: formatDateOnly(quote.quoteDate),
        Quote: total,
        Status: quote.status,
        Notes: quote.notes,
        QuoteId: quote.tmsQuoteId,
        Id: quote.tmsQuoteId,
        OrderId: quote.orderId,
        AccountId: quote.accountId,
        QuoteItems: quote.tmsQuoteItem
      });
    });
    setRowData(data);
  }, [currentOrder?.tmsQuote])

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const fillSelectedQuote = (id) => {
    const quote = _.find(rowData, { QuoteId: id });
    setSelectedQuote(quote);
    fillQuoteItemTable(quote?.QuoteItems)
  }

  const onGridReady = useCallback((params) => {
    if (!isFormHidden) {
      quoteGridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    if (!isFormHidden) {
      quoteGridRef.current.api.sizeColumnsToFit();
    }
  };

  const onGridSizeChanged = (params) => {
    if (!isFormHidden && params.clientHeight > 0 && params.clientWidth > 0) {
      quoteGridRef.current.api.sizeColumnsToFit();
    }
  };

  const [selectedQuote, setSelectedQuote] = useState({});
  const [selectedQuoteId, setSelectedQuoteId] = useState(null);
  const [isQuoteEdit, setIsQuoteEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedQuoteItemList, setSelectedQuoteItemList] = useState([]);
  const toggle = () => setModal(!modal);
  const quoteGridRef = useRef();
  const quoteItemGridRef = useRef();

  useEffect(() => {
    fillSelectedQuote(selectedQuoteId);
  }, [selectedQuoteId, modal]);

  const onSubmit = async (payload) => {
    let response = null

    response = await updateTMSQuote(payload);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } item.`,
      })
    );


    const returnedData = await response.json()

    const rowIndex = quoteGridRef.current.api.getRowNode(returnedData.tmsQuoteId).rowIndex;

    if (response.ok) {
      const updatedItem = {
        '#': rowIndex + 1,
        Name: returnedData.quoteName,
        Date: formatDateOnly(returnedData.quoteDate),
        Quote: returnedData.total,
        Status: returnedData.status,
        Notes: returnedData.notes,
        QuoteId: returnedData.tmsQuoteId,
        Id: returnedData.tmsQuoteId,
        OrderId: returnedData.orderId,
        AccountId: returnedData.accountId,
        QuoteItems: returnedData.tmsQuoteItem
      };

      const transaction = {
        update: [updatedItem],
      };

      quoteGridRef.current.api.applyTransaction(transaction);

      // setRowData(prevRowData => [...prevRowData, newItem]);

      dispatch(updateOrderTMSQuoteData({ orderId: selectedOrder?.orderId, updatedTMSQuote: returnedData }));
      toggle()
    }
  };

  const fillQuoteItemTable = (quoteItemList) => {
    let data = [];
    if (modal) {
      _.each(quoteItemList, (quoteItem, index) => {
        data.push({
          '#': index + 1,
          Type: quoteItem.type,
          Category: quoteItem.category,
          Description: quoteItem.description,
          Quantity: quoteItem.quantity,
          Rate: quoteItem.rate,
          Total: quoteItem.cost,
          Notes: quoteItem.notes,
          QuoteItemId: quoteItem.tmsQuoteItemId,
          Id: quoteItem.tmsQuoteItemId,
          QuoteId: quoteItem.tmsQuoteId,
          OrderId: quoteItem.orderId,
          AccountId: quoteItem.accountId,
        });
      });
    }

    setSelectedQuoteItemList(data);
  }

  const getRowId = (params) => params.data.QuoteId;

  const gridOptions = {
    tooltipShowDelay: 500,
  }

  useEffect(() => {
    setColumnDefs(columnDefinitions(checkPageEditable()));
  }, [checkPageEditable()])

  return (
    <div>
        @TODO: Show map here
    </div>
  );
}

export default MapDetails;
