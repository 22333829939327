import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedAccount = {},
  control,
  register,
  errors,
  setValue,
  reset,
  provinceStateRef
}) {

  const [provinceState, setProvinceState] = useState("")
  const [country, setCountry] = useState("")
  const [defaultTax, setDefaultTax] = useState("")

  const provinceStateOptions = PROVINCE_STATE;
  useEffect(() => {
    if (isFormEdit) {
      reset();
      const provinceOrState = PROVINCE_STATE.find((provinceState) => provinceState.value === selectedAccount["provinceState"]);
      setValue("accountName", selectedAccount["accountName"], { shouldDirty: false });
      setValue("taxId", selectedAccount["taxId"], { shouldDirty: false });
      setValue("notes", selectedAccount["notes"], { shouldDirty: false });
      setValue("addressLine1", selectedAccount["addressLine1"], { shouldDirty: false });
      setValue("addressLine2", selectedAccount["addressLine2"], { shouldDirty: false });
      setValue("city", selectedAccount["city"], { shouldDirty: false });
      setValue("postalCode", selectedAccount["postalCode"], { shouldDirty: false });
      setValue("provinceState", provinceOrState, { shouldDirty: false });
      setValue("country", parseInt(selectedAccount['country']), { shouldDirty: false });
      setValue("defaultTaxCode", parseInt(selectedAccount['defaultTaxCode']), { shouldDirty: false });
      setValue("active", selectedAccount['active'], { shouldDirty: false });
      setValue("phone", selectedAccount['phone'], { shouldDirty: false });
      setValue("fax", selectedAccount['fax'], { shouldDirty: false });
      setValue("cell", selectedAccount['cell'], { shouldDirty: false });

      setProvinceState(provinceOrState);
      setCountry(parseInt(selectedAccount['country']));
      setDefaultTax(parseInt(selectedAccount['defaultTaxCode']));
    } else {
      setProvinceState("");
      setCountry("");
      setDefaultTax("");
      reset();
    }

  }, [selectedAccount["accountId"]])

  const userType = useSelector((state) => state.auth.user.userType);

  const getActiveDefaultValue = () => {
    if (selectedAccount.length <= 0) {
      return 'true';
    }

    return selectedAccount.active ? 'true' : '';
  }

  const setCountryValue = (e) => {
    const provinceStateValue = e?.value;
    if (provinceStateValue != null) {
      const firstUSState = PROVINCE_STATE.find((provinceState) => provinceState.label === "Alabama").value;
      setValue("country", provinceStateValue >= firstUSState ? 2 : 1,);
      setCountry(provinceStateValue >= firstUSState ? 2 : 1,);
    }
  }

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="name" className="form-label">Name <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="name" tabIndex={1}
          defaultValue={isFormEdit ? selectedAccount?.accountName || '' : ''}
          autoComplete='on'
        {...register("accountName", { required: "Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.accountName && errors.accountName.message}
        </small>
      </div>
      
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultTaxCode" className="form-label">Default Tax Code: <span className="required-asterisk">*</span></label>
        <select
          className="form-select"
          id="defaultTaxCode"
          value={defaultTax} 
          tabIndex={2}
        {...register("defaultTaxCode", { valueAsNumber: true, onChange: (e) => setDefaultTax(e.target.value), required: "Default Tax Code is required" })}
        >
          <option key="none" value="" hidden>Default Tax Code</option>
          {TAX_TYPES.map((tax, index) =>
              <option key={index} value={tax.value}>{tax.label}</option>
            )}
        </select>
        <small className='form-error-message'>
          {errors?.defaultTaxCode && errors.defaultTaxCode.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="taxId" className="form-label">Tax ID: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="taxId" tabIndex={3}
        defaultValue={isFormEdit ? selectedAccount?.taxId || '' : ''}
        {...register("taxId", { required: "Tax ID is required" })}
        />
        <small className='form-error-message'>
          {errors?.taxId && errors.taxId.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="phone" className="form-label">Phone <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='phone'
          register={register}
          rules={{ required: "Phone is required" }}

          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              defaultValue={value === undefined ? '' : value}
              value={value === undefined ? '' : value}
              onChange={onChange}
              tabIndex={4}
              onBlur={onBlur}
              getInputRef={ref}
              className='form-control'
              format="(###) ###-####"
              id="phone"
              mask="_"
              allowEmptyFormatting autoComplete='on'
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.phone && errors.phone.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="fax" className="form-label">Fax</label>
        <Controller
          control={control}
          name='fax'
          register={register}

          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              defaultValue={isFormEdit ? selectedAccount?.fax || '' : ''} 
              value={isFormEdit ? selectedAccount?.fax || '' : ''} 
              onChange={onChange}
              tabIndex={5}
              onBlur={onBlur}
              className='form-control'
              format="(###) ###-####"
              id="fax"
              mask="_"
              allowEmptyFormatting
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.fax && errors.fax.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="mobile" className="form-label">Mobile</label>
        <Controller
          control={control}
          name='cell'
          register={register}

          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              defaultValue={isFormEdit ? selectedAccount?.cell || '' : ''} 
              value={isFormEdit ? selectedAccount?.cell || '' : ''} 
              onChange={onChange}
              tabIndex={6}
              onBlur={onBlur}
              className='form-control'
              format="(###) ###-####"
              id="mobile"
              mask="_"
              allowEmptyFormatting
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.cell && errors.cell.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="addressLine1" className="form-label">Address Line 1 <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="addressLine1" tabIndex={8}
          defaultValue={isFormEdit ? selectedAccount?.addressLine1 || '' : ''}
          {...register("addressLine1", { required: "Address Line 1 is required" })}
        />
        <small className='form-error-message'>
          {errors?.addressLine1 && errors.addressLine1.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="addressLine2" className="form-label">Address Line 2</label>
        <input type="text" className="form-control" id="addressLine2" tabIndex={9}
          defaultValue={isFormEdit ? selectedAccount?.addressLine2 || '' : ''}
          {...register("addressLine2")}
        />
        <small className='form-error-message'>
          {errors?.addressLine2 && errors.addressLine2.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="city" className="form-label">City <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="city" tabIndex={10}
          defaultValue={isFormEdit ? selectedAccount?.city || '' : ''}
          {...register("city", { required: "City is required" })}
        />
        <small className='form-error-message'>
          {errors?.city && errors.city.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="provinceState" className="form-label">Province/State</label>
        <div className="custom-selector">
          <Controller
            name="provinceState"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => { field.onChange(e); setCountryValue(e) }}
                ref={provinceStateRef}
                defaultValue={provinceState}
                options={provinceStateOptions}
                className="company-select"
                classNamePrefix="react-select"
                placeholder={'Province/State'}
                id="companyName"
                styles={selectStyle}
                isClearable
                tabIndex={11}
              />
            )}
          />
        </div>
        <small className='form-error-message'>
          {errors?.companyName && errors.companyName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="postalCode" className="form-label">Postal Code <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="postalCode" tabIndex={12}
          defaultValue={isFormEdit ? selectedAccount?.postalCode || '' : ''}
          {...register("postalCode", { required: "Postal Code is required", maxLength: 6 })}
        />
        <small className='form-error-message'>
          {errors?.postalCode && errors.postalCode.message}
        </small>
      </div>
      
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="country" className="form-label">Country: <span className="required-asterisk">*</span></label>
        <select
          className="form-select"
          id="country"
          value={country} 
          tabIndex={13}
        {...register("country", { valueAsNumber: true, onChange: (e) => setCountry(e.target.value), required: "Country is required" })}
        >
          <option key="none" value="" hidden>Country</option>
          {COUNTRY.map((country, index) =>
            <option key={index} value={country.value}>{country.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.country && errors.country.message}
        </small>
      </div>

      {
        (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
        <div className="col-12 mb-2 mt-3">
          <div className="form-check">
            <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={14}
              defaultChecked={getActiveDefaultValue}
              {...register("active")}
            />
            <label className="form-check-label align-middle " htmlFor="active"> Active</label>
            <small className='form-error-message'>
              {errors?.active && errors.active.message}
            </small>
          </div>
        </div>
      }
    </>
  );
}

export default PrimaryDetails;
