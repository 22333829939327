import { AccessTime, CalendarMonth } from "@mui/icons-material";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef
} from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationData, setFormPendingCompletion } from "../../redux/global/globalReducer";
import {
  addLoadItem,
  fetchLoadItemsByLoadId,
  updateLoadItem,
  updateLoadItems,
  deleteLoadItem,
} from "../../services/loadServices";
import _ from "lodash";
import {
  addLoadItemData,
  setLoadItem,
  setLoadItems,
  setLoadItemsListData,
  deleteLoadItemData,
} from "../../redux/loadItems/loadItemsReducer";
import moment from "moment";
import dayjs from "dayjs";
import DeleteItemActionRenderer from "../Templates/CellRenderers/Commons/DeleteItemActionRenderer";
import { GRID_CONSTANTS, PACKAGING_TYPE } from "../../constants";
import { enumValueFormatter, formatCurrency } from "../../utils/formatUtils";
import PackagingTypeEditor from "../Templates/Editor/PackagingTypeEditor";
import { NumericCellEditor } from "../Commons/Editor/NumericCellEditor";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";

const LoadItems = forwardRef(({
  isFormEdit = false,
  setIsFormDirty,
  isFormHidden,
  selectedOrder = {},
  checkPageEditable,
  setValue,
  control,
  register,
  errors,
  dirtyFields,
  dirtyLoadOrderColumns,
  setDirtyLoadOrderColumns
}, ref) => {
  const dispatch = useDispatch();
  const gridRef = useRef();

  const accountId = useSelector((state) => state.auth.user.accountId);
  const loadItemsState = useSelector((state) => state.loadItems);

  useEffect(() => {
    if(isFormHidden) {
      setDirtyLoadOrderColumns(false);
    }
  }, [isFormHidden])

  useEffect(() => {
    if (!_.isEmpty(selectedOrder)) {
      setValue("instructions", selectedOrder.instructions);
      setValue("pickupStartDate", moment(selectedOrder.pickupStartDate).clone().hour(7).minute(0).second(0).toDate());
      setValue("pickupEndDate", moment(selectedOrder.pickupEndDate).clone().hour(7).minute(0).second(0).toDate());
      setValue("dropoffStartDate", moment(selectedOrder.dropoffStartDate).clone().hour(7).minute(0).second(0).toDate());
      setValue("dropoffEndDate", moment(selectedOrder.dropoffEndDate).clone().hour(7).minute(0).second(0).toDate());

      setValue("pickupStartTime", moment(selectedOrder.pickupStartTime, 'HH:mm:ss').toDate());
      setValue("pickupEndTime", moment(selectedOrder.pickupEndTime, 'HH:mm:ss').toDate());
      setValue("dropoffStartTime", moment(selectedOrder.dropoffStartTime, 'HH:mm:ss').toDate());
      setValue("dropoffEndTime", moment(selectedOrder.dropoffEndTime, 'HH:mm:ss').toDate());
    }
  }, [selectedOrder]);

  useEffect(() => {
    setColumnDefs(columnDefinitions(checkPageEditable()));
  }, [checkPageEditable()])

  const handleUpdateLoadItems = async (showNotification = true) => {
    var loadItems = [];

    if (gridRef.current.api.getDisplayedRowCount() === 0) {
      if (showNotification) {
        dispatch(
          setNotificationData({
            type: "error",
            message: "Error!",
            description: "Please add a load item.",
          })
        );
      }
      return;
    }

    gridRef.current.api.forEachNode(node => {
      const loadItem = node.data;

      var payloadData = {
        accountId: accountId,
        orderId: selectedOrder.orderId,
        loadId: selectedOrder.load.loadId,
        loadItemId: loadItem["loadItemId"],
        description: loadItem["Description"],
        quantity: parseFloat(loadItem.Quantity),
        packagingType: +loadItem["Packaging Type"],
        width: parseFloat(loadItem.Width),
        height: parseFloat(loadItem.Height),
        length: parseFloat(loadItem.Length),
        weight: parseFloat(loadItem.Weight),
        linearFeet: parseFloat(loadItem["Linear Feet"]),
        hazMatClass: loadItem["HazMat Class"],
        NMFC: loadItem["NMFC"],
        itemValue: parseFloat(loadItem["Item Value"]),
      }
      loadItems.push(payloadData);
    });

    let response = await updateLoadItems(loadItems);
    if (showNotification) {
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } load item.`,
        })
      );
    }

    if (response.ok) {
      const returnedData = await response.json();
      setDirtyLoadOrderColumns(false);
      dispatch(setLoadItems(returnedData));
    }
  }

  const columnDefinitions = (isEditable) => {
    const defs = [
      {
        field: "Description",
        minWidth: 150,
        cellClass: "grid-column",
        editable: !isEditable,
      },
      {
        field: "Quantity",
        minWidth: 150,
        cellClass: "grid-column gray",
        editable: !isEditable,
        cellEditor: NumericCellEditor
      },
      {
        field: "Packaging Type",
        minWidth: 150,
        cellClass: "grid-column gray",
        editable: !isEditable,
        cellEditor: PackagingTypeEditor,
        cellEditorParams: {
          values: PACKAGING_TYPE,
        },
        valueFormatter: (params) => {
          return enumValueFormatter(params, PACKAGING_TYPE);
        }
      },
      {
        field: "Width",
        minWidth: 100,
        cellClass: "grid-column gray",
        editable: !isEditable,
        cellEditor: NumericCellEditor
      },
      {
        field: "Height",
        minWidth: 100,
        cellClass: "grid-column gray",
        editable: !isEditable,
        cellEditor: NumericCellEditor
      },
      {
        field: "Length",
        minWidth: 100,
        cellClass: "grid-column gray",
        editable: !isEditable,
        cellEditor: NumericCellEditor
      },
      {
        field: "Weight",
        minWidth: 100,
        cellClass: "grid-column gray",
        editable: !isEditable,
        cellEditor: NumericCellEditor
      },
      {
        field: "Linear Feet",
        minWidth: 100,
        cellClass: "grid-column gray",
        editable: !isEditable,
        cellEditor: NumericCellEditor
      },
      {
        field: "HazMat Class",
        minWidth: 150,
        cellClass: "grid-column gray",
        editable: !isEditable,
      },
      {
        field: "NMFC",
        minWidth: 150,
        cellClass: "grid-column gray",
        editable: !isEditable,
      },
      {
        field: "Item Value",
        minWidth: 140,
        cellClass: "grid-column gray",
        editable: !isEditable,
        cellEditor: NumericCellEditor,
        valueFormatter: (params) => { return formatCurrency(params.value) }
      },
      {
        field: "Edit",
        minWidth: 150,
        headerName: "",
        cellClass: "d-flex flex-row-reverse",
        autoHeight: true,
        hide: isEditable,
        cellRenderer: DeleteItemActionRenderer,
        cellRendererParams: {
          header: "Load Item",
          parentType: "Order",
          deleteFunction: deleteLoadItem,
          deleteReduxFunction: deleteLoadItemData,
          checkPageEditable: isEditable,
          saveData: handleUpdateLoadItems
        },
      },
    ];

    return defs;
  }

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    if (!isFormHidden) {
      gridRef.current.api.sizeColumnsToFit();
    }

  }, []);

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    if (!isFormHidden) {
      gridRef.current.api.sizeColumnsToFit();
    }
  };

  const onGridSizeChanged = (params) => {
    if (!isFormHidden && params.clientHeight > 0 && params.clientWidth > 0) {
      gridRef.current.api.sizeColumnsToFit();
    }
  };

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(columnDefinitions(false));
  const [loadItemsList, setLoadItemsList] = useState([]);
  

  useEffect(() => {
    setLoadItemsList(loadItemsState.data);
  }, [loadItemsState.data]);

  useEffect(() => {
    let data = [];
    _.each(loadItemsList, (loadItem) => {
      data.push({
        loadItemId: loadItem?.loadItemId,
        Description: loadItem?.description,
        Quantity: loadItem?.quantity,
        "Packaging Type": loadItem?.packagingType,
        Width: loadItem?.width,
        Height: loadItem?.height,
        Length: loadItem?.length,
        Weight: loadItem?.weight,
        "Linear Feet": loadItem?.linearFeet,
        "HazMat Class": loadItem?.hazMatClass,
        NMFC: loadItem?.nmfc,
        "Item Value": loadItem?.itemValue,
        Id: loadItem?.loadItemId,
      });
    });
    setRowData(data);
  }, [loadItemsList]);

  const handleAddLoadItem = async () => {
    await handleUpdateLoadItems(false);
    // add default load item values
    const payload = {
      accountId: accountId,
      orderId: selectedOrder.orderId,
      loadId: selectedOrder.load.loadId,
      description: "",
      quantity: 0,
      packagingType: 1,
      width: 0,
      height: 0,
      length: 0,
      weight: 0,
      linearFeet: 0,
      hazMatClass: "N/A",
      itemValue: 0,
    };

    const response = await addLoadItem(payload);
    const data = await response.json();

    dispatch(addLoadItemData(data));

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } load item.`,
      })
    );
  };

  const handleCellEditingStopped = async (params) => {
    if (params.oldValue !== params.newValue) {
      const payload = {
        accountId: accountId,
        orderId: selectedOrder.orderId,
        loadId: selectedOrder.load.loadId,
        loadItemId: params.data["loadItemId"],
        description: params.data["Description"],
        quantity: params.data["Quantity"],
        packagingType: +params.data["Packaging Type"],
        width: params.data["Width"],
        height: params.data["Height"],
        length: params.data["Length"],
        weight: params.data["Weight"],
        linearFeet: params.data["Linear Feet"],
        hazMatClass: params.data["HazMat Class"],
        NMFC: params.data["NMFC"],
        itemValue: params.data["Item Value"],
      };

      let response = await updateLoadItem(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } load item.`,
        })
      );

      const returnedData = await response.json();

      dispatch(setLoadItem(returnedData));
    }
  };



  React.useImperativeHandle(ref, () => ({
    handleUpdateLoadItems: handleUpdateLoadItems
  }));

  const onCellValueChanged = (params) => {
    // dispatch(setFormPendingCompletion(true));
    // setIsFormDirty(true);
    setDirtyLoadOrderColumns(true);
  };

  return (
    <>
      <div className="col-12 mb-2">
        <div className="d-flex flex-row align-items-center">
          <label className="form-label simple mb-0">Load Type:</label>
          <div className="form-check ms-3 mb-0">
            <input
              className="form-check-input"
              type="radio"
              name="shippingMethod"
              id="shippingMethodFTL"
              defaultChecked
              disabled={checkPageEditable()}
            />
            <label className="form-check-label" htmlFor="shippingMethodFTL">
              FTL
            </label>
          </div>
          <div className="form-check ms-3 me-5 mb-0">
            <input
              className="form-check-input"
              type="radio"
              name="shippingMethod"
              id="shippingMethodLTL"
              disabled={checkPageEditable()}
            />
            <label className="form-check-label" htmlFor="shippingMethodLTL">
              LTL
            </label>
          </div>

          <div className="form-check ms-5 mb-0">
            <input
              className="form-check-input"
              type="radio"
              name="measurementType"
              id="measurementTypeMetric"
              defaultChecked
              disabled={checkPageEditable()}
            />
            <label className="form-check-label" htmlFor="measurementTypeMetric">
              Metric
            </label>
          </div>
          <div className="form-check ms-3 mb-0">
            <input
              className="form-check-input"
              type="radio"
              name="measurementType"
              id="measurementTypeStandard"
              disabled={checkPageEditable()}
            />
            <label
              className="form-check-label"
              htmlFor="measurementTypeStandard"
            >
              Standard
            </label>
          </div>
          {!checkPageEditable() &&
            <div className="ms-auto d-flex flex-row flex-wrap align-items-center">
              <button
                type="button"
                className="btn btn-md btn-color-3 mt-2 me-2"
                onClick={handleAddLoadItem}
              >
                Add Load Item
              </button>
              <button
                type="button"
                disabled={!dirtyLoadOrderColumns}
                className="btn btn-md btn-color-3 mt-2"
                onClick={handleUpdateLoadItems}
              >
                Save Changes
              </button>
            </div>}

        </div>
      </div>

      <div className={`col-12 mb-2`}>
        <div className={`ag-theme-alpine form-content-section-container color-3-grid p-0`}>
          <AgGridReact
            className="no-header"
            rowData={rowData}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onColumnSizeChanged={onColumnsSizeChanged}
            domLayout="autoHeight"
            onGridSizeChanged={onGridSizeChanged}
            rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
            onCellValueChanged={onCellValueChanged}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
});

export default LoadItems;
