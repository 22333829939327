import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { deleteQuoteItem } from '../../../services/quoteServices';
import { setNotificationData } from '../../../redux/global/globalReducer';
import { useDispatch } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';

function ActionRemoveRowRenderer(props, args) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const pinnedRow = props.node.rowPinned != null ? props.node.rowPinned : null
  const toggle = () => setModal(!modal);

  useEffect(() => {
    props.refreshCell()
  }, [])

  const removeSelected = useCallback(async (params) => {
    const selectedNode = props.gridRef.current.api.getSelectedNodes();

    const response = await deleteQuoteItem(selectedNode[0].data["Item"])

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully deleted" : "Failed to delete"
          } quote item.`,
      })
    );

    if (response.ok) {
      props.fillQuoteData();
      props.fillTableData();
      setModal(false);
    }

  }, [props.rowData]);

  return (
    <div className='cell-renderer-content'>
      {pinnedRow === 'top' ?
        <>
          <button onClick={toggle} className="btn btn-primary btn-sm"><i className="fa-solid fa-check"></i></button>
          <Modal isOpen={modal} toggle={toggle} {...args}>
            <ModalHeader toggle={toggle}>Add Quote Item</ModalHeader>
            <ModalBody>
              Do you want to add the  Quote Item?
            </ModalBody>
            <ModalFooter>
              {/* <Button color="primary" onClick=""> */}
              <Button color="primary" onClick={() => props.postData()}>
                Yes
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </>
        :
        <>
          <button onClick={toggle} className="btn btn-color-delete btn-sm"><DeleteOutlined/></button>
          <Modal isOpen={modal} toggle={toggle} {...args}>
            <ModalHeader toggle={toggle}>Delete Quote Item</ModalHeader>
            <ModalBody>
              Are you sure you want to delete this item?
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={removeSelected}>
                Yes
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </>

      }
    </div>
  )
}

export default ActionRemoveRowRenderer