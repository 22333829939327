import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const driverReducer = createSlice({
  name: "driver",
  initialState,
  reducers: {
    setDriversListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setDriverData: (state, action) => {
      const index = state.data.findIndex(driver => driver.driverId === action.payload.driverId);
      const driversListData = [...state.data];
      driversListData[index] = action.payload;
      return {
        ...state,
        data: driversListData
      }
    },
    addDriverData: (state, action) => {
      const driversListData = [...state.data];
      driversListData.push(action.payload);
      return {
        ...state,
        data: driversListData
      }
    },
  },
});

export const {
  setDriversListData,
  setDriverData,
  addDriverData,
} = driverReducer.actions;

export default driverReducer.reducer;