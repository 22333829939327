import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchTerminals = async () => {
  return get({ path: 'Terminal' });
}

export const fetchTerminalsFiltered = async (accountId) => {
  return get({ path: `Terminal/filtered` });
}

export const addTerminal = async (payload) => {
  return post({ path: 'Terminal', body: payload });
}

export const updateTerminal = async (payload) => {
  return patch({ path: `Terminal/${payload.terminalId}`, body: payload });
}

export const fetchTerminalsByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchTerminalsFiltered(accountId) : await fetchTerminalsFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchTerminalsFiltered(accountId);
  }
  
  return await fetchTerminals();
}