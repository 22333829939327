import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ConfirmationModal } from '../../../Commons/Modals/ConfirmationModal';
import { DeleteOutlined } from '@ant-design/icons';

function ActionRendererPayItem(props, args) {
  const [modal, setModal] = useState(false);
  const [body] = useState("edit");
  const toggle = () => setModal(!modal);
  const rowIndex = props.node.rowIndex;
  const removeSelected = () => {
    props.removeSelected();
    setModal(false);
  }

  return (
    <div className='cell-renderer-content'>
      {
        rowIndex !== 0 &&
        <button onClick={toggle} className="btn btn-color-delete btn-sm"><DeleteOutlined/></button>
      }
      <ConfirmationModal
        isOpen={modal}
        toggle={toggle}
        onClick={removeSelected}
        headerText="Delete Payroll Run Line Item Pay Item"
        bodyText="Are you sure you want to delete this item?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        actionType="delete"
      />
    </div>
  )
}

export default ActionRendererPayItem