import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const truckReducer = createSlice({
  name: "truck",
  initialState,
  reducers: {
    setTrucksListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setTruckData: (state, action) => {
      const index = state.data.findIndex(truck => truck.truckId === action.payload.truckId);
      const trucksListData = [...state.data];
      trucksListData[index] = action.payload;
      return {
        ...state,
        data: trucksListData
      }
    },
    addTruckData: (state, action) => {
      const trucksListData = [...state.data];
      trucksListData.push(action.payload);
      return {
        ...state,
        data: trucksListData
      }
    },
  },
});

export const {
  setTrucksListData,
  setTruckData,
  addTruckData,
} = truckReducer.actions;

export default truckReducer.reducer;