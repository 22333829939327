import { get, post, patch, remove } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchTMSQuoteItems = async () => {
  return get({ path: 'TMSQuoteItem' });
}

export const fetchTMSQuoteItemsFiltered = async (accountId) => {
  return get({ path: `TMSQuoteItem/filtered` });
}

export const addTMSQuoteItem = async (payload) => {
  return post({ path: 'TMSQuoteItem', body: payload });
}

export const updateTMSQuoteItem = async (payload) => {
  return patch({ path: `TMSQuoteItem/update/${payload.tmsQuoteItemId}`, body: payload });
}

export const deleteTMSQuoteItem = async (tmsQuoteItemId) => {
    return remove({ path: `TMSQuoteItem/${tmsQuoteItemId}` });
}

export const updateTMSQuoteItems = async (payload) => {
  return patch({ path: `TMSQuoteItem/updateTMSQuoteItems`, body: payload });
}