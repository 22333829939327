import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const terminalReducer = createSlice({
  name: "terminal",
  initialState,
  reducers: {
    setTerminalsListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setTerminalData: (state, action) => {
      const index = state.data.findIndex(terminal => terminal.terminalId === action.payload.terminalId);
      const terminalsListData = [...state.data];
      terminalsListData[index] = action.payload;
      return {
        ...state,
        data: terminalsListData
      }
    },
    addTerminalData: (state, action) => {
      const terminalsListData = [...state.data];
      terminalsListData.push(action.payload);
      return {
        ...state,
        data: terminalsListData
      }
    },
  },
});

export const {
  setTerminalsListData,
  setTerminalData,
  addTerminalData,
} = terminalReducer.actions;

export default terminalReducer.reducer;