import React from 'react'
import { formatCurrency } from '../../utils/formatUtils';
function QuoteDetailFooter({
  updateNoteStatus,
  note,
  editable,
  subtotal,
  discount,
  total,
  tax,
  printMode
}) {
  return (
    <div className="row quote-footer">
      <div className={`col-12 ${(printMode) ? 'd-none' : ''}`}>
        <p className='mb-0 fw-bold non-printable'>Notes:</p>
      </div>
      <div className="col-6">
        {editable ?
          <div className={`form-group non-printable ${(!editable || printMode) ? 'd-none' : ''}`}>
            <textarea className="form-control" id="notes" rows="5" onBlur={updateNoteStatus} defaultValue={note} disabled={!editable}></textarea>
          </div>
          :
          <div className='notes-container'>
            {note || '-'}
          </div>
        }

      </div>
      <div className="col-6 d-flex justify-content-end">
        <div className='row text-end'>
          <div className="col-12">
            <div className="row">
              <div className='col-6'>
                <p className='mb-2 fw-bold label'>Subtotal:</p>
              </div>
              <div className='col-6 justify-content-end d-flex'>
                <p className="mb-2 content label">{formatCurrency(subtotal)}</p>
              </div>
            </div>

            <div className="row">
              <div className='col-6'>
                <p className='mb-2 fw-bold label'>Discount:</p>
              </div>
              <div className='col-6 justify-content-end d-flex'>
                <p className="mb-2 content label">{formatCurrency(discount)}</p>
              </div>
            </div>

            <div className="row">
              <div className='col-6'>
                <p className='mb-3 fw-bold label'>Tax:</p>
              </div>
              <div className='col-6 justify-content-end d-flex'>
                <p className="mb-3 content label">{formatCurrency(tax)}</p>
              </div>
            </div>
            
            <div className="row">
              <div className='col-6'>
                <p className='mb-0 fw-bold total'>Total:</p>
              </div>
              <div className='col-6 justify-content-end d-flex'>
                <p className="mb-0 total">{formatCurrency(total)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(printMode) && (
        <div className='col-12'>
          <p className='mb-0 fw-bold'>Notes:</p>
          <div className='notes-container'>
            {note || '-'}
          </div>
        </div>
      )}
    </div>
  )
}

export default QuoteDetailFooter