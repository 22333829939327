import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedCustomer = {},
  control,
  register,
  errors,
  setValue,
  reset,
  provinceStateRef
}) {

  return (
    <>
      <div className="col-12 col-sm-12 mb-2">
        <label htmlFor="notes" className="form-label">Customer Notes</label>
        <textarea type="text" className="form-control" id="notes" rows="5" tabIndex={13}
          defaultValue={isFormEdit ? selectedCustomer?.notes || '' : ''}
          {...register("notes")}
        />
        <small className='form-error-message'>
          {errors?.notes && errors.notes.message}
        </small>
      </div>
    </>
  );
}

export default PrimaryDetails;
