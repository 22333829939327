import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";
import { TimePicker } from 'antd';
import * as dayjs from 'dayjs';

function PrimaryDetails({
    isFormEdit,
    isFormHidden,
    setIsFormDirty,
    selectedQuote = {},
    control,
    register,
    errors,
    setValue,
    reset,
    provinceStateRef,
    customerList
}) {
    const userType = useSelector((state) => state.auth.user.userType);
    const [customerId, setCustomerId] = useState(selectedQuote['customerId'])

    useEffect(() => {
        if (isFormEdit) {
          setCustomerId(parseInt(selectedQuote['customerId']));
          setValue("customerId", parseInt(selectedQuote['customerId']));
          setValue("active", selectedQuote['active']);
        } else {
          setCustomerId("");
          reset();
        }
      }, [selectedQuote["quoteId"]])

      const getActiveDefaultValue = () => {
        // if (selectedQuote.length <= 0) {
        //   return 'true';
        // }
    
        // return selectedQuote.active ? 'true' : '';
      }

    return (
        <>
            <div className="col-6 mb-2">
                <label htmlFor="customerId" className="form-label">Customer <span className="required-asterisk">*</span></label>
                <select className="form-select" id="customerId"
                    defaultValue={isFormEdit ? selectedQuote?.customerId || '' : ''}
                    {...register("customerId", { valueAsNumber: true, onChange: (e) => setCustomerId(e.target.value), required: "Customer is required" })}>
                    <option key="none" value="" hidden>Select Customer</option>
                    {customerList.map((customer, index) =>
                        <option key={index} value={customer.customerId}>{customer.customerName}</option>
                    )}
                </select>
                <small className='form-error-message'>
                    {errors?.customerId && errors.customerId.message}
                </small>
            </div>
            <div className="col-6 mb-2">
                <label htmlFor="quoteName" className="form-label">Quote Name <span className="required-asterisk">*</span></label>
                <input type="text" className="form-control" id="quoteName"
                    defaultValue={isFormEdit ? selectedQuote?.quoteName || '' : ''}
                    {...register("quoteName", { required: "Quote Name is required" })}
                />
                <small className='form-error-message'>
                    {errors?.quoteName && errors.quoteName.message}
                </small>
            </div>
            {
                (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
                <div className="col-12 mb-2 mt-3">
                    <div className="form-check">
                        <input className="form-check-input align-middle" type="checkbox" value="" id="active"
                            defaultChecked={getActiveDefaultValue}
                            {...register("active")}
                        />
                        <label className="form-check-label align-middle " htmlFor="active"> Active</label>
                        <small className='form-error-message'>
                            {errors?.active && errors.active.message}
                        </small>
                    </div>
                </div>
            }
        </>
    );
}

export default PrimaryDetails;
