import React, { useEffect, useState, useRef } from 'react'
import { useForm, useFormState, useWatch, Controller } from 'react-hook-form'
import { addAccount, updateAccount } from '../../services/accountServices';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { isSuperAdmin, isAccountAdmin, isAccountUser, isNTMSUSer } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { PatternFormat } from 'react-number-format';
import { removePhoneFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { addAccountData, setAccountData } from '../../redux/account/accountReducer';
import PrimaryDetails from './PrimaryDetails';
import NoteDetails from './NoteDetails';
import { selectStyle } from '../../utils/styleUtils';
import Select from 'react-select';
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";
import FormTabs from '../Commons/Layouts/FormTabs';

function AccountForm({
  isFormEdit = false,
  selectedAccount = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
  openOrCloseButtonRef
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    formState,
  } = useForm({});
  const { dirtyFields, errors, isDirty } = formState;
  const [provinceState, setProvinceState] = useState("")
  const [country, setCountry] = useState("")
  const [defaultTax, setDefaultTax] = useState("")
  const userType = useSelector((state) => state.auth.user.userType);
  const provinceStateOptions = PROVINCE_STATE;

  const provinceStateRef = useRef("null");
  const isInitialRender = useRef(true);

  // form values
  setValue('accountId', 0);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (isFormHidden) {
      setValue("phone", "")
    }
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("accountName");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  const getActiveDefaultValue = () => {
    if (selectedAccount.length <= 0) {
      return 'true';
    }

    return selectedAccount.active ? 'true' : '';
  }

  const onSubmit = async (payload) => {
    let response = null;

    _.set(payload, 'phone', removePhoneFormat(payload.phone));
    _.set(payload, 'fax', removePhoneFormat(payload.fax));
    _.set(payload, 'cell', removePhoneFormat(payload.cell));
    _.set(payload, 'provinceState', payload.provinceState.value)

    if (payload.phone === "") {
      setError("phone", { message: "Phone is required" });
      return;
    }

    if (isFormEdit) {
      _.set(payload, 'accountId', selectedAccount.accountId);

      response = await updateAccount(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${
            response.ok ? "Successfully saved" : "Failed to save"
          } account.`,
        })
      );
    } else {
      _.set(payload, 'accountId', 0);
      response = await addAccount(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${
            response.ok ? "Successfully saved" : "Failed to save"
          } account.`,
        })
      );
    }

    if (response.ok) {
      const accountData = await response.json();
      dispatch(isFormEdit ? setAccountData(accountData) : addAccountData(accountData));
      if (!isAccountUser(userType) && !isAccountAdmin(userType)) {
        toggleFormDisplay();
        reset();
        focusOnOpenOrCloseButton()
      }
      else {
        setFocus("accountName");
      }

      setDefaultTax("");
      setCountry("");
      provinceStateRef.current.clearValue();

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);
      dispatch(setFormPendingCompletion(false));
    }
  }

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-1-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedAccount={selectedAccount}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              provinceStateRef={provinceStateRef}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Notes Details",
      key: 1,
      forceRender: true,
      children: (
        <div className="content-section-container color-1-section">
          <div className="row form-container" id="address-details-form">
            <NoteDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedAccount={selectedAccount}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              provinceStateRef={provinceStateRef}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* <div className="row">
        <div className="col-12">
          <div className="form-title mb-4">{`${isFormEdit ? 'Update' : 'New'} Account`}</div>
        </div>
      </div> */}

      <form id="account-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs 
          items={tabItems} 
          tabClassName="color-1" 
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>


    </>
  )
}

export default AccountForm
