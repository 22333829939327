import React, { useEffect, useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { selectStyle } from '../../../utils/styleUtils';
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select';
import { useDebouncedCallback } from "use-debounce";
import _ from 'lodash';
import { setNotificationData } from '../../../redux/global/globalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { COUNTRY, PROVINCE_STATE } from '../../../constants';
import { fetchSuggestions } from '../../../services/mapServices';

function TerminalModal({
  modal,
  setModal,
  toggle,
  terminalOptions,
  args
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, setError, clearErrors, reset, setFocus, getValues, control, formState: { errors, isDirty } } = useForm();
  const terminalState = useSelector((state) => state.terminal);

  const [userInput, setUserInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [formattedSuggestions, setFormattedSuggestions] = useState([]);
  const [terminalAddress, setTerminalAddress] = useState({});
  const [customAddress, setCustomAddress] = useState({});
  const [selectedOption, setSelectedOption] = useState("selectTerminalAddress");

  useEffect(() => {
    const fetchAsyncSuggestions = async () => {
      if (userInput.length > 2) {
        const results = await fetchSuggestions(userInput);
        setSuggestions(results);

        const formattedSuggestions = results.map((suggestion) => ({
          value: suggestion?.properties?.place_id,
          label: suggestion?.properties?.formatted,
        }));

        setFormattedSuggestions(formattedSuggestions);
      }
    };

    fetchAsyncSuggestions();
  }, [userInput]);

  useEffect(() => {
    handleRadioChange('selectCustomAddress', false);
  }, [customAddress]);
  
  useEffect(() => {
    handleRadioChange('selectTerminalAddress', false);
  }, [terminalAddress]);

  const handleChange = useDebouncedCallback((value) => {
    setUserInput(value);
  }, 1000);

  const handleSelect = (selected) => {
    if (_.isNull(selected)) {
      setValue("addressDetails", "");
      setCustomAddress({});
      return;
    }

    const selectedSuggestion = suggestions.find(
      (suggestion) => suggestion?.properties?.place_id === selected?.value
    );

    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const provinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const country = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (provinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    const formattedAddress = `${selectedSuggestion?.properties?.address_line1}\n${selectedSuggestion?.properties?.city}, ${provinceState.abbreviation}\n${selectedSuggestion?.properties?.postcode}\n${country.label}`;

    setCustomAddress({
      address1: selectedSuggestion?.properties?.address_line1,
      address2: selectedSuggestion?.properties?.address_line2,
      city: selectedSuggestion?.properties?.city,
      provinceState: provinceState.abbreviation,
      postalCode: selectedSuggestion?.properties?.postcode,
      country: selectedSuggestion?.properties?.country,
      formattedAddress: formattedAddress
    });
  };

  const onTerminalSelectChange = (e) => {
    if (_.isNull(e)) {
      setValue("addressDetails", "");
      setTerminalAddress({});
      return;
    }

    const selectedTerminal = _.find(terminalState.data, { terminalId: e.value });

    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const provinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.value === selectedTerminal?.provinceState;
      }
    );

    const country = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (provinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    const formattedAddress = `${selectedTerminal.address1}\n${selectedTerminal.city}, ${provinceState.abbreviation}\n${selectedTerminal.postalCode}\n${country.label}`

    setTerminalAddress({
      address1: selectedTerminal?.address1,
      address2: selectedTerminal?.address2,
      city: selectedTerminal?.city,
      provinceState: provinceState.abbreviation,
      postalCode: selectedTerminal?.postalCode,
      country: country?.label,
      formattedAddress: formattedAddress
    });
  }

  const openFormAndCloseModal = () => {
    reset();
    setValue("addressDetails", "");
    toggle();
  }

  const handleRadioChange = (selectedOption, updateOption = true) => {
    if (updateOption) {
      setSelectedOption(selectedOption);
    }

    switch (selectedOption) {
      case "selectTerminalAddress":
        setValue("addressDetails", terminalAddress?.formattedAddress)
        break;
      case "selectCustomAddress":
        setValue("addressDetails", customAddress?.formattedAddress)
        break;
      default:
        break;
    }
  }

  const onSubmit = async (payload) => {
    if (payload.addressDetails === "") {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: `Please select an address.`,
        })
      );
    } else {
      dispatch(
        setNotificationData({
          type: `success`,
          message: `Success!`,
          description: `WIP: Success message here.`,
        })
      );
    }
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Select Terminal</ModalHeader>
        <ModalBody>
          <form className="row form-container justify-content-center align-items-center" id="contact-form" >
            {/* <div className="col-6 col-sm-4 mb-2"></div> */}
            <div className="col-12 mb-2">
              <div className="d-flex flex-row align-items-center">
                <div className="me-1">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radioGroup"
                      id="radioButtonTerminalAddress"
                      value="selectTerminalAddress"
                      onChange={(e) => handleRadioChange(e.target.value)}
                      checked={selectedOption === "selectTerminalAddress"}
                      tabIndex={5}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="radioButtonTerminalAddress"
                    >
                      Terminal
                    </label>

                  </div>
                </div>
                <div className="ms-1 w-100">
                  <div className="custom-selector">
                    <Controller
                      name="terminalId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={(e) => { field.onChange(e); onTerminalSelectChange(e) }}
                          options={terminalOptions}
                          className="company-select"
                          classNamePrefix="react-select"
                          placeholder={'Search Terminal'}
                          id="customer"
                          styles={selectStyle}
                          isClearable
                          tabIndex={11}
                          isDisabled={selectedOption === "selectCustomAddress"}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="d-flex flex-row align-items-center">
                <div className="me-1">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radioGroup"
                      id="radioButtonCustomeAddress"
                      value="selectCustomAddress"
                      checked={selectedOption === "selectCustomAddress"}
                      onChange={(e) => handleRadioChange(e.target.value)}
                      tabIndex={5}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="radioButtonCustomeAddress"
                    >
                      Address
                    </label>
                  </div>
                </div>
                <div className="ms-2 w-100">
                  <div className="custom-selector">
                    <Controller
                      name="searchAddress"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={formattedSuggestions}
                          classNamePrefix="react-select"
                          placeholder={"Search Address"}
                          id="searchAddress"
                          styles={selectStyle}
                          isClearable
                          tabIndex={15}
                          onInputChange={handleChange}
                          onChange={handleSelect}
                          filterOption={() => true} // always show options
                          isDisabled={selectedOption === "selectTerminalAddress"}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <form className='form-container d-flex flex-row align-items-end justify-content-end' onSubmit={handleSubmit(onSubmit)}>
            <div className="me-2 col-12">
              <label htmlFor="addressDetails" className="form-label">
                Address Details
              </label>
              <textarea
                className="form-control"
                id="addressDetails"
                rows="4"
                maxLength={1000}
                tabIndex={15}
                readOnly
                {...register("addressDetails")}
              />
            </div>

            <Button className={'me-2'} color="primary" onClick={openFormAndCloseModal}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              OK
            </Button>
          </form>
        </ModalFooter>
      </Modal>
    </>

  )
}

export default TerminalModal