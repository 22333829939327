import { isSuperAdmin, isNTMSUSer } from "./roleUtils";

//
/*
 * Gets the id based on the user type
 * payloadAccountId: account ID from the dropdown
 * selectedAccountId: account ID of the masqueraded account
 * accountId: accountID for logged in user
 */ 
export const getAccountIdForEmployee = (payloadAccountId, selectedAccountId, accountId, userType) =>
{
  if(isSuperAdmin(userType))
  {
    return payloadAccountId.value;
  }
  else if(isNTMSUSer(userType))
  {
    return selectedAccountId === null ? accountId : selectedAccountId
  }
  else 
  {
    return accountId
  }
}