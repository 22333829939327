import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { THEME_COLORS } from '../../../constants';

const data = [
  {
    name: '5 Jan',
    "value": Math.floor(Math.random() * 30),
  },
  {
    name: '10 Jan',
    value: Math.floor(Math.random() * 30),
  },
  {
    name: '15 jan',
    value: Math.floor(Math.random() * 30),
  },
  {
    name: '20 Jan',
    value: Math.floor(Math.random() * 30),
  },
  {
    name: '25 Jan',
    value: Math.floor(Math.random() * 30),
  },
  {
    name: '30 Jan',
    value: Math.floor(Math.random() * 30),
  },
];


function Sales() {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" style={{ fontSize: 12 }} />
        <YAxis style={{ fontSize: 12 }} />
        <Tooltip />
        <Area type="monotone" dataKey="value" stroke={THEME_COLORS.COLOR_1} fill={THEME_COLORS.COLOR_1} fillOpacity={0.2} name="Closed Deals" />
        <Legend
          align='center'
          iconType='rect'
          wrapperStyle={{ fontSize: '14px', lineHeight: '24px', }}
          formatter={(value, entry, index) => <span style={{color:"black"}}>{value}</span>}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default Sales
