import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { deletePayrollRun } from '../../../../services/payrollRunServices';
import { DeleteOutlined } from '@ant-design/icons';
import { setNotificationData } from '../../../../redux/global/globalReducer';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from '../../../Commons/Modals/ConfirmationModal';

function EditPayrollRenderer(props, args) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const removeSelected = useCallback(async () => {
    const selectedNode = props.gridRef.current.api.getSelectedNodes();
    const response = await deletePayrollRun(selectedNode[0].data["id"])

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully deleted" : "Failed to delete"
          } payroll run.`,
      })
    );
    if (response.ok) {
      props.fillPayrollRunList();
      setModal(false);
    }
  }, [props.rowData]);

  return (
    <div className='cell-renderer-content'>
      {/* add state when table is set up */}
      <Link to="/payroll-runs/edit-payroll-run" style={{ textDecoration: 'none' }} state={{ payrollRunId: props.data.id, status: props.data.Status }}>
        <button className={`btn btn-color-4 ${props.data['Status'] === true ? "btn-sm" : "btn-edit"}`}>
          <i className={`fa-solid ${props.data['Status'] === true ? 'fa-eye' : 'fa-pen'}`}></i>{props.data['Status'] === false && "    Edit"}
        </button>
      </Link>
      {(props.data['Status'] === false) && <button onClick={toggle} className={`btn btn-color-delete btn-sm`}><DeleteOutlined /></button>}
      <ConfirmationModal
        isOpen={modal}
        toggle={toggle}
        onClick={removeSelected}
        headerText="Delete Payroll Run"
        bodyText="Are you sure you want to delete this item?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        actionType="delete"
      />
    </div>
  )
}

export default EditPayrollRenderer