import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ConfirmationModal } from '../../../Commons/Modals/ConfirmationModal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { deleteTMSQuote, updateTMSQuoteStatus, updateOrd } from '../../../../services/tmsQuoteServices';
import { deleteOrderTMSQuoteData, updateOrderDataStatus, updateOrderTMSQuoteDataStatus } from '../../../../redux/order/orderReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationData } from '../../../../redux/global/globalReducer';
import { DeleteOutlined, LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import { DefaultModal } from '../../../Commons/Modals/DefaultModal';

function QuoteActionRenderer({
  api,
  data,
  toggleQuoteModalDisplay,
  checkPageEditable,
  setSelectedOrderStatus,
},
  args) {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const [approveModal, setApproveModal] = useState(false);
  const toggleApproveModal = () => {
    if (formPendingCompletion) {
      setFormChangesModal(!formChangesModal);
    } else {
      setApproveModal(!approveModal);
    }
  };

  const [unapproveModal, setUnapproveModal] = useState(false);
  const toggleUnapproveModal = () => setUnapproveModal(!unapproveModal);

  const [formChangesModal, setFormChangesModal] = useState(false);
  const toggleFormChangesModal = () => setFormChangesModal(!formChangesModal);

  const formPendingCompletion = useSelector(
    (state) => state.global.formPendingCompletion
  );

  const handleDeleteItem = async () => {
    const orderId = data?.OrderId;
    const itemId = data?.Id;
    const response = await deleteTMSQuote(itemId)

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully deleted" : "Failed to delete"
          } Quote.`,
      })
    );

    if (response.ok) {
      dispatch(deleteOrderTMSQuoteData({ orderId, itemId }));
      api.applyTransaction({ remove: [data] });
    }
  };

  const updateQuoteStatus = async (status) => {
    let response = null;
    // var quoteDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');

    const payload = {
      status: status,
      accountId: data.AccountId,
      tmsQuoteId: data.QuoteId,
      orderId: data.OrderId
    }
    response = await updateTMSQuoteStatus(payload, data.QuoteId);
    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully update" : "Failed to update"
          } quote status.`,
      })
    );

    if (response.ok) {
      dispatch(updateOrderTMSQuoteDataStatus({ orderId: data.OrderId, tmsQuoteId: data.QuoteId, newStatus: status }));
      dispatch(updateOrderDataStatus({ orderId: data.OrderId, newStatus: status === 0 ? 1 : 2 }));
      setApproveModal(false);
      setUnapproveModal(false);
      setSelectedOrderStatus(status === 0 ? 1 : 2)
    }
  }

  const toggleEditModal = () => {
    toggleQuoteModalDisplay(false, data?.QuoteId);
  }

  return (
    <div className='cell-renderer-content d-flex flex-row'>
      <button
        onClick={toggleEditModal}
        id="update-quote"
        type='button'
        className={`btn btn-color-3 ${checkPageEditable ? 'btn-sm' : 'btn-edit'} ${data?.Status === 0 ? '' : ''}`}>
        <i className={`fa-solid ${checkPageEditable ? 'fa-eye' : 'fa-pen'}`}></i>
        {!checkPageEditable && <span>    Edit</span>}
      </button>
      {!checkPageEditable && <button onClick={toggleDeleteModal} id="delete-quote" type='button' className={`btn btn-color-delete btn-sm`}><DeleteOutlined /></button>}
      {!checkPageEditable && <button onClick={toggleApproveModal} id="approve-quote" type='button' className={`btn btn-color-1 btn-sm`}><LikeOutlined /></button>}
      {(checkPageEditable && data?.Status === 1) && <button onClick={toggleUnapproveModal} id="unapprove-quote" type='button' className={`btn btn-color-unapprove btn-sm`}><DislikeOutlined /></button>}
      <ConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onClick={handleDeleteItem}
        headerText="Delete Quote"
        bodyText="Are you sure you want to delete this item?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="danger"
        actionType={"delete"}
      />

      <ConfirmationModal
        isOpen={approveModal}
        toggle={toggleApproveModal}
        onClick={() => updateQuoteStatus(1)}
        headerText="Approve Quote"
        bodyText="Are you sure you want to approve this item?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="primary"
        actionType={"progress"}
      />

      <ConfirmationModal
        isOpen={unapproveModal}
        toggle={toggleUnapproveModal}
        onClick={() => updateQuoteStatus(0)}
        headerText="Un-Approve Quote"
        bodyText="Are you sure you want to un-approve this item?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="primary"
        actionType={"warning"}
      />

      <DefaultModal
        isOpen={formChangesModal}
        toggle={toggleFormChangesModal}
        onClick={() => updateQuoteStatus(1)}
        headerText="Approve Quote"
        bodyText="You have unsaved changes. Please review and save the changes before approving a quote."
        cancelButtonText="Close"
        actionType="warning"
      />
    </div>
  )
}

export default QuoteActionRenderer