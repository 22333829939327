import { InfoCircleFilled, CheckCircleFilled, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { Modal, Space } from 'antd';
import React from 'react';

export const ShowImageModal = ({
  isOpen,
  toggle,
  onClick,
  headerText,
  actionType,
  previewImage,
  confirmButtonText
}) => {
  let iconComponent;
  if (actionType === 'progress') {
    iconComponent = <CheckCircleFilled />;
  } else if (actionType === 'delete') {
    iconComponent = <ExclamationCircleFilled />;
  } else if (actionType === 'warning') {
    iconComponent = <QuestionCircleFilled />;
  } else if (actionType === 'primary') {
    iconComponent = <InfoCircleFilled/>
  }

  return (
    <Modal
      open={isOpen}
      className={`ant-modal-${actionType}`}
      title={
        <Space>
          {iconComponent}
          {headerText}
        </Space>
      }
      onOk={toggle}
      onCancel={toggle}
      footer={
        <div className='d-flex justify-content-end'>
          <button className={`btn btn-modal-${actionType} `} onClick={toggle}>
            {confirmButtonText}
          </button>
        </div>
      }
      // onCancel={toggle}
    >
      <img
        alt="example"
        style={{
          width: '100%',
        }}
        src={previewImage}
      />
    </Modal>
  );
};
