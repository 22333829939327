import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import ModalRender from '../Commons/ModalRender';
import ReactDatePicker from "react-datepicker";
import { addPayment } from '../../services/paymentServices';
import { PAYMENT_TYPES } from '../../constants';
import { NumericFormat } from 'react-number-format';
import { removeNumberFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { isNTMSUSer } from '../../utils/roleUtils';
import OverpayModal from '../Commons/Modals/OverpayModal';
import { useState } from 'react';
import { fetchGLCodesByUserType, fetchGLCodesFiltered } from '../../services/glCodeServices';
import { getGLCodefromPaymentType } from '../../utils/glCodeUtils';
import { setNotificationData } from '../../redux/global/globalReducer';
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';


function ExpensePaymentsForm({
  isFormEdit = false,
  selectedExpense = {},
  isFormHidden,
  fillPaymentList,
  fillExpenseList,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, clearErrors, reset, getValues, setFocus, control, formState: { errors, isDirty } } = useForm({
    defaultValues: {
      paymentDate: new Date()
    }
  });
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);

  const [gLCode, setGLCode] = useState("");
  const [gLCodeList, setGLCodeList] = useState([]);

  const [paymentData, setPaymentData] = useState({});
  const [overpayModal, setOverpayModal] = useState(false);
  const toggleOverpayModal = () => setOverpayModal(!overpayModal);
  const maxDate = moment().endOf('day');

  // form values
  setValue('customerId', 0);
  if (isNTMSUSer(userType)) {
    setValue('accountId', accountState.accountId != null ? accountState.accountId : accountId);
  } else {
    setValue('accountId', accountId);
  }
  setValue('expenseId', selectedExpense.expenseId);
  setValue('quoteId', selectedExpense.quoteId);
  setValue('customerId', selectedExpense.customerId);

  // useEffects
  useEffect(() => {
    clearErrors();
    fillGLCode();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
  }, [isFormHidden]);

  useEffect(() => {
    setIsFormDirty(isDirty)
  }, [isDirty])

  useEffect(() => {
    setFocus("paymentDate");
  }, [selectedExpense.expenseId])

  const onSubmit = async (payload) => {
    setPaymentData(payload);
    const paymentAmount = removeNumberFormat(payload.paymentAmount)
    const balance = _.get(selectedExpense, 'balance', 0)

    if (paymentAmount > balance) {
      setOverpayModal(true);
      return;
    }

    submitPayment(false, payload);
  }

  const submitPayment = async (overpaying = true, payload = {}) => {
    const data = !overpaying ? payload : paymentData;
    _.set(data, 'paymentId', 0);
    _.set(data, 'invoiceId', null);
    _.set(data, 'quoteId', null);
    _.set(data, 'customerId', null);
    _.set(data, 'paymentAmount', removeNumberFormat(data.paymentAmount));
    _.set(data, 'InternalReferenceId', 0);
    _.set(data, 'ExternalReferenceId', data.paymentReferenceId);
    _.set(data, 'PaymentAllocationType', 2);
    var paymentDate = dayjs(data.paymentDate).format('YYYY-MM-DD');
    _.set(data, 'paymentDate', paymentDate);

    const response = await addPayment(data);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } payment.`,
      })
    );

    fillPaymentList(selectedExpense.expenseId);
    fillExpenseList();

    if (isDirty) {
      setModal(false)
    }
    if (response.ok) {
      setIsFormDirty(false);
      reset();
      setGLCode(undefined);
    }
  }

  const fillGLCode = async (id) => {
    const accountTypeId = parseInt(process.env.REACT_APP_ACCOUNT_TYPE_COST_OF_GOODS_SOLD);
    const response = await fetchGLCodesFiltered();

    setGLCodeList(response);
  }

  const setGLCodeValue = () => {
    const { paymentType } = getValues();
    var glCode = getGLCodefromPaymentType(paymentType, gLCodeList);
    setValue("glCodeId", glCode.glCodeId)
    setGLCode(glCode.glCodeId)
  }

  return (
    <>
      <form className="row form-container" id="payments-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 col-sm-6 mb-2">
          <label htmlFor="paymentDate" className="form-label">Payment Date</label>
          <Controller
            control={control}
            name='paymentDate'
            register={register}
            rules={{ required: "Payment date is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker className="datepicker-field" onChange={onChange} disabledDate={(current) => current && current > maxDate} />
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.payStartDate && errors.payroll?.payStartDate.message}
          </small>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <label htmlFor="paymentType" className="form-label">Mode of Payment <span className="required-asterisk">*</span></label>
          <select className="form-select" id="paymentType"
            {...register("paymentType", { valueAsNumber: true, required: "Mode of Payment is required", onChange: (e) => { setGLCodeValue(e) } })}>
            <option key="none" value="" hidden>Select Mode</option>
            {PAYMENT_TYPES.map((paymentType, index) =>
              <option key={index} value={paymentType.value}>{paymentType.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.paymentType && errors.paymentType.message}
          </small>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <label htmlFor="paymentAmount" className="form-label">Amount <span className="required-asterisk">*</span></label>
          <Controller
            control={control}
            name='paymentAmount'
            register={register}
            rules={{ required: "Quantity is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat
                name={name}
                value={value === undefined ? '' : value}
                onChange={onChange}
                onBlur={onBlur}
                className='form-control'
                prefix="$"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                id="paymentAmount"
                mask="_"
                decimalScale={2}
                fixedDecimalScale
              />
            )}
          />
          <small className='form-error-message'>
            {errors?.paymentAmount && errors.paymentAmount.message}
          </small>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <label htmlFor="paymentReferenceId" className="form-label">Payment Reference ID</label>
          <input type="text" className="form-control" id="paymentReferenceId" {...register("paymentReferenceId")} />
          <small className='form-error-message'>
            {errors?.paymentReferenceId && errors.paymentReferenceId.message}
          </small>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <label htmlFor="glCodeId" className="form-label">GL Code: <span className="required-asterisk">*</span></label>
          <select className="form-control" id="glCodeId" value={gLCode} disabled={isFormEdit} {...register("glCodeId", { valueAsNumber: true, onChange: (e) => setGLCode(e.target.value), required: "GL code is required" })}>
            <option key="none" value="" hidden>GL Code</option>
            {gLCodeList.map((gl, index) =>
              <option key={index} value={gl.glCodeId}>{gl.glCodeName} - {gl.description}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.glCodeId && errors.glCodeId.message}
          </small>
        </div>
        <div className="col-12 col-sm-12 mb-2">
          <label htmlFor="paymentDescription" className="form-label">Description</label>
          <textarea className="form-control" id="paymentDescription" rows="3" {...register("paymentDescription")} />
          <small className='form-error-message'>
            {errors?.paymentDescription && errors.paymentDescription.message}
          </small>
        </div>
      </form>
      <div className='d-flex flex-row-reverse align-items-center'>
        <button className="btn btn-primary" form='payments-form' type="submit" disabled={_.isEmpty(selectedExpense)}>Save</button>
        <button className="btn btn-secondary me-2" form='payments-form' type="submit">Clear</button>
      </div>
      <ModalRender modal={modal} handleSubmit={handleSubmit} onSubmit={onSubmit} setModal={setModal} toggle={toggle} isFormEdit={isFormEdit} formType={"payments"} toggleFormDisplay={toggleFormDisplay} />
      <OverpayModal modal={overpayModal} setModal={setOverpayModal} toggle={toggleOverpayModal} submitPayment={submitPayment} />
    </>
  )
}

export default ExpensePaymentsForm
