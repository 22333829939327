import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import { isNTMSUSer } from '../../utils/roleUtils';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from "react-datepicker";
import { addAccountingSettings } from '../../services/accountingSettingsServices';
import moment from 'moment';
import { setAccountingSettingsData } from '../../redux/settings/settingsReducer';
import FormTabs from '../Commons/Layouts/FormTabs';
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";

function AccountingSettingsForm({
  isFormHidden
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const settingsState = useSelector((state) => state.settings);
  const [accountingSettings, setAccountingSettings] = useState();
  const userType = useSelector((state) => state.auth.user.userType);
  const [fiscalDateCheck, setFiscalDateCheck] = useState(false);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const isInitialRender = useRef(true);

  const formPendingCompletion = useSelector(
    (state) => state.global.formPendingCompletion
  );

  useEffect(() => {
    if (accountingSettings !== undefined) {
      setValue("fiscalStartDate", moment(accountingSettings.fiscalStartDate).clone().hour(7).minute(0).second(0).toDate());
    }
  }, [accountingSettings])

  // useEffects
  useEffect(() => {
    clearErrors();
    setFocus("fiscalStartDate");
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
    } else {
      dispatch(setFormPendingCompletion(false));
    }

  }, [formState])

  useEffect(() => {
    setAccountingSettings(settingsState.accountingSettings);
    if (!_.isEmpty(settingsState.accountingSettings)) {
      setFiscalDateCheck(true);
    }
  }, [settingsState.accountingSettings]);

  const onSubmit = async (payload) => {
    let response = null;
    var fiscalStartDate = moment(payload.fiscalStartDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'fiscalStartDate', fiscalStartDate);
    _.set(payload, 'accountingSettingsId', 0);
    if (isNTMSUSer(userType)) {
      _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
    } else {
      _.set(payload, 'accountId', accountId);
    }
    response = await addAccountingSettings(payload);
   
    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${
          response.ok ? "Successfully saved" : "Failed to save"
        } accounting settings.`,
      })
    );

    if (response.ok) {
      dispatch(setAccountingSettingsData(await response.json()));
      reset({}, { keepValues: true });
      dispatch(setFormPendingCompletion(false));
    }
  }

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-6-section">
          <div className="row form-container" id="primary-details-form">
            <div className="col-6 col-sm-4 mb-2">
              <label htmlFor="fiscalStartDate" className="form-label">Fiscal Start Date:</label>
              <Controller
                control={control}
                name='fiscalStartDate'
                register={register}
                rules={{ required: "Fiscal start date is required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <ReactDatePicker
                    className='datepicker-field'
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    id="fiscalStartDate"
                    dateFormat='yyyy-MM-dd'
                    disabled={fiscalDateCheck}
                    ref={(elem) => {
                      elem && ref(elem.input);
                    }}
                  />
                )}
              />
              <small className='form-error-message'>
                {errors?.fiscalStartDate && errors.fiscalStartDate.message}
              </small>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <form id="account-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs
          items={tabItems}
          tabClassName="color-6"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit" disabled={!_.isEmpty(accountingSettings)}>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AccountingSettingsForm
