import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { DatePicker } from 'antd';
import {
  PROVINCE,
  PROVINCE_STATE,
  PAY_FREQUENCY,
  VACATION_TYPE,
  PAY_METHOD_TYPE,
  TD1_PROVINCE,
  TD2_FEDERAL,
  TD1_PROVINCE_QC,
  EMPLOYEE_TYPE,
  EMPLOYEE_LEVEL,
  GENDER,
  MARITAL_STATUS,
  PREFERRED_LANGUAGE,
  EMERGENCY_CONTACT_RELATIONSHIP,
  COUNTRY
} from '../../constants';
import { isSuperAdmin, isAccountAdmin, isAccountUser } from "../../utils/roleUtils";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedUser = {},
  control,
  register,
  errors,
  setValue,
  reset,
  setProvinceState,
  setGender,
  setPreferredLanguage,
  setMaritalStatus,
  setEmployeeType,
  setEmployeeLevel,
  setEmergencyContactRelationship,
  setUserTypeValue,
  setTD1Province,
  setTD2Federal,
  setGLCode,
  setPayFrequency,
  setVacationType,
  setPayMethodType,
  setDefaultFormPayrollValue,
  setIsPayrollFormHidden,
  unregister,
  payrollSettings,
  isPayrollFormHidden,
  provinceState,
  userType,
  accountIdRef,
  options,
  userTypeValue,
  userTypeList,
  gender,
  maritalStatus,
  preferredLanguage,
  employeeType,
  employeeLevel,
  emergencyContactRelationship,
  provinceStateRef,
  provinceStateOptions,
  tD1Province,
  tD2Federal,
  payFrequency,
  vacationType,
  vacationTypeWatch,
  payMethodType,
  gLCode,
  gLCodeList
}) {

  const [accountIdValue, setAccountIdValue] = useState()
  const [payStartDate, setPayStartDate] = useState("");
  const [country, setCountry] = useState("");
  const [employeeStartDate, setEmployeeStartDate] = useState("");
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  useEffect(() => {
    if (isFormEdit) {
      setEmployeeEditData();
    } else {
      setNewEmployeeData();
    }
  }, [selectedUser["accountId"]])

const setEmployeeEditData = () => {
    const accId = {
      value: parseInt(selectedUser.account['accountId']),
      label: selectedUser.account['accountName']
    }

    const provinceOrState = PROVINCE_STATE.find((provinceState) => provinceState.value === selectedUser["provinceState"]);
    setProvinceState(provinceOrState);
    setGender(selectedUser["gender"]);
    setPreferredLanguage(selectedUser["preferredLanguage"]);
    setMaritalStatus(selectedUser["maritalStatus"]);
    setEmployeeType(selectedUser["employeeType"]);
    setEmployeeLevel(selectedUser["employeeLevel"]);
    setEmergencyContactRelationship(selectedUser["emergencyContactRelationship"]);
    setCountry(parseInt(selectedUser["country"]));
    setUserTypeValue(parseInt(selectedUser["userType"]));
    setEmployeeStartDate(dayjs(selectedUser['employeeStartDate']));
    setValue("gender", selectedUser["gender"]);
    setValue("preferredLanguage", selectedUser["preferredLanguage"]);
    setValue("maritalStatus", selectedUser["maritalStatus"]);
    setValue("employeeType", selectedUser["employeeType"]);
    setValue("employeeLevel", selectedUser["employeeLevel"]);
    setValue("emergencyContactRelationship", selectedUser["emergencyContactRelationship"]);
    setValue("provinceState", provinceOrState);
    setValue("country", selectedUser["country"]);
    setValue("userType", parseInt(selectedUser['userType']));
    setValue("phone", selectedUser['phone']);
    setValue("cell", selectedUser['cell']);
    setValue("emergencyContactNumber", selectedUser['emergencyContactNumber']);
    setValue("employeeStartDate", dayjs(selectedUser['employeeStartDate']));
    // Set payroll information if the employee is under payroll
    if (selectedUser.payroll != null) {
      setTD1Province(parseInt(selectedUser.payroll['tD1Province']));
      setTD2Federal(parseInt(selectedUser.payroll['tD2Federal']));
      setGLCode(parseInt(selectedUser.payroll['glCodeId']))
      setPayFrequency(parseInt(selectedUser.payroll['payFrequency']));
      setVacationType(parseInt(selectedUser.payroll['vacationType']));
      setPayMethodType(parseInt(selectedUser.payroll['payMethodType']));
      setPayStartDate(dayjs(selectedUser.payroll['payStartDate']));

      setValue("payroll.tD1Province", selectedUser.payroll['tD1Province']);
      setValue("payroll.tD2Federal", selectedUser.payroll['tD2Federal']);
      setValue("payroll.glCodeId", selectedUser.payroll['glCodeId']);
      setValue("payroll.payFrequency", selectedUser.payroll['payFrequency']);
      setValue("payroll.vacationType", selectedUser.payroll['vacationType']);
      setValue("payroll.payMethodType", selectedUser.payroll['payMethodType']);
      setValue("payroll.payPerUnit", selectedUser.payroll['payPerUnit']);
      setValue("payroll.overtimePaymentRate", selectedUser.payroll['overtimePaymentRate']);
      setValue("payroll.vacationAmount", selectedUser.payroll['vacationAmount']);
      setValue("payroll.payStartDate", dayjs(selectedUser.payroll['payStartDate']));
      if (selectedUser?.payroll?.payrollPaidContribution.length > 0) {
        setIsFieldDisabled(true);
        setPayStartDate(dayjs(selectedUser?.payroll?.payrollPaidContribution[0]['date']));
        setValue("payroll.payrollPaidContribution.previouslyPaidCPP", selectedUser?.payroll?.payrollPaidContribution[0]['previouslyPaidCPP']);
        setValue("payroll.payrollPaidContribution.previouslyPaidEI", selectedUser?.payroll?.payrollPaidContribution[0]['previouslyPaidEI']);
        setValue("payroll.payrollPaidContribution.date", dayjs(selectedUser?.payroll?.payrollPaidContribution[0]['date']));
      } else {
        setIsFieldDisabled(false);
        setValue("payroll.payrollPaidContribution.previouslyPaidCPP", 0);
        setValue("payroll.payrollPaidContribution.previouslyPaidEI", 0);
        setValue("payroll.payrollPaidContribution.date", dayjs());
        setDefaultFormPayrollValue();
      }
    } else {
      setDefaultFormPayrollValue()
    }
    setValue("active", selectedUser['active']);
    setValue("payrollEmployee", selectedUser['payrollEmployee']);
    setIsPayrollFormHidden(!selectedUser['payrollEmployee'])
    setValue("accountId", accId)
    setAccountIdValue(accId)
  }

  const setNewEmployeeData = () => {
    reset();
    setIsFieldDisabled(false);
    setValue("payrollEmployee", false);
    setIsPayrollFormHidden(true);
    if (payrollSettings != null) {
      setDefaultFormPayrollValue();
    }
    else {
      setValue("vacationAmount", "");
      setValue("phone", "");
      setValue("cell", "")
      setValue("emergencyContactNumber", "")
      setValue("payroll.payrollPaidContribution.previouslyPaidCPP", 0);
      setValue("payroll.payrollPaidContribution.previouslyPaidEI", 0);
    }
    setValue("payroll.payrollPaidContribution.date", dayjs())
  }

  const getActiveDefaultValue = () => {
    if (selectedUser.length <= 0) {
      return 'true';
    }

    return selectedUser.active ? 'true' : '';
  }

  const onPayrollEmployeeCheckboxChange = (event) => {
    setIsPayrollFormHidden(!isPayrollFormHidden);
    if (!isPayrollFormHidden === true) {
      unregister("payroll");
    }
  }

  useEffect(() => {
    if (!isPayrollFormHidden && !isFormEdit) {
      if (payrollSettings !== undefined || selectedUser.payroll === null) {
        setDefaultFormPayrollValue()
      } else {
        setTD1Province("");
        setTD2Federal("");
        setPayFrequency("");
        setVacationType("");
        setPayMethodType("");
        setGLCode("");
      }
    }

    if (!isPayrollFormHidden && payrollSettings === undefined && selectedUser.payroll === null) {
      setTD1Province(1);
      setTD2Federal(1);
      setValue("payroll.tD1Province", 1);
      setValue("payroll.tD2Federal", 1);
    }
  }, [isPayrollFormHidden])

  const getProvinceClaimCodes = () => {
    if (provinceState === 10 || provinceState === "10") {
      return TD1_PROVINCE_QC;
    }

    return TD1_PROVINCE;
  }

  const setCountryValue = (e) => {
    const provinceStateValue = e?.value;
    if (provinceStateValue != null) {
      const firstUSState = PROVINCE_STATE.find((provinceState) => provinceState.label === "Alabama").value;
      setValue("country", provinceStateValue >= firstUSState ? 2 : 1);
      setCountry(provinceStateValue >= firstUSState ? 2 : 1);
    }
  }

  return (
    <>
      {(isSuperAdmin(userType)) &&
        (<div className="col-6 col-sm-4 mb-2">
          <label htmlFor="accountId" className="form-label">Account</label>
          <div className="custom-selector">
            <Controller
              name="accountId"
              control={control}
              rules={{ required: "Account is required" }}
              render={({ field }) => (
                <Select
                  onChange={field.onChange}
                  value={field.value}
                  ref={accountIdRef}
                  options={options}
                  className="expense-select"
                  placeholder={'Enter account'}
                  defaultValue={accountIdValue}
                  id="accountId"
                  styles={selectStyle}
                  isClearable
                  tabIndex={1}
                />
              )}
            />
          </div>
          <small className='form-error-message'>
            {errors?.accountId && errors.accountId?.message}
          </small>
        </div>)
      }
      {(!isAccountUser(userType)) &&
        (<div className="col-6 col-sm-4 mb-2">
          <label htmlFor="userType" className="form-label">User Type: <span className="required-asterisk">*</span></label>
          <select className="form-select" id="userType" value={userTypeValue} tabIndex={2} {...register("userType", { valueAsNumber: true, onChange: (e) => setUserTypeValue(e.target.value), required: "User Type is required" })}>
            <option key="none" value="" hidden>User Type</option>
            {userTypeList.map((userType, index) =>
              <option key={userType.key} value={userType.key}>{userType.value}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.userType && errors.userType.message}
          </small>
        </div>)}

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="firstName" className="form-label">First Name: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="firstName" tabIndex={3}
          defaultValue={isFormEdit ? selectedUser?.firstName || '' : ''}
          {...register("firstName", { required: "First name is required" })}
        />
        <small className='form-error-message'>
          {errors?.firstName && errors.firstName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="middleName" className="form-label">Middle Name: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="middleName" tabIndex={4}
          defaultValue={isFormEdit ? selectedUser?.middleName || '' : ''}
          {...register("middleName", { required: "Middle Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.middleName && errors.middleName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="lastName" className="form-label">Last Name: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="lastName" tabIndex={5}
          defaultValue={isFormEdit ? selectedUser?.lastName || '' : ''}
          {...register("lastName", { required: "Last name is required" })}
        />
        <small className='form-error-message'>
          {errors?.lastName && errors.lastName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="email" className="form-label">Email: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="email" tabIndex={6}
          defaultValue={isFormEdit ? selectedUser?.email || '' : ''}
          autoComplete='on'
          {...register("email", { required: "email is required" })}
        />
        <small className='form-error-message'>
          {errors?.email && errors.email.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="gender" className="form-label">Gender: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="gender" value={gender} tabIndex={7} {...register("gender", { valueAsNumber: true, onChange: (e) => setGender(e.target.value), required: "Gender is required" })}>
          <option key="none" value="" hidden>Gender</option>
          {GENDER.map((gender, index) =>
            <option key={gender.value} value={gender.value}>{gender.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.gender && errors.gender.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="maritalStatus" className="form-label">Marital Status: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="maritalStatus" value={maritalStatus} tabIndex={8} {...register("maritalStatus", { valueAsNumber: true, onChange: (e) => setMaritalStatus(e.target.value), required: "Marital Status is required" })}>
          <option key="none" value="" hidden>Marital Status</option>
          {MARITAL_STATUS.map((maritalStatus, index) =>
            <option key={maritalStatus.value} value={maritalStatus.value}>{maritalStatus.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.maritalStatus && errors.maritalStatus.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="socialSec" className="form-label">Social Security: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="socialSec" tabIndex={9}
          defaultValue={isFormEdit ? selectedUser?.socialSec || '' : ''}
          {...register("socialSec", { required: "Middle Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.socialSec && errors.socialSec.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="preferredLanguage" className="form-label">Preferred Language: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="preferredLanguage" value={preferredLanguage} tabIndex={10} {...register("preferredLanguage", { valueAsNumber: true, onChange: (e) => setPreferredLanguage(e.target.value), required: "Preferred Language is required" })}>
          <option key="none" value="" hidden>Preferred Language</option>
          {PREFERRED_LANGUAGE.map((preferredLanguage, index) =>
            <option key={preferredLanguage.value} value={preferredLanguage.value}>{preferredLanguage.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.preferredLanguage && errors.preferredLanguage.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="employeeStartDate" className="form-label">Employee Start Date: <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='employeeStartDate'
          register={register}
          rules={{ required: "Employee Start Date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} value={value} />
          )}
        />
        <small className='form-error-message'>
          {errors?.employeeStartDate && errors.employeeStartDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="employeeType" className="form-label">Employee Type: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="employeeType" value={employeeType} tabIndex={12} {...register("employeeType", { valueAsNumber: true, onChange: (e) => setEmployeeType(e.target.value), required: "Employee Type is required" })}>
          <option key="none" value="" hidden>Employee Type</option>
          {EMPLOYEE_TYPE.map((employeeType, index) =>
            <option key={employeeType.value} value={employeeType.value}>{employeeType.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.employeeType && errors.employeeType.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="employeeLevel" className="form-label">Employee Level: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="employeeLevel" value={employeeLevel} tabIndex={13} {...register("employeeLevel", { valueAsNumber: true, onChange: (e) => setEmployeeLevel(e.target.value), required: "Employee Level is required" })}>
          <option key="none" value="" hidden>Employee Level</option>
          {EMPLOYEE_LEVEL.map((employeeLevel, index) =>
            <option key={employeeLevel.value} value={employeeLevel.value}>{employeeLevel.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.employeeLevel && errors.employeeLevel.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="phone" className="form-label">Phone <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='phone'
          register={register}
          rules={{ required: "Phone is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} tabIndex={14} className='form-control' format="(###) ###-####" id="phone" mask="_" allowEmptyFormatting autoComplete='on' />
          )}
        />
        <small className='form-error-message'>
          {errors?.phone && errors.phone.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="mobile" className="form-label">Mobile</label>
        <Controller
          control={control}
          name='cell'
          register={register}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat name={name} value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} tabIndex={15} className='form-control' format="(###) ###-####" id="mobile" mask="_" allowEmptyFormatting />
          )}
        />
        <small className='form-error-message'>
          {errors?.cell && errors.cell.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="emergencyContactName" className="form-label">Emergency Contact Name: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="emergencyContactName" tabIndex={16}
          defaultValue={isFormEdit ? selectedUser?.emergencyContactName || '' : ''}
          {...register("emergencyContactName", { required: "Emergency Contact Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.emergencyContactName && errors.emergencyContactName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="emergencyContactRelationship" className="form-label">Emergency Contact Relationship: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="emergencyContactRelationship" value={emergencyContactRelationship} tabIndex={17} {...register("emergencyContactRelationship", { valueAsNumber: true, onChange: (e) => setEmergencyContactRelationship(e.target.value), required: "Emergency Contact Relationship is required" })}>
          <option key="none" value="" hidden>Emergency Contact Relationship</option>
          {EMERGENCY_CONTACT_RELATIONSHIP.map((emergencyContactRelationship, index) =>
            <option key={emergencyContactRelationship.value} value={emergencyContactRelationship.value}>{emergencyContactRelationship.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.employeeLevel && errors.employeeLevel.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="emergencyContactNumber" className="form-label">Emergency Contact Number: <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='emergencyContactNumber'
          register={register}
          rules={{ required: "Emergency Contact Number is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} tabIndex={18} className='form-control' format="(###) ###-####" id="emergencyContactNumber" mask="_" allowEmptyFormatting autoComplete='on' />
          )}
        />
        <small className='form-error-message'>
          {errors?.emergencyContactNumber && errors.emergencyContactNumber.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="notes" className="form-label">Notes</label>
        <input type="text" className="form-control" id="notes" tabIndex={19}
          defaultValue={isFormEdit ? selectedUser?.notes || '' : ''}
          {...register("notes")}
        />
        <small className='form-error-message'>
          {errors?.notes && errors.notes.message}
        </small>
      </div>

      <div className="col-12 my-2">
        <hr></hr>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="street" className="form-label">Street: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="street" tabIndex={20}
          defaultValue={isFormEdit ? selectedUser?.street || '' : ''}
          {...register("street", { required: "Street is required" })}
        />
        <small className='form-error-message'>
          {errors?.street && errors.street.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="city" className="form-label">City: <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="city" tabIndex={21}
          defaultValue={isFormEdit ? selectedUser?.city || '' : ''}
          {...register("city", { required: "City is required" })}
        />
        <small className='form-error-message'>
          {errors?.city && errors.city.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="provinceState" className="form-label">Province/State</label>
        <div className="custom-selector">
          <Controller
            name="provinceState"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => { field.onChange(e); setCountryValue(e) }}
                ref={provinceStateRef}
                defaultValue={provinceState}
                options={provinceStateOptions}
                className="company-select"
                classNamePrefix="react-select"
                placeholder={'Province/State'}
                id="companyName"
                styles={selectStyle}
                isClearable
                tabIndex={22}
              />
            )}
          />
        </div>
        <small className='form-error-message'>
          {errors?.companyName && errors.companyName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="postalCode" className="form-label">Postal Code <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="postalCode" maxLength={6} tabIndex={23}
          defaultValue={isFormEdit ? selectedUser?.postalCode || '' : ''}
          {...register("postalCode", { required: "Postal Code is required", maxLength: 6 })}
        />
        <small className='form-error-message'>
          {errors?.postalCode && errors.postalCode.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="country" className="form-label">Country: <span className="required-asterisk">*</span></label>
        <select className="form-select" id="country" value={country} tabIndex={24} {...register("country", { valueAsNumber: true, onChange: (e) => setCountry(e.target.value), required: "Country is required" })}>
          <option key="none" value="" hidden>Country</option>
          {COUNTRY.map((country, index) =>
            <option key={index} value={country.value}>{country.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.country && errors.country.message}
        </small>
      </div>

      <div>

      </div>
      <div className={`row col-12 ${!isPayrollFormHidden ? '' : 'd-none'}`}>
        <div className="col-12 my-2">
          <hr></hr>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="tD1Province" className="form-label">TD1 Province:</label>
          <select className="form-select" id="tD1Province" value={tD1Province} tabIndex={25} {...register("payroll.tD1Province", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setTD1Province(e.target.value), required: "TD1 Province is required" })}>
            <option key="none" value="" hidden>TD1 Prov</option>
            {getProvinceClaimCodes().map((td1, index) =>
              <option key={index} value={td1.value}>{td1.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.payroll?.tD1Province && errors.payroll?.tD1Province.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="tD2Federal" className="form-label">TD2 Federal:</label>
          <select className="form-select" id="tD2Federal" value={tD2Federal} tabIndex={26} {...register("payroll.tD2Federal", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setTD2Federal(e.target.value), required: "TD2 Federal is required" })}>
            <option key="none" value="" hidden>TD1 Fed</option>
            {TD2_FEDERAL.map((td2, index) =>
              <option key={index} value={td2.value}>{td2.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.payroll?.tD2Federal && errors.payroll?.tD2Federal.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="payFrequency" className="form-label">Pay Frequency:</label>
          <select className="form-select" id="payFrequency" value={payFrequency} tabIndex={27} disabled={!(_.isEmpty(payrollSettings) && _.isEmpty(selectedUser))} {...register("payroll.payFrequency", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setPayFrequency(e.target.value), required: "Pay frequency is required" })}>
            <option key="none" value="" hidden>Pay Frequency</option>
            {PAY_FREQUENCY.map((payFrequency, index) =>
              <option key={index} value={payFrequency.value}>{payFrequency.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.payroll?.payFrequency && errors.payroll?.payFrequency.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="payStartDate" className="form-label">Pay Start Date:</label>
          <Controller
            control={control}
            name='payroll.payStartDate'
            register={register}
            rules={!isPayrollFormHidden && { required: "Pay start date is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker className="datepicker-field" onChange={onChange} value={value}/>
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.payStartDate && errors.payroll?.payStartDate.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="vacationType" className="form-label">Vacation Type:</label>
          <select className="form-select" id="vacationType" value={vacationType} tabIndex={29} {...register("payroll.vacationType", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setVacationType(e.target.value), required: "Vacation type is required" })}>
            <option key="none" value="" hidden>Vacation Type</option>
            {VACATION_TYPE.map((vacationType, index) =>
              <option key={index} value={vacationType.value}>{vacationType.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.payroll?.vacationType && errors.payroll?.vacationType.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="vacationAmount" className="form-label">Vacation Amount:</label>
          <Controller
            control={control}
            name='payroll.vacationAmount'
            register={register}
            rules={!isPayrollFormHidden && { required: "Vacation Amount is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={30} className='form-control' suffix={`${vacationTypeWatch === 0 ? '%' : ' days'}`} thousandsGroupStyle="thousand" thousandSeparator="," id="vacationAmount" mask="_" />
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.vacationAmount && errors.payroll?.vacationAmount.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="payMethodType" className="form-label">Pay Method Type:</label>
          <select className="form-select" id="payMethodType" value={payMethodType} tabIndex={31} {...register("payroll.payMethodType", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setPayMethodType(e.target.value), required: "Pay method type is required" })}>
            <option key="none" value="" hidden>Pay Method Type</option>
            {PAY_METHOD_TYPE.filter(method => method.value !== 4).map((payMethodType, index) =>
              <option key={index} value={payMethodType.value}>{payMethodType.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.payroll?.payMethodType && errors.payroll?.payMethodType.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="payPerUnit" className="form-label">Pay Per Unit:</label>
          <Controller
            control={control}
            name='payroll.payPerUnit'
            register={register}
            rules={!isPayrollFormHidden && { required: "Pay per unit is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={32} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="payPerUnit" mask="_" fixedDecimalScale />
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.payPerUnit && errors.payroll?.payPerUnit.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="overtimePaymentRate" className="form-label">Overtime Payment Rate:</label>
          <Controller
            control={control}
            name='payroll.overtimePaymentRate'
            register={register}
            rules={!isPayrollFormHidden && { required: "Overtime payment rate is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={33} className='form-control' suffix="%" thousandsGroupStyle="thousand" thousandSeparator="," id="overtimePaymentRate" mask="_" />
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.overtimePaymentRate && errors.payroll?.overtimePaymentRate.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="glCodeId" className="form-label">GL Code:</label>
          <select className="form-select" id="glCodeId" value={gLCode} tabIndex={34} {...register("payroll.glCodeId", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setGLCode(e.target.value), required: !isPayrollFormHidden })}>
            <option key="none" value="" hidden>GL Code</option>
            {gLCodeList.map((gl, index) =>
              <option key={index} value={gl.glCodeId}>{gl.glCodeName} - {gl.description}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.payroll?.glCodeId && errors.payroll?.glCodeId.message}
          </small>
        </div>

        <div className="col-12 my-2">
          <hr></hr>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="previouslyPaidCPP" className="form-label">Previously Paid CPP:</label>
          <Controller
            control={control}
            name='payroll.payrollPaidContribution.previouslyPaidCPP'
            register={register}
            rules={!isPayrollFormHidden && { required: "Previously paid CPP is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={35} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="previouslyPaidCPP" mask="_" fixedDecimalScale disabled={isFieldDisabled} />
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.payrollPaidContribution?.previouslyPaidCPP && errors.payroll?.payrollPaidContribution?.previouslyPaidCPP.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="previouslyPaidEI" className="form-label">Previously Paid EI:</label>
          <Controller
            control={control}
            name='payroll.payrollPaidContribution.previouslyPaidEI'
            register={register}
            rules={!isPayrollFormHidden && { required: "Previously paid EI is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={36} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="previouslyPaidEI" mask="_" fixedDecimalScale disabled={isFieldDisabled} />
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.payrollPaidContribution?.previouslyPaidEI && errors.payroll?.payrollPaidContribution?.previouslyPaidEI.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="contributionYear" className="form-label">Year Paid:</label>
          <Controller
            control={control}
            name='payroll.payrollPaidContribution.date'
            register={register}
            rules={!isPayrollFormHidden && { required: "Year paid is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker className="datepicker-field" onChange={onChange} value={value} picker="year" />
              
            )}
          />
          <small className='form-error-message'>
            {errors?.payroll?.payStartDate && errors.payroll?.payStartDate.message}
          </small>
        </div>
        <div className='col-12'>
          <p className='text-link' onClick={() => window.open('/claim-codes', '_blank')}>Claim Codes Values</p>
        </div>
      </div>
      <div className="row">
        {!isFormEdit || selectedUser?.payrollEmployee !== true ?
          <div className="col-10 mb-2 mt-3">
            <div className="form-check">
              <input className="form-check-input align-middle" type="checkbox" value="" id="payrollEmployee" tabIndex={38}
                {...register("payrollEmployee", { onChange: (event) => onPayrollEmployeeCheckboxChange(event) })}
              />
              <label className="form-check-label align-middle " htmlFor="payrollEmployee">Payroll Employee</label>
            </div>
            {/* <div className="form-check">
                <input className="form-check-input 
                  align-middle" 
                  type="checkbox" 
                  // value={} 
                  id="payrollEmployee"
                  onChange={(e) => onPayrollEmployeeCheckboxChange(e)}
                  {...register("payrollEmployee")}
                />
                <label className="form-check-label align-middle " htmlFor="payrollEmployee">Payroll Employee</label>
              </div> */}
          </div>
          :
          <div className="col-10 mb-2 mt-3"></div>
        }
        {
          (!isAccountUser(userType)) &&
          <div className="col-2 mb-2 mt-3">
            <div className="form-check align-self-end">
              <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={39}
                defaultChecked={getActiveDefaultValue}
                {...register("active")}
              />
              <label className="form-check-label align-middle " htmlFor="active"> Active</label>
              <small className='form-error-message'>
                {errors?.active && errors.active.message}
              </small>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default PrimaryDetails;
