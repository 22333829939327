import React from 'react'

function ActiveStatusRenderer(props) {
  const activeStatus = props.data["Status"]

  let label = ""
  let activeStatusClass = "";

  switch (activeStatus) {
    case false:
      activeStatusClass = "status-draft";
      label = "Inactive";
      break;
    case true:
      activeStatusClass = "status-approved";
      label = "Active";
      break;
    default:
      break;
  }

  return (
    <div className={`d-flex justify-content-center align-items-center status-container ${activeStatusClass}`}>
      <p className='status-text mb-0 fw-bold'>{label.toUpperCase()}</p>
    </div>
  )
}

export default ActiveStatusRenderer