import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { addContact, updateContact } from '../../services/contactServices';
import _ from 'lodash';
import { isSuperAdmin, isAccountAdmin, isNTMSUSer } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { PatternFormat } from 'react-number-format';
import { removePhoneFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { PROVINCE, PROVINCE_STATE, COUNTRY } from '../../constants';
import { addContactData, setContactData } from '../../redux/contact/contactReducer';
import Select from 'react-select';
import { selectStyle } from '../../utils/styleUtils';
import PrimaryDetails from './PrimaryDetails';
import NoteDetails from './NoteDetails';
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";
import FormTabs from '../Commons/Layouts/FormTabs';

function ContactForm({
  isFormEdit = false,
  selectedContact = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
  customerCreationInformation,
  setCustomerCreationInformation
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const userType = useSelector((state) => state.auth.user.userType);
  const accountId = useSelector((state) => state.auth.user.accountId);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const customerState = useSelector((state) => state.customer);
  const customerIdWatch = watch("customerId");

  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerId, setCustomerId] = useState("");

  const provinceStateRef = useRef("null");
  const isInitialRender = useRef(true);

  // useStates
  const [customerList, setCustomerList] = useState([]);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    setCustomerList(customerState.data);
  }, [customerState.data])

  useEffect(() => {
    clearErrors();
    reset();
    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("customerId");
      }, 50);

      if (customerCreationInformation !== null) {
        setCustomerName(customerCreationInformation.label);
        setCustomerEmail(customerCreationInformation.email);
        setCustomerId(customerCreationInformation.value);
        setCustomerCreationInformation(null);
      }
    } else {
      setCustomerName();
      setCustomerEmail();
      setCustomerId();
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  useEffect(() => {
    const accountIdFilter = _.filter(customerList, c => c.customerId === customerIdWatch);
    const firstItem = _.first(accountIdFilter);

    setValue("accountId", firstItem?.accountId);
  }, [customerIdWatch])

  const onSubmit = async (payload) => {
    let response = null;

    _.set(payload, 'phone', removePhoneFormat(payload.phone));
    _.set(payload, 'cell', removePhoneFormat(payload.cell));
    _.set(payload, 'provinceState', payload.provinceState.value)

    if (payload.phone === "") {
      setError("phone", { message: "Phone is required" });
      return;
    }

    if (isFormEdit) {
      _.set(payload, 'contactId', selectedContact.contactId);
      response = await updateContact(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${
            response.ok ? "Successfully saved" : "Failed to save"
          } contact.`,
        })
      );
    } else {
      _.set(payload, 'contactId', 0);
      _.set(payload, 'customerId', customerId);
      if (isNTMSUSer(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addContact(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${
            response.ok ? "Successfully saved" : "Failed to save"
          } contact.`,
        })
      );
    }

    if (response.ok) {
      const contactData = await response.json();
      dispatch(isFormEdit ? setContactData(contactData) : addContactData(contactData));
      provinceStateRef.current.clearValue();
      toggleFormDisplay();
      focusOnOpenOrCloseButton();
      dispatch(setFormPendingCompletion(false));
    }
  };

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-1-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedContact={selectedContact}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              provinceStateRef={provinceStateRef}
              customerId={customerId}
              setCustomerId={setCustomerId}
              customerName={customerName}
              setCustomerName={setCustomerName}
              customerEmail={customerEmail}
              setCustomerEmail={setCustomerEmail}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Notes Details",
      key: 1,
      forceRender: true,
      children: (
        <div className="content-section-container color-1-section">
          <div className="row form-container" id="address-details-form">
            <NoteDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedContact={selectedContact}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              provinceStateRef={provinceStateRef}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs 
          items={tabItems} 
          tabClassName="color-1" 
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ContactForm
