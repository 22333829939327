import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const loadItemsReducer = createSlice({
  name: "loadItems",
  initialState,
  reducers: {
    setLoadItemsListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    setLoadItem: (state, action) => {
      const index = state.data.findIndex(
        (loadItem) => loadItem.loadItemId === action.payload.loadItemId
      );
      const loadItems = [...state.data];
      loadItems[index] = action.payload;
      return {
        ...state,
        data: loadItems,
      };
    },
    setLoadItems: (state, action) => {
      const updatedLoadItems = action.payload;
    
      const updatedData = [...state.data];
    
      updatedLoadItems.forEach(updatedItem => {
        const index = updatedData.findIndex(item => item.loadItemId === updatedItem.loadItemId);
        if (index !== -1) {
          updatedData[index] = updatedItem;
        }
      });
    
      return {
        ...state,
        data: updatedData,
      };
    },
    addLoadItemData: (state, action) => {
      const loadItemData = [...state.data];
      loadItemData.push(action.payload);
      return {
        ...state,
        data: loadItemData
      }
    },
    deleteLoadItemData: (state, action) => {
      const updatedData = state.data.filter(item => item.loadItemId !== action.payload.itemId);
      return {
        ...state,
        data: updatedData
      };
    },
  },
});

export const { 
  addLoadItemData, 
  setLoadItemsListData, 
  setLoadItem,
  setLoadItems,
  deleteLoadItemData 
} = loadItemsReducer.actions;

export default loadItemsReducer.reducer;
