import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";
import ReactDatePicker from "react-datepicker";
import { DatePicker } from "antd";
import dayjs from "dayjs";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  control,
  register,
  errors,
  setValue,
  reset,
  minDate
}) {
  const payrolRunDateRef = useRef(null);
  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="startDate" className="form-label">Start Date:</label>
        <Controller
          control={control}
          name='startDate'
          register={register}
          rules={{ required: "Start date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ReactDatePicker
              className='datepicker-field'
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
              dateFormat='yyyy-MM-dd'
              id="startDate"
              readOnly={true}
              disabled={true}
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.startDate && errors.startDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="endDate" className="form-label">End Date:</label>
        <Controller
          control={control}
          name='endDate'
          register={register}
          rules={{ required: "End date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ReactDatePicker
              className='datepicker-field'
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
              dateFormat='yyyy-MM-dd'
              id="endDate"
              readOnly={true}
              disabled={true}
            />
          )}
        />
        <small className='form-error-message'>
          {errors?.endDate && errors.endDate.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="payrollRunDate" className="form-label">Payroll Run Date:</label>
        <Controller
          defaultValue={new Date()}
          control={control}
          name='payrollRunDate'
          register={register}
          rules={{ required: "Payroll run date is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker className="datepicker-field" onChange={onChange} />
          )}
        />
        <small className='form-error-message'>
          {errors?.payrollRunDate && errors.payrollRunDate.message}
        </small>
      </div>
    </>
  );
}

export default PrimaryDetails;
