import moment from "moment";
import _ from "lodash";
import { TAX_TYPES, USER_TYPES, THEME_COLORS } from "../constants";

export function formatDate(date) {
  const dateFormat = "YYYY-MM-DD";
  const toDate = new Date(date);
  return moment(toDate).format(dateFormat);
}

export function formatDateOnly(date) {
  const dateFormat = "YYYY-MM-DD"
  return moment(date).format(dateFormat)
}

export function formatQID(quoteSequenceId) {
  return `QID-${quoteSequenceId}`;
}

export function formatCurrency(number) {
  const formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });

  return formatter.format(number);
}

export function formatPhoneNumber(number) {
  var cleaned = ("" + number).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export const isNonEmptyString = (value) =>
  typeof value == "string" && value.length > 0;

export const accountTypeValueGetter = (params) => {
  const obj = {
    1: "Expense",
    2: "Labour Item",
    3: "Product Cost",
  };
  return obj[params.data["Account Type"]];
};

export const currencyTypeValueGetter = (params) => {
  const obj = {
    1: "Canada",
    2: "US",
  };
  return obj[params.data["Currency Type"]];
};

export const reportTypeRenderer = (report) => {
  const obj = {
    1: "Quote",
    2: "Invoice",
    3: "Payroll Run",
    4: "Pay Stub",
    5: "Expense",
    6: "Expense Run",
    7: "Payment",
    8: "Payment Run",
  };
  return obj[report];
};

export const defaultTaxCodeValueGetter = (params) => {
  const obj = {
    0: "None",
    1: "GST",
    2: "PST",
    3: "HST",
    4: "GST/PST",
    5: "OTHER",
  };
  return obj[params.data["Default Tax Code"]];
};

export const removeDollar = (value) => {
  return value !== undefined ? value.replace(/[$,]/g, "") : "";
};

export const removePercentage = (value) => {
  return value !== undefined ? value.replace(/[%,]/g, "") : "";
};

export const removeDays = (value) => {
  return value !== undefined ? value.replace(/[a-zA-Z\s]/g, "") : "";
};

export function formatTaxType(value) {
  const taxType = _.find(TAX_TYPES, { value: value });
  return _.get(taxType, "label", "None");
}

export function removePhoneFormat(value) {
  return value !== undefined ? value.replace(/[^\d]/g, "") : "";
}

export function removeNumberFormat(value) {
  if (isNaN(value) && typeof value === "string") {
    return value !== undefined ? value.replace(/[^0-9.]+/g, "") : "";
  } else {
    return value;
  }
}

export const formatTaxRate = (value) => {
  return Math.round(100000 * value) / 1000 + "%";
};

export const formTitleSelector = (value) => {
  var title = value;

  if (value === "gl-code") {
    title = "GL code";
  }

  if (value === "product-material") {
    title = "product material";
  }

  if (value === "expense-item") {
    title = "expense item";
  }

  if (value === "payroll-run") {
    title = "payroll run";
  }

  return title;
};

export const enumValueGetter = (params, enumMapping) => {
  const value = params.data[params.colDef.field];

  if (!(value >= 1 && value <= enumMapping.length)) {
    return "None";
  }

  return enumMapping[value - 1]?.label;
};

export const enumValueFormatter = (params, options) => {
  const statusObject = options.find((item) => item.value === params.value);

  if (statusObject) {
    return statusObject.label;
  } else {
    return "None";
  }
};

export const formatUserType = (userType) => {
  switch (userType) {
    case USER_TYPES.SUPER_ADMIN:
      return "Super Admin";
    case USER_TYPES.NTMS_USER:
      return "NTMS User";
    case USER_TYPES.ACCOUNT_ADMIN:
      return "Account Admin";
      case USER_TYPES.ACCOUNT_USER:
        return "Account User";
    default:
      return "";
  }
};

export const formatEllipsis = (params, maxLength) => {
  // const maxLength = 20; // Maximum length before truncating
  const value = params.value;
  return value.length > maxLength ? value.substring(0, maxLength) + '...' : value;
}

export function removeNonDigits(value) {
  if (_.isNil(value)) {
    return null;
  }

  return value.replace(/\D/g, '');
}

export const getThemeColor = (tabClassName) => {
  let themeColor = THEME_COLORS.COLOR_3; 
  const colorMappings = {
    'color-1': THEME_COLORS.COLOR_1,
    'color-2': THEME_COLORS.COLOR_2,
    'color-3': THEME_COLORS.COLOR_3,
    'color-4': THEME_COLORS.COLOR_4,
    'color-5': THEME_COLORS.COLOR_5,
    'color-6': THEME_COLORS.COLOR_6,
    'color-7': THEME_COLORS.COLOR_7,
    'color-8': THEME_COLORS.COLOR_8,
  };

  if (colorMappings.hasOwnProperty(tabClassName)) {
    themeColor = (colorMappings[tabClassName]);
  }
  return themeColor;
}