import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";

function CardHeader({
  title,
  options
}) {

  return (
    <div className="d-flex align-items-start">
      <div className='me-auto'>
        <p className='fw-bold'>{title}</p>
      </div>
      <span className='ms-2 dropdown-label'>Show:</span>  
      <select className="dropdown-field" id="Header">
      {options.map((option) => (
        <option key={option.value} value={option.label}>
          {option.label}
        </option>
      ))}
      </select>
    </div>
  )
}

export default CardHeader