import React from 'react'
// import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Modal, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  createPaystubReportFile,
  emailPaystubReport,
  emailPaystubReportToAll,
  emailGroupedPaystubs
} from '../../services/reportServices';
import { printPreviewReportWithBase64 } from '../../utils/payrollRunUtils';
import moment from 'moment';
import { setNotificationData } from '../../redux/global/globalReducer';
import { MODAL_OPTIONS } from '../../constants';
import { PrinterOutlined, MailOutlined } from '@ant-design/icons';

function PaystubReportMenuModal({
  modal,
  toggle,
  payrollRunLineItems,
  startDate,
  endDate,
  payrollRunId,
  args
}) {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const email = useSelector((state) => state.auth.user.email);

  const sendPaystubReportToAll = async () => {
    const payload = {
      accountId: selectedAccountState.accountId === null ? accountId : selectedAccountState.accountId,
      payrollRunId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      subject: 'Paystub Report',
      body: 'Report attached here',
      attachmentFileName: 'Paystub Report',
    };

    const response = await emailPaystubReportToAll(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }


    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully emailed" : "Failed email"
          } paystub report to all employees.`,
      })
    );
  }

  const sendGroupedPaystubs = async () => {
    const payload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      payrollRunId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      toEmail: email,
      subject: "Grouped Paystub Report",
      body: "Report attached here",
      attachmentFileName: "Grouped Paystub Report",
    };

    const response = await emailGroupedPaystubs(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully emailed" : "Failed email"
          } grouped paystubs report.`,
      })
    );
  };

  const sendPaystubReport = async (payrollRunLineItem) => {
    const payload = {
      payrollRunLineItemId: payrollRunLineItem.payrollRunLineItemId,
      payrollRunId: payrollRunLineItem.payrollRunId,
      accountId: selectedAccountState.accountId === null ? accountId : selectedAccountState.accountId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      toEmail: payrollRunLineItem.user.email,
      subject: 'Paystub Report',
      body: 'Report attached here',
      attachmentFileName: 'Paystub Report'
    };

    const response = await emailPaystubReport(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully emailed" : "Failed email"
          } ${payrollRunLineItem.userName}`,
      })
    );
  }

  const printPaystubReport = async (payrollRunLineItem) => {
    const payload = {
      payrollRunLineItemId: payrollRunLineItem.payrollRunLineItemId,
      payrollRunId: payrollRunId,
      accountId: selectedAccountState.accountId === null ? accountId : selectedAccountState.accountId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD")
    };

    const response = await createPaystubReportFile(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    printPreviewReportWithBase64(data.base64);
  }

  return (
    <Modal
      open={modal}
      className={`ant-modal-color-4`}
      style={{ top: MODAL_OPTIONS.top }}
      title={
        <Space>
          Paystub Report Menu
        </Space>
      }
      onCancel={toggle}
      footer={
        <>
          <div className='modal-header-container d-flex flex-row-reverse align-items-center'>
            <button
              className="btn btn-md btn-color-4 inverted ms-2 w-50"
              onClick={() => sendPaystubReportToAll()}
            >
              <MailOutlined /> &nbsp; Email to All Employees
            </button>
            <button
              className="btn btn-md btn-color-4 inverted me-2 w-50"
              onClick={() => sendGroupedPaystubs()}
            >
              <MailOutlined /> &nbsp; Email Grouped Paystubs
            </button>
          </div>
        </>
      }
    >
      <div className="content-body-container">
        <div className="row">
          {(payrollRunLineItems.data.length > 0) ?
            (payrollRunLineItems?.data?.map((payrollRunLineItem, index) =>
              <div key={index} className='col-12 mb-3'>
                <div className="card shadow-sm border-0">
                  <div className="card-body py-1">
                    <div className='d-flex align-items-center'>
                      <div className="me-auto p-2">
                        <span className='fw-bold me-3'>{index + 1}.</span>&nbsp;{payrollRunLineItem.userName}
                      </div>
                      <div className="p-1">
                        <button className="btn btn-color-4" onClick={() => sendPaystubReport(payrollRunLineItem)}><MailOutlined /> Mail</button>
                      </div>
                      <div className="p-1">
                        <button className="btn btn-color-4" onClick={() => printPaystubReport(payrollRunLineItem)}><PrinterOutlined /> Print</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
            :
            <div className="col-12 mb-3">No employees available</div>
          }
        </div>
      </div>

    </Modal>
  )
}

export default PaystubReportMenuModal