import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import { fetchTrailerMaintenanceByAccount } from "../../services/trailerMaintenanceServices";
import { useDispatch, useSelector } from "react-redux";
import { setTrailerMaintenanceListData } from "../../redux/trailerMaintenance/trailerMaintenanceReducer";
import TrailerMaintenanceForm from "./TrailerMaintenanceForm";
import { enumValueGetter, formatDateOnly } from "../../utils/formatUtils";
import {
  ENGINE_TYPES,
  OWNERSHIP_STATUS_TYPES,
  TRAILER_TYPES,
  TRANSMISSION_TYPES,
  VEHICLE_CLASSES,
  VEHICLE_TYPES,
} from "../../constants";
import TrailerMaintenanceModal from "./TrailerMaintenanceModal";
import { fetchTrailersFiltered } from "../../services/trailerServices";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function TrailerMaintenance() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const trailerMaintenanceState = useSelector(
    (state) => state.trailerMaintenance
  );
  const [createTrailerMaintenanceModal, setCreateTrailerMaintenanceModal] =
    useState(false);
  const createTrailerMaintenanceToggle = () =>
    setCreateTrailerMaintenanceModal(!createTrailerMaintenanceModal);
  const [trailerOptions, setTrailerOptions] = useState([]);
  const [trailerCreationInformation, setTrailerCreationInformation] =
    useState(null);

  const toggleFormDisplay = (isEdit = false, trailerMaintenanceId = null) => {
    if (isFormHidden) {
      setSelectedTrailerMaintenanceId(trailerMaintenanceId);
    } else {
      setSelectedTrailerMaintenanceId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && trailerMaintenanceList?.length > 0) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "VIN",
      minWidth: 120,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Make",
      minWidth: 120,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Model",
      minWidth: 120,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Last Maintenance Date",
      minWidth: 200,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Next Maintenance Date",
      minWidth: 200,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Last Inspection Date",
      minWidth: 200,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Next Inspection Date",
      minWidth: 200,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      resizable: false,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [trailerMaintenanceList, setTrailerList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTrailerMaintenance, setSelectedTrailerMaintenance] = useState(
    {}
  );
  const [selectedTrailerMaintenanceId, setSelectedTrailerMaintenanceId] =
    useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    getTrailersData();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setTrailerList(trailerMaintenanceState.data);
  }, [trailerMaintenanceState.data]);

  useEffect(() => {
    let data = [];
    _.each(trailerMaintenanceList, (trailerMaintenance, index) => {
      data.push({
        "#": index + 1,
        "Trailer ID": trailerMaintenance.trailerMaintenanceId,
        VIN: trailerMaintenance.vin,
        Make: trailerMaintenance.trailer.make,
        Model: trailerMaintenance.trailer.model,
        Date: trailerMaintenance.date,
        "Last Maintenance Date": formatDateOnly(
          trailerMaintenance.lastMaintenanceDate
        ),
        "Next Maintenance Date": formatDateOnly(
          trailerMaintenance.nextMaintenanceDate
        ),
        "Last Inspection Date": formatDateOnly(
          trailerMaintenance.lastInspectionDate
        ),
        "Next Inspection Date": formatDateOnly(
          trailerMaintenance.nextInspectionDate
        ),
        "Tire Condition": trailerMaintenance.tireCondition,
        Warranty: trailerMaintenance.warranty,
        Compliances: trailerMaintenance.complainces,
        Comments: trailerMaintenance.comments,
        Edit: {
          id: trailerMaintenance.trailerMaintenanceId,
          btnClass: "btn-color-7",
        },
      });
    });

    setRowData(data);
  }, [trailerMaintenanceList]);

  useEffect(() => {
    fillSelectedTrailerMaintenance(selectedTrailerMaintenanceId);
  }, [selectedTrailerMaintenanceId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getTrailerMaintenanceData();
    dispatch(setTrailerMaintenanceListData(response));

    setIsLoading(false);
  };

  const getTrailerMaintenanceData = async () => {
    return await fetchTrailerMaintenanceByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
  };

  const fillSelectedTrailerMaintenance = (id) => {
    const trailerMaintenance = _.find(trailerMaintenanceList, {
      trailerMaintenanceId: id,
    });
    setSelectedTrailerMaintenance(trailerMaintenance);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if(!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  const getTrailersData = async () => {
    const response = await fetchTrailersFiltered(accountId);
    setTrailerOptions(
      response.map((trailer) => ({
        label: trailer.vin,
        value: trailer.trailerId,
        make: trailer.make,
        model: trailer.model,
        year: trailer.year,
      }))
    );
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Trailers Maintenance"
                : isFormEdit
                  ? "Edit Trailers Maintenance"
                  : "New Trailers Maintenance"
            }
            subtitle={"trailers maintenance"}
            dataCount={trailerMaintenanceList.length}
            filterData={filterData}
            onClickAdd={createTrailerMaintenanceToggle}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(trailerMaintenanceList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-7-grid color-7-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-7' content='trailer maintenance' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <TrailerMaintenanceForm
            isFormEdit={isFormEdit}
            selectedTrailerMaintenance={selectedTrailerMaintenance}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
            trailerCreationInformation={trailerCreationInformation}
            setTrailerCreationInformation={setTrailerCreationInformation}
          />
        </div>
      </div>
      <TrailerMaintenanceModal
        modal={createTrailerMaintenanceModal}
        setModal={setCreateTrailerMaintenanceModal}
        toggle={createTrailerMaintenanceToggle}
        trailerOptions={trailerOptions}
        trailerCreationInformation={trailerCreationInformation}
        setTrailerCreationInformation={setTrailerCreationInformation}
        toggleFormCancelDisplay={toggleFormCancelDisplay}
      />
    </div>
  );
}

export default TrailerMaintenance;
