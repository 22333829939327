import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchCustomers = async () => {
  return get({ path: 'Customer' });
}

export const fetchCustomersFiltered = async (accountId) => {
  return get({ path: `Customer/filtered` });
}

export const addCustomer = async (payload) => {
  return post({ path: 'Customer', body: payload });
}

export const updateCustomer = async (payload) => {
  return patch({ path: `Customer/${payload.customerId}`, body: payload });
}

export const fetchCustomersByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchCustomersFiltered(accountId) : await fetchCustomersFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchCustomersFiltered(accountId);
  }
  
  return await fetchCustomers();
}