import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const trailerReducer = createSlice({
  name: "trailer",
  initialState,
  reducers: {
    setTrailersListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setTrailerData: (state, action) => {
      const index = state.data.findIndex(trailer => trailer.trailerId === action.payload.trailerId);
      const trailersListData = [...state.data];
      trailersListData[index] = action.payload;
      return {
        ...state,
        data: trailersListData
      }
    },
    addTrailerData: (state, action) => {
      const trailersListData = [...state.data];
      trailersListData.push(action.payload);
      return {
        ...state,
        data: trailersListData
      }
    },
  },
});

export const {
  setTrailersListData,
  setTrailerData,
  addTrailerData,
} = trailerReducer.actions;

export default trailerReducer.reducer;