import React from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { selectStyle } from '../../../utils/styleUtils';
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select';
import _ from 'lodash';
import { setNotificationData } from '../../../redux/global/globalReducer';
import { useDispatch } from 'react-redux';

function ContactModal({
  modal,
  setModal,
  toggle,
  customerOptions,
  customerCreationInformation,
  setCustomerCreationInformation,
  toggleFormCancelDisplay,
  // isFormEdit,
  // formType,
  // toggleFormDisplay,
  args
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, setError, clearErrors, reset, setFocus, control, formState: { errors, isDirty } } = useForm();

  const onCustomerSelectChange = (e) => {
    setCustomerCreationInformation(e);
  }

  const openFormAndCloseModal = () => {
    if (customerCreationInformation === null) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: `Please select a customer`,
        })
      );
      return
    }
    toggleFormCancelDisplay();
    toggle();
  }

  const resetValue = () => {
    setCustomerCreationInformation(null);
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Create a Contact</ModalHeader>
        <ModalBody>
          <form className="row form-container justify-content-center align-items-center" id="contact-form" >
            {/* <div className="col-6 col-sm-4 mb-2"></div> */}
            <div className="col-8 col-sm-6 mb-2">
              <label htmlFor="customerId" className="form-label">Customer</label>
              <div className="custom-selector">
                <Controller
                  name="customerId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(e) => { field.onChange(e); onCustomerSelectChange(e) }}
                      value={customerCreationInformation}
                      options={customerOptions}
                      className="company-select"
                      classNamePrefix="react-select"
                      placeholder={'Customer'}
                      id="customer"
                      styles={selectStyle}
                      isClearable
                      tabIndex={11}
                    />
                  )}
                />
              </div>
              <small className='form-error-message'>
                {errors?.companyName && errors.companyName.message}
              </small>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={openFormAndCloseModal}>
            Create Contact
          </Button>
        </ModalFooter>
      </Modal>
    </>

  )
}

export default ContactModal