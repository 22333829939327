import React from 'react'
import { formatDateOnly, formatCurrency, formatTaxType } from '../../utils/formatUtils';
import { getExpenseBalance } from '../../utils/paymentUtils';

function ExpenseDetails({ selectedExpense }) {
  return (
    <div className='row invoice-details-container'>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Expense ID: <span className='content'>{selectedExpense?.expenseId ?? '-'}</span></p>
        
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Description": <span className='content'>{selectedExpense?.description ?? '-'}</span></p>
        
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Reference ID: <span className='content'>{selectedExpense?.referenceId ?? '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>GL Code: <span className='content'>{selectedExpense?.glCodeId ?? '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Date: <span className='content'>{selectedExpense?.expenseDate !== undefined ? formatDateOnly(selectedExpense?.expenseDate) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Quantity: <span className='content'>{selectedExpense?.quantity !== undefined ? formatCurrency(selectedExpense?.quantity) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Unit Price: <span className='content'>{selectedExpense?.rate !== undefined ? formatCurrency(selectedExpense?.rate) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Tax Type: <span className='content'>{selectedExpense?.taxType !== undefined ? formatTaxType(selectedExpense?.taxType) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Tax Rate: <span className='content'>{selectedExpense?.taxRate !== undefined ? selectedExpense?.taxRate : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Discount: <span className='content'>{selectedExpense?.discount !== undefined ? formatCurrency(selectedExpense?.discount) : '-'}</span></p>
        
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Subtotal: <span  className='content'>{selectedExpense?.subtotal !== undefined ? formatCurrency(selectedExpense?.subtotal) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Total Tax: <span className='content'>{selectedExpense?.totalTax !== undefined ? formatCurrency(selectedExpense?.totalTax) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold'>Balance: <span className='content'>{selectedExpense !== undefined ? formatCurrency(getExpenseBalance(selectedExpense)) : '-'}</span></p>
      </div>
      <div className="col-6 col-lg-3"></div>
      <div className="col-6 col-lg-3"></div>
      <div className="col-6 col-lg-3">
        <p className='fw-bold total'>Total: {selectedExpense?.total !== undefined ? formatCurrency(selectedExpense?.total) : '-'}</p>
      </div>
    </div>
  )
}

export default ExpenseDetails