import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";
import { NumericFormat } from 'react-number-format';
import { provinceCheck } from '../../utils/provinceUtils';

function ExpenseItemPrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedItem = {},
  control,
  register,
  errors,
  setValue,
  reset,
  province,
  gLCodeList,
  userType,
}) {

  const [gLCode, setGLCode] = useState("");
  const [defaultTaxCode, setDefaultTaxCode] = useState("")

  useEffect(() => {
    if (isFormEdit) {
      setDefaultTaxCode(parseInt(selectedItem['taxType']));
      setGLCode(parseInt(selectedItem['glCodeId']));
      setValue("taxType", parseInt(selectedItem['taxType']));
      setValue("glCodeId", parseInt(selectedItem['glCodeId']));
      setValue("active", selectedItem['active'])
      setValue("defaultRate", (parseFloat(selectedItem["defaultRate"]) * 100).toFixed(3))
    } else {
      setDefaultTaxCode("");
      setGLCode("");
      reset()
    }

  }, [selectedItem["expenseItemId"]])

  function setDefaultRate(e) {
    const value = e.target.value
    setValue('defaultRate', (provinceCheck(province, value) * 100).toFixed(3))
  }

  const getActiveDefaultValue = () => {
    if (selectedItem.length <= 0) {
      return 'true';
    }

    return selectedItem.active ? 'true' : '';
  }

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="expenseTitle" className="form-label">Title</label>
        <input type="text" className="form-control" id="expenseTitle" tabIndex={1}
          defaultValue={isFormEdit ? selectedItem?.expenseTitle || '' : ''}
          {...register("expenseTitle", { required: "Title is required" })}
        />
        <small className='form-error-message'>
          {errors?.expenseTitle && errors.expenseTitle.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="expenseDescription" className="form-label">Description</label>
        <input type="text" className="form-control" id="expenseDescription" tabIndex={2}
          defaultValue={isFormEdit ? selectedItem?.expenseDescription || '' : ''}
          {...register("expenseDescription", { required: "Description is required" })}
        />
        <small className='form-error-message'>
          {errors?.expenseDescription && errors.expenseDescription.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="taxType" className="form-label">Tax Type:</label>
        <select className="form-select" id="taxType" defaultValue={defaultTaxCode} tabIndex={3} {...register("taxType", { onChange: (e) => { setDefaultTaxCode(e); setDefaultRate(e) }, valueAsNumber: true, required: "Tax Type is required" })}>
          <option key="none" value="" hidden>Tax Type</option>
          {TAX_TYPES.map((tax, index) =>
            <option key={index} value={tax.value}>{tax.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.taxType && errors.taxType.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultRate" className="form-label">Default Rate:</label>
        <Controller
          control={control}
          name='defaultRate'
          register={register}
          rules={{ valueAsNumber: true }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat value={value} name={name} onChange={onChange} onBlur={onBlur} tabIndex={4} className='form-control' suffix="%" thousandsGroupStyle="thousand" thousandSeparator="," id="defaultRate" mask="_" />
          )}
        />
        <small className='form-error-message'>
          {errors?.defaultRate && errors.defaultRate.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="glCodeId" className="form-label">GL Code:</label>
        <select className="form-select" id="glCodeId" value={gLCode} tabIndex={5} {...register("glCodeId", { valueAsNumber: true, onChange: (e) => setGLCode(e.target.value), required: "GL Code is required" })}>
          <option key="none" value="" hidden>GL Code</option>
          {gLCodeList.map((gl, index) =>
            <option key={index} value={gl.glCodeId}>{gl.glCodeName} - {gl.description}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.glCodeId && errors.glCodeId.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="notes" className="form-label">Notes</label>
        <input type="text" className="form-control" id="notes" tabIndex={6}
          defaultValue={isFormEdit ? selectedItem?.notes || '' : ''}
          {...register("notes")} />
        <small className='form-error-message'>
          {errors?.notes && errors.notes.message}
        </small>
      </div>
      {
        (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
        <div className="col-12 mb-2 mt-3">
          <div className="form-check">
            <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={7}
              defaultChecked={getActiveDefaultValue}
              {...register("active")}
            />
            <label className="form-check-label align-middle " htmlFor="active"> Active</label>
            <small className='form-error-message'>
              {errors?.active && errors.active.message}
            </small>
          </div>
        </div>
      }
    </>
  );
}

export default ExpenseItemPrimaryDetails;
