import { combineReducers } from 'redux';
import globalReducer from './global/globalReducer';
import accountReducer from './account/accountReducer';
import authReducer from './auth/authReducer';
import customerReducer from './customer/customerReducer';
import contactReducer from './contact/contactReducer';
import quoteReducer from './quote/quoteReducer';
import invoiceReducer from './invoice/invoiceReducer';
import payrollRunLineItemsReducer from './payrollRunLineItems/payrollRunLineItemsReducer';
import glCodeReducer from './glCode/glCodeReducer';
import settingsReducer from './settings/settingsReducer';
import reportTemplateReducer from './reportTemplate/reportTemplateReducer';
import truckReducer from './truck/truckReducer';
import truckMaintenanceReducer from './truckMaintenance/truckMaintenanceReducer';
import trailerReducer from './trailer/trailerReducer';
import trailerMaintenanceReducer from './trailerMaintenance/trailerMaintenanceReducer';
import driverReducer from './driver/driverReducer';
import orderReducer from './order/orderReducer';
import accessorialReducer from './accessorial/accessorialReducer';
import loadItemsReducer from './loadItems/loadItemsReducer';
import terminalReducer from './terminal/terminalReducer';
import legReducer from './leg/legReducer';


const rootReducers = combineReducers({
  global: globalReducer,
  auth: authReducer,
  account: accountReducer,
  customer: customerReducer,
  contact: contactReducer,
  quote: quoteReducer,
  invoice: invoiceReducer,
  payrollRunLineItems: payrollRunLineItemsReducer,
  glCode: glCodeReducer,
  settings: settingsReducer,
  reportTemplate: reportTemplateReducer,
  truck: truckReducer,
  truckMaintenance: truckMaintenanceReducer,
  trailer: trailerReducer,
  trailerMaintenance: trailerMaintenanceReducer,
  driver: driverReducer,
  order: orderReducer,
  accessorial: accessorialReducer,
  loadItems: loadItemsReducer,
  terminal: terminalReducer,
  leg: legReducer,
});

export default rootReducers;
