import React from 'react'
import { formatDate, formatDateOnly, formatCurrency, formatPhoneNumber } from '../../utils/formatUtils';

function QuoteDetails({
  name,
  email,
  phone,
  address,
  estimateNo,
  estimateName,
  estimateDate,
  subtotal,
  discount,
  total,
  tax,
  printMode,
  printQuoteItems,
  setIsOpen
}) {
  return (
    <div className='content-header-container quote-details-container row'>
      <div className={`${printMode ? 'col-6' : 'col-12'} col-lg-6 mb-2`}>
        <div className='quote-details-container color-3  shadow-sm'>
          <div className="content-title bolded mb-3">
            Customer
          </div>
          <div className='row quote-details'>
            <div className="col-12">
              <div className="row">
                <div className='col-1'>
                  <p className='mb-2 fw-bold'>Name:</p>
                </div>
                <div className='col-11'>
                  <p className="mb-2 content">{name}</p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className='col-1'>
                  <p className='mb-2 fw-bold'>Email:</p>
                </div>
                <div className='col-11'>
                  <p className="mb-2 content">{email}</p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className='col-1'>
                  <p className='mb-2 fw-bold'>Phone:</p>
                </div>
                <div className='col-11'>
                  <p className="mb-2 content">{phone}</p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className='col-1'>
                  <p className='mb-2 fw-bold'>Address:</p>
                </div>
                <div className='col-11'>
                  <p className="mb-2 content">{address}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${printMode ? 'col-6' : 'col-12'} col-lg-6 mb-2`}>
        <div className="quote-details-container color-3 shadow-sm">
          <div className="content-title bolded d-flex flex-row align-items-center mb-3 my-auto">
            Quote Details
            <p className='fw-bold ms-auto me-2 my-auto'>Created by: Account User</p>

            <button className="btn btn-color-3 me-2 " onClick={printQuoteItems}>Print</button>
            <button className="btn btn-color-3 " onClick={() => setIsOpen(true)}>Send Email</button>
          </div>
          <div className='row quote-details'>
            <div className="col-6">
              <div className="row">
                <div className='col-3'>
                  <p className='mb-2 fw-bold'>Est. No.:</p>
                </div>
                <div className='col-9'>
                  <p className="mb-2 content">{estimateNo}</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row align-item">
                <div className='col-3'>
                  <p className='mb-2 fw-bold'>Subtotal:</p>
                </div>
                <div className='col-4 justify-content-end d-flex'>
                  <p className="mb-2 content">{formatCurrency(subtotal)}</p>
                </div>
              </div>
            </div>
            {/* <div className="col-4"></div> */}
            <div className="col-6">
              <div className="row">
                <div className='col-3'>
                  <p className='mb-2 fw-bold'>Est. Name:</p>
                </div>
                <div className='col-9'>
                  <p className="mb-2 content">{estimateName}</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className='col-3'>
                  <p className='mb-2 fw-bold'>Discount:</p>
                </div>
                <div className='col-4 justify-content-end d-flex'>
                  <p className="mb-2 content">{formatCurrency(discount)}</p>
                </div>
              </div>
            </div>
            {/* <div className="col-4"></div> */}
            <div className="col-6">
              <div className="row">
                <div className='col-3'>
                  <p className='mb-2 fw-bold'>Est. Date:</p>
                </div>
                <div className='col-9'>
                  <p className="mb-2 content">{formatDateOnly(estimateDate)}</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className='col-3'>
                  <p className='mb-2 fw-bold'>Tax:</p>
                </div>
                <div className='col-4 justify-content-end d-flex'>
                  <p className="mb-2 content">{formatCurrency(tax)}</p>
                </div>
              </div>
            </div>
            <div className="col-6"></div>
            <div className="col-6">
              <div className="row">
                <div className='col-3'>
                  <p className='mb-2 fw-bold total'>Total:</p>
                </div>
                <div className='col-4 justify-content-end d-flex'>
                  <p className="mb-2 total">{formatCurrency(total)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuoteDetails