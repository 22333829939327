import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedContact = {},
  control,
  register,
  errors,
  setValue,
  reset,
  provinceStateRef,
  customerId,
  setCustomerId,
  customerName,
  setCustomerName,
  customerEmail,
  setCustomerEmail,
}) {

  const userType = useSelector((state) => state.auth.user.userType);
  const [provinceState, setProvinceState] = useState("");
  const [country, setCountry] = useState("");
  const provinceStateOptions = PROVINCE_STATE;

  useEffect(() => {
    if (isFormEdit) {
      const provinceOrState = PROVINCE_STATE.find((provinceState) => provinceState.value === selectedContact["provinceState"]);
      setCustomerName(selectedContact.customer["customerName"]);
      setCustomerEmail(selectedContact.customer["defaultEmail"]);
      setCustomerId(selectedContact.customer["customerId"]);
      setProvinceState(provinceOrState);
      setCountry(parseInt(selectedContact['country']));
      setValue("provinceState", provinceOrState);
      setValue("customerId", parseInt(selectedContact['customerId']));
      setValue("active", selectedContact['active']);
      setValue("phone", selectedContact['phone']);
      setValue("fax", selectedContact['fax']);
      setValue("country", parseInt(selectedContact['country']));
    } else {
      setProvinceState("");
      setCustomerName();
      setCustomerEmail();
      setCustomerId();
      reset();
    }

  }, [selectedContact["contactId"]])

  const setCountryValue = (e) => {
    const provinceStateValue = e?.value;
    if (provinceStateValue != null) {
      const firstUSState = PROVINCE_STATE.find((provinceState) => provinceState.label === "Alabama").value;
      setValue("country", provinceStateValue >= firstUSState ? 2 : 1);
      setCountry(provinceStateValue >= firstUSState ? 2 : 1);
    }
  }

  const getActiveDefaultValue = () => {
    if (selectedContact.length <= 0) {
      return 'true';
    }

    return selectedContact.active ? 'true' : '';
  }

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="customerName" className="form-label">Customer Name:</label>
        <input type="text" className="form-control" id="customerName" tabIndex={1}
          defaultValue={customerName}
          disabled
        />
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="customerEmail" className="form-label">Customer Email:</label>
        <input type="text" className="form-control" id="customerEmail" tabIndex={1}
          defaultValue={customerEmail}
          disabled
        />
      </div>

      <div className="col-12 my-2">
        <hr></hr>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="firstName" className="form-label">First Name <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="firstName" tabIndex={2}
          defaultValue={isFormEdit ? selectedContact?.firstName || '' : ''}
          {...register("firstName", { required: "First Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.firstName && errors.firstName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="lastName" className="form-label">Last Name <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="lastName" tabIndex={3}
          defaultValue={isFormEdit ? selectedContact?.lastName || '' : ''}
          {...register("lastName", { required: "Last Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.lastName && errors.lastName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="email" className="form-label">Email <span className="required-asterisk">*</span></label>
        <input type="email" className="form-control" id="email" tabIndex={4}
          defaultValue={isFormEdit ? selectedContact?.email || '' : ''}
          autoComplete='on'
          {...register("email", { required: "Email is required" })}
        />
        <small className='form-error-message'>
          {errors?.email && errors.email.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="phone" className="form-label">Phone <span className="required-asterisk">*</span></label>
        <Controller
          control={control}
          name='phone'
          register={register}
          rules={{ required: "Phone is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} tabIndex={5} getInputRef={ref} className='form-control' format="(###) ###-####" id="phone" mask="_" allowEmptyFormatting autoComplete='on' />
          )}
        />
        <small className='form-error-message'>
          {errors?.phone && errors.phone.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="mobile" className="form-label">Mobile</label>
        <Controller
          control={control}
          name='cell'
          register={register}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              defaultValue={isFormEdit ? selectedContact?.cell || '' : ''}
              value={isFormEdit ? selectedContact?.cell || '' : ''}
              onChange={onChange}
              onBlur={onBlur}
              tabIndex={6}
              className='form-control'
              format="(###) ###-####"
              id="mobile"
              mask="_"
              allowEmptyFormatting

            />
          )}
        />
        <small className='form-error-message'>
          {errors?.cell && errors.cell.message}
        </small>
      </div>

      <div className="col-12 my-2">
        <hr></hr>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="street" className="form-label">Street</label>
        <input type="text" className="form-control" id="street" tabIndex={8}
          defaultValue={isFormEdit ? selectedContact?.street || '' : ''}
          {...register("street")}
        />
        <small className='form-error-message'>
          {errors?.street && errors.street.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="city" className="form-label">City</label>
        <input type="text" className="form-control" id="city" tabIndex={9}
          defaultValue={isFormEdit ? selectedContact?.city || '' : ''}
          {...register("city")}
        />
        <small className='form-error-message'>
          {errors?.city && errors.city.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="provinceState" className="form-label">Province/State</label>
        <div className="custom-selector">
          <Controller
            name="provinceState"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => { field.onChange(e); setCountryValue(e) }}
                ref={provinceStateRef}
                defaultValue={provinceState}
                options={provinceStateOptions}
                className="company-select"
                classNamePrefix="react-select"
                placeholder={'Province/State'}
                id="companyName"
                styles={selectStyle}
                isClearable
                tabIndex={10}
              />
            )}
          />
        </div>
        <small className='form-error-message'>
          {errors?.companyName && errors.companyName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="postalCode" className="form-label">Postal Code</label>
        <input type="text" className="form-control" id="postalCode" tabIndex={11}
          defaultValue={isFormEdit ? selectedContact?.postalCode || '' : ''}
          {...register("postalCode")}
        />
        <small className='form-error-message'>
          {errors?.postalCode && errors.postalCode.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="country" className="form-label">Country: <span className="required-asterisk">*</span></label>
        <select
          className="form-select"
          id="country"
          value={country}
          tabIndex={12}
          {...register("country", { valueAsNumber: true, onChange: (e) => setCountry(e.target.value), required: "Country is required" })}
        >
          <option key="none" value="" hidden>Country</option>
          {COUNTRY.map((country, index) =>
            <option key={index} value={country.value}>{country.label}</option>
          )}
        </select>
        <small className='form-error-message'>
          {errors?.country && errors.country.message}
        </small>
      </div>

      {
        (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
        <div className="col-12 mb-2 mt-3">
          <div className="form-check">
            <input className="form-check-input align-middle" type="checkbox" value="" id="active"
              defaultChecked={getActiveDefaultValue} tabIndex={13}
              {...register("active")}
            />
            <label className="form-check-label align-middle " htmlFor="active"> Active</label>
            <small className='form-error-message'>
              {errors?.active && errors.active.message}
            </small>
          </div>
        </div>
      }

    </>
  );
}

export default PrimaryDetails;
