import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from "react-datepicker";
import { PAY_FREQUENCY, VACATION_TYPE, PAY_METHOD_TYPE, TD1_PROVINCE, TD2_FEDERAL } from '../../constants';
import { fetchGLCodesByUserType } from '../../services/glCodeServices';
import { addPayrollSettings, updatePayrollSettings } from '../../services/payrollSettingsServices';
import { removeNumberFormat } from '../../utils/formatUtils';
import moment from 'moment';
import { setPayrollSettingsData } from '../../redux/settings/settingsReducer';
import { isNTMSUSer, isAccountUser } from '../../utils/roleUtils';
import { fetchApprovedPayrollRunsFiltered } from '../../services/payrollRunServices';
import { ConfirmationModal } from '../Commons/Modals/ConfirmationModal';
import PrimaryDetails from './PrimaryDetails';
import FormTabs from '../Commons/Layouts/FormTabs';
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";

function PayrollSettingsForm({
  isFormEdit = false,
  setIsFormEdit,
  isFormHidden,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const [payrollSettings, setPayrollSettings] = useState();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const settingsState = useSelector((state) => state.settings);
  const isInitialRender = useRef(true);

  const [gLCodeList, setGLCodeList] = useState([]);
  const [disabledFieldCheck, setDisabledFieldCheck] = useState(false);
  const [editableAccountSettingsCheck, setEditableAccountSettingsCheck] = useState(false);
  const [disabledPayrollStartDateCheck, setDisabledPayrollStartDateCheck] = useState(false);
  const [disabledPayrollPeriodCheck, setDisabledPayrollPeriodCheck] = useState(false);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const [payrollStartDateModal, setPayrollStartDateModal] = useState(false);
  const payrollStartDateToggle = () => setPayrollStartDateModal(!payrollStartDateModal);
  const [payrollPeriodModal, setPayrollPeriodModal] = useState(false);
  const payrollPeriodToggle = () => setPayrollPeriodModal(!payrollPeriodModal);

  // // useEffects
  useEffect(() => {
    clearErrors();
    fillGLCode();
    checkApprovedPayrollRuns();
    setFocus("")
    setFocus("tD1Province");
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
    } else {
      dispatch(setFormPendingCompletion(false));
    }

  }, [formState])

  useEffect(() => {
    setPayrollSettings(settingsState.payrollSettings);

    if (!_.isEmpty(settingsState.payrollSettings)) {
      setDisabledFieldCheck(true);
      setDisabledPayrollStartDateCheck(true);
      setDisabledPayrollPeriodCheck(true);
      setDisabledFieldCheck(true);
      setIsFormEdit(true);
    }
  }, [settingsState.payrollSettings]);

  const fillGLCode = async (id) => {
    const accountTypeId = parseInt(process.env.REACT_APP_ACCOUNT_TYPE_COST_OF_GOODS_SOLD);
    let response = await fetchGLCodesByUserType(userType, selectedAccountState.accountId, accountId, accountTypeId)
    setGLCodeList(response);
  }

  const checkApprovedPayrollRuns = async () => {
    let response = null;
    if (!isAccountUser(userType)) {
      if (isNTMSUSer(userType)) {
        response = selectedAccountState.accountId === null ? await fetchApprovedPayrollRunsFiltered(accountId) : await fetchApprovedPayrollRunsFiltered(selectedAccountState.accountId);
      }
      else {
        response = await fetchApprovedPayrollRunsFiltered(accountId);

      }
    }
    const returnedData = await response;

    setEditableAccountSettingsCheck(returnedData);
  }

  const onSubmit = async (payload) => {
    let response = null;
    _.set(payload, 'overtimePaymentRate', removeNumberFormat(payload.overtimePaymentRate) / 100);
    var payrollStartDate = moment(payload.payrollStartDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'payrollStartDate', payrollStartDate);
    
    if (isFormEdit) {
      _.set(payload, 'payrollSettingsId', payrollSettings.payrollSettingsId);
      _.set(payload, 'accountId', payrollSettings.accountId);

      response = await updatePayrollSettings(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } default payroll settings.`,
        })
      );
    } else {
      _.set(payload, 'payrollSettingsId', 0);
      if (isNTMSUSer(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }

      response = await addPayrollSettings(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } default payroll settings.`,
        })
      );
    }
    if (response.ok) {
      dispatch(setPayrollSettingsData(await response.json()));
      reset({}, { keepValues: true });
      dispatch(setFormPendingCompletion(false));
      setIsFormEdit(true);
      setFocus("tD1Province");
    }
  }

  const acceptEditPayrollStartDate = () => {
    setDisabledPayrollStartDateCheck(!disabledPayrollStartDateCheck);
    payrollStartDateToggle();
  }
  const acceptEditPayrollPeriod = () => {
    setDisabledPayrollPeriodCheck(!disabledPayrollPeriodCheck);
    payrollPeriodToggle();
  }

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-6-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              // setIsFormDirty={setIsFormDirty}
              payrollSettings={payrollSettings}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              disabledFieldCheck={disabledFieldCheck}
              gLCodeList={gLCodeList}
              disabledPayrollStartDateCheck={disabledPayrollStartDateCheck}
              editableAccountSettingsCheck={editableAccountSettingsCheck}
              payrollStartDateToggle={payrollStartDateToggle}
              disabledPayrollPeriodCheck={disabledPayrollPeriodCheck}
              payrollPeriodToggle={payrollPeriodToggle}
            // provinceStateRef={provinceStateRef}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <form id="payroll-settings-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs
          items={tabItems}
          tabClassName="color-6"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>

      <ConfirmationModal
        isOpen={payrollStartDateModal}
        toggle={payrollStartDateToggle}
        onClick={acceptEditPayrollStartDate}
        headerText="Change Payroll Start Date"
        bodyText="Are you sure you want to change this? This will delete your drafted payroll run."
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="danger"
      />
      <ConfirmationModal
        isOpen={payrollPeriodModal}
        toggle={payrollPeriodToggle}
        onClick={acceptEditPayrollPeriod}
        headerText="Change Payroll Period"
        bodyText="Are you sure you want to change this? This will delete your drafted payroll run."
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="danger"
      />
    </>
  )
}

export default PayrollSettingsForm
