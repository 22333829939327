import React from 'react';
import { PieChart, Pie, Area, Cell, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { getTotalCustomersByMonth } from '../../../utils/dashboardUtils';
import { THEME_COLORS, CHART_CONSTANTS } from '../../../constants';

function Invoice({ customers }) {
  // Months are zero-indexed, so January starts with 0
  const data = [
    { name: 'Paid', value: Math.floor(Math.random() * 30) },
    { name: 'Unpaid', value: Math.floor(Math.random() * 30) },
    { name: 'Not Sent', value: Math.floor(Math.random() * 30) },
  ];
  const COLORS = [THEME_COLORS.COLOR_1, THEME_COLORS.COLOR_3, THEME_COLORS.COLOR_5];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={300} height={300}>
        <Pie
          data={data}
          cy={150}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={2}
          dataKey="value"
          startAngle={210}
          endAngle={-210}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          width='100%'
          layout='horizontal'
          wrapperStyle={{ fontSize: CHART_CONSTANTS.LEGEND_FONT_SIZE, lineHeight: CHART_CONSTANTS.LEGEND_LINE_HEIGHT }}
          formatter={(value, entry, index) => <span style={{color:CHART_CONSTANTS.LEGEND_TEXT_COLOR}}>{value}</span>}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default Invoice
