import { store } from "../redux/store";
import { isNTMSUSer } from '../utils/roleUtils';

export const getAccountIdOnSubmit = (userType, accountId, selectedAccountId = null) => {
  if(isNTMSUSer(userType)) {
    return selectedAccountId != null ? selectedAccountId : accountId
  }
  else {
    return accountId
  }
}