import React from 'react'
import { Flex, Spin } from 'antd';

function LoadingPage(props) {
  return (

    <div className='d-flex justify-content-center align-items-center mt-5'>
      <div className='small-container no-bg rounded'>
        {/* <div className='title text-center mb-4'>
          Loading Data
        </div> */}
        <div className="d-flex justify-content-center">
          {/* <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div> */}
          <Flex align="center" gap="middle">
            <Spin size="large" className="antd-spinner" />
          </Flex>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage