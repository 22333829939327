import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash';
import { isSuperAdmin, isNTMSUSer, isAccountAdmin  } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { addQuote, updateQuote } from '../../services/quoteServices';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { addQuoteData, setQuoteData } from '../../redux/quote/quoteReducer';
import PrimaryDetails from './PrimaryDetails';
import FormTabs from '../Commons/Layouts/FormTabs';
import { setFormPendingCompletion } from '../../redux/global/globalReducer';
import { setNotificationData } from '../../redux/global/globalReducer';
import moment from 'moment';

function QuoteForm({
  isFormEdit = false,
  selectedQuote = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { 
    register, 
    handleSubmit, 
    setValue, 
    clearErrors, 
    reset, 
    setFocus, 
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const customerState = useSelector((state) => state.customer);
  const [customerId, setCustomerId] = useState(selectedQuote['customerId'])
  const accountState = useSelector((state) => state.account.selectedAccount);
  const userType = useSelector((state) => state.auth.user.userType);
  const isInitialRender = useRef(true);

  // Form Values
  setValue('accountId', accountId);

  // useStates
  const [customerList, setCustomerList] = useState([]);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    setCustomerList(customerState.data);
  }, [customerState.data]);

  useEffect(() => {
    clearErrors();
    reset();
    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("customerId");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])

  const onSubmit = async (payload) => {
    let response = null;
    if (isFormEdit) {
      _.set(payload, 'quoteId', selectedQuote.quoteId);
      _.set(payload, 'accountId', selectedQuote.accountId);
      response = await updateQuote(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${
            response.ok ? "Successfully saved" : "Failed to save"
          } quote.`,
        })
      );
    } else {
      _.set(payload, 'quoteId', 0);
      if (isNTMSUSer(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      var quoteDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
      _.set(payload, 'quoteDate', quoteDate);
      response = await addQuote(payload);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${
            response.ok ? "Successfully saved" : "Failed to save"
          } quote.`,
        })
      );
    }

    if (response.ok && !isFormEdit) {
      const quoteData = await response.json();
      dispatch(isFormEdit ? setQuoteData(quoteData) : addQuoteData(quoteData));
      navigate("/quotes/edit-quote", { state: { quoteId: quoteData.quoteId } });
      return;
    }

    toggleFormDisplay();
  };

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-3-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedQuote={selectedQuote}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              customerList={customerList}
            />
          </div>
        </div>
      ),
    }
  ];

  return (
    <>
      <form id="quote-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTabs
          items={tabItems}
          tabClassName="color-3"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>

      <ModalRender
        modal={modal}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setModal={setModal}
        toggle={toggle}
        isFormEdit={isFormEdit}
        formType={"quote"}
        toggleFormDisplay={toggleFormDisplay}

      />
    </>
  )
}

export default QuoteForm
