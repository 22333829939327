import React from 'react'

function DefaultTemplateRenderer(props) {
  const status = props.data["Default"]

  let label = ""
  let statusClass = "";
  switch (status) {
    case false:
      statusClass = "status-false";
      label = "False";
      break;
    case true:
      statusClass = "status-true";
      label = "True";
      break;
    default:
      break;
  }

  return (
    <div className={`d-flex justify-content-center align-items-center status-container ${statusClass}`}>
      <p className='status-text mb-0 fw-bold'>{label.toUpperCase()}</p>
    </div>
  )
}

export default DefaultTemplateRenderer