import React, { useState } from 'react'
import _ from 'lodash';
import { DeleteOutlined } from '@ant-design/icons';
import { ConfirmationModal } from '../../../Commons/Modals/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationData } from '../../../../redux/global/globalReducer';

function EditFormRenderer({
  api,
  data,
  toggleFormDisplay,
  deleteFunction = null,
  deleteReduxFunction = null,
  header = "",
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const editData = data["Edit"];
  const dispatch = useDispatch();

  const toggleDisplay = () => {
    toggleFormDisplay(true, editData.id);
  }

  const handleDeleteItem = async () => {
    var itemId = data?.Id
    const response = await deleteFunction(itemId)

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully deleted" : "Failed to delete"
          } ${header}.`,
      })
    );
    if (response.ok) {
      api.applyTransaction({ remove: [data] });
      dispatch(deleteReduxFunction({ itemId }));
      toggle();
    }
  };

  return (
    <div className='cell-renderer-content d-flex flex-row-reverse'>
      {
        editData?.formType === undefined &&
        <button
          className={`btn btn-edit ${_.isNil(editData.btnClass) ? "btn-primary" : editData.btnClass}`}
          type="submit"
          onClick={() => toggleDisplay()}
        >
          <i className='fa-solid fa-pen'></i>
          <span>    Edit</span>
        </button>
      }

      {
        editData?.formType === "Order" &&
        <>
          {!(editData.status > 1) &&
            <button onClick={toggle} type='button' className={`btn btn-color-delete btn-sm`}><DeleteOutlined /></button>
          }
          <button
            className={`btn ${editData.status > 1 ? 'btn-sm' : 'btn-edit'} ${_.isNil(editData.btnClass) ? "btn-primary" : editData.btnClass}`}
            type="submit"
            onClick={() => toggleDisplay()}
          >
            <i className={`fa-solid ${editData.status > 1 ? 'fa-eye' : 'fa-pen'}`}></i>
            {editData.status < 2 && <span>    Edit</span>}
          </button>
        </>
      }
      <ConfirmationModal
            isOpen={modal}
            toggle={toggle}
            onClick={handleDeleteItem}
            headerText="Delete Accessorial"
            bodyText="Are you sure you want to delete this item?"
            confirmButtonText="Yes"
            cancelButtonText="No"
            buttonColor="primary"
            actionType={"delete"}
          />
    </div>
  )
}

export default EditFormRenderer
