import React, { useState } from "react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import ImagesForm from "./ImageSettingsForm";

function ImageSettings() {
  const parentNavItem = {
    title: "Settings",
    link: "/settings",
  };

  // useStates
  const [accountList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);

  return (
    <div>
      <>
        <div className="row mb-0">
          <div className="col-12">
            <ContentHeader
              title="Image Settings"
              hideSearch="true"
              hideTotal="true"
              hideButton={true}
              hideSubtitle={true}
              parentNavItem={parentNavItem}
            />
          </div>
        </div>
        <div className="content-body-container justify-content-center row">
          <div className={`col-5 mb-2`}>
            <ImagesForm isFormEdit={isFormEdit} setIsFormEdit={setIsFormEdit} />
          </div>
        </div>
      </>
    </div>
  );
}

export default ImageSettings;
