import quoteReducer from '../redux/quote/quoteReducer';
import { 
  QUOTE_REPORTS, 
  INVOICE_REPORTS, 
  INCOME_REPORTS, 
  ACCOUNTS_RECEIVABLE_REPORTS, 
  ACCOUNTS_PAYABLE_REPORTS,
  PAYROLL_REPORTS,
} from '../constants';

import {
  createQuotesReportFile,
  createInvoicesReportFile,
  createIncomeReportFile,
  createAccountsReceivableReportFile,
  createAccountsPayableReportFile,
  createPD7AReportFile,
  createEmployeePaystubReportFile,
  emailPD7AReport 
} from '../services/reportServices'
import _ from 'lodash';
import moment from 'moment';

export const getReportStatus = status => {
  var statusValue = null
  const statusList = {
    'Unsettled Quotes': 0,
    'Approved Quotes': 1,
    'Archived Quotes': 2,
    'Outstanding Invoices': 'unpaid',
    'Paid Invoices': 'paid'
  }
  statusValue = statusList[status]
  return statusValue || null
}

export const createReport = async (reportLabel, payload) => {
  if (_.some(QUOTE_REPORTS, { label: reportLabel })) {
    return await createQuotesReportFile(payload);
  }

  if (_.some(INVOICE_REPORTS, { label: reportLabel })) {
    return await createInvoicesReportFile(payload);
  }

  if (_.some(INCOME_REPORTS, { label: reportLabel })) {
    return await createIncomeReportFile(payload);
  }

  if (_.some(ACCOUNTS_RECEIVABLE_REPORTS, { label: reportLabel })) {
    return await createAccountsReceivableReportFile(payload);
  }

  if (_.some(ACCOUNTS_PAYABLE_REPORTS, { label: reportLabel })) {
    return await createAccountsPayableReportFile(payload);
  }

  if (_.some(PAYROLL_REPORTS, { label: reportLabel })) {
    if (reportLabel === "PD7A") {
      return await createPD7AReportFile(payload);
    }

    if (reportLabel === "Employee Paystubs") {
      return await createEmployeePaystubReportFile(payload);
    }
  }

  return null;
}

export const sendReport = async (reportLabel, payload) => {
  if (_.some(PAYROLL_REPORTS, { label: reportLabel })) {
    return await emailPD7AReport(payload);
  }

  return null;
}

export const singleDateCheck = (reportLabel) => {
  if (_.some(ACCOUNTS_RECEIVABLE_REPORTS, { label: reportLabel })) {
    return true;
  }

  if (_.some(ACCOUNTS_PAYABLE_REPORTS, { label: reportLabel })) {
    return true;
  }
  
  return false;
}
